import React from 'react';
import { Box } from '../Box';
import { Transition } from '../../styling';

const ModalBackdrop = ({ invisible = false, open, animations, disableAnimate = false, ...props }) => {
  const backdrop = (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      left={0}
      bg={invisible ? 'transparent' : 'rgba(0,0,0, 0.18)'}
      cursor="default"
      pointerEvents={'auto'}
      disableAnimationDefaults
      disableAnimate={disableAnimate}
      inert={true}
      focusable={
        false
      } /* <- TODO: should this be true? What is the standard for accessibility on onpress backdrops? if set to true make sure to pass isTop and set to false when not top modal */
      {...props}
    />
  );

  return !disableAnimate && !invisible ? (
    <Transition items={open} showHide animations={animations}>
      {backdrop}
    </Transition>
  ) : open ? (
    backdrop
  ) : null;
};

ModalBackdrop.displayName = 'ModalBackdrop';

ModalBackdrop.defaultProps = {
  animations: {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 500,
      friction: 30,
      clamp: true,
    },
  },
};

export { ModalBackdrop };
