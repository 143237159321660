import React, { useEffect } from 'react';
import { helpers } from '@turf/turf';
import { withMap } from './Contexts';
import { ShapeSource } from './ShapeSource';
import { SymbolLayer } from './SymbolLayer';
import { loadImage } from './Images';

// NOTE: Custom implementation needed because MarkerView renders poorly on Android
// Instead using a SymbolLayer with ShapeSource. Fixes android issue and improves performance
const Marker = withMap(
  React.forwardRef(function Marker(props, ref) {
    const {
      iconImage = 'defaultMarker',
      map,
      iconSize = 0.5,
      iconOffset = null, // [0, 0]
      iconOpacity = 1,
      text = '',
      textOffset = [0, 0.75],
      textSize = 15,
      textColor = 'white',
      id,
      children,
      coordinate,
      ...rest
    } = props;

    useEffect(() => {
      if (!map || !map.mounted || !map.mounted.current || !iconImage) {
        return;
      }
      loadImage(null, iconImage, map);
    }, [iconImage, map]);

    let lat = null;
    let lng = null;
    if (Array.isArray(coordinate) && coordinate.length === 2) {
      lng = coordinate[0];
      lat = coordinate[1];
    }

    const featureCollection = React.useMemo(() => {
      if (lat !== null && lng !== null) {
        const point = helpers.point([lng, lat]);
        const collection = helpers.featureCollection([point]);
        return collection;
      }
      return null;
    }, [lat, lng]);

    const symbolStyle = React.useMemo(() => {
      let offset = iconOffset;
      if (!iconOffset) {
        if (iconImage === 'defaultMarker') {
          offset = [0, -40];
        } else {
          offset = [0, 0];
        }
      }
      let textStyle = null;
      if (text) {
        textStyle = {
          textField: `${text}`,
          textSize,
          textColor,
          textOffset,
          textAllowOverlap: true,
        };
      }
      return {
        iconImage,
        iconSize,
        iconOffset: offset,
        iconOpacity,
        iconAllowOverlap: true,
        ...textStyle,
      };
    }, [iconImage, iconSize, iconOffset, iconOpacity, text, textSize, textColor, textOffset]);

    if (featureCollection && iconImage) {
      return (
        <ShapeSource id={id} shape={featureCollection} ref={ref} {...rest}>
          <SymbolLayer id={`${id}symbol`} style={symbolStyle} />
        </ShapeSource>
      );
    }
    return null;
  })
);

export { Marker };
