import React, { useRef } from 'react';
import { Box } from '../Box';
import { Text } from '../Text';
import { withStyles } from '../../styling';
import { padding } from '../../system/props';
import { merge } from 'merge-anything';
import { isObject } from '../../utils';
import { useForkRef, useLayoutEffect } from '../../hooks';

const filterProps = ['justifyContent', 'alignItems', 'alignSelf', ...padding.filterProps];

const MenuItem = withStyles(
  (props) => {
    const { px = '$3', py = '$2', justifyContent = 'flex-start', alignItems = 'flex-start', alignSelf = 'stretch', selected = false } = props;

    return {
      root: {
        justifyContent,
        alignItems,
        alignSelf,
        ...padding({ ...props, px, py }),
        animations: merge(
          {
            hovered: {
              bg: `rgba(0, 0, 0, ${selected ? 0.12 : 0.08})`,
            },
            focused: {
              bg: `rgba(0, 0, 0, ${selected ? 0.12 : 0.08})`,
              config: {
                duration: 25,
              },
            },
            pressed: {
              bg: `rgba(0, 0, 0, ${selected ? 0.14 : 0.1})`,
            },
            default: {
              bg: `rgba(0, 0, 0, ${selected ? 0.1 : 0})`,
            },
          },
          props.animations && isObject(props.animations) ? props.animations : {}
        ),
      },
      text: {},
    };
  },
  { name: 'MenuItem', filterProps }
)(
  React.forwardRef(function MenuItem(props, ref) {
    const { children, selected, TextProps, accessibility = {}, styles, role = 'menuitem', autoFocus = false, value, ...rest } = props;

    const itemRef = useRef();

    useLayoutEffect(() => {
      if (autoFocus && itemRef.current) {
        itemRef.current.focus();
      }
    }, [autoFocus]);

    const handleRef = useForkRef(itemRef, ref);

    return (
      <Box
        ref={handleRef}
        disableAnimationDefaults
        focusable
        accessibility={{
          accessibilityRole: role,
          accessibilitySelected: selected,
          ...accessibility,
          accessibilityState: {
            selected,
            ...accessibility.accessibilityState,
          },
        }}
        focused={autoFocus ? false : undefined}
        {...rest}
      >
        {typeof children === 'string' ? (
          <Text noWrap style={styles.text} value={value} {...styles.props.text} {...TextProps}>
            {children}
          </Text>
        ) : (
          children
        )}
      </Box>
    );
  })
);

export { MenuItem };
