import { isNull, isObject } from '../../ui/utils';
import { MIN_MAX_RANGE, DATES_FROM_TO, SELECT, MULTI_SELECT, SWITCH, CHECKBOX } from '../../constants/searchData/filterTypes';
import { getBooleanFromQueryValue, getRangeFromQueryString } from './queryUtils';
import moment from 'moment-timezone';

export function transformRangeField({ field, value }, asDate = false) {
  const range = getRangeFromQueryString(value);
  const filters = [];
  const r0 = !isNull(range[0]) ? (asDate ? moment(range[0]) : range[0]) : null;
  const r1 = !isNull(range[1]) ? (asDate ? moment(range[1]) : range[1]) : null;
  const isValid = (val) => {
    if (val !== null) {
      if (asDate) {
        if (val.isValid()) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  };
  if (isValid(r0)) {
    filters.push({ field, value: asDate ? r0.format('YYYY-MM-DD') : r0, operator: 'gte' });
  }
  if (isValid(r1)) {
    filters.push({ field, value: asDate ? r1.format('YYYY-MM-DD') : r1, operator: 'lte' });
  }
  if (!filters.length) {
    return null;
  }
  return filters;
}

export function transformSelectField({ field, value, operator = 'equals' }, fieldProp) {
  if (fieldProp.options && fieldProp.options[value]) {
    if (fieldProp.options[value].fieldValue !== undefined) {
      if (Array.isArray(fieldProp.options[value].fieldValue)) {
        if (!fieldProp.options[value].fieldValue.length) {
          return null;
        }
        return fieldProp.options[value].fieldValue;
      }
      if (isObject(fieldProp.options[value].fieldValue) && fieldProp.options[value].fieldValue.field) {
        return fieldProp.options[value].fieldValue;
      }
      return { field, value: fieldProp.options[value].fieldValue, operator };
    }
    if (!isNull(fieldProp.options[value].value)) {
      return { field, value: fieldProp.options[value].value, operator };
    }
  } else if (fieldProp.options) {
    for (const key in fieldProp.options) {
      const opt = fieldProp.options[key];
      if (opt && isObject(opt) && opt.value === value) {
        if (opt.fieldValue !== undefined) {
          if (Array.isArray(opt.fieldValue)) {
            if (!opt.fieldValue.length) {
              return null;
            }
            return opt.fieldValue;
          }
          if (isObject(opt.fieldValue) && opt.fieldValue.field) {
            return opt.fieldValue;
          }
          return { field, value: opt.fieldValue, operator };
        }
        if (!isNull(opt.value)) {
          return { field, value: opt.value, operator };
        }
      }
    }
  }
  return null;
}

export function transformMultiSelectField({ field, value, operator = 'equals' }, fieldProp) {
  const strValue = typeof value === 'string' ? value.trim() : null;
  if (strValue) {
    const arrValues = strValue.split(',');
    let filters = [];
    for (const val of arrValues) {
      const parsed = transformSelectField({ field, value: val, operator }, fieldProp);
      if (parsed) {
        if (Array.isArray(parsed) && parsed.length) {
          filters = [...filters, ...parsed];
        } else {
          filters.push(parsed);
        }
      }
    }
    if (filters.length) {
      return filters;
    }
  }
  return null;
}

export function transformSwitchField({ field, value, operator = 'equals' }, fieldProp) {
  if (isNull(value)) {
    return null;
  }
  const on = getBooleanFromQueryValue(value);
  if (fieldProp.options) {
    const filter = transformSelectField({ field, value: on ? 'on' : 'off', operator }, fieldProp);
    if (filter) {
      return filter;
    }
  }
  return on ? { field, value: on, operator } : null;
}

export function transformSearchField({ field, value, operator = 'ilike' }, fieldProp) {
  let strValue = !isNull(value) && typeof value === 'string' ? value.trim() : '';
  if (!strValue) {
    return null;
  }
  strValue = `%${value}%`;
  return { field, value: strValue, operator: operator ? operator : 'ilike' };
}

// takes one of the decoded items (fields from filter[], search[], or sort{}) from the URL search query
// returns what to actually have the value as for the api call
export const createDefaultTransformField = (fieldPropConstant, forWhat = 'filter') => {
  // change to transformField
  if (forWhat === 'search') {
    return ({ field, value, operator } = {}, overrideFieldProp) => {
      const fieldProp = overrideFieldProp ? overrideFieldProp : fieldPropConstant;
      return transformSearchField({ field, value, operator }, fieldProp);
    };
  }
  return ({ field, value, operator } = {}, overrideFieldProp) => {
    const fieldProp = overrideFieldProp ? overrideFieldProp : fieldPropConstant;
    if (value === undefined) {
      return null;
    }
    switch (fieldProp.type) {
      case MIN_MAX_RANGE: {
        return transformRangeField({ field, value });
      }
      case DATES_FROM_TO: {
        return transformRangeField({ field, value }, true);
      }
      case SELECT: {
        return transformSelectField({ field, value, operator }, fieldProp);
      }
      case MULTI_SELECT: {
        return transformMultiSelectField({ field, value, operator }, fieldProp);
      }
      case SWITCH: {
        return transformSwitchField({ field, value, operator }, fieldProp);
      }
      case CHECKBOX: {
        console.warn('dont use checkbox for now');
        return transformSwitchField({ field, value, operator }, fieldProp);
      }
      default:
    }
    if (value === null || value === 'null' || (typeof value === 'string' && !value.trim())) {
      return null;
    }
    return { field, value, operator };
  };
};
