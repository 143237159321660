import mergeOptions from 'merge-options';

/*
  Created from react-native-async-storage implementation to implmeent session storage in addition to local storage.
  see implementation from (v1.15.5) of original here: https://github.com/react-native-async-storage/async-storage/blob/6f7b459fc8c21aedc0c267b372ff2f591952fda6/src/AsyncStorage.js
*/

class SessionStorage {
  static getItem(key, callback) {
    return createPromise(() => {
      return window.sessionStorage.getItem(key);
    }, callback);
  }

  static setItem(key, value, callback) {
    return createPromise(() => {
      return window.sessionStorage.setItem(key, value);
    }, callback);
  }

  static removeItem(key, callback) {
    return createPromise(() => {
      return window.sessionStorage.removeItem(key);
    }, callback);
  }

  static mergeItem(key, value, callback) {
    return createPromise(() => {
      mergeSessionStorageItem(key, value);
    }, callback);
  }

  static clear(callback) {
    return createPromise(() => {
      return window.sessionStorage.clear();
    }, callback);
  }

  static getAllKeys(callback) {
    return createPromise(() => {
      const numberOfKeys = window.sessionStorage.length;
      const keys = [];
      for (let i = 0; i < numberOfKeys; i += 1) {
        const key = window.sessionStorage.key(i);
        keys.push(key);
      }
      return keys;
    }, callback);
  }

  static flushGetRequests() {}

  static multiGet(keys, callback) {
    const promises = keys.map((key) => SessionStorage.getItem(key));
    const processResult = (result) => result.map((value, i) => [keys[i], value]);
    return createPromiseAll(promises, callback, processResult);
  }

  static multiSet(keyValuePairs, callback) {
    const promises = keyValuePairs.map((item) => SessionStorage.setItem(item[0], item[1]));
    return createPromiseAll(promises, callback);
  }

  static multiRemove(keys, callback) {
    const promises = keys.map((key) => SessionStorage.removeItem(key));
    return createPromiseAll(promises, callback);
  }

  static multiMerge(keyValuePairs, callback) {
    const promises = keyValuePairs.map((item) => SessionStorage.mergeItem(item[0], item[1]));
    return createPromiseAll(promises, callback);
  }
}

const merge = mergeOptions.bind({ concatArrays: true, ignoreUndefined: true });

const mergeSessionStorageItem = (key, value) => {
  const oldValue = window.sessionStorage.getItem(key);
  const oldObject = JSON.parse(oldValue);
  const newObject = JSON.parse(value);
  const nextValue = JSON.stringify(merge(oldObject, newObject));
  window.sessionStorage.setItem(key, nextValue);
};

const createPromise = (getValue, callback) => {
  return new Promise((resolve, reject) => {
    try {
      const value = getValue();
      if (callback) {
        callback(null, value);
      }
      resolve(value);
    } catch (err) {
      if (callback) {
        callback(err);
      }
      reject(err);
    }
  });
};

const createPromiseAll = (promises, callback, processResult) => {
  return Promise.all(promises).then(
    (result) => {
      const value = processResult ? processResult(result) : null;
      callback && callback(null, value);
      return Promise.resolve(value);
    },
    (errors) => {
      callback && callback(errors);
      return Promise.reject(errors);
    }
  );
};

export { SessionStorage };
