import React, { useState, useRef } from 'react';
import { Platform } from 'react-native';
import { inputComponentVariants } from '../TextInput';
import { useControlledState, useEventCallback, useForkRef } from '../../hooks';
import { withStyles } from '../../styling';
import { useInputListItems } from './useInputListItems';

const ListInput = withStyles(
  {
    textInput: {},
    textBox: ({ vertical = false }) => {
      if (vertical) {
        return {
          flexWrap: 'nowrap',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        };
      }
      return null;
    },
    input: {},
  },
  { name: 'Select' }
)(
  React.forwardRef(function ListInput(props, ref) {
    const {
      value, // array, required
      inputValue: inputValueControlled,
      onChangeValue,
      onInputChangeValue,
      onFocus,
      onBlur,
      disabled = false,
      // item control props
      renderItem, // render the chips (not the text)
      getItemLabel, // if value array not of strings use this to indicate the label to render if not using renderItem
      focusedItem: focusedItemControlled = undefined,
      onChangeFocusedItem,
      itemProps = null,
      onAddItem,
      onRemoveItem,
      onUpdateItem,
      onItemKeyDown,
      onItemBlur,
      onItemFocus,
      onItemPress,
      onItemDelete,
      onKeyPress,
      // input props
      styles,
      startAdornment, // dont pass
      variant = 'filled',
      component = undefined,
      filled: filledProp = undefined,
      inputRef: inputRefProp,
      textBoxRef: textBoxRefProp,
      onPress,
      selectOnFocus = false,
      clearOnBlur = props.addInputValueOnBlur ? true : false,
      blurOnSubmit = false,
      addInputValueOnBlur = false,
      // TODO
      editable = false, // <- if true all items can be pressed and edited. if function (item) => false or true
      vertical = false, // if vertical than items are entered line by line instead of in a flexible grid
      ...rest
    } = props;

    const [inputValue, setInputValueState] = useControlledState({ controlledValue: inputValueControlled, defaultValue: '' });
    const [inputFocused, setInputFocused] = useState(false);
    const ignoreFocus = useRef(false);
    const firstFocus = useRef(true);
    const inputRef = useRef(null);
    const textBoxRef = useRef(null);
    const handleInputRef = useForkRef(inputRef, inputRefProp);
    const handleTextBoxRef = useForkRef(textBoxRef, textBoxRefProp);

    const focusInput = useEventCallback((force = false) => {
      if ((!inputFocused || force === true) && inputRef.current) {
        inputRef.current.focus();
      }
    });

    const blurInput = useEventCallback(() => {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    });

    const handleInputChangeValue = useEventCallback((newValue) => {
      if (inputValue !== newValue) {
        setInputValueState(newValue);
        if (onInputChangeValue) {
          onInputChangeValue(newValue);
        }
      }
    });

    const {
      items: listItems,
      focusedItem,
      addItem,
      handleKeyPress,
    } = useInputListItems({
      value,
      inputValue,
      inputFocused,
      disabled,
      focusInput,
      blurInput,
      onChangeValue,
      onChangeInputValue: handleInputChangeValue,
      getItemLabel,
      renderItem,
      onItemPress: (e, item) => {
        const { index = null } = item || {};
        if (onItemPress) {
          onItemPress(e, item);
        }
        if (inputFocused) {
          ignoreFocus.current = true;
        } else if (!inputFocused && index !== null && focusedItem !== index) {
          focusInput();
        }
      },
      onItemDelete: (...args) => {
        if (onItemDelete) {
          onItemDelete(...args);
        }
        if (inputFocused) {
          ignoreFocus.current = true;
        }
      },
      focusedItem: focusedItemControlled,
      onChangeFocusedItem,
      itemProps,
      onAddItem,
      onRemoveItem,
      onUpdateItem,
      onItemKeyDown,
      onItemBlur,
      onItemFocus,
      onKeyPress,
      editable,
      vertical,
    });

    const handleOnPressInput = useEventCallback((e) => {
      focusInput();
      if (
        firstFocus.current &&
        !ignoreFocus.current &&
        selectOnFocus &&
        inputRef.current &&
        inputRef.current.selectionEnd - inputRef.current.selectionStart === 0
      ) {
        inputRef.current.select();
      }
      firstFocus.current = false;
      if (onPress) {
        onPress(e);
      }
    });

    const handleInputOnFocus = useEventCallback((e) => {
      setInputFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    });

    const handleInputOnBlur = useEventCallback((e) => {
      if (Platform.OS === 'web' && !disabled && focusedItem === -1) {
        let shouldFocus = false;
        if (
          textBoxRef.current &&
          (textBoxRef.current.parentElement.contains(document.activeElement) ||
            (e && e.relatedTarget && textBoxRef.current.parentElement.contains(e.relatedTarget)))
        ) {
          shouldFocus = true;
        }
        if (shouldFocus) {
          focusInput(true);
          return;
        }
      }

      setInputFocused(false);
      firstFocus.current = true;
      ignoreFocus.current = false;

      if (addInputValueOnBlur && inputValue && inputValue.trim()) {
        addItem({ value: inputValue }, true);
      } else if (clearOnBlur && inputValue) {
        handleInputChangeValue('');
      }
      if (onBlur) {
        onBlur(e);
      }
    });

    const inputProps = {
      value: inputValue,
      onChangeValue: handleInputChangeValue,
      onBlur: handleInputOnBlur,
      onFocus: handleInputOnFocus,
      inputRef: handleInputRef,
      ref,
      onPress: handleOnPressInput,
      textBoxRef: handleTextBoxRef,
      disabled,
      styles,
      startAdornment: listItems,
      filled: filledProp !== undefined ? filledProp : value.length || inputValue,
      onKeyPress: handleKeyPress,
      blurOnSubmit,
      ...rest,
      multiline: false,
    };

    if (disabled && inputFocused) {
      blurInput();
    }

    const Component = component ? component : inputComponentVariants[variant];
    return <Component {...inputProps} />;
  })
);

export { ListInput, useInputListItems };
