import React from 'react';
import { Text } from '../Text';
import { withStyles } from '../../styling';

const HelperText = React.memo(
  withStyles(
    ({ error, inputVariant = 'underlined', onColor = '$background', theme }) => ({
      root: {
        padY: '$0.5',
        padX: inputVariant === 'underlined' ? 0 : '$1',
        maxWidth: '80%',
        color: error ? '$error' : theme.colors.alpha(theme.colors.on(onColor), 0.7),
        lineHeight: 0,
        cursor: 'default',
        props: {
          small: true,
        },
      },
    }),
    {
      name: 'TextField.HelperText',
      filterProps: ['error', 'inputVariant', 'focused', 'textBoxStyle', 'filled', 'disabled', 'endAdornment', 'startAdornment', 'hovered'],
    }
  )(Text)
);

export { HelperText };
