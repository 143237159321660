import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';
import { CollapseArrowIcon, ExpandArrowIcon } from '../../components/icons';
import { Box, Text, Heading, Button, Spacing, Map, Menu, MenuItem, FileInput } from '../../ui';
import { getBounds } from '../../ui/utils';
import { formatMoney, getAddressLines } from '../../utils';
import { useApi, useAsync, useNavigate } from '../../hooks';
import { TextData, NavBar, Page, Main, NavBackButton, ContentCard, NavRow, NavGroup, LayoutBox } from '../../components';
import { CoverageNames } from '../../constants';

export const PolicyPage = (props) => {
  const { policyId } = useParams();
  const { getPolicyWithId, getPolicyConcentrations } = useApi();
  const handleGetPolicy = useCallback(async () => {
    return await getPolicyWithId(policyId);
  }, [policyId, getPolicyWithId]);
  const handleGetPolicyConcentrations = useCallback(async () => {
    var cons = null;
    try {
      cons = await getPolicyConcentrations(policyId);
    } catch (error) {
      console.error(error);
    }
    return cons;
  }, [policyId, getPolicyConcentrations]);

  const { value: policy, status } = useAsync(handleGetPolicy);
  const { value: concentrations } = useAsync(handleGetPolicyConcentrations);

  const sites = useMemo(() => {
    let sites = [];
    if (policy && policy.locations && Array.isArray(policy.locations) && policy.locations.length) {
      for (const location of policy.locations) {
        sites.push(location);
        // if (location.site) {
        //   sites.push(location.site);
        // }
      }
    }
    return sites;
  }, [policy]);

  return (
    <>
      <NavBar>
        <Box width="100%" height={({ theme }) => theme.sizes.appBarHeight} />
        <NavRow>
          <NavGroup start flex={1}>
            <NavBackButton path="/policies" label="Back to Policies" />
          </NavGroup>
        </NavRow>
      </NavBar>
      <Page
        layout={status === 'pending' || !policy ? 'center' : 'top-center'}
        padTop={
          status === 'pending' || !policy
            ? 0
            : ({ theme: { breakpoints, sizes } }) => breakpoints({ xs: sites.length ? 200 : 36, sm: sites.length ? 400 : 48 })
        }
        {...props}
      >
        {status === 'pending' ? <ActivityIndicator /> : null}
        {status === 'error' ? <Text>Could not get policy information. Try refreshing the page.</Text> : null}
        {policy ? (
          <>
            <SitesMap sites={sites} />

            <Main layout="top-left" padBottom={164}>
              <Box flexDirection="row" zIndex={99} justifyContent="center" alignItems="center" width="100%">
                <Text
                  xSmall
                  bold
                  uppercase
                  padX="$2.25"
                  padY="$1"
                  bg="$brandOrange"
                  onColor={'$brandOrange'}
                  borderRadius={12}
                  style={{ fontWeight: 600 }}
                >
                  <Text fontWeight={300}>Station Status: </Text>Install ticket created
                </Text>
              </Box>

              <Spacing vertical={2} />
              <ContentCard padTop="$4" padBottom="$12" bg="white" overflow="visible">
                <Box
                  zIndex={-2}
                  borderRadius="inherit"
                  position="absolute"
                  bg="white"
                  width="100%"
                  height="50"
                  top={0}
                  left={0}
                  shadow={{ color: '$gray.900', offsetY: -30, opacity: sites.length ? 0.8 : 0, radius: 50 }}
                />
                <Box zIndex={-1} borderRadius="inherit" position="absolute" bg="white" width="100%" height="100%" flex={1} top={0} left={0} />
                <PolicyDetails policy={policy} concentrations={concentrations} />
              </ContentCard>
            </Main>
          </>
        ) : null}
      </Page>
    </>
  );
};

const SitesMap = ({ sites }) => {
  if (!sites || !sites.length) {
    return null;
  }
  return (
    <LayoutBox bg="$primary.dark" absolute width="100%" height={({ theme }) => theme.breakpoints({ xs: 300, sm: 500 })}>
      <LocationMap sites={sites} style={{ width: '100%', height: '100%' }} />
    </LayoutBox>
  );
};

const PolicyDetails = ({ policy, concentrations }) => {
  const {
    // id,
    insured,
    address,
    region,
    postcode,
    // broker: { name: brokerName },
    // agent: { name: agentName, email: agentEmail },
    // carrier: { name: carrierName },
    // product: { name: productName, productType },
    // product,
    payoutsEnabled,
    effectiveAt,
    effectiveUntil,
    // boundAt,
    policyNumber,
    sourcePolicyNumber,
    fees,
  } = policy;

  const addressLines = getAddressLines(address, region, postcode);
  // const productDisplayName = useMemo(() => {
  //   let { name: productName, productType } = product;
  //   if (productName && ProductNames[productName]) {
  //     productName = ProductNames[productName];
  //     if (!productType) {
  //       return productName;
  //     }
  //   }
  //   if (productType && ProductTypes[productType]) {
  //     productType = ProductTypes[productType];
  //     if (!productName) {
  //       return productType;
  //     }
  //   }
  //   return `${productType} - ${productName}`;
  // }, [product]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [concentrationsExpanded, setConcentrationsExpanded] = useState(true);
  const btnRef = useRef(null);
  const navigate = useNavigate();

  const totalFees = fees ? fees.map((f) => f.amount).reduce((a, b) => a + b, 0) : 0;
  const grossPremium = policy.netPremium + totalFees;

  const timezone = policy && policy.timezone ? policy.timezone : 'America/Chicago';

  var riskConcentrations = null;

  if (concentrations && policy && policy.locations) {
    const conKeys = Object.keys(concentrations);
    riskConcentrations = conKeys.map((k) => {
      const myLocation = policy.locations.find((l) => l.id === k);
      return { name: myLocation.name, riskId: myLocation.riskId, concentrations: concentrations[k] };
    });
    riskConcentrations.sort((a, b) => (a.riskId > b.riskId ? 1 : a.riskId < b.riskId ? -1 : 0));
  }

  const handleDownloadPolicy = useCallback(async () => {
    window.open(policy.downloadLink, '_blank');
  }, [policy.downloadLink]);
  const { execute: downloadPolicy } = useAsync(handleDownloadPolicy, { immediate: false });

  const { regeneratePolicy } = useApi();
  const handleRegenPolicy = useCallback(async () => {
    console.log(policy.policyId);
    // Call the API function
    const response = await regeneratePolicy(policy.id);
    return response;
  }, [regeneratePolicy, policy.id, policy.policyId]);
  const { execute: regenPolicy } = useAsync(handleRegenPolicy, { immediate: false });

  return (
    <Box mt="$-1">
      <Menu anchorNode={btnRef.current} open={menuOpen} onClose={() => setMenuOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <MenuItem onPress={() => navigate.to('/submitclaim')}>Submit Claim</MenuItem>
      </Menu>
      <LayoutBox row justifyContent="space-between" alignItems="center" width="100%" pb="$2.5" border={{ bottom: { width: 1, color: '$gray.200' } }}>
        <Box>
          <Box flexDirection="row" alignItems="center" gap={12}>
            <Box size="10" bg={payoutsEnabled ? '$secondary' : '$coral'} borderRadius="$circle" />
            <Text size="medium" color={payoutsEnabled ? '$secondary' : '$coral'} style={{ fontWeight: 600 }} padTop={2} uppercase>
              {payoutsEnabled ? 'Active' : 'Inactive'}
            </Text>
          </Box>

          <Text size="xSmall" bold color="$gray.400" style={{ fontWeight: 500 }} uppercase>{`#${sourcePolicyNumber || policyNumber}`}</Text>
        </Box>
        <LayoutBox row layout="center-right" gap={12}>
          <Button target="_blank" color="$primary" rel="noreferrer" onPress={regenPolicy}>
            Regenerate Policy
          </Button>
          <Button href={policy.downloadLink} target="_blank" color="$primary" rel="noreferrer" onPress={downloadPolicy}>
            Download Policy
          </Button>
          <Button color="$primary" outlined ref={btnRef} onPress={() => setMenuOpen((c) => !c)}>
            More Options
          </Button>
        </LayoutBox>
      </LayoutBox>
      <Box flexDirection="row" pt="$2" pb="$6" border={{ bottom: { width: 0, color: '$gray.200' } }}>
        <Box flexDirection="column" flex={1.5} pt="$2.5">
          <Heading level={3} padY="$0.5">
            {insured}
          </Heading>
          {Array.isArray(addressLines) ? (
            addressLines.map((a, i) => (
              <Text large key={`aaline${i}`}>
                {a}
              </Text>
            ))
          ) : (
            <Text large>{addressLines}</Text>
          )}
          <Box padTop="$3" flexDirection="row" alignSelf="flex-start">
            <TextData data={moment(effectiveAt).tz(timezone).format('MM-DD-YYYY')} label="Effective At" />
            <Spacing horizontal={6} />
            <TextData data={moment(effectiveUntil).tz(timezone).format('MM-DD-YYYY')} label="Effective Until" />
          </Box>
          <Box padTop="$3" flexDirection="row" alignSelf="flex-start">
            <TextData data={`$${formatMoney(policy.coveredValue)}`} label="Covered Value" />
            <Spacing horizontal={6} />
            <TextData data={`$${formatMoney(policy.netPremium)}`} label="Net Premium" />
          </Box>
          <Box padTop="$3" flexDirection="row" alignSelf="flex-start">
            <TextData data={`$${formatMoney(totalFees)}`} label="Total Fees" />
            <Spacing horizontal={6} />
            <TextData data={`$${formatMoney(grossPremium)}`} label="Gross Premium" />
          </Box>
          <Box padTop="$3" flexDirection="row" alignSelf="flex-start">
            <Text size="medium" color="$gray.500" bold={true}>
              Fees
            </Text>
          </Box>
          {fees.map((fee) => (
            <Box key={`fee-${fee.fee_id}`} padTop="$1" flexDirection="row" alignSelf="flex-start">
              <Text small style={{ width: '10em' }}>
                {fee.name}
              </Text>
              <Spacing horizontal={6} />
              <Text small>{formatMoney(fee.amount, { prefix: '$' })}</Text>
            </Box>
          ))}
        </Box>
        <Box flex={1} gap={24} alignItems="flex-end"></Box>
      </Box>
      <Spacing vertical={4} />
      <Attachments policy={policy} attachments={policy.attachments} />
      <LocationsAndCoverages locations={policy.locations} />
      {riskConcentrations ? (
        <>
          <Spacing vertical={4} />
          <Box width="100%" display={concentrationsExpanded ? 'flex' : 'none'} pt="$3">
            <Text size="small" bold={true}>
              Risk Concentrations by Location
            </Text>
            {riskConcentrations.map((loc) => (
              <div key={`rc-${loc.location_id}`}>
                <Spacing vertical={1} />
                <Text size="small" bold={true}>
                  {loc.riskId ? `${loc.riskId}. ${loc.name}` : loc.name}
                </Text>
                <table style={{ maxWidth: '35em' }}>
                  <thead>
                    <tr>
                      <td style={{ paddingRight: '1em', paddingLeft: '1em' }}>
                        <Text bold={true} size="small">
                          Radius
                        </Text>
                      </td>
                      <td style={{ textAlign: 'center', paddingRight: '1em', paddingLeft: '1em' }}>
                        <Text bold={true} size="small">
                          Max Allowed
                        </Text>
                      </td>
                      <td style={{ textAlign: 'center', paddingRight: '1em', paddingLeft: '1em' }}>
                        <Text bold={true} size="small">
                          Concentration
                        </Text>
                      </td>
                      <td style={{ textAlign: 'center', paddingRight: '1em', paddingLeft: '1em' }}>
                        <Text bold={true} size="small">
                          Flags
                        </Text>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {loc.concentrations.map((con, i) => (
                      <tr key={con.radius_mi} style={{ backgroundColor: con.local_coverage > con.local_max ? '#F6BA5E' : 'none' }}>
                        <td style={{ paddingRight: '1em', paddingLeft: '1em' }}>
                          <Text size="small">{con.radius_mi} miles</Text>
                        </td>
                        <td style={{ textAlign: 'right', paddingRight: '1em', paddingLeft: '1em' }}>
                          <Text size="small">${formatMoney(con.local_max)}</Text>
                        </td>
                        <td style={{ textAlign: 'right', paddingRight: '1em', paddingLeft: '1em' }}>
                          <Text size="small">${formatMoney(con.local_coverage)}</Text>
                        </td>
                        <td style={{ textAlign: 'right', paddingRight: '1em', paddingLeft: '1em' }}>
                          <Text size="small">{con.local_coverage > con.local_max ? 'OVER' : ''}</Text>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </Box>
          <LayoutBox row width="100%" layout="center-left" pt="$2">
            <Button
              variant="outlined"
              color="$primary"
              size="large"
              label={`${concentrationsExpanded ? 'Hide' : 'Expand'} Risk Concentration Details`}
              endIcon={concentrationsExpanded ? <CollapseArrowIcon color="$primary" /> : <ExpandArrowIcon color="$primary" />}
              onPress={() => setConcentrationsExpanded(!concentrationsExpanded)}
            />
          </LayoutBox>
        </>
      ) : null}
      <Spacing vertical={4} />
    </Box>
  );
};

const Attachments = ({ policy, attachments = [] }) => {
  const { id } = policy;
  const [inputValue, setInputValue] = useState(null);
  const lastInputValue = useRef(inputValue);

  const { uploadPolicyAttachment } = useApi();
  const uploadDocument = useCallback(
    async (document) => {
      await uploadPolicyAttachment(id, {
        document,
      });
      // await refreshApplicationData(true);
    },
    [uploadPolicyAttachment, id]
  );

  const { execute } = useAsync(uploadDocument, { immediate: false });

  useEffect(() => {
    // attachment upload
    if (inputValue && inputValue !== lastInputValue.current) {
      lastInputValue.current = inputValue;

      execute(inputValue);
    }
  }, [inputValue, execute]);

  attachments.sort((a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  });

  const tdProps = {
    labelProps: { size: 'small' },
    invert: true,
    flex: 1,
  };

  return (
    <LayoutBox>
      <LayoutBox row justifyContent="space-between" alignItems="flex-end">
        <Heading level={3} small weight="$regular">
          Attachments
        </Heading>
        <LayoutBox row layout="bottom-right" gap={12}>
          <FileInput
            value={inputValue}
            onChangeValue={(files) => {
              if (files.length) {
                setInputValue(files[0]);
              }
            }}
          />
        </LayoutBox>
      </LayoutBox>
      <Spacing vertical={1.5} border={{ bottom: { width: 1, color: '$gray.200' } }} />
      {attachments.map((attachment) => (
        <LayoutBox row flex={1} key={`${attachment.attachment_id}`}>
          <TextData label="Name" data={attachment.name} href={attachment.url} {...tdProps} textProps={{ color: '$primary' }} />
          <TextData label="Modified" data={moment(attachment.lastModified).format('MM-DD-YYYY')} {...tdProps} />
        </LayoutBox>
      ))}
      <Spacing vertical={5} />
    </LayoutBox>
  );
};

const LocationsAndCoverages = ({ locations }) => {
  locations.sort((a, b) => a.riskId - b.riskId);
  return (
    <LayoutBox flex={1}>
      <Heading level={3} small weight="$regular">
        Covered Locations
      </Heading>
      <Spacing vertical={1.5} border={{ bottom: { width: 1, color: '$gray.200' } }} />
      <Spacing vertical={3} />
      {locations.map((data) => {
        return (
          <LayoutBox row flex={1} mb="$8" key={`${data.id}`}>
            <Box flex={1.2} border={{ right: { width: 1, color: '$gray.200' } }}>
              <LocationItem data={data} />
            </Box>
            <Box flex={1} padLeft="$4">
              <Text dim uppercase bold lineHeight={32}>
                Coverages
              </Text>
              <Spacing vertical={2} />
              {data.coverages.map((coverage) => {
                return <CoverageItem data={coverage} key={`${coverage.coverageId}${data.id}`} />;
              })}
            </Box>
          </LayoutBox>
        );
      })}
    </LayoutBox>
  );
};

// const MonthlyInventoryReporting = ({ policy }) => {
//   console.log(moment().month(3).format('MM-DD-YYYY'));
//   return (
//     <LayoutBox>
//       <LayoutBox row justifyContent="space-between" alignItems="flex-end">
//         <Heading level={3} small weight="$regular">
//           Monthly Inventory Reports
//         </Heading>
//         <LayoutBox row layout="bottom-right" gap={12}>
//           <Button text color="$primary" href="/documents/monthly_inventory_reporting_form.pdf">
//             Download Form
//           </Button>
//           <Button>Upload Report</Button>
//         </LayoutBox>
//       </LayoutBox>
//       <Spacing vertical={1.5} border={{ bottom: { width: 1, color: '$gray.200' } }} />
//       <Spacing vertical={3} />
//       <Text bold dim>
//         No monthly inventory reports have been submitted yet
//       </Text>
//       {/* {locations.map((data) => {
//         return (
//           <LayoutBox row flex={1} mb="$8" key={`${data.id}`}>
//             <Box flex={1.2} border={{ right: { width: 1, color: '$gray.200' }}}>
//               <LocationItem data={data}  />
//             </Box>
//             <Box flex={1} padLeft="$4">
//               <Text dim uppercase bold lineHeight={32}>Coverages</Text>
//               <Spacing vertical={2} />
//               {data.coverages.map(coverage => {
//                 return (
//                   <CoverageItem data={coverage} key={`${coverage.coverageId}${data.id}`} />
//                 )
//               })}
//             </Box>

//           </LayoutBox>
//         )
//       })} */}
//     </LayoutBox>
//   );
// };

// const MonthlyInventoryItem = ({ monthNum }) => {};

const LocationItem = ({ data, onSelect }) => {
  const { address, postcode, region, name, riskId, coveredValue } = data;

  const addressLines = getAddressLines(address, region, postcode);
  return (
    <LayoutBox layout="top-left" stretch>
      <Heading level={4}>
        {riskId}. {name}
      </Heading>
      <Spacing vertical={1.5} />
      {Array.isArray(addressLines) ? (
        addressLines.map((a, i) => (
          <Text lineHeight={24} key={`aline${i}`}>
            {a}
          </Text>
        ))
      ) : (
        <Text>{addressLines}</Text>
      )}
      <Spacing vertial={1.5} />
      <DollarItem label="Total Insured Value" value={coveredValue} />
    </LayoutBox>
  );
};

const CoverageItem = ({ data }) => {
  const { coverageType, deductible, aggregate, payoutLimit } = data;

  return (
    <LayoutBox layout="top-left" stretch mb="$3.25">
      <Text large bold>
        {CoverageNames[coverageType]}
      </Text>
      <Spacing vertical={0.75} />
      <DollarItem label="Deductible" value={deductible} />
      {aggregate ? <DollarItem label="Aggregate" value={aggregate} /> : null}
      <DollarItem label="Payout Limit" value={payoutLimit} />
    </LayoutBox>
  );
};

const DollarItem = ({ label, value }) => {
  return (
    <Box flexDirection="row" alignItems="center" gap={12}>
      <Text bold small>{`${label}:`}</Text>
      <Text>{`$${formatMoney(value)}`}</Text>
    </Box>
  );
};

// const ListItem = styled(Box)(({ theme }) => ({
//   width: '100%',
//   flexDirection: 'column',
//   justifyContent: 'flex-start',
//   alignSelf: 'stretch',
//   borderRadius: 6,
//   // borderWidth: 1,
//   // borderColor: theme.colors.opacity('$gray.300', 0.3),
//   px: theme.spacing(0),
//   py: theme.spacing(2.75),
//   marginBottom: theme.spacing(1),
//   // bg: theme.colors.white,
// }));

// map with site pins and install ticket created (status of policy)
// payoutsEnabled ? -> ACTIVE || INACTIVE

// ** effective at - until

// insured name, info
// agent name
// product
// carrier name (capitalize)

// location/coverage list (LEFT location name & address, RIGHT - coverage list)

//

const LocationMap = React.forwardRef((props, ref) => {
  const { sites, ...rest } = props;
  const site = sites.length ? sites[0] : null;
  const { bounds, maxBounds } = useMemo(() => {
    if (site && site.lat && site.lon) {
      const { lat, lon } = site;
      return {
        bounds: getBounds({ center: [lon, lat], hectares: 1 }),
        maxBounds: getBounds({ center: [lon, lat], hectares: 15 }),
      };
    }
    return { bounds: null, maxBounds: null };
  }, [site]);

  const markerCoordinate = useMemo(() => {
    return site ? [site.lon, site.lat] : null;
  }, [site]);

  if (!site) {
    return null;
  }
  return (
    <Map staticMap ref={ref} {...rest}>
      <Map.Camera bounds={bounds} maxBounds={maxBounds} setBoundsOnResize={false} animationDuration={400} />
      {markerCoordinate ? <Map.Marker id="mapmarker" coordinate={markerCoordinate} /> : null}
    </Map>
  );
});
