import React, { useMemo, useRef } from 'react';
import { Platform } from 'react-native';
import { Box } from '../Box';
import { withStyles } from '../../styling';
import { backgroundColor, border, flexBox, shadow, sizes } from '../../system/props';
import { useEventCallback, useForkRef, useLayoutEffect, useRefArray } from '../../hooks';
import { setRef } from '../../utils';
import { ScrollView } from '../ScrollView';

const filterProps = ['maxWidth', ...backgroundColor.filterProps, ...border.filterProps, ...flexBox.filterProps, ...shadow.filterProps];

const MenuList = withStyles(
  (props) => ({
    root: {
      ...sizes({
        maxWidth: 360,
        ...props,
      }),
      ...backgroundColor({ bg: '$white', ...props }),
      ...border({
        borderWidth: 1,
        borderRadius: 3,
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.04)',
        ...props,
      }),
      ...flexBox({
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        ...props,
      }),
      ...shadow({
        shadow: {
          color: 'rgb(49, 51, 51)',
          offset: { y: 5 },
          opacity: 0.07,
          radius: 28,
        },
        ...props,
      }),
    },
  }),
  { name: 'MenuList', filterProps }
)(
  React.forwardRef(function MenuList(props, ref) {
    const {
      accessibility,
      scrollable = false,
      ScrollComponent = ScrollView,
      ScrollProps,
      autoFocus = false,
      autoFocusItem = false,
      disableKeyboardFocusing = false,
      disabledItemsFocusable = false,
      disableListWrap = false,
      styles,
      role = 'menu',
      variant = 'menu',
      children,
      onKeyDown,
      ...rest
    } = props;
    const menuProps = {
      focusable: true,
      accessibility: {
        accessibilityRole: role,
        ...accessibility,
      },
    };

    const menuListRef = useRef();
    const handleRef = useForkRef(menuListRef, ref);
    useLayoutEffect(() => {
      if (autoFocus && menuListRef.current) {
        menuListRef.current.focus();
      }
    }, [autoFocus]);

    let activeItemIndex = -1;
    React.Children.forEach(children, (child, index) => {
      if (!React.isValidElement(child)) {
        return;
      }
      if (!child.props.disabled) {
        if (variant === 'select' && child.props.selected) {
          activeItemIndex = index;
        } else if (activeItemIndex === -1) {
          activeItemIndex = index;
        }
      }
    });

    const childrenArray = useMemo(() => React.Children.toArray(children), [children]);
    const itemRefs = useRefArray(!disableKeyboardFocusing ? childrenArray : null);
    const items = useMemo(
      () =>
        childrenArray.map((child, index) => {
          if (!React.isValidElement(child)) {
            return null;
          }
          const newChildProps = {};
          if (index === activeItemIndex) {
            if (autoFocusItem) {
              newChildProps.autoFocus = true;
            }
            if (!child.props.focusable && variant === 'select') {
              newChildProps.focusable = true;
            }
          }
          if (!disableKeyboardFocusing) {
            newChildProps.ref = (refValue) => {
              setRef(itemRefs[index], refValue);
              if (child.props.ref) {
                setRef(child.props.ref, refValue);
              }
            };
          }

          return React.cloneElement(child, newChildProps);
        }),
      [childrenArray, activeItemIndex, autoFocusItem, disableKeyboardFocusing, variant, itemRefs]
    );

    const validIndex = (index, direction) => {
      if (!itemRefs.length) {
        return -1;
      }
      let nextIndex = direction === 'next' ? index + 1 : index - 1;
      let currDirection = direction;
      let wrappedOnce = false;

      while (true) {
        if (nextIndex >= itemRefs.length) {
          if (wrappedOnce) {
            return -1;
          } else if (direction === 'next' && disableListWrap) {
            nextIndex = itemRefs.length - 1;
            currDirection = 'previous';
            wrappedOnce = true;
          } else {
            nextIndex = 0;
            wrappedOnce = true;
          }
        } else if (nextIndex < 0) {
          if (wrappedOnce) {
            return -1;
          } else if (direction === 'previous' && disableListWrap) {
            nextIndex = 0;
            currDirection = 'next';
            wrappedOnce = true;
          } else {
            nextIndex = itemRefs.length - 1;
            wrappedOnce = true;
          }
        } else {
          if (itemRefs[nextIndex] && itemRefs[nextIndex].current && (disabledItemsFocusable || !itemRefs[nextIndex].current.disabled)) {
            return nextIndex;
          } else {
            nextIndex = currDirection === 'next' ? nextIndex + 1 : nextIndex - 1;
          }
        }
      }
    };

    const handleKeyDown = useEventCallback((e) => {
      if (e && e.key) {
        if (!e.nativeEvent) {
          e.nativeEvent = { key: e.key };
        } else if (!e.nativeEvent.key) {
          e.nativeEvent.key = e.key;
        }
      }
      if (onKeyDown) {
        onKeyDown(e);
      }
      if (!e || !e.nativeEvent || !e.nativeEvent.key || e.preventHandlerDefault || !itemRefs.length) {
        return;
      }
      const currFocusIndex = itemRefs.length
        ? itemRefs.findIndex((item) => {
            if (item.current) {
              return (
                item.current.focused ||
                item.current.hasVisibleFocus ||
                (item.current.isFocused && typeof item.current.isFocused === 'function' && item.current.isFocused()) ||
                (Platform.OS === 'web' && document !== undefined && document.activeElement === item.current)
              );
            }
            return false;
          })
        : -1;

      let nextFocusIndex = currFocusIndex;
      switch (e.nativeEvent.key) {
        case 'ArrowDown':
          preventDefaultForEvent(e);
          nextFocusIndex = validIndex(currFocusIndex, 'next');
          break;
        case 'ArrowUp':
          preventDefaultForEvent(e);
          nextFocusIndex = validIndex(currFocusIndex, 'previous');
          break;
        case 'Home':
          preventDefaultForEvent(e);
          nextFocusIndex = validIndex(-1, 'next');
          break;
        case 'End':
          preventDefaultForEvent(e);
          nextFocusIndex = validIndex(itemRefs.length, 'previous');
          break;
        default:
          break;
      }

      if (nextFocusIndex !== -1 && nextFocusIndex !== currFocusIndex && itemRefs[nextFocusIndex].current && itemRefs[nextFocusIndex].current.focus) {
        itemRefs[nextFocusIndex].current.focus();
      }
    });

    return (
      <Box ref={handleRef} onKeyDown={!disableKeyboardFocusing ? handleKeyDown : undefined} {...menuProps} {...rest}>
        {scrollable ? <ScrollComponent {...ScrollProps}>{items}</ScrollComponent> : items}
      </Box>
    );
  })
);

function preventDefaultForEvent(e) {
  if (e && e.preventDefault) {
    e.preventDefault();
  }
}

export { MenuList };
