import { Log, UserManager, WebStorageStateStore } from 'oidc-client';
import { useState, useCallback, useEffect } from 'react';

function useAuthService() {
  const [userManager] = useState(
    new UserManager({
      authority: process.env.REACT_APP_KALIDASA_BASE_URI,
      client_id: process.env.REACT_APP_OPENQUOTE_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_OPENQUOTE_BASE_URI,
      silent_redirect_uri: process.env.REACT_APP_OPENQUOTE_BASE_URI,
      post_logout_redirect_uri: process.env.REACT_APP_OPENQUOTE_BASE_URI,
      response_type: 'code',
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        issuer: process.env.REACT_APP_KALIDASA_BASE_URI,
        authorization_endpoint: process.env.REACT_APP_KALIDASA_BASE_URI + '/oauth/authorize',
        token_endpoint: process.env.REACT_APP_KALIDASA_BASE_URI + '/oauth/token',
        userinfo_endpoint: process.env.REACT_APP_KALIDASA_BASE_URI + '/users/me',
        end_session_endpoint: process.env.REACT_APP_KALIDASA_BASE_URI + '/logout',
        jwks_uri: '',
      },
    })
  );

  useEffect(() => {
    Log.logger = console;
    Log.level = Log.WARN;
  }, []);

  const signinRedirectCallback = useCallback(
    async (...args) => {
      await userManager.signinRedirectCallback(...args);
    },
    [userManager]
  );

  const getUser = useCallback(async () => {
    return await userManager.getUser().then((user) => user);
  }, [userManager]);

  const getAuthState = useCallback(async () => {
    const user = await getUser();
    const authenticated = user && user.access_token ? true : false;
    return {
      authenticated,
      expired: !authenticated || user.expired,
      renewable: authenticated && user.refresh_token ? true : false,
    };
  }, [getUser]);

  const login = useCallback(() => {
    userManager.signinRedirect();
  }, [userManager]);

  const renewSession = useCallback(async () => {
    return await userManager.signinSilent();
  }, [userManager]);

  const logout = useCallback(async () => {
    userManager.signoutRedirect();
  }, [userManager]);

  return {
    signinRedirectCallback,
    getUser,
    getAuthState,
    login,
    renewSession,
    logout,
  };
}

export { useAuthService };
