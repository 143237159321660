import { useEffect, useState } from 'react';
import { Clipboard } from 'react-native';

const listeners = new Set();

function setString(content) {
  Clipboard.setString(content);
  listeners.forEach((listener) => listener(content));
}

export function useClipboard() {
  const [data, updateClipboardData] = useState('');

  // Get initial data
  useEffect(() => {
    Clipboard.getString().then(updateClipboardData);
  }, []);

  // Listen for updates
  useEffect(() => {
    listeners.add(updateClipboardData);

    return () => {
      listeners.delete(updateClipboardData);
    };
  }, []);

  return [data, setString];
}
