import React, { useMemo } from 'react';
import { Box } from '../Box';
import { Text } from '../Text';
import { useTheme } from '../../hooks';
import moment from 'moment-timezone';
import { CalendarViewTypes } from './constants';

const Month = React.memo(function Day(props) {
  const { month, date, selected, focused, disabled, minDate, maxDate, onSelect, isTodaysMonth, disableHighlightTodaysMonth, ...rest } = props;

  const theme = useTheme();

  const opacity = disabled ? 0.7 : 1;

  const text = useMemo(() => {
    if (date) {
      return date.format('MMM');
    }
    if (month) {
      return moment(month, 'YYYYMM').format('MMM');
    }
  }, [date, month]);

  return (
    <Box
      onPress={disabled || !onSelect ? null : () => onSelect(date || month, CalendarViewTypes.day)}
      disabled={disabled}
      justifyContent="center"
      alignItems="center"
      opacity={opacity}
      borderRadius={9999999}
      zIndex={selected ? 2 : 1}
      scale={selected ? 1.4 : 1}
      {...rest}
    >
      <Text center bold={selected} color={selected ? '$primary' : null} selectable={false}>
        {text}
      </Text>
      {isTodaysMonth && !disableHighlightTodaysMonth ? (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          justifyContent="flex-end"
          alignItems="center"
          bg="transparent"
          paddingBottom="15%"
        >
          <Box width="50%" height={2} borderRadius={8} bg={theme.colors.secondary} />
        </Box>
      ) : null}
    </Box>
  );
});

export { Month };
