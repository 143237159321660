import { flatten } from './utils';
import { parseStyles } from '../system';
import { isNull } from '../utils';

const APPLIES_STYLE = 'isApplyStyle';
const CONFIG = 'isConfig';

const animationEventHandlers = {
  onStart: CONFIG,
  onRest: CONFIG,
  onChange: CONFIG,
  onPause: CONFIG,
  onResume: CONFIG,
  onDelayEnd: CONFIG,
  onProps: CONFIG,
};
/*
(animationResult = { cancelled, finished, value: { ...styles } }, springController, item (if transition))
^^ event arguments for all
except onProps and onDelayEnd which have:
(props/props object of the spring merged after new props, the new springValue object as a result)
*/

const animationPropTypes = {
  initial: APPLIES_STYLE,
  enter: APPLIES_STYLE,
  update: APPLIES_STYLE,
  leave: APPLIES_STYLE,
  from: APPLIES_STYLE,
  to: APPLIES_STYLE,
  delay: CONFIG,
  loop: CONFIG,
  trail: CONFIG,
  reset: CONFIG,
  config: CONFIG,
  immediate: CONFIG,
  reverse: CONFIG,
  cancel: CONFIG,
  pause: CONFIG,
  expires: CONFIG,
  sort: CONFIG,
  keys: CONFIG,
  key: CONFIG,
  ref: CONFIG,
  ...animationEventHandlers,
};

const transitionStyleKeyProps = {
  enter: true,
  update: true,
  leave: true,
};

function resolveAnimation(animationProps, styleProps, forTransition = false) {
  const a = flatten(animationProps, styleProps);
  if (!a) {
    return null;
  }

  const animation = {};
  const stylesToAdd = {};
  let hasStylesToAdd = false;

  for (const prop in a) {
    if (animationPropTypes[prop]) {
      if (isNull(a[prop])) {
        continue;
      } else if (animationPropTypes[prop] === CONFIG) {
        animation[prop] = a[prop];
      } else {
        if (forTransition && transitionStyleKeyProps[prop]) {
          if (typeof a[prop] === 'function') {
            // TODO: ^^ any APPLIES_STYLE thing that is a function should be handled like this.
            // maybe wrap the function in a function?. otherwise expect the styles parsed beforehand.
            // ^ same goes for arrays. See useTransition/useSpring chaining animations etc...
            animation[prop] = a[prop];
            continue;
          } else {
            // events like onStart and onRest can be called per transition type (enter, leave, update) and we want to include them if defined
            // parseStyles will call the functions if not removed before hand.
            const tResult = resolveAnimation(a[prop], styleProps);
            const tStyles = {};
            const tEvents = {};
            for (const tProp in tResult) {
              if (animationEventHandlers[tProp]) {
                tEvents[tProp] = tResult[tProp];
              } else {
                tStyles[tProp] = tResult[tProp];
              }
            }
            animation[prop] = { ...parseStyles(tStyles, styleProps), ...tEvents };
          }
        } else {
          animation[prop] = parseStyles(a[prop], styleProps);
        }
      }
    } else {
      stylesToAdd[prop] = a[prop];
      hasStylesToAdd = true;
    }
  }

  if (hasStylesToAdd && !forTransition) {
    animation.to = {
      ...parseStyles(stylesToAdd, styleProps),
      ...animation.to,
    };
  }
  // if (animation.from && forTransition && (animation.reset !== true && animation.reset !== false)) {
  //   animation.reset = false;
  // }
  return animation;
}

export { resolveAnimation };
