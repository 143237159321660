import React from 'react';

export const MapContext = React.createContext(undefined);
export const SourceContext = React.createContext(undefined);

export function withSource(Component) {
  const SourceComponent = React.forwardRef(function SourceComponent(props, ref) {
    const source = React.useContext(SourceContext) || props.source;
    const sourceId = source && source.id ? source.id : props.sourceId;
    const map = React.useContext(MapContext);
    return <Component ref={ref} sourceId={sourceId} source={source} map={map} {...props} />;
  });
  return SourceComponent;
}

export function withMap(Component) {
  const MapComponent = React.forwardRef(function MapComponent(props, ref) {
    const map = React.useContext(MapContext);
    return <Component ref={ref} map={map} {...props} />;
  });
  return MapComponent;
}
