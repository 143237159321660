import { styled } from '../../ui/styling';
import { layout as layoutProps } from '../../ui/system/props';
// import { getFlexLayout } from './utils';
import { LayoutBox } from './LayoutBox';
import { withContainerContext } from './context';

export const Section = withContainerContext(
  styled(LayoutBox)((props) => {
    const { parentContainer } = props;
    const SectionProps = parentContainer && parentContainer.SectionProps ? parentContainer.SectionProps : null;
    const parentHasRowLayout = parentContainer && parentContainer.flexDirection === 'row' ? true : false;
    const parentDirectionBasedProps = parentHasRowLayout
      ? {
          height: '100%',
          marginY: 'auto',
        }
      : {
          width: '100%',
          marginX: 'auto',
        };

    return {
      ...layoutProps({
        ...parentDirectionBasedProps,
        ...props,
      }),
      props: {
        ...SectionProps,
        defaultLayout: {
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        },
      },
    };
  })
);
