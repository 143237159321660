import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Heading, Switch } from '../../../ui';
import { useEventCallback } from '../../../ui/hooks';
import { isNull, isObject } from '../../../ui/utils';

export const SwitchFilterControl = React.forwardRef(function SwitchFilterControl(props, ref) {
  const { field, value: valueProp, setQuery, active } = props;
  const value = useMemo(() => {
    if (isNull(valueProp)) {
      if (isObject(field.options) && (!isNull(field.options.on) || !isNull(field.options.off))) {
        return isNull(field.options.on) ? true : false;
      }
    }
    return valueProp;
  }, [valueProp, field]);
  const controlProps = useMemo(() => {
    const controls = isObject(field.controls) ? field.controls : {};
    const options = isObject(field.options) ? field.options : {};
    const optionLabels = {
      on: isObject(options.on) && !isNull(options.on.label) ? `${options.on.label}` : undefined,
      off: isObject(options.off) && !isNull(options.off.label) ? `${options.off.label}` : undefined,
    };
    const { on, off, ...sharedProps } = controls;
    let onProps = { label: optionLabels.on, ...sharedProps };
    let offProps = { label: optionLabels.off, ...sharedProps };
    if (isObject(on)) {
      onProps = { ...onProps, ...on };
    }
    if (isObject(off)) {
      offProps = { ...offProps, ...off };
    }
    return { on: onProps, off: offProps };
  }, [field]);

  const [checked, setChecked] = useState(value ? true : false);
  const checkRef = useRef(checked);
  checkRef.current = checked;
  useEffect(() => {
    let val = value ? true : false;
    if (val !== checkRef.current) {
      setChecked((curr) => {
        if (curr !== val) {
          return val;
        }
        return curr;
      });
    }
  }, [value]);

  const onChangeValue = useEventCallback((next) => {
    setChecked(next);
    if (field.setField) {
      field.setField(next, setQuery, field);
    }
  });

  return (
    <Box flex={1} width="100%">
      <Heading level={6} dim={active ? 0.8 : true}>
        {field.label}
      </Heading>
      <Box flexDirection="row" justifyContent="flex-start">
        <Switch ref={ref} checked={checked} onChangeValue={onChangeValue} {...controlProps[checked ? 'on' : 'off']} />
      </Box>
    </Box>
  );
});

export const CheckboxFilterControl = React.forwardRef(function CheckboxFilterControl(props, ref) {
  return <SwitchFilterControl ref={ref} {...props} />;
});
