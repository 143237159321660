import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dialog, LayoutBox, Main, Page } from '../../components';
import { AddPlusIcon, CollapseArrowIcon, ExpandArrowIcon, ForwardArrowIcon } from '../../components/icons';
import { useApi, useAsync } from '../../hooks';
import { ActivityIndicator, Button, Heading, Spacing, Text, Box, Menu, MenuItem, TextField, Checkbox, Autocomplete } from '../../ui';
import { inputTests, isNull, isObject } from '../../ui/utils';

export const AdminView = () => {
  const { getBrokers, getAgents, getProducts } = useApi();
  const getData = useCallback(async () => {
    const agents = await getAgents();
    const brokers = await getBrokers();
    const products = await getProducts();
    return { agents, brokers, products };
  }, [getBrokers, getAgents, getProducts]);
  const { value, status, execute } = useAsync(getData, { resetValueOnExecute: false, immediate: true });
  const { agents, brokers, products } = value || {};

  const [agentEditing, setAgentEditing] = useState(null);
  const [brokerEditing, setBrokerEditing] = useState(null);
  const [expandCollapseAll, setExpandCollapseAll] = useState(1);

  return (
    <Page padTop={({ theme }) => theme.breakpoints({ xs: 0, sm: 0 })}>
      <Box width="100%" position="sticky" top={0} bg="$white" zIndex="$header" height={({ theme }) => theme.sizes.appBarHeight + theme.spacing(1)} />
      <Main
        px={({ theme }) => theme.breakpoints({ xs: '$2', sm: '$6' })}
        layout="top-left"
        padTop={({ theme }) => theme.sizes.appBarHeight + theme.spacing(1)}
        padBottom="$16"
      >
        <Heading level={2} small>
          Administration
        </Heading>
        <Heading level={5}>for Brokers and Agents</Heading>
        <Spacing vertical={1} />
        <LayoutBox
          width="100%"
          row
          layout="center"
          justifyContent="space-between"
          position="sticky"
          bg="$white"
          zIndex="$header"
          pt="$2"
          pb="$1"
          top={({ theme }) => theme.sizes.appBarHeight}
          border={{
            bottom: {
              width: 1,
              color: '$gray.200',
            },
          }}
        >
          <Box display={({ theme }) => theme.breakpoints({ xs: 'none', sm: 'flex' })}>
            {status === 'pending' && value ? (
              <ActivityIndicator />
            ) : brokers && brokers.length ? (
              <Text bold dim={0.6}>
                {`${brokers.length} Brokers `}
                <Text weight="$regular">{` /  ${agents && agents.length ? agents.length : 0} Agents`}</Text>
              </Text>
            ) : (
              <Text></Text>
            )}
          </Box>
          <Box flexDirection="row" gap="22">
            <Button
              outlined
              color="$primary"
              variant="text"
              endIcon={expandCollapseAll <= 0 ? <ExpandArrowIcon color="$primary" mb={3} /> : <CollapseArrowIcon color="$primary" mt={3} />}
              label={expandCollapseAll <= 0 ? 'Expand All' : 'Collapse All'}
              onPress={() => {
                if (expandCollapseAll > 0) {
                  setExpandCollapseAll(-1);
                } else {
                  setExpandCollapseAll(1);
                }
              }}
            />
            <AddButtonAndDialogs
              {...{
                execute,
                status,
                agentEditing,
                setAgentEditing,
                brokerEditing,
                setBrokerEditing,
                brokers,
                products,
              }}
            />
          </Box>
        </LayoutBox>
        {status === 'pending' && !value ? (
          <LayoutBox width="100%" minHeight="50%" layout="center">
            <ActivityIndicator />
          </LayoutBox>
        ) : status === 'error' ? (
          <Text>Sorry something went wrong, please refresh or contact an administrator.</Text>
        ) : Array.isArray(brokers) && !brokers.length ? (
          <Text>{`No brokers were found.`}</Text>
        ) : (
          <BrokerList
            {...{
              brokers,
              agents,
              setAgentEditing,
              setBrokerEditing,
              expandCollapseAll,
              setExpandCollapseAll,
            }}
          />
        )}
      </Main>
    </Page>
  );
};

const AddButtonAndDialogs = ({ execute, status, agentEditing, setAgentEditing, brokerEditing, setBrokerEditing, brokers, products }) => {
  const [open, setOpen] = useState(false);
  const [brokerOpen, setBrokerOpen] = useState(false);
  const [agentOpen, setAgentOpen] = useState(false);
  const btnRef = useRef();
  const agentEditRef = useRef(null);
  const brokerEditRef = useRef(null);
  useEffect(() => {
    if (agentEditing) {
      setAgentOpen(true);
      agentEditRef.current = agentEditing;
    } else if (brokerEditing) {
      setBrokerOpen(true);
      brokerEditRef.current = brokerEditing;
    }
  }, [agentEditing, brokerEditing]);
  return (
    <>
      <Button px="$2.5" endIcon={<AddPlusIcon color="$white" size={18} />} label="Add" onPress={() => setOpen((c) => !c)} ref={btnRef} />
      <Menu anchorNode={btnRef.current} open={open} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'bottom' }}>
        <MenuItem
          onPress={() => {
            setOpen(false);
            setAgentOpen(false);
            brokerEditRef.current = null;
            setBrokerEditing(null);
            setBrokerOpen(true);
          }}
        >
          Add Broker
        </MenuItem>
        <MenuItem
          onPress={() => {
            setOpen(false);
            setBrokerOpen(false);
            agentEditRef.current = null;
            setAgentEditing(null);
            setAgentOpen(true);
          }}
        >
          Add Agent
        </MenuItem>
      </Menu>
      <AddBrokerDialog
        open={brokerOpen}
        editing={brokerEditing || brokerEditRef.current ? true : false}
        data={brokerEditing ? brokerEditing : brokerEditRef.current}
        onClose={() => {
          setBrokerOpen(false);
          setBrokerEditing(null);
        }}
        execute={execute}
        status={status}
      />
      <AddAgentDialog
        open={agentOpen}
        editing={agentEditing || agentEditRef.current ? true : false}
        data={agentEditing ? agentEditing : agentEditRef.current}
        onClose={() => {
          setAgentOpen(false);
          setAgentEditing(null);
        }}
        execute={execute}
        status={status}
        brokers={brokers}
        products={products}
      />
    </>
  );
};

const AddBrokerDialog = ({ execute, status, editing = false, data, open, ...rest }) => {
  const { createBroker, editBrokerWithId } = useApi();
  const [name, setName] = useState(data && data.id && data.name ? data.name : '');

  const addEditBroker = useCallback(
    async ({ name: brokerName, id }) => {
      if (!brokerName || typeof brokerName !== 'string' || !brokerName.trim()) {
        throw 'Please enter a valid name.';
      }
      if (editing) {
        if (!id) {
          throw 'Could not edit broker.';
        }
        await editBrokerWithId(id, { name: brokerName });
      } else {
        await createBroker({ name: brokerName });
        setName('');
      }

      execute();
    },
    [createBroker, editBrokerWithId, execute, editing]
  );

  const { status: addEditStatus, execute: executeAddEdit, error, setStatus } = useAsync(addEditBroker, { immediate: false });
  useEffect(() => {
    setName(data && data.id && data.name ? data.name : '');
    setStatus('idle');
  }, [editing, open, data, setStatus]);

  const editingId = editing && data && data.id ? data.id : null;

  return (
    <Dialog
      open={open}
      heading={editing ? 'Edit Broker' : 'Add Broker'}
      actions={
        <Button
          disabled={status === 'pending' || addEditStatus === 'pending'}
          loading={status === 'pending' || addEditStatus === 'pending'}
          onPress={() => {
            executeAddEdit({ name, id: editingId });
          }}
          label="Submit"
        />
      }
      prompt={
        <Text color={error ? '$coral' : '$secondary'}>
          {error && addEditStatus !== 'idle' ? error : addEditStatus === 'success' ? (editing ? 'Changes successful' : 'Successfully added') : ''}
        </Text>
      }
      styles={{
        dialogBox: {
          marginLeft: ({ theme }) => theme.layout.centeredOverlay.marginLeft,
        },
      }}
      {...rest}
    >
      <TextField label="Broker Name" value={name} onChangeValue={(v) => setName(v)} />
    </Dialog>
  );
};

const AddAgentDialog = ({ execute, status, editing = false, data, open, products = [], brokers = [], ...rest }) => {
  const { createAgent, editAgentWithId } = useApi();
  const [name, setName] = useState(editing && data && data.id && data.name ? data.name : '');
  const [email, setEmail] = useState(editing && data && data.id && data.email ? data.email : '');
  const [selectedProducts, setSelectedProducts] = useState(editing && data && data.id && Array.isArray(data.products) ? [...data.products] : []);
  const [brokerId, setBrokerId] = useState(
    editing && data && data.id && (!isNull(data.brokerId) || (isObject(data.broker) && !isNull(data.broker.id)))
      ? data.brokerId
        ? data.brokerId
        : data.broker.id
      : null
  );
  const addEditAgent = useCallback(
    async (data = {}) => {
      console.log(data);
      const { fullName = '', email = '', brokerId, products = [], id } = data;
      if (!fullName || typeof fullName !== 'string' || !fullName.trim()) {
        throw 'Please enter a valid name.';
      } else if (!email || !inputTests.email(email)) {
        throw 'Please enter a valid email.';
      } else if (brokerId === null) {
        throw 'Please select a brokerage or create one for this agent.';
      } else if (products.length < 1) {
        throw 'Please assign products to this agent.';
      }
      if (editing) {
        if (!id) {
          throw 'Could not edit agent.';
        }
        await editAgentWithId(id, { fullName, email, brokerId, productIds: products });
      } else {
        await createAgent({ fullName, email, brokerId, productIds: products, broker: { id: brokerId } });
        setName('');
        setEmail('');
      }
      execute();
    },
    [createAgent, editAgentWithId, execute, editing]
  );
  const { status: addEditStatus, execute: executeAddEdit, error, setStatus } = useAsync(addEditAgent, { immediate: false });

  useEffect(() => {
    setName(editing && data && data.id && data.name ? data.name : '');
    setEmail(editing && data && data.id && data.email ? data.email : '');
    setSelectedProducts((currProducts) => {
      if (editing && data && data.id && Array.isArray(data.products)) {
        return data.products;
      }
      return editing && data ? [] : currProducts;
    });
    setBrokerId((currBrokerId) => {
      if (editing && data && data.id && (!isNull(data.brokerId) || (isObject(data.broker) && !isNull(data.broker.id)))) {
        return data.brokerId ? data.brokerId : data.broker.id;
      }
      return editing && data ? null : currBrokerId;
    });
    setStatus('idle');
  }, [open, editing, data, execute, setStatus]);

  const editingId = editing && data && data.id ? data.id : null;

  return (
    <Dialog
      open={open}
      heading={editing ? 'Edit Agent' : 'Add Agent'}
      actions={
        <Button
          disabled={status === 'pending' || addEditStatus === 'pending'}
          loading={status === 'pending' || addEditStatus === 'pending'}
          onPress={() => {
            executeAddEdit({ fullName: name, email, brokerId, products: selectedProducts, id: editingId });
          }}
          label="Submit"
        />
      }
      prompt={
        <Text color={error ? '$coral' : '$secondary'}>
          {error && addEditStatus !== 'idle' ? error : addEditStatus === 'success' ? (editing ? 'Changes successful' : 'Successfully added') : ''}
        </Text>
      }
      styles={{
        dialogBox: {
          marginLeft: ({ theme }) => theme.layout.centeredOverlay.marginLeft,
        },
      }}
      {...rest}
    >
      <TextField label="Full Name" value={name} onChangeValue={(v) => setName(v)} />
      <TextField label="Email" value={email} onChangeValue={(v) => setEmail(v)} />
      <Autocomplete
        options={brokers}
        autoComplete
        autoHighlight
        loading={status === 'pending'}
        getOptionLabel={(option) => {
          if (typeof option === 'string' || typeof option === 'number') {
            const brokerOption = brokers.find((b) => b.id === option);
            if (brokerOption) {
              return brokerOption.name;
            }
          } else if (option) {
            return option.name;
          } else {
            return '';
          }
        }}
        isOptionEqualToValeu={(option, value) => option.id === value || option === value}
        value={brokerId}
        onChangeValue={(v) => setBrokerId(v && v.id ? v.id : null)}
        renderInput={(iProps) => <TextField {...iProps} label="Brokerage" placeholder="-- Select a broker --" filled />}
      />
      <LayoutBox layout="top-left" pt="$1">
        <Text small dim py="$1" bold>
          PRODUCTS
        </Text>
        {products && products.length
          ? products.map((p) => (
              <Checkbox
                label={p.name}
                checked={selectedProducts.includes(p.id)}
                key={p.id}
                onPress={() => {
                  if (!selectedProducts.includes(p.id)) {
                    setSelectedProducts((curr) => [...curr, p.id]);
                  } else {
                    setSelectedProducts((curr) => curr.filter((id) => id !== p.id));
                  }
                }}
              />
            ))
          : null}
      </LayoutBox>
    </Dialog>
  );
};

const BrokerList = ({ brokers, ...rest }) => {
  const sortedBrokers = useMemo(() => (brokers && brokers.length ? brokers.sort((a, b) => a.name.localeCompare(b.name)) : []), [brokers]);
  return (
    <>
      {sortedBrokers.map((b) => (
        <BrokerItem key={b.id} broker={b} {...rest} />
      ))}
    </>
  );
};

const BrokerItem = ({ broker, setBrokerEditing, agents, setAgentEditing, expandCollapseAll, setExpandCollapseAll, ...rest }) => {
  const { id: brokerId, name } = broker;
  const sortedAgents = useMemo(() => {
    if (Array.isArray(agents) && agents.length) {
      const filteredAgents = agents.filter((a) => a.broker && a.broker.id === brokerId);
      if (filteredAgents.length) {
        return filteredAgents.sort((a, b) => a.name.localeCompare(b.name));
      }
    }
    return [];
  }, [agents, brokerId]);

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expandCollapseAll > 0) {
      setExpanded(true);
    } else if (expandCollapseAll < 0) {
      setExpanded(false);
    }
  }, [expandCollapseAll]);

  return (
    <Box width="100%" alignItems="flex-start" {...rest}>
      <LayoutBox
        sx={{
          flex: 1,
          width: '100%',
          maxWidth: '100%',
          mt: '$2',
          py: '$1',
          justifyContent: 'space-between',
        }}
        row
        layout="top-left"
        onPress={() => {
          setExpandCollapseAll(0);
          setExpanded(!expanded);
        }}
      >
        {({ hovered, pressed, focused }) => {
          return (
            <>
              <Box flexDirection="row" flex={1} alignSelf="stretch" alignItems="flex-start" minHeight={38}>
                {expanded ? <ExpandArrowIcon color="$primary" mt={3} /> : <ForwardArrowIcon color="$primary" mt={3} />}
                <Spacing horizontal={1} />
                <Box flex={1} width="100%">
                  <Box flexDirection="row" width="100%" alignItems="center">
                    <Text bold large>
                      {name}
                    </Text>
                    <Text small display={({ theme }) => theme.breakpoints({ xs: 'none', sm: 'flex' })} padLeft="$1">
                      {`${sortedAgents.length} Agent${sortedAgents.length === 1 ? '' : 's'}`}
                    </Text>
                    <Spacing horizontal={1} />
                    <Box flex={1} height={hovered || pressed || focused ? 1 : 0} bg="$gray.200" alignSelf="center" />
                  </Box>
                  <Text small bold dim display={({ theme }) => theme.breakpoints({ xs: 'flex', sm: 'none' })}>
                    {`${sortedAgents.length} Agent${sortedAgents.length === 1 ? '' : 's'}`}
                  </Text>
                </Box>
              </Box>
              <Spacing horizontal={1} />
              <Button
                color="$primary"
                variant="outlined"
                display={({ theme }) => theme.breakpoints({ xs: 'flex', sm: hovered || pressed || focused ? 'flex' : 'none' })}
                onPress={() => setBrokerEditing(broker)}
              >
                Edit Broker
              </Button>
            </>
          );
        }}
      </LayoutBox>
      {sortedAgents.length ? (
        <>
          <Box
            sx={{
              width: '100%',
              maxWidth: '100%',
              padLeft: '$4',
              height: expanded ? null : 0,
              maxHeight: expanded ? null : 0,
              overflow: 'hidden',
            }}
          >
            <AgentList agents={sortedAgents} setAgentEditing={setAgentEditing} />
          </Box>
        </>
      ) : (
        <Text dim>No agents have been added to this brokerage</Text>
      )}
    </Box>
  );
};

const AgentList = ({ agents, setAgentEditing }) => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        borderRadius: 8,
        overflow: 'hidden',
        border: {
          width: 1,
          color: '$gray.200',
        },
      }}
    >
      {agents.map((d, i) => (
        <LayoutBox
          key={`agent${d.id}`}
          sx={{
            bg: i % 2 === 0 ? '$gray.50' : 'transparent',
            flex: 1,
            width: '100%',
            maxWidth: '100%',
            py: '$1.5',
            padX: '$1.5',
            border: {
              top: {
                width: 1,
                color: '$gray.100',
              },
              bottom: {
                width: i === agents.length - 1 ? 1 : 0,
                color: '$gray.100',
              },
            },
          }}
          row
          layout="center-left"
          onPress={() => setAgentEditing(d)}
        >
          {({ hovered, pressed, focused }) => {
            return (
              <LayoutBox row width="100%" justifyContent="space-between" layout="center">
                <LayoutBox row layout="center-left" flex={1} gap={8}>
                  <Text flexShrink={0} maxLines={1} bold small>
                    {d.name}
                  </Text>
                  <Text flexShrink={2} xSmall bold dim maxLines={1}>
                    {d.email}
                  </Text>
                </LayoutBox>
                <Text color="$primary" xSmall bold uppercase opacity={hovered || pressed || focused ? 1 : 0}>
                  Edit Agent
                </Text>
              </LayoutBox>
            );
          }}
        </LayoutBox>
      ))}
    </Box>
  );
};
