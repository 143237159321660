import React from 'react';
import { Box } from '../Box';
import { Text } from '../Text';
import { localeUtils } from '../../utils';
import { CountryFlag } from './CountryFlag';
import { withStyles } from '../../styling';

export const CountryListItem = withStyles(
  {
    root: {
      width: '100%',
      maxWidth: '100%',
      height: 60,
      flexDirection: 'row',
      padLeft: '$2',
      padRight: '$2',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    selected: {
      bg: ({ theme }) => theme.colors.alpha('$gray.500', 0.4),
    },
    countryFlag: {},
    text: {
      props: {
        size: 'small',
      },
    },
    nameText: {},
    callingCode: {},
    countryCode: {},
  },
  { name: 'CountryListItem', preserveStyleProp: true }
)(
  React.forwardRef(function CountryListItem(props, ref) {
    const {
      onPress,
      index,
      value = 'US',
      countryCode: ccProp,
      selected = false,
      hideFlag = false,
      hideName = false,
      hideCallingCode = false,
      hideCurrency = false,
      styles,
      children,
      ...rest
    } = props;

    const countryCode = ccProp ? ccProp : value;
    const handleOnPress = () => {
      if (onPress) {
        onPress(countryCode, index);
      }
    };

    return (
      <Box ref={ref} onPress={onPress ? handleOnPress : undefined} {...styles.toProps({ root: true, selected }, props)} {...rest}>
        {!hideFlag ? <CountryFlag countryCode={countryCode} {...styles.toProps('countryFlag')} /> : null}
        {!hideName ? <Text maxLines={1} value={`${localeUtils.getCountryName(countryCode)}`} {...styles.toProps(['text', 'nameText'])} /> : null}
        {!hideCallingCode ? (
          <Text maxLines={1} value={`${localeUtils.getCountryCallingCode(countryCode)}`} {...styles.toProps(['text', 'callingCode'])} />
        ) : null}
        {!hideCurrency ? (
          <Text maxLines={1} value={`${localeUtils.getCountryCurrency(countryCode)}`} {...styles.toProps(['text', 'currencyText'])} />
        ) : null}
        {children}
      </Box>
    );
  })
);
