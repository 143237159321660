import React, { useMemo } from 'react';
import { Box, Text, Button, ActivityIndicator, Spacing } from '../../ui';
import { useBreakpoint } from '../../ui/hooks';
import { withStyles } from '../../ui/styling';
import { useAuth, useNavigate, useProgressPropsForBindFormStatus } from '../../hooks';
import { AppBar, Logo, NavRow, NavGroup, NavBackButton, AppActionsGroup, ShareButtonView, Chip } from '../../components';
import { useSiteChecklistContext } from './context';
// import { Stepper } from './Stepper';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';

export const Nav = withStyles(({ theme: { colors, breakpoints, spacing } }) => {
  return {
    appBar: {
      padX: 0,
      // props: {
      //   transparent: true,
      //   disablePlaceholder: true,
      // },
    },
    customNavRow: {
      justifyContent: 'space-evenly',
      alignItems: 'flex-start',
    },
    customNavGroup: {
      // flexDirection: breakpoints({ xs: 'row', sm: 'column' })
    },
    title: {
      props: breakpoints({
        xs: {
          level: 3,
          size: 'small',
          marginLeft: spacing(1.75),
          color: colors.on('$background'),
        },
        sm: {
          level: 3,
          size: 'small',
        },
      }),
    },
    leftNavText: {
      props: breakpoints({
        xs: { color: colors.on('$background') },
        sm: {},
      }),
    },
    details: {
      display: breakpoints({ xs: 'none', sm: 'flex' }),
      padTop: breakpoints({ sm: 0, md: spacing(1) }),
      maxWidth: 300,
    },
    helpButton: {
      props: breakpoints({
        xs: {
          outlined: true,
          color: '$gray.500',
          label: '?',
          borderRadius: '$circle',
        },
        sm: {
          outlined: true,
          color: '$gray.500',
          label: 'Need Help?',
        },
      }),
    },
  };
})((props) => {
  const { styles } = props;
  const navigate = useNavigate();
  const {
    name: locationName,
    // data,
    bindForm,
    // canMakeChanges,
    status,
    basePath,
    internalViewer,
    canInternalMakeChanges,
    editingInternal,
    setEditingInternal,
    formStatus,
  } = useSiteChecklistContext();

  const location = useLocation();
  const isHomePage = useMemo(() => {
    return location && location.pathname === basePath ? true : false;
  }, [location, basePath]);
  const { loggedIn } = useAuth();

  const { approvedAt, rejectedAt, receivedAt, requestedAt } = bindForm || {};

  const [statusProps] = useProgressPropsForBindFormStatus(formStatus);
  const lastUpdated = useMemo(() => {
    return moment(approvedAt ? approvedAt : rejectedAt ? rejectedAt : receivedAt ? receivedAt : requestedAt).format('MM-DD-YYYY');
  }, [approvedAt, rejectedAt, receivedAt, requestedAt]);
  // const [breakpoint] = useBreakpoint();
  return (
    <AppBar transparent={isHomePage} disablePlaceholder {...styles.toProps('appBar')}>
      <NavRow {...styles.toProps('customNavRow')} padX={({ theme }) => theme.breakpoints({ xs: theme.spacing(1.5), sm: theme.spacing(4) })}>
        {!loggedIn ? (
          <NavGroup start flex={0} {...styles.toProps('customNavGroup')} onPress={() => navigate.to({ pathname: `${basePath}/onboard` })}>
            <CustomLogo isHomePage={isHomePage} />
            {/* <Box marginLeft="$1.75" mt={3}>
              <Text color={isHomePage ? 'white' : null} large uppercase letterSpacing={1} {...styles.toProps('leftNavText')}>UNDERSTORY</Text>
            </Box> */}
            <Box marginLeft="$1.75" mt={8}>
              <Text color={isHomePage ? 'white' : null} large uppercase letterSpacing={1} {...styles.toProps('leftNavText')}>
                UNDERSTORY
              </Text>
              <Text color={isHomePage ? 'white' : null} small bold mt={-2} {...styles.toProps('leftNavText')}>
                Installation Checklist
              </Text>
            </Box>
          </NavGroup>
        ) : (
          <NavGroup start flex={0}>
            <NavBackButton
              onPress={(navigate) =>
                navigate.canGoBack
                  ? navigate.back()
                  : bindForm && bindForm.quoteId
                  ? navigate.back(`/quotes/${bindForm.quoteId}`)
                  : navigate.back('/')
              }
              styles={
                isHomePage
                  ? {
                      text: { color: 'white' },
                      icon: { color: 'white' },
                    }
                  : null
              }
            />
          </NavGroup>
        )}
        <NavGroup center flex={1} opacity={({ theme }) => theme.breakpoints({ xs: 0, sm: 1 })}>
          {isHomePage ? null : (
            <Box maxWidth="100%" justifyContent="center" alignItems="center">
              {loggedIn ? (
                <Text
                  maxWidth="100%"
                  opacity={0}
                  maxLines={1}
                  color={isHomePage ? 'white' : null}
                  small
                  bold
                  mt={-2}
                  {...styles.toProps('leftNavText')}
                >
                  Installation Checklist
                </Text>
              ) : null}
              <Text maxLines={2} maxWidth="100%">
                {locationName ? locationName : ''}
              </Text>
            </Box>
          )}
        </NavGroup>
        {loggedIn ? (
          <AppActionsGroup flex={0} height={60}>
            <ShareButtonView url={`${window.location.origin}${basePath}`} variant="outlined" color="$primary" size="small" />
          </AppActionsGroup>
        ) : (
          <NavGroup flex={0} end height={60} gap={8}>
            <ShareButtonView url={`${window.location.origin}${basePath}`} variant="outlined" color="$primary" size="small" />
            <Button
              small
              {...styles.toProps('helpButton')}
              onPress={() => {
                // setOpen(true)
              }}
            />
          </NavGroup>
        )}
      </NavRow>
      {isHomePage ? null : (
        <NavRow mt="$-0.5" minHeight={60} maxHeight={60} bg={editingInternal ? '$secondary.light' : '$white'}>
          <Box
            sx={({ breakpoints, spacing }) => ({
              flexDirection: 'row',
              maxWidth: '$mainWidth',
              marginX: 'auto',
              width: '100%',
              height: '100%',
              minHeight: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              padX: breakpoints({ xs: spacing(1.5), sm: spacing(4) }),
            })}
          >
            <NavGroup start flex={0} sx={{ padLeft: 0, padRight: 0 }}>
              <Box sx={{ flexDirection: 'row', alignItems: 'center' }}></Box>
            </NavGroup>
            <NavGroup end flex={2}>
              {status === 'pending' ? (
                <Box sx={{ flexDirection: 'row' }}>
                  <Text small dim color={editingInternal ? 'white' : undefined}>
                    Saving...
                  </Text>
                  <Spacing horizontal={0.5} />
                  <ActivityIndicator />
                </Box>
              ) : (
                <Text xSmall italic color={editingInternal ? 'white' : undefined}>{`Last updated ${lastUpdated}`}</Text>
              )}
              <Spacing horizontal={2} />
              <Chip {...statusProps} children={`STATUS:  ${statusProps.children}`} sx={{ borderRadius: '$circle' }} />
              {internalViewer && canInternalMakeChanges ? (
                <>
                  <Spacing horizontal={2} />
                  <Box height="80%" width="1" bg="$gray.100" />
                  <Spacing horizontal={2} />
                  <Button
                    label={editingInternal ? 'End Editing' : 'Enable Editing'}
                    color={!editingInternal ? '$secondary' : '$white'}
                    variant={!editingInternal ? 'outlined' : undefined}
                    onPress={() => setEditingInternal((c) => !c)}
                  />
                </>
              ) : null}
            </NavGroup>
          </Box>
        </NavRow>
      )}
    </AppBar>
  );
});

const CustomLogo = withStyles({
  root: {
    size: 45,
  },
  white: {
    position: 'absolute',
    left: 0,
    right: 0,
    props: { width: '100%', height: '100%', src: '/images/understory_logo_white.png' },
  },
  normal: {
    props: { width: 45, height: 45 },
  },
})((props) => {
  const { styles, isHomePage, ...rest } = props;
  const [breakpoint] = useBreakpoint();
  const displayWhite = breakpoint.key !== 'xs' && isHomePage;
  return <Box {...rest}>{displayWhite ? <Logo {...styles.toProps('white')} /> : <Logo {...styles.toProps('normal')} />}</Box>;
});

// const NoConnector = React.forwardRef((props, ref) => {
//   return <StepConnector opacity={0} {...props} ref={ref} />;
// });
