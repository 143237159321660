import React, { useState, useContext, createContext } from 'react';
import { Dimensions } from 'react-native';
import { useOnViewportChange } from './useOnViewportChange';

const viewportContext = createContext();
const initWindow = Dimensions.get('window');

export function ProvideViewport({ children }) {
  const [viewport, setViewport] = useState(() => initWindow);
  useOnViewportChange(setViewport);
  return <viewportContext.Provider value={viewport}>{children}</viewportContext.Provider>;
}

export const useViewport = () => useContext(viewportContext);

export function withViewport(WrappedComponent) {
  const ViewportComponent = React.forwardRef((props, ref) => {
    const viewport = useViewport();
    return <WrappedComponent viewport={viewport} ref={ref} {...props} />;
  });
  return ViewportComponent;
}
