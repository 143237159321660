import { inputTests } from '../../ui/utils';

export const validateApplicationLocationData = ({ loc = {}, locations = [], shouldAddRiskId }) => {
  const { id, name, address, regionCode, postcode, countryCode, lat, lon, coveredValue, riskId } = loc;
  const errors = { validationError: true };
  let valid = true;

  // Validate riskId
  if (shouldAddRiskId && !inputTests.number(riskId)) {
    errors.riskId = 'Risk ID is required';
    valid = false;
  } else if (shouldAddRiskId && locations.some((location) => location.riskId === riskId && location.id !== id)) {
    errors.riskId = 'This Risk ID is already associated with another location. Please use a different Risk ID.';
    valid = false;
  }

  // Validate name
  if (!inputTests.filled(name)) {
    errors.name = 'The business name of the location is required';
    valid = false;
  } else if (locations.some((location) => location.name.toLowerCase() === name.toLowerCase() && location.id !== id)) {
    errors.name = 'This business name is already in use. Please choose a different one.';
    valid = false;
  }

  if (!inputTests.filled(address)) {
    errors.address = 'Required';
    valid = false;
  }
  if (!inputTests.filled(regionCode)) {
    errors.regionCode = 'Required';
    valid = false;
  }
  if (!inputTests.filled(postcode)) {
    errors.postcode = 'Required';
    valid = false;
  }
  if (!inputTests.filled(countryCode)) {
    errors.countryCode = 'Required';
    valid = false;
  }
  if (!inputTests.number(lat)) {
    errors.lat = 'Required';
    valid = false;
  }
  if (!inputTests.number(lon)) {
    errors.lon = 'Required';
    valid = false;
  }
  if (!inputTests.number(coveredValue)) {
    errors.coveredValue = 'Required';
    valid = false;
  } else if (coveredValue <= 0) {
    errors.coveredValue = 'Value cannot be less than or equal to 0';
    valid = false;
  }

  if (!valid) {
    throw errors;
  }

  return true;
};
