import React, { useContext } from 'react';

const StepperContext = React.createContext({});

const StepContext = React.createContext({});

function useStepperContext() {
  return useContext(StepperContext);
}

function useStepContext() {
  return useContext(StepContext);
}

export { StepperContext, StepContext, useStepperContext, useStepContext };
