import { resolveStyleFunc, getBreakpointValue } from '../../ui/system';
import { getStyle } from '../../ui/styling';
import { isObject } from '../../ui/utils';

function getStylesForLayout(layout, flexDirection) {
  const attr = flexDirection === 'row' ? ['alignItems', 'justifyContent'] : ['justifyContent', 'alignItems'];
  switch (layout) {
    case 'top-left':
      return { [attr[0]]: 'flex-start', [attr[1]]: 'flex-start' };
    case 'top-center':
      return { [attr[0]]: 'flex-start', [attr[1]]: 'center' };
    case 'top-right':
      return { [attr[0]]: 'flex-start', [attr[1]]: 'flex-end' };
    case 'center-left':
      return { [attr[0]]: 'center', [attr[1]]: 'flex-start' };
    case 'center':
      return { [attr[0]]: 'center', [attr[1]]: 'center' };
    case 'center-right':
      return { [attr[0]]: 'center', [attr[1]]: 'flex-end' };
    case 'bottom-left':
      return { [attr[0]]: 'flex-end', [attr[1]]: 'flex-start' };
    case 'bottom-center':
      return { [attr[0]]: 'flex-end', [attr[1]]: 'center' };
    case 'bottom-right':
      return { [attr[0]]: 'flex-end', [attr[1]]: 'flex-end' };
    default:
      return null;
  }
}

const validFlexDirectionValues = ['row', 'column', 'row-reverse', 'column-reverse'];

function getFlexLayout(props, defaults) {
  const { layout: layoutProp, row = false, stretch = false, flexDirection: fdProp, style: styleProp } = props;
  let flexDirection = resolveStyleFunc(fdProp, props);
  if (styleProp) {
    const style = getStyle(styleProp);
    if (style && style.flexDirection) {
      flexDirection = style.flexDirection;
    }
  }
  if (!flexDirection || !validFlexDirectionValues.includes(flexDirection)) {
    if (row) {
      const displayAsRow = resolveLayoutStyleProp(row, props);
      flexDirection = displayAsRow ? (typeof displayAsRow === 'string' ? displayAsRow : 'row') : 'column';
    }
    if (!flexDirection && defaults && defaults.flexDirection) {
      flexDirection = resolveStyleFunc(defaults.flexDirection, props);
    }
    if (!flexDirection) {
      flexDirection = 'column';
    }
  }
  const layout = resolveLayoutStyleProp(layoutProp, props);
  const result = {
    ...defaults,
    ...getStylesForLayout(layout, flexDirection),
    flexDirection,
  };
  if (stretch && resolveLayoutStyleProp(stretch, props)) {
    result.alignItems = 'stretch';
  }
  return result;
}

function resolveLayoutStyleProp(value, props, opt = {}) {
  const { breakpointUp = false } = opt;
  if (!value) {
    return undefined;
  } else if (value === true) {
    return value;
  } else if (typeof value === 'function') {
    return resolveStyleFunc(value, props);
  } else if (isObject(value)) {
    return getBreakpointValue(value, props);
  } else if (typeof value === 'string' && props.theme && props.theme.breakpoints.keys.indexOf(value) !== -1) {
    const dir = breakpointUp ? 'up' : 'down';
    return getBreakpointValue(
      {
        [props.theme.breakpoints[dir](value)]: false,
        [value]: true,
      },
      props
    );
  }
  return value;
}

export { getFlexLayout, resolveLayoutStyleProp };
