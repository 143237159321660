import { useMemo } from 'react';
// import { Colors } from '../constants';
import { bindFormItemData } from '../constants/searchData/bindFormItemData';

const statusOptions = bindFormItemData.status.filter.options;

export const useBindFormStatus = (bindFormData) => {
  const { isUpdatable, approvedAt, rejectedAt, receivedAt } = bindFormData || {};

  const status = useMemo(() => {
    if (approvedAt) {
      return { ...statusOptions['accepted'], fieldValue: undefined };
    }

    if (rejectedAt) {
      if (isUpdatable) {
        return { ...statusOptions['revising'], fieldValue: undefined };
      }
      return { ...statusOptions['unresolved'], fieldValue: undefined };
    }

    if (receivedAt) {
      if (!isUpdatable) {
        return { ...statusOptions['reviewable'], fieldValue: undefined };
      }
      return { ...statusOptions['in-progress'], fieldValue: undefined };
    }

    return { ...statusOptions['not-started'], fieldValue: undefined };
  }, [approvedAt, isUpdatable, receivedAt, rejectedAt]);

  return status;
};

export const useProgressPropsForBindFormStatus = (formStatus) => {
  return useMemo(() => {
    let progress = '0%';
    let color = '#ccc';
    let text = 'Unknown';

    const formStatusName = formStatus ? formStatus.name : null;
    if (formStatusName) {
      text = formStatus.label;
      switch (formStatusName) {
        case 'not-started':
          progress = '5%';
          break;
        case 'in-progress':
          color = '#F9E79F';
          progress = '25%';
          break;
        case 'reviewable':
          color = '$coral';
          progress = '55%';
          break;
        case 'revising': {
          color = 'orange';
          progress = '75%';
          break;
        }
        case 'unresolved': {
          color = 'red';
          progress = '60%';
          break;
        }
        case 'accepted': {
          color = '$secondary';
          progress = '100%';
          break;
        }
        default:
          break;
      }
    }

    return [progress, { color, children: text }];
  }, [formStatus]);
};
