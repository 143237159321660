import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import { ActivityIndicator, Box, Button, DatePicker, Heading, Menu, MenuItem, Paragraph, Text, TextField, TextInput } from '../../../../ui';
import { Controller } from 'react-hook-form';
import { Dialog, LayoutBox } from '../../../../components';
import { CalendarIcon, CancelIcon, CrossIcon, EditIcon, EllipsisIcon, GreenCheckIcon, RedCrossIcon } from '../../../../components/icons';
import moment from 'moment-timezone';
import { formatMoney, getGMTTimestamp } from '../../../../utils';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';
import { useAdjustmentForm } from '../../context/QuoteAdjustment/AdjustmentFormContext';
import { QuoteStat } from './QuoteStat';
import { useQuoteData } from '../../context/QuoteDataContext';
import { useApi, useAppState, useAsync, useNavigate } from '../../../../hooks';
import { ShieldCheck } from '../../../../ui/icons';

const QuoteDescription = ({ canAddQuoteDescription, quote }) => (
  <Box>
    <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'} backgroundColor="#fff" padding={24} borderRadius={8} gap={16}>
      <Heading level={5}>Quote Description</Heading>
      <Paragraph style={{ color: '#637381', fontWeight: '500' }}>{quote.description || 'No Quote Description'}</Paragraph>
      {canAddQuoteDescription ? <AddQuoteDescription quoteId={quote.id} currentDescription={quote.description} alignSelf="flex-start" /> : null}
    </Box>
  </Box>
);

const AddQuoteDescription = memo(function AddQuoteDescription(props) {
  const { quoteId, currentDescription = '', ...rest } = props;
  const [description, setDescription] = useState('');
  const [open, setOpen] = useState(false);
  const { addDescriptionForQuoteWithId } = useApi();
  const handleAddDescription = useCallback(
    async (val) => {
      console.log(quoteId, val);
      const result = await addDescriptionForQuoteWithId(quoteId, val);
      console.log(result);
      setOpen(false);
    },
    [addDescriptionForQuoteWithId, quoteId]
  );
  const { execute, status } = useAsync(handleAddDescription, { immediate: false });

  useEffect(() => {
    if (open) {
      setDescription(currentDescription || '');
    }
  }, [open, currentDescription]);

  const loading = status === 'pending';

  return (
    <>
      <Button color="$primary.light" variant="text" label="Update" onPress={() => setOpen(true)} {...rest} />
      <Dialog
        open={open}
        heading="Update Quote Description"
        onClose={() => !loading && setOpen(false)}
        actions={
          <>
            <Button label="Cancel" variant="text" color="$primary" onPress={() => setOpen(false)} disabled={loading} />
            <Button
              label="Submit"
              onPress={() => execute(description)}
              disabled={loading || !description.trim()}
              loading={loading}
              color={!description ? '$gray.500' : '$primary'}
            />
          </>
        }
      >
        <TextField
          InputProps={{ multiline: true }}
          multiline
          height={200}
          width="100%"
          maxWidth="100%"
          disabled={loading}
          value={description}
          onChangeValue={(v) => setDescription(v)}
        />
      </Dialog>
    </>
  );
});

const EditableField = ({ value, handleOnPress, shouldShowPicker = true, component }) => {
  return (
    <Box
      style={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 8,
        fontSize: 16,
      }}
      flexDirection="row"
      paddingLeft={4}
    >
      <>
        {shouldShowPicker && (
          <Box onPress={() => handleOnPress()}>
            <CalendarIcon />
          </Box>
        )}

        {!component ? <Text maxWidth="100%" size="medium" color="#637381" maxLines={2} bold value={value} /> : component}
      </>
    </Box>
  );
};

const OverViewSection = ({ quote }) => {
  const { adjusting } = useAdjustmentState();
  const { setValue } = useAdjustmentForm();
  const { adjustmentFactor, netPremium, locations } = quote;

  const [{ showPremium, showAdjustmentFactor, canAdjustQuote }] = useAppState();
  const [isStartDateEditable, setIsStartDateEditable] = useState(false);
  const [startEditingStartDate, setStartEditingStartDate] = useState(false);
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(quote.coverageAt ? moment.utc(quote.coverageAt) : moment.utc());

  const [isEndDateEditable, setIsEndDateEditable] = useState(false);
  const [startEditingEndDate, setStartEditingEndDate] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [endDate, setEndDate] = useState(quote.coverageUntil ? moment.utc(quote.coverageUntil) : moment(startDate).add(1, 'year'));

  const [isAdjustmentEditable, setIsAdjustmentEditable] = useState(false);
  const [startEditingAdjustment, setStartEditingAdjustment] = useState(false);
  const [adjustmentFactorValue, setAdjustmentFactorValue] = useState(adjustmentFactor.toFixed(6));
  const [targetPremiumValue, setTargetPremiumValue] = useState(netPremium);

  const [isPremiumEditable, setIsPremiumEditable] = useState(false);
  const [startEditingPremium, setStartEditingPremium] = useState(false);

  const handleSetFieldEditable = (field) => {
    setIsStartDateEditable(field === 'startDate');
    setIsEndDateEditable(field === 'endDate');
    setIsAdjustmentEditable(field === 'adjustmentFactor');
    setIsPremiumEditable(field === 'targetPremium');
  };

  useEffect(() => {
    // Calculate endDate as startDate + 1 year whenever startDate changes
    const newEndDate = moment(startDate).add(1, 'year');
    adjusting && setValue('quote.coverageUntil', Date.parse(newEndDate));
    setEndDate(newEndDate);
  }, [startDate, adjusting, setValue]);

  useEffect(() => {
    setStartDate(moment.utc(quote.coverageAt));
    setEndDate(moment.utc(quote.coverageUntil));
    setTargetPremiumValue(netPremium);
    setAdjustmentFactorValue(quote.adjustmentFactor.toFixed(6));
  }, [quote, netPremium]);

  return (
    <LayoutBox
      row
      flexDirection="column"
      alignItems="flex-start"
      alignSelf="stretch"
      flex="1 0 0"
      padding="24px"
      borderRadius="8px"
      backgroundColor="#fff"
      width="50%"
    >
      <Text row style={{ fontWeight: 600, fontSize: 16, maxLines: 2 }} color=" #212B36">
        Overview
      </Text>
      <LayoutBox row alignItems="center" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch">
        <Text style={{ color: '#212B36', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>Start Date </Text>
        <Box style={{ color: '#637381', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>
          {startEditingStartDate && adjusting ? (
            <EditableField
              component={
                <TextInput label="Start Date" value={startDate.format('MM-DD-YY')} variant="filled" placeholder="0" bold alignItems="center" />
              }
              handleOnPress={() => setStartDatePickerOpen(true)}
            />
          ) : (
            <div
              style={{ display: 'flex', alignItems: 'center', cursor: `${adjusting ? 'pointer' : ''}` }}
              onMouseEnter={() => canAdjustQuote && handleSetFieldEditable('startDate')}
            >
              <Text maxWidth="100%" size="medium" color="#637381" maxLines={2} bold ma>
                {startDate.format('MM/DD/YYYY')}
              </Text>
            </div>
          )}
        </Box>
        <Box height="24px" width="24px" alignItems="center" flexDirection="row" marginRight={4}>
          {isStartDateEditable && !startEditingStartDate && adjusting ? (
            <Box
              onPress={() => {
                setStartEditingStartDate(true);
              }}
            >
              <EditIcon />
            </Box>
          ) : (
            startEditingStartDate &&
            adjusting && (
              <>
                <Box
                  onPress={() => {
                    adjusting && setValue('quote.coverageAt', getGMTTimestamp(moment(Date.parse(startDate)).format('DD-MM-YYYY')));
                    setStartEditingStartDate(false);
                  }}
                >
                  <GreenCheckIcon />
                </Box>
                <Box
                  onPress={() => {
                    setIsStartDateEditable(false);
                    setStartEditingStartDate(false);
                    setStartDate(moment.utc(quote.coverageAt));
                  }}
                >
                  <CrossIcon />
                </Box>
              </>
            )
          )}
        </Box>
        <Dialog open={startDatePickerOpen} onClose={() => setStartDatePickerOpen(false)}>
          <DatePicker
            date={startDate}
            onChange={(date) => {
              setStartDate(date);
              setStartDatePickerOpen(false);
            }}
          />
        </Dialog>
      </LayoutBox>
      <LayoutBox row alignItems="center" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch">
        <Text style={{ color: '#212B36', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>End Date </Text>
        <Box style={{ color: '#637381', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>
          {startEditingEndDate && adjusting ? (
            <EditableField
              component={
                <TextInput
                  label="End Date"
                  value={endDate.format('MM-DD-YY')}
                  variant="filled"
                  placeholder="0"
                  type="text"
                  bold
                  alignItems="center"
                />
              }
              handleOnPress={() => setEndDatePickerOpen(true)}
            />
          ) : (
            <div
              style={{ display: 'flex', alignItems: 'center', cursor: `${adjusting ? 'pointer' : ''}` }}
              onMouseEnter={() => {
                canAdjustQuote && handleSetFieldEditable('endDate');
              }}
            >
              <Text maxWidth="100%" size="medium" color="#637381" maxLines={2} bold ma>
                {endDate.format('MM/DD/YYYY')}
              </Text>
            </div>
          )}
        </Box>
        <Box height="24px" width="24px" alignItems="center" flexDirection="row" marginRight={4}>
          {isEndDateEditable && !startEditingEndDate && adjusting ? (
            <Box
              onPress={() => {
                setStartEditingEndDate(true);
              }}
            >
              <EditIcon />
            </Box>
          ) : (
            startEditingEndDate &&
            adjusting && (
              <>
                <Box
                  onPress={() => {
                    adjusting && setValue('quote.coverageUntil', getGMTTimestamp(moment(Date.parse(endDate)).format('DD-MM-YYYY')));
                    setStartEditingEndDate(false);
                  }}
                >
                  <GreenCheckIcon />
                </Box>
                <Box
                  onPress={() => {
                    setIsEndDateEditable(false);
                    setStartEditingEndDate(false);
                    setEndDate(moment.utc(quote.coverageUntil));
                  }}
                >
                  <CrossIcon />
                </Box>
              </>
            )
          )}
        </Box>
        <Dialog open={endDatePickerOpen} onClose={() => setEndDatePickerOpen(false)}>
          <DatePicker
            date={endDate}
            onChange={(date) => {
              setEndDate(date);
              setEndDatePickerOpen(false);
            }}
          />
        </Dialog>
      </LayoutBox>
      <LayoutBox row alignItems="center" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch" marginRight="10px">
        <Text style={{ color: '#212B36', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>Total Insured Value </Text>
        <Text
          maxWidth="100%"
          size="medium"
          color="#637381"
          maxLines={2}
          style={{ color: '#637381', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}
          bold
          ma
          value={formatMoney(quote.coverageTotal, { prefix: '$' })}
        ></Text>
        <Box height="24px" width="24px" alignItems="center" flexDirection="row" marginRight={4}></Box>
      </LayoutBox>
      <LayoutBox row alignItems="center" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch" marginRight="10px">
        <Text style={{ color: '#212B36', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>Locations </Text>
        <Text
          maxWidth="100%"
          size="medium"
          color="#637381"
          maxLines={2}
          style={{ color: '#637381', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}
          bold
          ma
          value={`${locations.length}`}
        ></Text>
        <Box height="24px" width="24px" alignItems="center" flexDirection="row" marginRight={4}></Box>
      </LayoutBox>
      <LayoutBox row alignItems="center" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch" marginRight="10px">
        <Text style={{ color: '#212B36', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>Adjustment Factor </Text>
        <Box style={{ color: '#637381', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>
          {showPremium &&
            showAdjustmentFactor &&
            (startEditingAdjustment && adjusting ? (
              <EditableField
                component={
                  <Controller
                    name="quote.adjustmentFactor"
                    rules={{ required: 'Required' }}
                    render={({ field: { value, ref, onChange, onBlur }, fieldState: { error } }) => {
                      return (
                        <TextInput
                          value={value !== null ? value : ''}
                          variant="filled"
                          placeholder="0"
                          type="number"
                          error={error}
                          helperText={error ? error.message : null}
                          onChangeValue={(v) => {
                            onChange(v);
                            setAdjustmentFactorValue(Number(v).toFixed(6));
                          }}
                          onBlur={(e, valueError) => {
                            if (valueError && valueError.fixed) {
                              setValue('quote.adjustmentFactor', valueError.fixed, { shouldValidate: true });
                            } else if (value === null) {
                              setValue('quote.adjustmentFactor', null, { shouldValidate: true });
                            }
                            onBlur(e);
                          }}
                          inputRef={ref}
                        />
                      );
                    }}
                  />
                }
                shouldShowPicker={false}
              />
            ) : (
              <div
                style={{ display: 'flex', alignItems: 'center', cursor: `${adjusting ? 'pointer' : ''}` }}
                onMouseEnter={() => {
                  canAdjustQuote && handleSetFieldEditable('adjustmentFactor');
                }}
              >
                <Text maxWidth="100%" size="medium" color="#637381" maxLines={2} bold ma>
                  {adjustmentFactorValue}
                </Text>
              </div>
            ))}
        </Box>
        <Box height="24px" width="24px" alignItems="center" flexDirection="row" marginRight={4}>
          {isAdjustmentEditable && !startEditingAdjustment && adjusting ? (
            <Box
              onPress={() => {
                setStartEditingAdjustment(true);
              }}
            >
              <EditIcon />
            </Box>
          ) : (
            startEditingAdjustment &&
            adjusting && (
              <Box
                flexDirection="row"
                onPress={() => {
                  setStartEditingAdjustment(false);
                  adjusting && setValue('quote.adjustmentFactor', Number(adjustmentFactorValue));
                }}
              >
                <Box>
                  <GreenCheckIcon />
                </Box>
                <Box
                  onPress={() => {
                    setStartEditingAdjustment(false);
                    adjusting && setValue('quote.adjustmentFactor', adjustmentFactor.toFixed(6));
                    setAdjustmentFactorValue(adjustmentFactor.toFixed(6));
                  }}
                >
                  <CrossIcon />
                </Box>
              </Box>
            )
          )}
        </Box>
      </LayoutBox>
      <LayoutBox row alignItems="center" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch" marginRight="10px">
        <Text style={{ color: '#212B36', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>Target Premium </Text>
        <Box style={{ color: '#637381', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>
          {showPremium &&
            showAdjustmentFactor &&
            (startEditingPremium && adjusting ? (
              <EditableField
                component={
                  <Controller
                    name="quote.targetPremium"
                    render={({ field: { value, ref, onChange, onBlur }, fieldState: { error } }) => {
                      return (
                        <TextInput
                          label="Target Premium"
                          value={value || targetPremiumValue}
                          variant="filled"
                          placeholder="0"
                          type="number"
                          error={error}
                          helperText={error ? error.message : null}
                          onChangeValue={(v) => {
                            onChange(v);
                            setTargetPremiumValue(v);
                          }}
                          onBlur={(e, valueError) => {
                            if (valueError && valueError.fixed) {
                              setValue('quote.targetPremium', valueError.fixed, { shouldValidate: true });
                            } else if (value === null) {
                              setValue('quote.targetPremium', null, { shouldValidate: true });
                            }
                            onBlur(e);
                          }}
                          inputRef={ref}
                          LabelProps={{ weight: '$bold', size: 'small' }}
                          hideHelperText={false}
                        />
                      );
                    }}
                  />
                }
                shouldShowPicker={false}
              />
            ) : (
              <div
                style={{ display: 'flex', alignItems: 'center', cursor: `${adjusting ? 'pointer' : ''}` }}
                onMouseEnter={() => {
                  canAdjustQuote && handleSetFieldEditable('targetPremium');
                }}
              >
                <Text maxWidth="100%" size="medium" color="#637381" maxLines={2} bold ma>
                  {targetPremiumValue || ' '}
                </Text>
              </div>
            ))}
        </Box>
        <Box height="24px" width="24px" alignItems="center" flexDirection="row" marginRight={4}>
          {isPremiumEditable && !startEditingPremium && adjusting ? (
            <Box
              onPress={() => {
                setStartEditingPremium(true);
              }}
            >
              <EditIcon />
            </Box>
          ) : (
            startEditingPremium &&
            adjusting && (
              <Box
                flexDirection="row"
                onPress={() => {
                  adjusting && setValue('quote.targetPremium', Number(targetPremiumValue));
                  setStartEditingPremium(false);
                }}
              >
                <Box>
                  <GreenCheckIcon />
                </Box>
                <Box
                  onPress={() => {
                    adjusting && setValue('quote.targetPremium', netPremium);
                    setTargetPremiumValue(netPremium);
                    setStartEditingPremium(false);
                  }}
                >
                  <CrossIcon />
                </Box>
              </Box>
            )
          )}
        </Box>
      </LayoutBox>
    </LayoutBox>
  );
};

const CustomerDetailsSection = ({ quote }) => {
  const btnRef = useRef();
  const { generateQuoteDocs } = useApi();
  const navigate = useNavigate();
  const { application } = quote;
  const [{ canViewWorkbook }] = useAppState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [apiError, setApiError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [successFulApiCall, setSuccessFulApiCall] = useState(false);

  const getAgentName = (agent) => {
    if (typeof agent.name === 'string') return agent.name.split('@')[0];
    else if (agent.email) return agent.email.split('@')[0];
    else return 'Not listed';
  };

  const getStates = (locations) => {
    const regionCodes = locations.map((location) => location.regionCode);
    const uniqueSortedRegionCodes = [...new Set(regionCodes)].sort();
    return uniqueSortedRegionCodes.join(', ');
  };

  const customerDetails = [
    { label: 'Agent', value: getAgentName(quote.agent) },
    { label: 'Broker', value: quote.broker.name },
    { label: 'Applied At', value: moment.utc(application.appliedAt).format('MM-DD-YYYY') },
    { label: 'Target Date', value: moment.utc(application.targetEffectiveAt).format('MM-DD-YYYY') },
    { label: 'States', value: getStates(quote.locations) },
  ];

  const handleDownloadItem = useCallback(
    async (itemType) => {
      let url;
      switch (itemType) {
        case 'workbook':
          url = quote.workbookUrl;
          break;
        case 'quote':
          url = quote.quoteDocumentUrl;
          break;
        case 'draftPolicy':
          url = quote.draftPolicyUrl;
          break;
        case 'binder':
          url = quote.binderUrl;
          break;
        default:
          console.error('Invalid item type:', itemType);
          return;
      }
      window.open(url, '_blank');
    },
    [quote]
  );

  const { execute: downloadItem } = useAsync(handleDownloadItem, { immediate: false });

  const handleGenerateQuoteDocs = async (quoteId) => {
    setLoading(true);
    setSuccessFulApiCall(false);
    setApiError(false);

    try {
      const response = await generateQuoteDocs(quoteId);
      if (response && !response.errorMessage) {
        setLoading(false);
        setSuccessFulApiCall(true);
      } else {
        setLoading(false);
        setApiError(true);
      }
    } catch (error) {
      setLoading(false);
      setApiError(true);
    }
  };

  const InfoRow = ({ label, value }) => (
    <LayoutBox row alignItems="center" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch">
      <Text style={{ color: '#212B36', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>{label}</Text>
      <Text maxWidth="100%" bold style={{ color: '#637381', fontSize: 14, fontStyle: 'normal', fontWeight: 500, flex: '1 0 0' }}>
        {value}
      </Text>
    </LayoutBox>
  );

  return (
    <LayoutBox
      row
      flexDirection="column"
      alignItems="flex-start"
      alignSelf="stretch"
      flex="1 0 0"
      padding="24px"
      gap={4}
      borderRadius="8px"
      width="50%"
      backgroundColor="#fff"
    >
      <Box flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
        <Text row style={{ fontWeight: 600, fontSize: 16, maxLines: 2 }} color=" #212B36">
          {quote.insured}
        </Text>
        <Box justifyContent="flex-end" onPress={() => setMenuOpen(true)} ref={btnRef}>
          <Menu
            anchorNode={btnRef.current}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            rounded={20}
          >
            <MenuItem
              px={'$1'}
              py={'3'}
              width="220px"
              onClick={() => {
                navigate.to(`/applications/${application.id}`);
              }}
            >
              <Text color="#000" bold>
                View Application
              </Text>
            </MenuItem>
            <MenuItem px={'$1'} py={'2'} onClick={() => handleGenerateQuoteDocs(quote.id)}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Text color="#000" bold>
                  Refresh Document
                </Text>
                <span>
                  {loading && <ActivityIndicator color="#00ff00" size="small" />}
                  {successFulApiCall && <ShieldCheck />}
                  {apiError && <RedCrossIcon />}
                </span>
              </div>
            </MenuItem>
            {canViewWorkbook && (
              <>
                <Box px={'$1'} py={'2'} borderTopWidth={1} borderTopColor="$gray.200">
                  <Text color="#212B36">Download</Text>
                </Box>
                <MenuItem px={'$1'} py={'2'} onClick={() => downloadItem('workbook')}>
                  <Text color="#000" bold>
                    Workbook
                  </Text>
                </MenuItem>
                <MenuItem px={'$1'} py={'2'} onClick={() => downloadItem('quote')}>
                  <Text color="#000" bold>
                    Quote
                  </Text>
                </MenuItem>
                <MenuItem px={'$1'} py={'2'} onClick={() => downloadItem('draftPolicy')}>
                  <Text color="#000" bold>
                    Sample Policy
                  </Text>
                </MenuItem>
                <MenuItem px={'$1'} py={'2'} onClick={() => downloadItem('binder')}>
                  <Text color="#000" bold>
                    Binder
                  </Text>
                </MenuItem>
              </>
            )}
          </Menu>
          <EllipsisIcon />
        </Box>
      </Box>
      <Text row style={{ fontWeight: 400, fontSize: 14, maxLines: 2 }} color=" #212B36">
        {application?.customer && application.customer?.address}
      </Text>
      {customerDetails.map((item, index) => (
        <InfoRow key={index} label={item.label} value={item.value} />
      ))}
    </LayoutBox>
  );
};

export const NewQuoteDetails = React.forwardRef((props, ref) => {
  const { quote } = props;
  const { data, totalAllInCost } = useQuoteData();
  const [{ canAddQuoteDescription }] = useAppState();
  const { showOverViewSection, setShowOverViewSection } = useAdjustmentState();

  return (
    <Box ref={ref}>
      <LayoutBox gap={18}>
        <QuoteStat data={data} totalAllInCost={totalAllInCost} />
      </LayoutBox>
      <div className={`details-container ${showOverViewSection ? 'open' : 'closed'}`}>
        <LayoutBox gap={18} marginBottom={15}>
          <LayoutBox row alignSelf="stretch" gap={32} alignItems="center" width="100%">
            <OverViewSection quote={quote} />
            <CustomerDetailsSection quote={quote} />
          </LayoutBox>
        </LayoutBox>
        <LayoutBox gap={18}>
          <QuoteDescription canAddQuoteDescription={canAddQuoteDescription} quote={quote} />
        </LayoutBox>
      </div>
      <Box alignItems="start" bold>
        <Button
          color="$primary.light"
          variant="text"
          label={showOverViewSection ? 'Show Less' : 'Show More'}
          onPress={() => setShowOverViewSection(!showOverViewSection)}
        />
      </Box>
    </Box>
  );
});
