import React, { useCallback, useEffect, useState } from 'react';
import { Box, GridModal, Text, FileInput, Heading } from '../ui';
import { LayoutBox } from './Layout';
import { FileIcon, CrossIcon, WarningIcon } from './icons';
import { View } from 'react-native-web';
import { readString } from 'react-papaparse';

const renderModal = (currentModal, setCurrentModal, file, handleFileChange, setSelectedFileRows, errors) => {
  switch (currentModal) {
    case 'ImportFileModal':
      return <ImportFileModal handleFileChange={handleFileChange} errors={errors} />;
    case 'ProgressModal':
      return (
        <ProgressModal
          setCurrentModal={setCurrentModal}
          file={file}
          setSelectedFileRows={setSelectedFileRows}
          handleFileChange={handleFileChange}
          errors={errors}
        />
      );
    case 'SuccessModal':
      return <SuccessModal />;
    default:
      return null;
  }
};

const SuccessModal = () => {
  return (
    <Box alignItems="start" paddingLeft={10}>
      <Heading paddingTop={30} level={4} paddingBottom={10}>
        Upload complete
      </Heading>
      <Text dim size="large">
        Your file is successfully imported. Confirm import by selecting the import action below.
      </Text>
    </Box>
  );
};

const ProgressModal = ({ setCurrentModal, file, setSelectedFileRows, handleFileChange, errors = [] }) => {
  const [progress, setProgress] = useState(0);

  const increaseProgress = useCallback(() => {
    setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 10 : 100));
  }, [setProgress]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      !errors.length && increaseProgress();
    }, 1000);
    return () => clearInterval(intervalId);
  }, [progress, increaseProgress, errors]);

  const onPressHandleFile = () => {
    setCurrentModal('ImportFileModal');
    setSelectedFileRows([]);
    handleFileChange();

    setProgress(0);
  };
  return !errors.length ? (
    <Box>
      <Box flexDirection="row" alignItems="start" paddingLeft={10}>
        <Heading paddingTop={30} level={5}>
          File
        </Heading>
      </Box>
      <LayoutBox width="100%" paddingLeft={1} paddingRight={10} paddingTop={10} paddingBottom={10} flexDirection="row" alignItems="center">
        <LayoutBox width="30%">
          <Text size="middle">{file.name}</Text>
        </LayoutBox>
        <LayoutBox width="60%" flexDirection="row" alignItems="start">
          <View style={{ width: '100%', height: 6, backgroundColor: '#95C8E43D', borderRadius: 10, overflow: 'hidden' }}>
            <View style={{ width: `${progress}%`, height: '100%', backgroundColor: '#5F2994' }} />
            {progress === 100 && setCurrentModal('SuccessModal')}
          </View>
        </LayoutBox>
        <LayoutBox width="5%" paddingLeft={3}>
          <Text size="middle">{progress}%</Text>
        </LayoutBox>
        <LayoutBox width="5%" paddingLeft={3}>
          <Box onPress={onPressHandleFile}>
            <CrossIcon />
          </Box>
        </LayoutBox>
      </LayoutBox>
    </Box>
  ) : (
    <ImportFileModal handleFileChange={handleFileChange} errors={errors} />
  );
};

const ImportFileModal = ({ handleFileChange, errors = [] }) => {
  const hasError = errors && errors.length > 0;

  return (
    <Box marginY="16px" paddingLeft="78px">
      {hasError && (
        <Box backgroundColor="#FFBBBA" width="550px" borderRadius={8} padding="10px" marginBottom={10}>
          <Box flexDirection="row">
            <Box>
              <WarningIcon height={60} width={60} />
            </Box>
            <Text color="#5A0100" size="medium" weight={700} marginLeft="5px">
              Please resolve the following errors and re-import the file:
            </Text>
          </Box>
          <Box alignItems="start" maxHeight="200px" overflow="auto">
            {errors?.map((error, index) => (
              <Text key={index} color="#5A0100" size="medium">
                {error}
              </Text>
            ))}
          </Box>
        </Box>
      )}
      <FileInput accept=".csv" onChangeValue={handleFileChange} inputFieldIcon={<FileIcon />} />
    </Box>
  );
};

const ImportModal = ({ importModal, setImportModal, setSelectedFileRows, errors, setErrors, addImportedFileRows }) => {
  const [currentModal, setCurrentModal] = useState('ImportFileModal');
  const [file, setFile] = useState();

  const handleImport = () => {
    addImportedFileRows();
    setImportModal(false);
    setCurrentModal('ImportFileModal');
    setFile('');
    setSelectedFileRows([]);
    setErrors([]);
  };

  const handleFileChange = (e) => {
    const file = e[0];
    setFile(file);
    setCurrentModal('ProgressModal');
    const reader = new FileReader();
    reader.onload = async (event) => {
      const csvData = event.target.result;
      const parsedData = await new Promise((resolve, reject) => {
        readString(csvData, {
          header: true,
          dynamicTyping: true,
          complete: (parsedData) => resolve(parsedData),
          error: (error) => reject(error),
        });
      });
      const data = parsedData.data.filter((row) => Object.values(row).some((val) => val !== null && val !== undefined));
      setSelectedFileRows(data);
    };
    reader.readAsText(file);
  };

  return (
    <GridModal
      title="Import"
      buttonText="Import"
      handlePress={handleImport}
      open={importModal}
      onClose={() => {
        setImportModal(false);
        setCurrentModal('ImportFileModal');
        setFile('');
        setSelectedFileRows([]);
        setErrors([]);
      }}
      width="730px"
      disabled={currentModal !== 'SuccessModal'}
    >
      {renderModal(currentModal, setCurrentModal, file, handleFileChange, setSelectedFileRows, errors)}
    </GridModal>
  );
};

export { ImportModal };
