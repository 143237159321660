export const calculateCoverageLimits = (type, coveredValue, floodSublimit = 0, eqSublimit = 0) => {
  const avgTotalInsuredValue = Number(coveredValue);
  const defaultLimit = 5000000;
  const defaultFalsePretense = 200000;
  switch (type) {
    case 'comprehensive':
    case 'collision':
    case 'hail':
    case 'terrorism':
      return avgTotalInsuredValue;
    case 'flood':
      return Math.min(avgTotalInsuredValue, floodSublimit || defaultLimit);
    case 'earthquake':
      return Math.min(avgTotalInsuredValue, eqSublimit || defaultLimit);
    case 'falsePretense':
      return Math.min(avgTotalInsuredValue, defaultFalsePretense);
    default:
      return 0;
  }
};

export const getDefaultCoverages = (quotes, data) => {
  let allCoverages = ['comprehensive', 'flood', 'earthquake', 'hail', 'collision', 'falsePretense', 'terrorism'];
  const locationIndex = quotes.locations.findIndex((location) => location.id === data?.id);
  const previousIndex = locationIndex <= 0 ? quotes.locations.length - 1 : locationIndex - 1;

  const previousDeductibles =
    quotes.locations.length > 0 ? quotes.locations[previousIndex].coverages.map((cov) => cov.deductible) : [0, 0, 0, 0, 0, 0, 0];

  const coverageIds = [6, 21, 22, 9, 7, 10, 11];

  allCoverages = allCoverages.map((item, index) => {
    return {
      coverageId: coverageIds[index], // Add coverageId
      coverageType: item,
      locationId: data?.id || null,
      payoutLimit: calculateCoverageLimits(item, data?.coveredValue) || 0,
      deductible: previousDeductibles[index] || 0,
      aggregate: 0,
      netPremium: 0,
      isIncluded: false, // Add isIncluded
    };
  });

  return allCoverages;
};
