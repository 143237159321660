import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Button, Text, TextField, Spacing, Menu, MenuItem } from '../../../../ui';
import { Controller, useWatch } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';
import { withStyles } from '../../../../ui/styling';
import { useApi, useAsync, useAppState } from '../../../../hooks';
import { AppLoadingOverlay, Dialog } from '../../../../components';
// import { useEventCallback } from '../../../../ui/hooks';
// import { ApplicationView } from '../../../ApplicationPage';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';
import { useAdjustmentForm } from '../../context/QuoteAdjustment/AdjustmentFormContext';
import { useAdjustmentPreview } from '../../context/QuoteAdjustment/AdjustmentPreviewContext';
import { EllipsisIcon } from '../../../../components/icons';
import { ViewQuoteHistoryButton } from './QuoteHistory';
import { useQuoteData } from '../../context/QuoteDataContext';
import { QuoteStatuses } from '../../../../constants';

export const QuoteActions = withStyles(({ theme }) => ({
  // root: {
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  //   alignItems: 'flex-end',
  //   alignSelf: 'stretch',
  //   padBottom: '$2',
  //   padLeft: '$4',
  //   padRight: '$2',
  //   minHeight: 60,
  //   opacity: 0,
  // },
  // start: {
  //   flexDirection: 'row',
  //   justifyContent: 'flex-start',
  //   alignItems: 'center',
  //   props: { gap: 12 },
  // },
  // end: {
  //   flexDirection: 'row',
  //   justifyContent: 'flex-end',
  //   alignItems: 'center',
  //   props: { gap: 12 },
  // },
  root: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '36px',
    width: '80px',
    fontSize: '14px',
  },
  primaryAction: {
    marginLeft: '$1.5',
    props: {
      color: '$primary',
      variant: 'contained',
    },
  },
  secondaryAction: {
    marginLeft: '$1.5',
    props: {
      color: '$primary',
      variant: 'text',
    },
  },
  rejectAction: {
    marginLeft: '$1.5',
    props: {
      color: '#FE0300',
      variant: 'contained',
    },
  },
}))(
  React.forwardRef(function QuoteActions(props, ref) {
    const { quote, styles, refreshQuoteData, printRef, ...rest } = props;
    const { id: quoteId } = quote;
    const btnRef = useRef();
    const [menuOpen, setMenuOpen] = useState(false);

    const primaryActionProps = {
      style: styles.primaryAction,
      ...styles.props.primaryAction,
    };

    const secondaryActionProps = {
      style: styles.secondaryAction,
      ...styles.props.secondaryAction,
    };
    const rejectionProps = {
      style: styles.rejectAction,
      ...styles.props.rejectAction,
    };
    const [{ canAdjustQuote, canRequestChangesToQuote, canPrintQuote, canAcceptQuote, canRejectQuote }] = useAppState();
    const { adjusting, disable, submitting, setCanPreview, canExecuteBind, enable } = useAdjustmentState();

    const { data, lastUpdated } = useQuoteData();

    const { hasQuoteError, trigger } = useAdjustmentForm();
    const [submitAdjustmentOpen, setSubmitAdjustmentOpen] = useState(false);
    const [publishQuoteOpen, setPublishQuoteOpen] = useState(false);
    const [acceptQuoteOpen, setAcceptQuoteOpen] = useState(false);
    const [rejectQuoteOpen, setRejectQuoteOpen] = useState(false);
    const [requestChangesOpen, setRequestChangesOpen] = useState(false);
    const [bindModalOpen, setBindModalOpen] = useState(false);

    useEffect(() => {
      if (submitAdjustmentOpen) {
        trigger();
      }
    }, [submitAdjustmentOpen, trigger]);

    useEffect(() => {
      setSubmitAdjustmentOpen(false);
    }, [hasQuoteError]);

    const { releaseQuoteWithId, submitRequestOnQuoteId, acceptQuoteWithId, rejectQuoteWithId, bindQuoteWithId } = useApi();

    /* RELEASE/PUBLISH QUOTE API/STATE */
    const {
      execute: releaseQuote,
      status: releaseStatus,
      error: releaseError,
      setStatus: setReleaseStatus,
    } = useAsync(releaseQuoteWithId, {
      immediate: false,
    });

    useEffect(() => {
      if (releaseStatus === 'success') {
        setReleaseStatus('idle');
        refreshQuoteData();
      }
    }, [refreshQuoteData, releaseStatus, setReleaseStatus]);

    /* REQUEST CHANGES API/STATE */
    const {
      execute: requestChanges,
      status: requestChangesStatus,
      error: requestChangesError,
      setStatus: setRequestChangesStatus,
    } = useAsync(submitRequestOnQuoteId, { immediate: false });

    useEffect(() => {
      if (requestChangesStatus === 'success') {
        setRequestChangesStatus('idle');
        refreshQuoteData();
      }
    }, [refreshQuoteData, requestChangesStatus, setRequestChangesStatus]);

    /* ACCEPT QUOTE API/STATE */
    const {
      execute: acceptQuote,
      status: acceptQuoteStatus,
      error: acceptQuoteError,
      setStatus: setAcceptQuoteStatus,
    } = useAsync(acceptQuoteWithId, { immediate: false });

    useEffect(() => {
      if (acceptQuoteStatus === 'success') {
        setAcceptQuoteStatus('idle');
        refreshQuoteData();
      }
    }, [refreshQuoteData, acceptQuoteStatus, setAcceptQuoteStatus]);

    /* REJECT QUOTE API/STATE */
    const {
      execute: rejectQuote,
      status: rejectQuoteStatus,
      error: rejectQuoteError,
      setStatus: setRejectQuoteStatus,
    } = useAsync(rejectQuoteWithId, { immediate: false });

    useEffect(() => {
      if (rejectQuoteStatus === 'success') {
        setRejectQuoteStatus('idle');
        refreshQuoteData();
      }
    }, [refreshQuoteData, rejectQuoteStatus, setRejectQuoteStatus]);

    const errors = acceptQuoteError || rejectQuoteError || requestChangesError || releaseError;

    if (errors) {
      console.log('ERROR with an action', errors);
    }

    const handlePrint = useReactToPrint({
      content: () => printRef.current,
    });

    const handleBind = useCallback(async () => {
      const policy = await bindQuoteWithId(quoteId);
      await refreshQuoteData();
      if (policy && typeof policy === 'object' && policy.id) {
        console.log('POLICY!', policy);
      }
    }, [quoteId, bindQuoteWithId, refreshQuoteData]);
    const { execute: executeBind, status: executeBindStatus } = useAsync(handleBind, { immediate: false });
    const bound = quote.status === QuoteStatuses.bound;

    const rightElements = [];
    if (canAdjustQuote) {
      if (adjusting) {
        rightElements.push(
          <Box justifyContent="center" key="actions">
            <Text color="#5F2994" bold>
              Action :
            </Text>
          </Box>
        );
        rightElements.push(
          <Button key="preview" onPress={() => setCanPreview(true)} {...secondaryActionProps}>
            Preview
          </Button>
        );
        rightElements.push(
          <Button key="endEditing" onPress={disable} {...secondaryActionProps}>
            Cancel
          </Button>
        );
        rightElements.push(
          <Button
            key="submitAdjustments"
            onPress={() => setSubmitAdjustmentOpen(true)}
            disabled={hasQuoteError}
            {...primaryActionProps}
            color={hasQuoteError ? '$gray.400' : '$primary'}
          >
            Submit Adjustments
          </Button>
        );
      } else if (!canExecuteBind) {
        rightElements.push(
          <Button key="startEditing" {...primaryActionProps} onPress={enable}>
            Edit
          </Button>
        );
        rightElements.push(
          <Button key="publishQuote" {...primaryActionProps} onPress={() => setPublishQuoteOpen(true)}>
            Publish
          </Button>
        );
      }
    }

    if (canRequestChangesToQuote) {
      rightElements.push(
        <Button key="requestChanges" {...secondaryActionProps} onPress={() => setRequestChangesOpen(true)}>
          Request Changes
        </Button>
      );
    }

    if (canRejectQuote) {
      rightElements.push(
        <Button key="rejectQuote" {...rejectionProps} onPress={() => setRejectQuoteOpen(true)}>
          Reject Quote
        </Button>
      );
    }
    if (canAcceptQuote) {
      rightElements.push(
        <Button key="acceptQuote" {...primaryActionProps} onPress={() => setAcceptQuoteOpen(true)}>
          Accept Quote
        </Button>
      );
    }

    if (canExecuteBind && !bound) {
      rightElements.push(
        <Button
          key="executeBind"
          onPress={executeBindStatus === 'pending' ? null : () => setBindModalOpen(true)}
          disabled={executeBindStatus === 'pending'}
          {...primaryActionProps}
          loading={executeBindStatus === 'pending'}
        >
          Execute Bind
        </Button>
      );
    }

    return (
      <Box ref={ref} {...rest} justifyContent="flex-end">
        {rightElements}
        <Box onPress={() => setMenuOpen(true)} ref={btnRef}>
          <Menu
            anchorNode={btnRef.current}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            rounded={20}
          >
            {canPrintQuote && (
              <MenuItem px={'$1'} py={'2'} onClick={handlePrint} ref={printRef} width={'180px'}>
                Print Quote
              </MenuItem>
            )}
            <MenuItem px={'$1'} py={'2'}>
              <ViewQuoteHistoryButton data={data} lastUpdated={lastUpdated} label={'History'} color="$gray.500" marginLeft="$-1.75" />
            </MenuItem>
          </Menu>
          <EllipsisIcon />
        </Box>
        {adjusting && (
          <SubmitAdjustmentDialog open={submitAdjustmentOpen} hasQuoteError={hasQuoteError} onClose={() => setSubmitAdjustmentOpen(false)} />
        )}
        <ConfirmDialog
          key="releaseQuote"
          onConfirm={() => releaseQuote(quoteId)}
          open={publishQuoteOpen}
          onClose={() => setPublishQuoteOpen(false)}
          heading="Publish Quote"
          prompt="Please confirm that you've completed your assessment and adjustments and you wish to release the quote to broker and client."
        />
        <SubmitMessageDialog
          key="requestChanges"
          onSubmit={(message) => requestChanges(quoteId, message)}
          open={requestChangesOpen}
          onClose={() => setRequestChangesOpen(false)}
          heading="Request Changes to Quote"
          prompt="Please describe the changes you would like the Underwriter to make."
          messageRequired
        />
        <ConfirmDialog
          key="acceptQuote"
          onConfirm={() => acceptQuote(quoteId)}
          open={acceptQuoteOpen}
          onClose={() => setAcceptQuoteOpen(false)}
          heading="Accept Quote"
          prompt="Please confirm that you and your client are accepting the quote and ready to bind."
        />
        <ConfirmDialog
          key="rejectQuote"
          onConfirm={() => rejectQuote(quoteId)}
          open={rejectQuoteOpen}
          onClose={() => setRejectQuoteOpen(false)}
          heading="Reject Quote"
          prompt="Please confirm that you would like to reject and discard this quote."
        />
        <AppLoadingOverlay
          loading={
            submitting ||
            releaseStatus === 'pending' ||
            acceptQuoteStatus === 'pending' ||
            rejectQuoteStatus === 'pending' ||
            requestChangesStatus === 'pending'
          }
        />
        <ExecuteBindModal
          setBindModalOpen={setBindModalOpen}
          bindModalOpen={bindModalOpen}
          executeBindStatus={executeBindStatus}
          executeBind={executeBind}
          bound={bound}
        />
      </Box>
    );
  })
);

const ButtonEnabledWithWatchValue = ({ name, control, defaultValue = '', disabled = false, ...btnProps }) => {
  const value = useWatch({ control, name, defaultValue });
  return <Button disabled={disabled || !value} {...btnProps} />;
};

export const SubmitAdjustmentDialog = ({ open, onClose, hasQuoteError = false }) => {
  const { adjusting, submitting } = useAdjustmentState();
  const { handleSubmit, control, trigger } = useAdjustmentForm();
  const { setSubmitDialogOpen } = useAdjustmentPreview() || {};

  useEffect(() => {
    if (setSubmitDialogOpen && typeof setSubmitDialogOpen === 'function') {
      setSubmitDialogOpen(open);
    }
  }, [open, setSubmitDialogOpen]);

  const submit = async () => {
    await trigger();
    handleSubmit();
    onClose();
  };

  const disabled = hasQuoteError || submitting;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      heading="Submit Adjustment"
      prompt="Please provide the reason for this adjustment."
      actions={
        <Box flexDirection="row" alignItems="center" gap={18}>
          <Button variant="text" color="$primary" onPress={onClose}>
            Cancel
          </Button>
          {adjusting && (
            <ButtonEnabledWithWatchValue
              name="notes"
              control={control}
              defaultValue=""
              onPress={submit}
              disabled={disabled}
              color={({ disabled }) => (disabled ? '$gray.400' : '$primary')}
            >
              Submit
            </ButtonEnabledWithWatchValue>
          )}
        </Box>
      }
    >
      <Spacing vertical={1} />
      {adjusting ? (
        <Controller
          name="notes"
          control={control}
          rules={{ required: 'Required' }}
          render={({ field: { value, ref, onChange, onBlur }, fieldState: { error } }) => (
            <TextField
              value={value}
              label="Adjustment Reason"
              onChangeValue={(v) => onChange(v)}
              onBlur={onBlur}
              inputRef={ref}
              variant="filled"
              error={error}
              helperText={error ? error.message : null}
            />
          )}
        />
      ) : null}
    </Dialog>
  );
};

const ConfirmDialog = ({ open, onConfirm, onClose, heading = 'Are you sure?', prompt = '', confirmText = 'Confirm', cancelText = 'Cancel' }) => {
  const handleConfirm = useCallback(() => {
    if (!open) {
      return;
    }
    if (onClose) {
      onClose();
    }
    if (onConfirm) {
      onConfirm();
    }
  }, [open, onConfirm, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      heading={heading}
      prompt={prompt}
      actions={
        <Box flexDirection="row" alignItems="center" gap={18}>
          <Button variant="text" color="$primary" onPress={onClose}>
            {cancelText}
          </Button>
          <Button disabled={!open} onPress={handleConfirm} color="$primary">
            {confirmText}
          </Button>
        </Box>
      }
    ></Dialog>
  );
};

const SubmitMessageDialog = ({
  open,
  initialMessage = '',
  onSubmit,
  onClose,
  messageRequired = false,
  requiredMessage = 'Required',
  inputProps = {},
  heading = 'Submit Message',
  prompt = '',
  submitText = 'Submit',
  cancelText = 'Cancel',
}) => {
  const [message, setMessage] = useState(initialMessage);
  const submitDisabled = !open || (messageRequired && (!message || !message.trim()));

  const [error, setError] = useState(null);

  const validate = () => {
    if (messageRequired) {
      if (!message || !message.trim()) {
        setError(requiredMessage);
        return false;
      }
    }
    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (!open || !validate()) {
      return;
    }
    if (onClose) {
      onClose();
    }
    if (onSubmit) {
      onSubmit(message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      heading={heading}
      prompt={prompt}
      actions={
        <Box flexDirection="row" alignItems="center" gap={18}>
          <Button variant="text" color="$primary" onPress={onClose}>
            {cancelText}
          </Button>
          <Button disabled={submitDisabled} onPress={handleSubmit} color={submitDisabled ? '$gray.400' : '$primary'}>
            {submitText}
          </Button>
        </Box>
      }
    >
      <Spacing vertical={1} />
      <TextField
        value={message}
        onChangeValue={(v) => {
          setMessage(v);
          if (error) {
            validate();
          }
        }}
        onBlur={() => validate()}
        label="Message"
        variant="filled"
        error={error}
        helperText={error ? error : null}
        {...inputProps}
      />
    </Dialog>
  );
};

// const ViewApplicationButton = ({ quoteId }) => {
//   const [open, setOpen] = useState(false);
//   const closeModal = useEventCallback(() => setOpen(false));
//   return (
//     <>
//       <Button label="View Application" variant="text" onPress={() => setOpen(!open)} />
//       <Modal
//         open={open}
//         onClose={closeModal}
//         hideBackdrop
//         scrollable
//         ScrollProps={{
//           minHeight: '101%',
//           padTop: 100,
//           containerStyle: { minHeight: '101%' },
//           provideNode: true,
//         }}
//       >
//         <ApplicationView quoteId={quoteId}></ApplicationView>
//         <ModalBackdrop open={open} onPress={closeModal} zIndex="-2" />
//       </Modal>
//     </>
//   );
// };

const ExecuteBindModal = ({ bindModalOpen, setBindModalOpen, executeBindStatus, executeBind, bound }) => {
  return (
    <Dialog
      prompt={<Text large>Please confirm you would like to bind and activate the policy</Text>}
      open={bindModalOpen}
      onClose={() => setBindModalOpen(false)}
      actions={
        <>
          <Button variant="text" label="Cancel" color="$primary" onPress={() => setBindModalOpen(false)} />
          <Spacing horizontal={2} />
          <Button
            label="Execute Bind"
            disabled={executeBindStatus === 'pending'}
            onPress={() => {
              setBindModalOpen(false);
              if (executeBindStatus !== 'pending' && !bound) {
                executeBind();
              }
            }}
          />
        </>
      }
    />
  );
};
