/* eslint-disable */
import React, { useRef, useEffect, useImperativeHandle } from 'react';
import equal from 'fast-deep-equal/react';
import { withMap } from './Contexts';
import { getExpandedMaxBounds, debounce } from '../../utils';

const Camera = withMap(
  React.forwardRef(function Camera(props, ref) {
    const { map, bounds, maxBounds, setBoundsOnResize, animationDuration = 400, animationMode = 'easeTo' } = props;

    const mounted = useRef(true);
    useEffect(() => {
      return () => {
        mounted.current = false;
      };
    }, []);

    const opts = useRef(null);
    opts.current = { animationDuration, animationMode };
    const lastBounds = useRef(null);
    const lastMaxBounds = useRef(null);

    useEffect(() => {
      if (!map || !map.mounted || !map.mounted.current || !mounted.current) {
        return;
      }

      const maxBoundsChanged = !equal(lastMaxBounds.current, maxBounds);
      const boundsOrPaddingChanged = !equal(lastBounds.current, bounds);

      const checkAndSetBounds = () => {
        if (boundsOrPaddingChanged) {
          if (bounds && Array.isArray(bounds.ne) && Array.isArray(bounds.sw)) {
            const dest = map.cameraForBounds([bounds.sw, bounds.ne], {
              padding: {
                top: bounds.paddingTop || 0,
                bottom: bounds.paddingBottom || 0,
                left: bounds.paddingLeft || 0,
                right: bounds.paddingRight || 0,
              },
            });
            if (dest) {
              if (opts.current.animationMode === 'flyTo') {
                map.flyTo({
                  ...dest,
                  duration: opts.current.animationDuration,
                });
              } else {
                map.easeTo({
                  ...dest,
                  duration: opts.current.animationDuration,
                });
              }
            }
          }
          lastBounds.current = bounds;
        }
      };
      if (maxBoundsChanged) {
        if (maxBounds && Array.isArray(maxBounds.ne) && Array.isArray(maxBounds.sw)) {
          const expanded = getExpandedMaxBounds(maxBounds, 0.5);
          const maxBoundsArr = [expanded.sw, expanded.ne];
          map.setMaxBounds(maxBoundsArr);
          checkAndSetBounds();
        }
        lastMaxBounds.current = maxBounds;
      } else {
        checkAndSetBounds();
      }
    }, [bounds, maxBounds, map]);

    const maintainBounds = useRef(false);
    maintainBounds.current = setBoundsOnResize;
    useEffect(() => {
      let resizeHandler = null;
      if (mounted.current && map && map.mounted && map.mounted.current) {
        const handleResize = () => {
          if (mounted.current && map && map.mounted && map.mounted.current) {
            if (!maintainBounds.current && !map.isStatic) {
              return;
            }
            const b = lastBounds.current;
            if (b && Array.isArray(b.ne) && Array.isArray(b.sw)) {
              const dest = map.cameraForBounds([b.sw, b.ne], {
                padding: {
                  top: b.paddingTop || 0,
                  bottom: b.paddingBottom || 0,
                  left: b.paddingLeft || 0,
                  right: b.paddingRight || 0,
                },
              });
              if (dest) {
                map.easeTo({
                  ...dest,
                  duration: 200,
                });
              }
            }
          }
        };
        resizeHandler = debounce(handleResize, 200);
        map.on('resize', resizeHandler);
      }
      return () => {
        if (map && resizeHandler) {
          map.off('resize', resizeHandler);
        }
      };
    }, [map]);

    useImperativeHandle(
      ref,
      () => ({
        // TODO: implement this
      }),
      [map]
    );
    return null;
  })
);

Camera.defaultProps = {
  _isMapboxCamera: true, // internal use for Map
};

export { Camera };
