import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Box, Map, Text, TextField, Button, Svg, IconButton, Spacing } from '../../../ui';
import { useBreakpoint, useEventCallback } from '../../../ui/hooks';
import { getBounds } from '../../../ui/utils';
import { withStyles } from '../../../ui/styling';
import { BulletList, ButtonOptionList, ReviewCard, ReviewCardContentList, ReviewItem, Section } from './components';
import { useApi } from '../../../hooks';
import { useSiteChecklistContext } from '../context';
import { Dialog } from '../../../components';
import { CollapseArrowIcon, LockIcon } from '../../../components/icons';

const deploymentTypeOptions = [
  {
    name: 'roof',
    label: 'Roof Deployment',
    value: 'roof',
  },
  {
    name: 'ground',
    label: 'Ground Deployment',
    value: 'ground',
  },
];

export const InstallLocationData = React.forwardRef((props, ref) => {
  const { editing, setEditing, ...rest } = props;
  const { editingEnabled, bindForm, data, submitRequest, status, errors, updateData, internalViewer } = useSiteChecklistContext();

  const open = editing === 'install-location' && editingEnabled;
  const [dialogOpen, setDialogOpen] = useState(false);

  const closeDialog = useEventCallback(() => setDialogOpen(false));

  const { submitDetailsOnBindingForm } = useApi();
  const submit = useMemo(() => {
    return (inputs) => {
      return submitRequest(async () => {
        let { lat = null, lon = null, locationDescription, roofDeployment = undefined, groundDeployment = undefined } = inputs;

        roofDeployment =
          typeof roofDeployment !== 'boolean' ? (typeof data.roofDeployment === 'boolean' ? data.roofDeployment : true) : roofDeployment;
        groundDeployment =
          typeof groundDeployment !== 'boolean' ? (typeof data.groundDeployment === 'boolean' ? data.groundDeployment : false) : groundDeployment;
        const roofDeploymentMarkedUnusable = roofDeployment === false && !groundDeployment;
        if (!roofDeploymentMarkedUnusable && (!lat || !lon) && !locationDescription) {
          throw { location: 'A pin on the map or description of desired location to install is required' };
        }
        await submitDetailsOnBindingForm(
          {
            ...data,
            roofDeployment,
            groundDeployment,
            lat,
            lon,
            locationDescription,
          },
          bindForm,
          internalViewer
        );
        await updateData();
        closeDialog();
        setEditing(false);
      });
    };
  }, [data, submitRequest, submitDetailsOnBindingForm, setEditing, closeDialog, internalViewer, bindForm, updateData]);

  const { lat, lon, groundDeployment, roofDeployment, locationDescription, pocEmail, pocPhone, pocName } = data;
  const [adminSelectedDeploymentType, setAdminSelectedDeploymentType] = useState(groundDeployment ? 'ground' : roofDeployment ? 'roof' : null);

  const { latitude, longitude, address, location } = bindForm;
  const [coords, setCoords] = useState({ lat, lon });
  const [locationInput, setLocationInput] = useState(locationDescription || '');

  const lastData = useRef({ lat, lon, locationDescription, groundDeployment, roofDeployment });
  useEffect(() => {
    if (lat !== lastData.current.lat || lon !== lastData.current.lon) {
      setCoords({ lat, lon });
    }
    if (locationDescription !== lastData.current.locationDescription) {
      setLocationInput(locationDescription || '');
    }
    if (groundDeployment !== lastData.current.groundDeployment || roofDeployment !== lastData.current.roofDeployment) {
      setAdminSelectedDeploymentType(groundDeployment ? 'ground' : roofDeployment ? 'roof' : null);
    }
    lastData.current = { lat, lon, locationDescription, groundDeployment, roofDeployment };
  }, [lat, lon, locationDescription, groundDeployment, roofDeployment]);

  useEffect(() => {
    if (!open) {
      setCoords({ lat: lastData.current.lat, lon: lastData.current.lon });
      setLocationInput(lastData.current.locationDescription || '');
    }
  }, [open]);

  // const disabled = !internalViewer && formStatus.value === 'not-started'; TODO check not-started logic.
  const disabled = !internalViewer && !pocEmail && !pocPhone && !pocName;
  const sectionNotStarted = !lat && !lon && !locationDescription;
  const incompatableLocation = !groundDeployment && !roofDeployment;

  const { heading, subheading, description, placeholder } = useMemo(() => {
    if (groundDeployment) {
      return {
        heading: 'Ground Install Location',
        subheading: open
          ? 'Where do you want the installation on the grounds of your property? Click and drag the pin to indicate a location that meets the following criteria:'
          : '',
        description: 'Optionally describe where on the grounds that meet criteria we can install the station.',
        placeholder: 'Example: NW corner of the lot',
      };
    }
    return {
      heading: 'Roof Install Location',
      subheading:
        open && !incompatableLocation
          ? "We'll install the Dot weather sensor on your rooftop. Click and drag the pin to indicate a location that meets the following criteria:"
          : open || incompatableLocation
          ? 'More options requested. No available roof locations meet criteria.'
          : disabled
          ? 'Complete the previous section to start'
          : '',
      description: 'Optionally describe the building and desired roof placement of the station.',
      placeholder: 'Example: Building located at the NW corner of the lot. You can install the station on the center of the roof',
    };
  }, [groundDeployment, open, disabled, incompatableLocation]);
  const mouseCoords = useRef([longitude, latitude]);
  const mapCenter = useRef([longitude, latitude]);
  const map = useRef(null);
  const onMouseMove = useCallback((evt) => {
    if (evt) {
      const [longitude, latitude] = evt.geometry.coordinates;
      if (longitude && latitude) {
        mouseCoords.current = [longitude.toFixed(8) * 1, latitude.toFixed(8) * 1];
      }
    }
  }, []);
  const onRegionChange = useCallback(async (region) => {
    try {
      let centerArr;
      if (region) {
        centerArr = region.geometry.coordinates;
      }
      if (map.current) {
        if (!centerArr || !Array.isArray(centerArr)) {
          centerArr = await map.current.getCenter();
        }
      }
      if (centerArr) {
        mapCenter.current = [centerArr[0].toFixed(8) * 1, centerArr[1].toFixed(8) * 1];
      }
    } catch (err) {
      console.log('onRegionChange ERROR: ', err);
    }
  }, []);
  const [breakpoint] = useBreakpoint();
  const mobileControls = breakpoint.key === 'xs';
  const onPressMap = useCallback(() => {
    if (mobileControls) {
      return;
    }
    const [longitude, latitude] = mouseCoords.current;
    setCoords({ lat: latitude, lon: longitude });
  }, [mobileControls]);
  const onPressAddPin = useCallback(() => {
    const [longitude, latitude] = mapCenter.current;
    if (mobileControls || !(coords.lat && coords.lon)) {
      setCoords({ lat: latitude, lon: longitude });
    } else {
      setCoords({ lat: null, lon: null });
    }
  }, [mobileControls, coords]);

  return (
    <ReviewCard
      heading={heading}
      subheading={subheading}
      ref={ref}
      opacity={disabled ? 0.62 : !editingEnabled || !editing || open ? 1 : 0.77}
      ActionComponent={open && editingEnabled && !disabled ? IconButton : disabled ? IconButton : undefined}
      headerAction={
        !open && editingEnabled && !disabled
          ? {
              label: sectionNotStarted && !incompatableLocation && !internalViewer ? 'Start' : 'Edit',
              onPress: () => setEditing('install-location'),
            }
          : open && editingEnabled && !disabled
          ? {
              children: <CollapseArrowIcon />,
              onPress: () => setEditing(false),
            }
          : disabled
          ? {
              children: <LockIcon />,
              color: '$gray.300',
              disabled: true,
            }
          : undefined
      }
      footerActions={
        !disabled &&
        open &&
        editingEnabled && {
          left: internalViewer
            ? [
                {
                  label: 'Change Installation Type',
                  disabled: status === 'pending',
                  onPress: () => setDialogOpen(true),
                },
              ]
            : groundDeployment || incompatableLocation
            ? []
            : [
                {
                  label: 'A roof installation will not work',
                  disabled: status === 'pending',
                  onPress: () => setDialogOpen(true),
                },
              ],
          right: incompatableLocation
            ? []
            : [
                {
                  label: 'Save',
                  onPress: () => submit({ lat: coords.lat, lon: coords.lon, locationDescription: locationInput }),
                  disabled: status === 'pending',
                  loading: status === 'pending',
                },
              ],
        }
      }
      {...rest}
    >
      {open && !disabled ? (
        !incompatableLocation ? (
          <>
            {errors && errors.location ? (
              <Text pb="$1.5" color="$error" bold small>
                {errors.location}
              </Text>
            ) : null}
            <Box flexDirection="row" flex={1} width={'100%'} justifyContent="space-between" alignItems="flex-start">
              <BulletList
                padLeft={0}
                items={[
                  <Box flex={1}>
                    <Text bold>A flat or nearly flat roof </Text>
                    <Text small>We cannot install on roofs with a slope greater than 5 degrees</Text>
                  </Box>,
                  <Box flex={1}>
                    <Text bold>Unobstructed view of the sky </Text>
                    <Text small>{'No taller (>20 ft) buildings, trees, or objects within 50 ft of the installation location.'}</Text>
                  </Box>,
                  <Box flex={1}>
                    <Text bold>Away from areas of routine maintenance</Text>
                    <Text small>{'We want to minimize the risk of anyone bumping into the Dot during maintenance of the HVAC, etc. '}</Text>
                  </Box>,
                  <Box flex={1}>
                    <Text bold>Safe access</Text>
                    <Text small>
                      {
                        'The roof must follow local building codes for structure and safety. A roof that can only be accessed via external, free-standing ladder must be no higher than 25ft.'
                      }
                    </Text>
                  </Box>,
                  <Box flex={1}>
                    <Text bold>{`On the property located at ${address || (location && location.address ? location.address : 'N/A')}`}</Text>
                  </Box>,
                ]}
                maxWidth="40%"
              />
              <Box width="55%" overflow="hidden" borderRadius={8}>
                <LocationMap
                  style={{ height: 400, width: '100%' }}
                  onRegionIsChanging={onRegionChange}
                  onRegionDidChange={onRegionChange}
                  onMouseMove={onMouseMove}
                  cursor="crosshair"
                  onPress={onPressMap}
                  ref={map}
                  location={bindForm}
                  station={coords}
                />
                <Button outlined alignSelf="flex-start" position="absolute" top="$2" right="$2" bg="$white" onPress={onPressAddPin}>
                  {coords.lat && coords.lon ? 'Remove Pin' : 'Set Pin'}
                </Button>
                {mobileControls && <MapReticle />}
              </Box>
            </Box>
            <Section heading={description} size="xSmall" padTop="$6">
              <TextField
                placeholder={placeholder}
                value={locationInput}
                onChangeValue={(v) => setLocationInput(v)}
                InputProps={{ multiline: true }}
                multiline
                height={200}
                maxWidth="80%"
                disabled={status === 'pending'}
              />
            </Section>
          </>
        ) : !internalViewer ? (
          <Section subheading="As a reminder here is the criteria that must be met for a roof installation:">
            <BulletList
              padLeft={0}
              items={[
                <Box flex={1}>
                  <Text bold>A flat or nearly flat roof </Text>
                  <Text small>We cannot install on roofs with a slope greater than 5 degrees</Text>
                </Box>,
                <Box flex={1}>
                  <Text bold>Unobstructed view of the sky </Text>
                  <Text small>{'No taller (>20 ft) buildings, trees, or objects within 50 ft of the installation location.'}</Text>
                </Box>,
                <Box flex={1}>
                  <Text bold>Away from areas of routine maintenance</Text>
                  <Text small>{'We want to minimize the risk of anyone bumping into the Dot during maintenance of the HVAC, etc. '}</Text>
                </Box>,
                <Box flex={1}>
                  <Text bold>Safe access</Text>
                  <Text small>
                    {
                      'The roof must follow local building codes for structure and safety. A roof that can only be accessed via external, free-standing ladder must be no higher than 25ft.'
                    }
                  </Text>
                </Box>,
                <Box flex={1}>
                  <Text bold>{`On the property located at ${address || (location && location.address ? location.address : 'N/A')}`}</Text>
                </Box>,
              ]}
              maxWidth="90%"
            />
            <Spacing vertical={5} />
            <Text large bold>
              Did you find a suitable roof location that meets criteria?
            </Text>
            <Spacing vertical={2} />
            <Button
              height={50}
              alignSelf="flex-start"
              label="Yes, add roof install location details"
              disabled={status === 'pending'}
              loading={status === 'pending'}
              onPress={() =>
                submit({ roofDeployment: true, groundDeployment: false, lat: coords.lat, lon: coords.lon, locationDescription: locationInput })
              }
            />
          </Section>
        ) : (
          <ReviewCardContentList>
            <Box width="100%" borderRadius={8} bg="orange" px="$1" py="$1">
              <Text>{'Customer has indicated that a roof installation will not work.'}</Text>
            </Box>
          </ReviewCardContentList>
        )
      ) : !disabled ? (
        incompatableLocation ? (
          <ReviewCardContentList>
            <Box width="100%" borderRadius={8} bg="orange" px="$1" py="$1">
              <Text>
                {internalViewer
                  ? 'Customer has indicated that a roof installation will not work.'
                  : 'A member of our team will call the number you provided to help find a better location after the checklist is submitted'}
              </Text>
            </Box>
          </ReviewCardContentList>
        ) : (
          <ReviewCardContentList>
            <Box pb="$3" width="100%" justifyContent="flex-start" alignItems="flex-start" overflow="hidden" borderRadius={8}>
              <LocationMap style={{ height: 300, width: '100%', maxWidth: '100%' }} location={bindForm} station={{ lat, lon }} staticMap />
              <Box
                display={lat && lon ? 'none' : 'flex'}
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                zIndex={2}
                bg={({ theme }) => theme.colors.opacity('$white', 0.55)}
                justifyContent="center"
                alignItems="center"
              >
                <Text weight="$extraBold" bg="white" opacity={0.85} px="$2" py="$1.5" borderRadius={8}>
                  No map location provided
                </Text>
              </Box>
            </Box>
            <ReviewItem
              heading="Description of Location"
              action={
                editingEnabled && !sectionNotStarted
                  ? {
                      label: 'Edit',
                      onPress: () => setEditing('install-location'),
                    }
                  : undefined
              }
              onPress={editingEnabled && !sectionNotStarted ? () => setEditing('install-location') : null}
            >
              {locationDescription ? (
                <Text bold py="$1">
                  {locationDescription}
                </Text>
              ) : editingEnabled ? (
                <Text py="$1" color="$secondary" bold>
                  Add a description
                </Text>
              ) : (
                <Text py="$1" dim>
                  No description provided
                </Text>
              )}
            </ReviewItem>
          </ReviewCardContentList>
        )
      ) : null}
      <Dialog
        boxGapIgnore
        open={dialogOpen}
        onClose={status === 'pending' ? null : closeDialog}
        heading={internalViewer ? 'Change Deployment Type (Admin)' : 'Roof wont work?'}
        prompt={
          internalViewer
            ? 'WARNING: Customer or admin data for location coordinates and description will be reset by changing the deployment type after you press Change and Update'
            : 'Please confirm that a roof at your location does not meet the criteria. We will reach out to find another suitable install location.'
        }
        actions={
          <Box flexDirection="row" alignItems="center" gap={18}>
            <Button
              label="Cancel"
              variant="text"
              color={status === 'pending' ? '$gray.300' : '$primary'}
              disabled={status === 'pending'}
              onPress={status !== 'pending' ? closeDialog : null}
            />
            <Button
              label={internalViewer ? 'Change and Update' : 'Confirm'}
              disabled={status === 'pending'}
              loading={status === 'pending'}
              onPress={
                internalViewer
                  ? () =>
                      submit({
                        roofDeployment: adminSelectedDeploymentType === 'ground' ? false : true,
                        groundDeployment: adminSelectedDeploymentType === 'ground' ? true : false,
                        lat: coords.lat,
                        lon: coords.lon,
                        locationDescription: locationInput,
                      })
                  : () =>
                      submit({ roofDeployment: false, groundDeployment: false, lat: coords.lat, lon: coords.lon, locationDescription: locationInput })
              }
            />
          </Box>
        }
      >
        {internalViewer ? (
          <ButtonOptionList
            options={deploymentTypeOptions}
            selected={adminSelectedDeploymentType}
            onSelect={(val) => setAdminSelectedDeploymentType(val)}
            disabled={status === 'pending'}
          />
        ) : null}
      </Dialog>
    </ReviewCard>
  );
});

export const LocationMap = React.forwardRef((props, ref) => {
  const { location = {}, station = {}, ...rest } = props;
  const { lat: latitude, lon: longitude } = location;

  const { maxBounds } = useMemo(() => {
    if (latitude && longitude) {
      return {
        bounds: getBounds({ center: [longitude, latitude], hectares: 1 }),
        maxBounds: getBounds({ center: [longitude, latitude], hectares: 15 }),
      };
    }
    return { bounds: null, maxBounds: null };
  }, [latitude, longitude]);

  const markerCoordinate = useMemo(() => {
    return station ? [station.lon, station.lat] : null;
  }, [station]);

  return (
    <Map ref={ref} {...rest}>
      <Map.Camera
        // bounds={bounds} // <- bounds is causing a weird alignment issue where the marker doesnt get centered. maxBounds by itself doesnt get weird results.
        maxBounds={maxBounds}
        setBoundsOnResize={false}
        animationDuration={400}
      />
      {station.lat && station.lon ? <Map.Marker id="mapmarker" coordinate={markerCoordinate} /> : null}
    </Map>
  );
});

const { Line, Circle } = Svg;
const MapReticle = withStyles(
  ({ size = 25, color = '#ffffff', theme }) => {
    return {
      root: {
        position: 'absolute',
        zIndex: 11,
        flex: 1,
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      },
      Svg: {
        props: {
          size,
          color,
        },
      },
    };
  },
  { filterProps: ['size', 'color'] }
)(function MapReticles(props) {
  const { styles, ...rest } = props;
  return (
    <Box pointerEvents="none" {...rest}>
      <Svg {...styles.toProps('Svg')}>
        <Line x1="0" x2="5" y1="12.5" y2="12.5" stroke="currentColor" strokeWidth="2" />
        <Line x1="20" x2="25" y1="12.5" y2="12.5" stroke="currentColor" strokeWidth="2" />
        <Line x1="12.5" x2="12.5" y1="0" y2="5" stroke="currentColor" strokeWidth="2" />
        <Line x1="12.5" x2="12.5" y1="20" y2="25" stroke="currentColor" strokeWidth="2" />
        <Circle cx="12.5" cy="12.5" r="8" stroke="currentColor" strokeWidth="2" fill="transparent" />
      </Svg>
    </Box>
  );
});
