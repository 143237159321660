import React from 'react';
import { styled } from '../../ui/styling';
import { layout as layoutProps, backgroundColor as bgProps, border as borderProps } from '../../ui/system/props';
import { getFlexLayout } from './utils';
import { LayoutBox } from './LayoutBox';

export const Page = styled(LayoutBox)((props) => {
  const { SectionProps } = props;

  return {
    ...layoutProps({
      flex: 1,
      width: '100%',
      // maxWidth: '100vw',
      minHeight: '100%',
      alignSelf: 'stretch',
      ...props,
    }),
    ...bgProps({ backgroundColor: 'transparent', ...props }),
    props: {
      include: SectionProps ? { SectionProps } : null,
      defaultLayout: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
      },
    },
  };
});

export const ModalPage = styled(LayoutBox, { filterProps: borderProps.filterProps })((props) => {
  const { SectionProps, theme } = props;
  const flexLayout = getFlexLayout(props, {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  });

  return {
    ...layoutProps({
      flex: 1,
      width: theme.breakpoints({ xs: '100%', sm: theme.breakpoints.values.xs - theme.spacing(4), md: 960 }),
      maxWidth: '100vw',
      alignSelf: 'stretch',
      ...flexLayout,
      ...props,
    }),
    ...bgProps({ backgroundColor: '$white', ...props }),
    ...borderProps({ borderRadius: 8, borderWidth: 0, borderColor: theme.colors.alpha('$white', 0.5), ...props }),
    props: { include: SectionProps ? { SectionProps } : null },
  };
});

export const PageDisplay = React.forwardRef(function PageDisplay(props, ref) {
  const { asModal = false, children, ...rest } = props;

  if (asModal) {
    return (
      <ModalPage ref={ref} {...rest}>
        {children}
      </ModalPage>
    );
  }
  return (
    <Page ref={ref} {...rest}>
      {children}
    </Page>
  );
});
