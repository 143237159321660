import { MIN_MAX_RANGE, DATES_FROM_TO, SELECT, MULTI_SELECT } from './filterTypes';

export const bindFormItemData = {
  agent: {
    type: 'object',
    notes: 'half of these properties are missing',
    properties: {
      email: {
        display: true,
        sort: {
          name: 'agent_email',
          label: 'Agent Email',
        },
        search: {
          name: 'agent_email',
          label: 'Agent Email',
        },
        type: 'string',
      },
      name: {
        display: true,
        sort: {
          name: 'agent_name',
          label: 'Agent Name',
        },
        search: {
          name: 'agent_name',
          label: 'Agent Name',
        },
        type: 'string',
      },
      contactId: {
        add: true,
        type: 'number',
        notes: 'To be consistent with every other agent object',
      },
      id: {
        type: 'number',
      },
      userId: {
        type: 'number',
      },
    },
  },
  approvedAt: {
    display: true,
    filter: {
      type: DATES_FROM_TO,
      name: 'approved_at',
      label: 'Approved At',
    },
    sort: {
      name: 'approved_at',
      label: 'Approved At',
    },
    type: 'number',
  },
  'approvedBy<original>': {
    replace: true,
    type: 'number',
    notes: 'This is just a number when it should be an object with name and contact info instead...',
  },
  approvedBy: {
    add: true,
    type: 'object',
    notes: 'It would be great if this was an object with the user info (id, name, email)',
    properties: {
      id: {
        add: true,
        type: 'number',
        notes: 'W/e the currently used id for approvedBy is go here instead',
      },
      name: {
        display: true,
        sort: {
          name: 'approver_name',
          label: 'Approved By',
        },
        search: {
          name: 'approver_name',
          label: 'Approved By',
        },
        add: true,
        type: 'string',
      },
      email: {
        display: true,
        sort: {
          name: 'approver_email',
          label: 'Approver Email',
        },
        search: {
          name: 'approver_email',
          label: 'Approver Email',
        },
        add: true,
        type: 'string',
      },
    },
  },
  broker: {
    type: 'object',
    properties: {
      id: {
        type: 'number',
      },
      name: {
        display: true,
        sort: {
          name: 'broker_name',
          label: 'Broker Name',
        },
        search: {
          name: 'broker_name',
          label: 'Broker Name',
        },
        type: 'string',
      },
    },
  },
  details: {
    type: 'object',
  },
  formType: {
    display: true,
    filter: {
      order: 0,
      initial: { field: 'form_type', value: null, operator: 'equals' },
      type: SELECT,
      name: 'form_type',
      label: 'Form Type',
      defaults: { value: null },
      options: {
        all: {
          value: null,
          name: 'all',
          label: 'All',
        },
        'site-checklist': {
          value: 'site-checklist',
          name: 'site-checklist',
          label: 'Site Checklist',
        },
        'lease-agreement': {
          value: 'lease-agreement',
          name: 'lease-agreement',
          label: 'Lease Agreement',
        },
      },
    },
    sort: {
      name: 'form_type',
      label: 'Form Type',
    },
    type: 'string',
  },
  coveredValue: {
    display: true,
    filter: {
      order: 2,
      type: MIN_MAX_RANGE,
      name: 'covered_value',
      label: 'Covered Value',
      initial: { field: 'covered_value', value: 1000000, operator: 'gte' },
      defaults: {
        value: [1000000, null],
      },
    },
    sort: {
      name: 'covered_value',
      label: 'Covered Value',
    },
    type: 'string',
  },
  id: {
    type: 'string',
  },
  insured: {
    display: true,
    sort: {
      name: 'insured',
      label: 'Insured',
    },
    search: {
      name: 'insured',
      label: 'Insured Name',
    },
    type: 'string',
  },
  isUpdatable: {
    display: true,
    filter: {
      type: null,
      name: 'is_updatable',
      label: 'Editable',
      exclude: true,
    },
    type: 'string',
    notes: 'This could be just be instead part of a derived prop made in api based on updatable, approved, rejected, etc',
  },
  name: {
    // display: true,
    // sort: {
    //     name: "",
    //     label: ""
    // },
    // search: {
    //   name: "",
    //   label: ""
    // },
    type: 'string',
    notes: 'What is the purpose of this?',
  },
  quoteId: {
    type: 'string',
  },
  receivedAt: {
    display: true,
    filter: {
      type: DATES_FROM_TO,
      name: 'received_at',
      label: 'Received At',
    },
    sort: {
      name: 'received_at',
      label: 'Received At',
    },
    type: 'number',
  },
  rejectedAt: {
    display: true,
    filter: {
      type: DATES_FROM_TO,
      name: 'rejected_at',
      label: 'Rejected At',
    },
    sort: {
      name: 'rejected_at',
      label: 'Rejected At',
    },
    type: 'number',
  },
  'rejectedBy<original>': {
    replace: true,
    type: 'number',
    notes: 'This is just a number when it should be an object with name and contact info instead...',
  },
  rejectedBy: {
    add: true,
    type: 'object',
    notes: 'It would be great if this was an object with the user info (id, name, email)',
    properties: {
      id: {
        add: true,
        type: 'number',
        notes: 'W/e the currently used id for rejectedBy is go here instead',
      },
      name: {
        display: true,
        sort: {
          name: 'rejecter_name',
          label: 'Rejected By',
        },
        search: {
          name: 'rejecter_name',
          label: 'Rejected By',
        },
        add: true,
        type: 'string',
      },
      email: {
        display: true,
        sort: {
          name: 'rejecter_email',
          label: 'Rejecter Email',
        },
        search: {
          name: 'rejecter_email',
          label: 'Rejecter Email',
        },
        add: true,
        type: 'string',
      },
    },
  },
  requestedAt: {
    display: true,
    filter: {
      type: DATES_FROM_TO,
      name: 'requested_at',
      label: 'Requested At',
    },
    sort: {
      initial: { field: 'requested_at', by: 'DESC' },
      name: 'requested_at',
      label: 'Requested At',
    },
    type: 'number',
  },
  s3DownloadLink: {
    type: 'string',
  },
  url: {
    type: 'string',
  },
  locationId: {
    replace: true,
    type: 'string',
    notes: 'THIS should be full object of the location and id found there',
  },
  address: {
    replace: true,
    type: 'string',
  },
  countryCode: {
    replace: true,
    type: 'string',
  },
  regionCode: {
    replace: true,
    type: 'string',
  },
  latitude: {
    replace: true,
    type: 'number',
    notes: 'Why is is lat everywhere else, but longitude here?',
  },
  longitude: {
    replace: true,
    type: 'number',
    notes: 'Why is is lon everywhere else, but longitude here?',
  },
  location: {
    add: true,
    type: 'object',
    notes: 'Needs to exist on both form types and should just be an object based off of locationId',
    properties: {
      id: {
        add: true,
        type: 'string',
      },
      name: {
        display: true,
        sort: {
          name: 'location_name',
          label: 'Location Name',
        },
        search: {
          name: 'location_name',
          label: 'Location Name',
        },
        add: true,
        type: 'string',
      },
      address: {
        display: true,
        search: {
          name: 'location_address',
          label: 'Address',
        },
        add: true,
        type: 'string',
      },
      country: {
        display: true,
        sort: {
          name: 'location_country',
          label: 'Country',
        },
        search: {
          name: 'location_country',
          label: 'Country',
        },
        add: true,
        type: 'string',
      },
      countryCode: {
        display: true,
        filter: {
          type: MULTI_SELECT,
          defaults: { value: null },
          order: 10,
          name: 'location_country_code',
          label: 'Country',
          options: {},
          exclude: true,
        },
        sort: {
          name: 'location_country_code',
          label: 'Country',
          exclude: true,
        },
        search: {
          name: 'location_country_code',
          label: 'Country Code',
        },
        add: true,
        type: 'string',
      },
      lat: {
        add: true,
        type: 'number',
      },
      lon: {
        add: true,
        type: 'number',
      },
      postcode: {
        display: true,
        sort: {
          name: 'location_postcode',
          label: 'Postcode',
        },
        search: {
          name: 'location_postcode',
          label: 'Postcode',
        },
        add: true,
        type: 'string',
      },
      region: {
        display: true,
        sort: {
          name: 'location_region',
          label: 'Region/Province',
        },
        search: {
          name: 'location_region',
          label: 'Region/Province',
        },
        add: true,
        type: 'string',
      },
      regionCode: {
        display: true,
        sort: {
          name: 'location_region_code',
          label: 'Region/Province Code',
          exclude: true,
        },
        filter: {
          order: 9,
          type: MULTI_SELECT,
          name: 'location_region_code',
          label: 'Region/Province',
          options: {},
          exclude: true,
        },
        add: true,
        type: 'string',
      },
    },
  },
  siteId: {
    type: 'number',
  },
  // DERIVED PROPERTIES
  status: {
    display: true,
    filter: {
      order: 1,
      initial: { field: 'status', value: null, operator: 'equals' }, // this is a default filter (fallback when none set)
      type: SELECT,
      name: 'status',
      label: 'Status',
      defaults: { value: null },
      options: {
        any: {
          label: 'Any',
          name: 'any',
          value: null,
          fieldValue: null,
        },
        'not-started': {
          label: 'Not Started',
          name: 'not-started',
          value: 'not-started',
          fieldValue: [{ field: 'received_at', operator: 'equals', value: null }],
        },
        'in-progress': {
          label: 'In Progress',
          name: 'in-progress',
          value: 'in-progress',
          fieldValue: [
            { field: 'rejected_at', operator: 'equals', value: null },
            { field: 'approved_at', operator: 'equals', value: null },
            { field: 'is_updatable', operator: 'equals', value: true },
            { field: 'received_at', operator: 'notequals', value: null },
          ],
        },
        reviewable: {
          label: 'Review Ready',
          name: 'reviewable',
          value: 'reviewable',
          fieldValue: [
            { field: 'approved_at', operator: 'equals', value: null },
            { field: 'is_updatable', operator: 'equals', value: false },
            { field: 'received_at', operator: 'notequals', value: null },
          ],
        },
        revising: {
          label: 'Revision Needed',
          name: 'revising',
          value: 'revising',
          fieldValue: [
            { field: 'rejected_at', operator: 'notequals', value: null },
            { field: 'approved_at', operator: 'equals', value: null },
            { field: 'is_updatable', operator: 'equals', value: true },
          ],
        },
        unresolved: {
          label: 'Unresolved',
          name: 'unresolved',
          value: 'unresolved',
          fieldValue: [
            { field: 'rejected_at', operator: 'notequals', value: null },
            { field: 'approved_at', operator: 'equals', value: null },
            { field: 'is_updatable', operator: 'equals', value: false },
          ],
        },
        accepted: {
          label: 'Accepted',
          name: 'accepted',
          value: 'accepted',
          fieldValue: [{ field: 'approved_at', operator: 'notequals', value: null }],
        },
      },
    },
    type: 'string',
  },
};
