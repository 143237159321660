import React, { useEffect, useRef } from 'react';
import { Platform } from 'react-native';
import { useControlledState, useEventCallback, useRefArray } from '../../hooks';
import { isObject } from '../../utils';
import { Chip } from '../Chip';

const defaultValue = [];
const defaultInputValue = '';

function useInputListItems(props) {
  const {
    value = defaultValue, // required
    inputValue = defaultInputValue, // required
    inputFocused, // required
    disabled, // required
    focusInput, // required
    blurInput, // required
    onChangeValue, // required
    onChangeInputValue, // required
    getItemLabel: getItemLabelProp,
    renderItem: renderItemProp,
    focusedItem: focusedItemControlled,
    onChangeFocusedItem,
    itemProps = null,
    onAddItem,
    onRemoveItem,
    onUpdateItem,
    onItemBlur,
    onItemFocus,
    onItemPress,
    onItemKeyDown,
    // NOTE: onItemKeyDown is for key events on items where onKeyPress is called only if
    // utilizing the handleKeyPress func returned from this hook and passing it to input, another component, etc...
    onKeyPress,
    // NOTE: onItemDelete is diff from onRemoveItem.
    // onItemDelete is a component event for an item when there's a delete action either from keyboard or clear btn, etc
    // onRemoveItem is a state event in this hook when an item is going to be removed and the value array will change
    onItemDelete,
    vertical = false,
  } = props;

  const [focusedItem, setFocusedItemState] = useControlledState({ controlledValue: focusedItemControlled, defaultValue: -1 });
  const setFocusedItem = useEventCallback((nextFocusedItem) => {
    if (nextFocusedItem !== focusedItem) {
      setFocusedItemState(nextFocusedItem);
      if (onChangeFocusedItem) {
        onChangeFocusedItem(nextFocusedItem);
      }
    }
  });
  const focusing = useRef(null);
  const inputHasFocused = useRef(inputFocused);
  const itemRefs = useRefArray(value);

  useEffect(() => {
    if (focusedItem > value.length - 1) {
      setFocusedItem(-1);
      if (focusInput) {
        focusInput();
      }
    } else if (inputFocused && !inputHasFocused.current && focusedItem !== -1) {
      setFocusedItem(-1);
    }
    inputHasFocused.current = inputFocused;
  }, [focusedItem, value, focusInput, inputFocused, setFocusedItem]);

  const handleChangeValue = (newValue) => {
    if (value === newValue) {
      return;
    }
    if (onChangeValue) {
      onChangeValue(newValue);
    }
  };

  const addItem = useEventCallback((item, clearInput = false) => {
    if (!item || !item.value || !item.value.trim()) {
      return;
    }
    if (onAddItem) {
      const cancel = onAddItem(item);
      if (cancel === true) {
        return;
      }
    }
    const newValue = value.slice();
    newValue.push(item.value.trim());
    handleChangeValue(newValue);
    if (onChangeInputValue && clearInput && inputValue !== '') {
      onChangeInputValue('');
    }
  });

  const removeItem = useEventCallback((item, reason) => {
    if (item && item.index >= 0 && item.index < value.length) {
      if (onRemoveItem) {
        const cancel = onRemoveItem(item, reason);
        if (cancel === true) {
          return;
        }
      }
      const newValue = value.slice();
      newValue.splice(item.index, 1);
      handleChangeValue(newValue);
      if (reason === 'keyup' && focusedItem !== -1) {
        setFocusedItem(-1);
        focusInput();
      }
    }
  });

  const updateItem = useEventCallback((item) => {
    if (item && item.index >= 0 && item.index < value.length) {
      if (!item.value || !item.value.trim()) {
        removeItem(item);
      } else {
        if (onUpdateItem) {
          const cancel = onUpdateItem(item);
          if (cancel === true) {
            return;
          }
        }
        const newValue = value.slice();
        newValue[item.index] = item.value;
        handleChangeValue(newValue);
      }
    }
  });

  const getItemLabel = (item) => {
    if (getItemLabelProp) {
      return getItemLabelProp(item);
    }
    return isObject(item) ? (item.label ? item.label : `${item}`) : item;
  };

  function validItemIndex(index, direction) {
    if (index === -1) {
      return -1;
    }

    let nextFocus = index;

    while (true) {
      if ((direction === 'next' && nextFocus === value.length) || (direction === 'previous' && nextFocus === -1)) {
        return -1;
      }

      const item = itemRefs[index].current;

      if (!item || !item.focusable || item.disabled) {
        nextFocus += direction === 'next' ? 1 : -1;
      } else {
        return nextFocus;
      }
    }
  }

  const focusItem = useEventCallback((directionOrIndex) => {
    let nextItem = focusedItem;
    let direction = directionOrIndex;

    if (typeof directionOrIndex === 'number') {
      nextItem = directionOrIndex;
      direction = null;
    }

    if (direction) {
      if (focusedItem === -1) {
        if (inputValue === '' && direction === 'previous') {
          nextItem = value.length - 1;
        }
      } else {
        nextItem += direction === 'next' ? 1 : -1;

        if (nextItem < 0) {
          nextItem = 0;
        }

        if (nextItem === value.length) {
          nextItem = -1;
        }
      }
    }

    nextItem = validItemIndex(nextItem, direction);
    setFocusedItem(nextItem);
    focusing.current = nextItem;
    if (nextItem === -1) {
      if (focusInput) {
        focusInput();
      }
      return !inputFocused;
    } else {
      if (inputFocused && blurInput) {
        blurInput();
      }
      const item = itemRefs[nextItem].current;
      const isItemAlreadyFocused = !item
        ? false
        : item.focused ||
          (typeof item.isFocused === 'function' && item.isFocused()) ||
          (Platform.OS === 'web' && document !== undefined && document.activeElement === item);

      if (item && item.focus) {
        item.focus();
      }

      return !isItemAlreadyFocused;
    }
  });

  const handleItemOnKeyDown = useEventCallback((e, fromElsewhere = false) => {
    if (e && e.key) {
      if (!e.nativeEvent) {
        e.nativeEvent = { key: e.key };
      } else if (!e.nativeEvent.key) {
        e.nativeEvent.key = e.key;
      }
    }

    if (onItemKeyDown && !fromElsewhere) {
      const cancel = onItemKeyDown(e);
      if (cancel === true) {
        return;
      }
    }

    if (!e || !e.nativeEvent || !e.nativeEvent.key) {
      return;
    }
    // ^^ if desired to prevent processing the event here. set e.preventHandlerDefault to true on passed prop onItemKeyDown
    // ex: onItemKeyDown: (e) => e.preventHandlerDefault = true,
    // can also use onItemKeyDown and pass true to cancel
    if (e.preventHandlerDefault) {
      return;
    }
    const PREVIOUS_KEY = vertical ? 'ArrowUp' : 'ArrowLeft';
    const NEXT_KEY = vertical ? 'ArrowDown' : 'ArrowRight';
    let handledKey = false;
    switch (e.nativeEvent.key) {
      case PREVIOUS_KEY:
        if (focusItem('previous')) {
          preventDefaultForEvent(e);
        }
        handledKey = PREVIOUS_KEY;
        break;
      case NEXT_KEY:
        if (focusItem('next')) {
          preventDefaultForEvent(e);
        }
        handledKey = NEXT_KEY;
        break;
      case 'Enter':
        if (inputFocused && focusedItem === -1 && inputValue) {
          addItem({ value: inputValue }, true);
          handledKey = 'Enter';
        }
        break;
      case 'Escape':
        if (focusedItem !== -1) {
          setFocusedItem(-1);
          if (focusInput) {
            focusInput();
          }
          handledKey = 'Escape';
        }
        break;
      case 'Backspace':
        if (inputFocused && focusedItem === -1 && inputValue === '' && value.length > 0 && !vertical) {
          const index = value.length - 1;
          removeItem({ value: value[index], index });
          handledKey = 'Backspace';
        }
        break;
      default:
    }

    return handledKey;
  });

  const handleItemOnPress = useEventCallback((e, item) => {
    if (onItemPress) {
      onItemPress(e, item);
    }
  });

  const handleItemOnDelete = useEventCallback((e, item) => {
    if (onItemDelete) {
      const cancel = onItemDelete(e, item);
      if (cancel === true) {
        return;
      }
    }

    if (e && e.preventHandlerDefault) {
      return;
    }

    removeItem(item, e ? e.type : null);
  });

  const handleItemOnBlur = useEventCallback((e, item) => {
    const { index = null } = item || {};
    if (onItemBlur) {
      const cancel = onItemBlur(e, item);
      if (cancel === true) {
        return;
      }
    }

    if (e && e.preventHandlerDefault) {
      return;
    }
    if (index !== null) {
      if (focusing.current === index) {
        setFocusedItem(-1);
      }
    }
  });

  const getItemProps = ({ index, item }) => {
    const itemLabel = getItemLabel(item);
    return {
      key: `${index}${itemLabel}`,
      disabled,
      focusable: inputFocused || focusedItem !== -1,
      label: itemLabel,
      value: item,
      index,
      marginX: 2,
      marginBottom: 2,
      marginTop: 4,
      ...itemProps,
      ref: itemRefs[index],
      onPress: handleItemOnPress,
      onKeyDown: handleItemOnKeyDown,
      onDelete: handleItemOnDelete,
      onBlur: handleItemOnBlur,
      onFocus: onItemFocus,
    };
  };

  const renderItemComponent = renderItemProp ? renderItemProp : (itemProps) => <Chip {...itemProps} />;

  const renderItem = (item, index) => {
    const itemProps = getItemProps({ item, index });
    return renderItemComponent(itemProps, item, index);
  };

  const items =
    value.length > 0
      ? value.map((item, index) => {
          return renderItem(item, index);
        })
      : null;

  const handleKeyPress = useEventCallback((e) => {
    if (onKeyPress) {
      onKeyPress(e);
    }
    // can call the function and use the logic for other keyboard events like input keyboard events
    return handleItemOnKeyDown(e, true);
  });

  return {
    items,
    focusedItem,
    setFocusedItem,
    itemRefs,
    addItem,
    removeItem,
    updateItem,
    focusItem,
    handleKeyPress,
  };
}

function preventDefaultForEvent(e) {
  if (e && e.preventDefault) {
    e.preventDefault();
  }
}

export { useInputListItems };
