import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { ActivityIndicator } from 'react-native';
import { Box, Text, Heading, Button, Spacing, FileInput } from '../../../../ui';
import { isObject } from '../../../../ui/utils';
import { ContentCard, LayoutBox, Chip, TextData, ShareButtonView, AppLoadingOverlay, Dialog } from '../../../../components';
import { QuoteStatuses, BindingFormTypes } from '../../../../constants';
import { useApi, useAppState, useAsync, useBindFormStatus, useNavigate, useProgressPropsForBindFormStatus } from '../../../../hooks';
import { getAddressLines, formatMoney } from '../../../../utils';
import moment from 'moment-timezone';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';

const useBindFormLabels = (data) => {
  let {
    // id,
    formType,
    // insured,
    address,
    region,
    postcode,
    // url,
    // details,
    // isUpdatable,
    // approvedBy,
    // rejectedBy,
    // requestedAt,
    // approvedAt,
    // rejectedAt,
    location,
    // receivedAt,
    // broker = {},
    // agent = {},
  } = data;
  // const isSiteChecklist = formType === BindingFormTypes.siteChecklist;

  // const isSubmitted = isSiteChecklist && (!isUpdatable || approvedAt) ? true : !isSiteChecklist && url ? true : false;
  // const readyForReview = isSiteChecklist && !isUpdatable && !approvedAt ? true : !isSiteChecklist && url && !approvedAt ? true : false;
  // const accepted = approvedAt;
  // const rejected = !approvedAt && rejectedAt;
  // const hasRejected = rejectedAt ? true : false;
  // const waitingOnClient = !isSubmitted && !readyForReview && !accepted;
  // const waitingOnClientReview = waitingOnClient && hasRejected;

  if (isObject(location)) {
    if (!address && location.address) {
      address = location.address;
    }
    if (!region && location.region) {
      region = location.region;
    }
    if (!postcode && location.postcode) {
      postcode = location.postcode;
    }
  }
  let addressLines = getAddressLines(address, region, postcode);
  if (!address && !region && !postcode) {
    addressLines = ['Address Missing', 'Unknown Region'];
  }

  const formStatus = useBindFormStatus(data);
  const [progress, statusProps] = useProgressPropsForBindFormStatus(formStatus);

  const formTypeLabel = useMemo(() => {
    if (formType === BindingFormTypes.siteChecklist) {
      return 'Site Checklist';
    } else if (formType === BindingFormTypes.leaseAgreement) {
      return 'Lease Agreement';
    } else {
      return 'Other';
    }
  }, [formType]);

  return { addressLines, progress, statusProps, formTypeLabel };
};

export const BindFormsView = ({ data: quote, updating, update, lastUpdated }) => {
  const quoteId = quote.id;
  // const navigate = useNavigate();
  const [{ canBind, canReviewBindForms, canReviewPSCL }] = useAppState();
  const { bindQuoteWithId, getBindingFormsWithQuoteId } = useApi();
  const { setCanExecuteBind } = useAdjustmentState();

  const handleBind = useCallback(async () => {
    const policy = await bindQuoteWithId(quoteId);
    await update();
    if (policy && typeof policy === 'object' && policy.id) {
      console.log('POLICY!', policy);
    }
    // navigate.to('/policies');
  }, [quoteId, bindQuoteWithId, update]);
  const { execute: executeBind, status: executeBindStatus } = useAsync(handleBind, { immediate: false });

  const handleGetBindingForms = useCallback(async () => {
    const bForms = await getBindingFormsWithQuoteId(quoteId);
    const forms = getBindingForms(bForms, quote);
    console.log(forms);
    return forms;
  }, [quoteId, quote, getBindingFormsWithQuoteId]);

  const { status, value: forms } = useAsync(handleGetBindingForms);

  useEffect(() => {
    if (!forms) setCanExecuteBind(false);
    else setCanExecuteBind(true);
  }, [forms, quote, setCanExecuteBind]);

  const readyToBind = useMemo(() => {
    // dmf 2022-10-08 we're always ready to bind
    return true;

    // if (quote && forms) {
    //   let leaseReady = true;
    //   let siteChecklistsReady = true;
    //   for (const lease of forms.leaseAgreements) {
    //     if (!lease.approvedBy) {
    //       leaseReady = false;
    //     }
    //   }
    //   for (const pscl of forms.siteChecklists) {
    //     if (!pscl.approvedBy) {
    //       siteChecklistsReady = false;
    //     }
    //   }
    //   return leaseReady && siteChecklistsReady ? true : false;
    // }
    // return false;
  }, []);
  const [bindModalOpen, setBindModalOpen] = useState(false);

  if (!forms && status === 'pending') {
    return <ActivityIndicator />;
  }
  if (!quote) {
    return <Text>Quote does not exist or is not ready to bind</Text>;
  }
  if (!forms) {
    return <Text>No bind forms are available for this quote</Text>;
  }

  const bound = quote.status === QuoteStatuses.bound;

  console.log(canBind, bound, executeBindStatus);
  return (
    <LayoutBox marginTop="$3">
      <AppLoadingOverlay loading={executeBindStatus === 'pending'} />
      <Dialog
        prompt={<Text large>Please confirm you would like to bind and activate the policy</Text>}
        open={bindModalOpen}
        onClose={() => setBindModalOpen(false)}
        actions={
          <>
            <Button variant="text" label="Cancel" color="$primary" onPress={() => setBindModalOpen(false)} />
            <Spacing horizontal={2} />
            <Button
              label="Execute Bind"
              disabled={executeBindStatus === 'pending' || !readyToBind}
              onPress={() => {
                setBindModalOpen(false);
                if (executeBindStatus !== 'pending' && readyToBind && !bound) {
                  executeBind();
                }
              }}
            />
          </>
        }
      />
      {/* {!bound ? (
          <Box
          mb="$5"
          // mx="$3"
          px="$2"
          py="$2.5"
          bg={({ theme }) => theme.colors.alpha(readyToBind? "$leaf" : "$brandOrange", readyToBind ? 0.4 : 0.3 )}
          borderRadius={6}
          borderWidth={0}
          borderColor="$primary.light"
        >
          <Text mb="$1" bold small uppercase style={{ fontWeight: 600 }}>Current Status</Text>
          <Text large>{readyToBind ? "Ready to Bind. The underwriter will bind the quote and generate a policy after a final review." : "Waiting on form submissions or review"}</Text>
        </Box>
        ) : null} */}

      <ContentCard
        borderWidth="0"
        shadowOpacity="0"
        width="100%"
        padBottom="$5"
        padX="0"
        // padX="$4"
        padTop="$5"
      >
        <Box
          pb="$3"
          mb="$2"
          border={{ bottom: { width: 1, color: '$gray.200' } }}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Heading level={3} small padX="$2">
            {'Bind Forms'}
          </Heading>
          {/* {canBind && !bound ? (
            <Button
              minWidth={148}
              height={44}
              alignSelf="flex-end"
              large
              onPress={executeBindStatus === 'pending' || !readyToBind ? null : () => setBindModalOpen(true)}
              disabled={executeBindStatus === 'pending' || !readyToBind}
              color={readyToBind ? '$primary' : '$gray.600'}
              label="Execute Bind"
              loading={executeBindStatus === 'pending'}
            />
          ) : null} */}
          {/* <Text maxWidth={650}>In order to bind please upload required forms and complete the pre-site checklists for each location.</Text> */}
        </Box>

        {forms.leaseAgreements.length ? (
          <PolicyLeaseAgreementUpload canReview={canReviewBindForms} data={forms.leaseAgreements} update={update} updating={updating} />
        ) : null}
        <Spacing vertical={2} />
        {forms.locations.length ? (
          <SiteChecklists
            canReview={canReviewPSCL}
            locations={forms.locations}
            siteChecklists={forms.siteChecklists}
            update={update}
            updating={updating}
          />
        ) : null}
      </ContentCard>
    </LayoutBox>
  );
};

const SiteChecklists = ({ locations, siteChecklists, updating, canReview, update }) => {
  // const navigate = useNavigate();

  // const completed = locations.filter(({ siteChecklistForm }) => {
  //   return siteChecklistForm.approvedBy || siteChecklistForm.readyForReview ? true : false;
  // });

  // const complete = completed.length === locations.length;

  // const { approveBindingFormWithIdForQuoteId, rejectBindingFormWithIdForQuoteId } = useApi();
  // const acceptRejectForm = useCallback(
  //   async (accept = false, quoteId, formId) => {
  //     if (accept) {
  //       await approveBindingFormWithIdForQuoteId(formId, quoteId);
  //     } else {
  //       await rejectBindingFormWithIdForQuoteId(formId, quoteId, 'There was an issue');
  //       // todo ^^ get reason modal in here
  //     }
  //     await update();
  //   },
  //   [approveBindingFormWithIdForQuoteId, rejectBindingFormWithIdForQuoteId, update]
  // );
  // const { status: reviewStatus, execute: submitReview, error: reviewError } = useAsync(acceptRejectForm, { immediate: false });

  return (
    <>
      {/* <Box flexDirection="row" padBottom="$3" justifyContent="space-between" alignItems="center">
        <Heading level={3} medium>Site Checklists</Heading>
        <Box ml="$2" px="$1.75" py="$1" bg={complete ? '$secondary' : "$gray.200"} borderRadius={10}>
          <Text  bold small opacity={updating ? 0 : 1} onColor={complete ? '$secondary' : "$gray.200"}>
            {complete ? 'Complete' : 'Incomplete'}
          </Text>
          {updating ? (
            <Box position="absolute" left={0} top={0} width="100%" height="100%" justifyContent="center" alignItems="center">
              <ActivityIndicator />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Text small mt="$-1.5" mb="$2" maxWidth={480}>For each location provide the data through the online form which can be shared with your client.</Text>

      <Text pt="$1.5">{`${completed.length} / ${locations.length} checklists completed`}</Text>
      <Box width="100%" height={1} bg="$gray.200" /> */}
      {locations.map((location, i) => {
        return <SiteChecklistItem location={location} canReview={canReview} index={i} key={`loc${location.id}`} />;
      })}
    </>
  );
};

const SiteChecklistItem = ({ location, canReview, index }) => {
  const navigate = useNavigate();
  const { name, coveredValue, siteChecklistForm } = location;
  const { id: formId, quoteId, approvedBy, isUpdatable, approvedAt, rejectedAt, requestedAt, receivedAt } = siteChecklistForm;
  const siteChecklistPath = `/site-checklist/${quoteId}/${formId}`;
  const navigateToSiteChecklistForm = () => navigate.to(siteChecklistPath);
  const { addressLines, statusProps, formTypeLabel } = useBindFormLabels(siteChecklistForm);
  const tivColor = coveredValue < 1000000 ? '$coral' : '$gray.100';
  return (
    <>
      <ContentCard alignItems="stretch" borderRadius={8} border={{ width: 1, color: '$gray.100' }} padX="$3" padY="$2" zIndex={index + 1}>
        <LayoutBox row justifyContent="space-between" alignItems="flex-start" pb="$1.5" border={{ bottom: { width: 1, color: '$gray.100' } }}>
          <LayoutBox flexDirection="column-reverse" flex={1.35} layout="center-left">
            <Text small color={tivColor}>
              TIV: {formatMoney(coveredValue, { prefix: '$' })}
            </Text>
            <Chip {...statusProps} />
            <Text bold>{formTypeLabel}</Text>
          </LayoutBox>
          <LayoutBox flex={1.2} layout="center-left">
            <Text small capitalize bold>
              {name}
            </Text>
            {Array.isArray(addressLines) ? (
              addressLines.map((a, i) => (
                <Text small key={`aline${i}`} maxLines={1}>
                  {a}
                </Text>
              ))
            ) : (
              <Text small maxLines={2}>
                {addressLines}
              </Text>
            )}
          </LayoutBox>
          <TextData
            flex={0.8}
            label="Requested on"
            data={requestedAt ? moment(requestedAt).format('MM-DD-YYYY') : 'Not available'}
            alignItems="inherit"
            size="small"
            invert
            labelProps={{ size: 'xSmall' }}
          />
          <TextData
            flex={0.8}
            label="Last Updated"
            data={moment(approvedAt ? approvedAt : rejectedAt ? rejectedAt : receivedAt ? receivedAt : requestedAt).format('MM-DD-YYYY')}
            alignItems="inherit"
            size="small"
            invert
            labelProps={{ size: 'xSmall' }}
          />
        </LayoutBox>
        <LayoutBox row alignItems="center" justifyContent="flex-end" pt="$1.5" gap={8}>
          <ShareButtonView url={`${window.location.origin}${siteChecklistPath}`} />
          <Button onPress={navigateToSiteChecklistForm} outlined={approvedBy ? true : false} color={canReview ? '$secondary' : '$primary'}>
            {approvedBy ? 'View Form  →' : canReview && !isUpdatable ? 'Review  →' : 'Continue   →'}
          </Button>
        </LayoutBox>
      </ContentCard>

      <Spacing vertical={2} />
    </>
  );
  // return (
  //   <Box mt="$3.5" pb="$5" key={`loc${id}`}>
  //     {approvedBy || rejectedBy ? (
  //       <Text small bold marginBottom="$1.25" uppercase color={approvedBy ? '$secondary' : rejectedBy ? '$coral' : '$brandOrange'}>
  //         {approvedBy ? 'Approved' : rejectedBy ? 'Rejected' : 'Pending Review'}
  //       </Text>
  //     ) : null}
  //     <Heading level={5}>{capitalize(name)}</Heading>
  //     <Text dim small bold>
  //       {address}
  //     </Text>
  //     <Box padTop="$2" alignItems="flex-start">
  //       <Box flexDirection="row" gap="36">
  //         <Button onPress={navigateToSiteChecklistForm} outlined={approvedBy ? true : false} color={canReview ? '$secondary' : '$primary'}>
  //           {approvedBy ? 'View Form  →' : canReview && !isUpdatable ? 'Review  →' : 'Continue   →'}
  //         </Button>
  //       </Box>
  //     </Box>
  //   </Box>
  // );
};
// http://localhost:3000/site-checklist/730f32db-beb8-4e53-a77a-ad6b5b5a85e3/3571ad96-6c8c-4bd0-aa21-92ce317114be/details
// change color of buttons for review / continue
// inidicate ready for review

const PolicyLeaseAgreementUpload = ({ data, updating, update, children, canReview, ...rest }) => {
  const complete = useMemo(() => {
    for (const f of data) {
      const { url, s3DownloadLink } = f;
      if (!url && !s3DownloadLink) {
        return false;
      }
    }
    return true;
  }, [data]);
  console.log(data);
  const url = useMemo(() => {
    for (const f of data) {
      if (f.url) {
        return f.url;
      }
    }
    return null;
  }, [data]);
  const [inputValue, setInputValue] = useState(null);
  const lastInputValue = useRef(inputValue);

  const { uploadBindingFormOnQuoteId, approveBindingFormWithIdForQuoteId, rejectBindingFormWithIdForQuoteId } = useApi();
  const uploadDocument = useCallback(
    async (forms, document) => {
      for (const f of forms) {
        const { quoteId, id } = f;
        await uploadBindingFormOnQuoteId(quoteId, {
          id,
          document,
        });
      }
      await update();
    },
    [uploadBindingFormOnQuoteId, update]
  );

  const { status, execute } = useAsync(uploadDocument, { immediate: false });
  const loading = status === 'pending' || updating;

  useEffect(() => {
    if (inputValue && inputValue !== lastInputValue.current) {
      lastInputValue.current = inputValue;
      execute(data, inputValue);
    }
  }, [inputValue, execute, data]);

  const acceptRejectForm = useCallback(
    async (accept = false) => {
      for (const form of data) {
        const { quoteId, id } = form;
        if (accept) {
          await approveBindingFormWithIdForQuoteId(id, quoteId);
        } else {
          await rejectBindingFormWithIdForQuoteId(id, quoteId);
        }
      }
      await update();
    },
    [data, approveBindingFormWithIdForQuoteId, rejectBindingFormWithIdForQuoteId, update]
  );
  const { status: reviewStatus, execute: submitReview } = useAsync(acceptRejectForm, { immediate: false });
  const fd = data && data.length ? data[0] : {};
  const { approvedBy = 0, requestedAt, approvedAt, rejectedAt, receivedAt } = fd;

  const { statusProps, formTypeLabel } = useBindFormLabels(fd);

  const nameOfFile = useMemo(() => {
    if (complete) {
      for (const f of data) {
        const { s3DownloadLink } = f;
        if (s3DownloadLink) {
          const start = s3DownloadLink.lastIndexOf('/');
          const end = s3DownloadLink.length;
          if (start !== -1 && start + 1 < end) {
            return s3DownloadLink.slice(start + 1, end);
          }
        }
      }
    }
    return '';
  }, [data, complete]);
  return (
    <ContentCard alignItems="stretch" borderRadius={8} border={{ width: 1, color: '$gray.100' }} padX="$3" padY="$2">
      <LayoutBox row justifyContent="space-between" alignItems="flex-start" pb="$1.5" border={{ bottom: { width: 1, color: '$gray.100' } }}>
        <LayoutBox flexDirection="column-reverse" flex={1.35} layout="center-left">
          <Chip {...statusProps} />
          <Text bold>{formTypeLabel}</Text>
        </LayoutBox>
        <LayoutBox flex={1.2} layout="center-left">
          <Text bold>Policy Wide</Text>
          <Text small>All Locations</Text>
          {/* <Text small capitalize bold>{name}</Text>
          {Array.isArray(addressLines) ?
              addressLines.map((a, i) => (
                <Text small key={`aline${i}`} maxLines={1}>{a}</Text>
              )) : <Text small maxLines={2}>{addressLines}</Text>} */}
        </LayoutBox>
        <TextData
          flex={0.8}
          label="Requested on"
          data={requestedAt ? moment(requestedAt).format('MM-DD-YYYY') : 'Not available'}
          alignItems="inherit"
          size="small"
          invert
          labelProps={{ size: 'xSmall' }}
        />
        <TextData
          flex={0.8}
          label="Last Updated"
          data={moment(approvedAt ? approvedAt : rejectedAt ? rejectedAt : receivedAt ? receivedAt : requestedAt).format('MM-DD-YYYY')}
          alignItems="inherit"
          size="small"
          invert
          labelProps={{ size: 'xSmall' }}
        />
      </LayoutBox>
      <LayoutBox row alignItems="center" justifyContent="flex-end" pt="$1.5">
        {nameOfFile ? (
          <Text small dim>
            {nameOfFile}
          </Text>
        ) : null}
        <FileInput
          hideUpload={canReview && complete}
          value={inputValue}
          url={url}
          disabled={loading}
          onChangeValue={(files) => {
            if (files.length) {
              setInputValue(files[0]);
            }
          }}
        >
          {canReview && complete && !approvedBy ? (
            <ReviewActions onAccept={() => submitReview(true)} onReject={() => submitReview(false)} disabled={reviewStatus === 'pending'} />
          ) : null}
        </FileInput>
      </LayoutBox>
    </ContentCard>
  );
  // return (
  //   <>
  //     {approvedBy || rejectedBy ? (
  //       <Text small bold marginBottom="$1.25" uppercase color={approvedBy ? '$secondary' : rejectedBy ? '$coral' : '$brandOrange'}>
  //         {approvedBy ? 'Approved' : rejectedBy ? 'Rejected' : 'Pending Review'}
  //       </Text>
  //     ) : null}
  //     <Box flexDirection="row" padBottom="$3" justifyContent="space-between" alignItems="center" {...rest}>
  //       <Heading level={5}>Policy Lease Agreement</Heading>
  //       <Box ml="$2" px="$1.75" py="$1" bg={complete ? '$secondary' : '$gray.200'} borderRadius={10}>
  //         <Text bold small opacity={loading ? 0 : 1} onColor={complete ? '$secondary' : '$gray.200'}>
  //           {complete ? 'Complete' : 'Incomplete'}
  //         </Text>
  //         {loading ? (
  //           <Box position="absolute" left={0} top={0} width="100%" height="100%" justifyContent="center" alignItems="center">
  //             <ActivityIndicator />
  //           </Box>
  //         ) : null}
  //       </Box>
  //     </Box>

  //     {children}
  //     <FileInput
  //       hideUpload={canReview && complete}
  //       value={inputValue}
  //       url={url}
  //       disabled={loading}
  //       onChangeValue={(files) => {
  //         if (files.length) {
  //           setInputValue(files[0]);
  //         }
  //       }}
  //     >
  //       {canReview && complete && !approvedBy ? (
  //         <ReviewActions onAccept={() => submitReview(true)} onReject={() => submitReview(false)} disabled={reviewStatus === 'pending'} />
  //       ) : null}
  //     </FileInput>
  //   </>
  // );
};

const ReviewActions = ({ onAccept, onReject, disabled }) => {
  return (
    <Box flexDirection="row" justifyContent="flex-start" alignItems="center" pointerEvents={disabled ? 'none' : 'auto'} gap={12}>
      <Button onPress={onAccept} disabled={disabled}>
        Approve
      </Button>
      <Button onPress={onReject} disabled={disabled}>
        Reject
      </Button>
    </Box>
  );
};

function getBindingForms(response, quote) {
  // TODO: fix this. api needs to be sending an applicaitonLocationId with the lease agreement as well...
  let forms = { locations: [] };

  let leaseAgreements = [];
  let siteChecklists = [];
  if (quote) {
    const bindingForms = response;
    if (!Array.isArray(bindingForms) || !bindingForms.length) {
      return null;
    }
    // temporarily necessary

    for (const form of bindingForms) {
      console.log(form.formType);
      if (form.formType === BindingFormTypes.leaseAgreement) {
        leaseAgreements.push({ ...form });
      } else if (form.formType === BindingFormTypes.siteChecklist) {
        let readyForReview = !form.isUpdatable;
        siteChecklists.push({ ...form, readyForReview });
      }
    }
    for (const sc of siteChecklists) {
      let location = null;
      const quoteLocation = quote.locations.find((l) => l.id === sc.location.id);
      if (quoteLocation) {
        const existingLocation = forms.locations.find((l) => l.id === quoteLocation.id);
        if (existingLocation) {
          location = existingLocation;
        } else {
          location = { ...quoteLocation };
          location.forms = location.forms ? location.forms : [];
          forms.locations.push(location);
        }
        location.siteChecklistForm = sc;
        location.forms.push(sc);
      }
    }
    // for (const la of leaseAgreements) {
    //   for (const location of forms.locations) {
    //     if (la.name.includes(location.name)) {
    //       location.forms.unshift(la);
    //     }
    //   }
    // }
  }

  forms.leaseAgreements = leaseAgreements;
  forms.siteChecklists = siteChecklists;
  return forms;
}

// function getPreboundQuote(quotes, quoteId) {
//   let found = null;
//   for (const q of quotes) {
//     let isValid = false;
//     if (q.status === QuoteStatuses.prebound || q.status === QuoteStatuses.bound) {
//       isValid = true;
//     }
//     if (quoteId) {
//       if (isValid) {
//         if (`${quoteId}` === `${q.id}`) {
//           return q;
//         } else if (!found) {
//           found = q;
//         }
//       }
//     } else if (isValid) {
//       return q;
//     }
//   }
//   return found;
// }
