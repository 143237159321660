import React from 'react';
import { Svg } from '../../ui';

const icon = {
  paths: [
    {
      d: 'M271.203 271.703C321.581 221.325 321.581 139.647 271.203 89.2691C220.825 38.8914 139.147 38.8914 88.7696 89.2691C38.3919 139.647 38.3919 221.325 88.7696 271.703C139.147 322.08 220.825 322.08 271.203 271.703ZM247.222 247.722C284.355 210.588 284.355 150.383 247.222 113.25C210.088 76.1163 149.883 76.1163 112.75 113.25C75.6163 150.383 75.6163 210.588 112.75 247.722C149.883 284.855 210.088 284.855 247.222 247.722Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    },
    {
      d: 'M262.011 286.552C255.372 279.913 255.372 269.149 262.011 262.51C268.65 255.871 279.413 255.871 286.052 262.51L423.231 399.689C429.87 406.328 429.87 417.092 423.231 423.731C416.592 430.369 405.828 430.369 399.189 423.731L262.011 286.552Z',
    },
  ],
};

export const SearchIcon = ({ fill, color = 'black', ...props }) => {
  return (
    <Svg fill={color} color={color} size="24" viewBox="0 0 512 512" {...props}>
      {icon.paths.map((path, i) => (
        <Svg.Path key={`path${i}`} {...path} />
      ))}
    </Svg>
  );
};
