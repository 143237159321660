import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useRouteMatch, Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { useEventCallback, useBreakpoint } from '../../ui/hooks';
import { QuotesListView } from '../QuotesListView';
import { ApplicationsListView } from '../ApplicationsListView';
import { PoliciesListView } from '../PoliciesListView';
import { ClaimsListView } from '../ClaimsListView';
import { BindFormsListView } from '../BindFormsListView';
import { AnalyticsView } from '../AnalyticsView';
import { useAuth, useNavigate } from '../../hooks';
import { Box, ScrollView, Text, IconButton, ModalBackdrop } from '../../ui';
import {
  AppBar,
  Page,
  NavRow,
  LogoNavGroup,
  AppActionsGroup,
  ScrollToTop,
  Sidebar,
  LayoutBox,
  Logo,
  useSidebarState,
  appSidebarPortalName,
} from '../../components';
import {
  ApplicationDocumentIcon,
  CarInsuranceIcon,
  LineChartIcon,
  MenuIcon,
  QuotePriceIcon,
  SignUpCalendarIcon,
  ToolboxIcon,
} from '../../components/icons';
import { CollateralButtonAndModal } from '../CollateralPage';
import { UserRoles } from '../../constants';

const Dashboard = ({ children }) => {
  // const auth = useAuth();
  return (
    <Page row="sm" bg="$white" maxHeight="100%" maxWidth="100%">
      <AppSideBarMenu />
      <ScrollContainer displayForPaths={dashboardPaths}>
        <Switch>
          <Route path={['/applications/:id', '/applications']}>
            <ApplicationsListView searchPath="/applications" />
          </Route>
          <Route path={['/quotes/:id', '/quotes']}>
            <QuotesListView searchPath="/quotes" />
          </Route>
          <Route path="/bind-forms">
            <BindFormsListView searchPath="/bind-forms" />
          </Route>
          <Route path={['/policies/:id', '/policies']}>
            <PoliciesListView searchPath="/policies" />
          </Route>
          <Route path={['/claims/:id', '/claims']}>
            <ClaimsListView searchPath="/claims" />
          </Route>
          <Route path="/analytics">
            <AnalyticsView />
          </Route>
          <Route exact path="/">
            <Redirect to="/quotes" />
          </Route>
        </Switch>
      </ScrollContainer>
      <ScrollContainer displayForPaths={dashboardPaths} inverse>
        {children}
      </ScrollContainer>
    </Page>
  );
};

const dashboardPaths = ['/applications', '/quotes', '/bind-forms', '/policies', '/claims', '/analytics'];

const useDisplayForPaths = (paths = [], inverse) => {
  const location = useLocation();
  const pathname = location ? location.pathname : '';
  const includes = paths.includes(pathname);
  let display = includes ? true : false;
  if (inverse) {
    display = !display;
  }
  return display ? 'flex' : 'none';
};

const ScrollContainer = ({ displayForPaths, inverse = false, children }) => {
  const display = useDisplayForPaths(displayForPaths, inverse);
  const scrollNode = useRef();
  useEffect(() => {
    if (scrollNode.current) {
      if (display !== 'flex') {
        if (!scrollNode.current.scrollEventManager.eventsDisabled) {
          scrollNode.current.scrollEventManager.disableEvents();
        }
      } else if (scrollNode.current.scrollEventManager.eventsDisabled) {
        scrollNode.current.scrollEventManager.enableEvents();
      }
    }
  }, [display]);
  return (
    <ScrollView
      ref={scrollNode}
      width="100%"
      maxWidth="100%"
      height="100%"
      maxHeight="100vh"
      display={display}
      scrollEnabled={display === 'flex'}
      provideNode
      contentContainer={{
        width: '100%',
        maxWidth: '100%',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Page minHeight="101vh" row="md" bg="$primary">
        <ScrollToTop
          trigger={(curr, last) => {
            if (curr !== last) {
              if (displayForPaths.includes(curr)) {
                if (!inverse) {
                  return curr;
                }
              }
            }
            return last;
          }}
        />
        <DashboardNavBar hide={display === 'none'} />
        <LayoutBox flex={1} width="100%" bg="$white" borderLeftRadius={16}>
          {children}
        </LayoutBox>
      </Page>
    </ScrollView>
  );
};

const DashboardNavBar = () => {
  const [, setOpen] = useSidebarState();
  const [breakpoint] = useBreakpoint();
  return (
    <AppBar transparent disablePlaceholder>
      <NavRow
        sx={({ breakpoints }) =>
          breakpoints({
            xs: {
              justifyContent: 'space-between',
              bg: '$white',
            },
            sm: {
              justifyContent: 'flex-end',
              bg: 'transparent',
            },
          })
        }
      >
        <LogoNavGroup
          marginLeft={({ theme }) => theme.breakpoints({ xs: 0, sm: theme.sizes.sidebarWidthSm })}
          display={({ theme }) => theme.breakpoints({ xs: 'flex', sm: 'none' })}
          title={<Text display={({ theme }) => theme.breakpoints({ xs: 'none', sm: 'none', md: 'none' })}>Understory</Text>}
          onPress={breakpoint.key === 'xs' ? () => setOpen((o) => !o) : undefined}
        >
          {breakpoint.key === 'xs' ? <MenuIcon color="$primary" /> : null}
        </LogoNavGroup>
        <AppActionsGroup />
      </NavRow>
    </AppBar>
  );
};

// TODO:
// appState on Routes.js should set eitehr a default tab or an array of available tabs or both
// ^^ <DashboardTabs will render the available tabs based of the array of tab names mapped to the Tabs object name key
const Tabs = {
  applications: {
    name: 'applications',
    label: 'Applications',
    icon: ApplicationDocumentIcon,
  },
  quotes: {
    name: 'quotes',
    label: 'Quotes',
    icon: QuotePriceIcon,
  },
  'bind-forms': {
    name: 'bind-forms',
    label: 'Bind Forms',
    icon: SignUpCalendarIcon,
  },
  policies: {
    name: 'policies',
    label: 'Policies',
    icon: CarInsuranceIcon,
  },
  // claims: {
  //   name: 'claims',
  //   label: 'Claims',
  //   icon: CarHailDamageIcon,
  // },
  analytics: {
    name: 'analytics',
    label: 'Analytics',
    icon: LineChartIcon,
  },
  admin: {
    name: 'admin',
    label: 'Admin',
    icon: ToolboxIcon,
    roles: [UserRoles.internal],
  },
};

const AppSideBarMenu = () => {
  return (
    <>
      <AppSideBar condensed hide={{ xs: true, sm: false, md: true }} />
      <AnimateSidebar />
    </>
  );
};

const AnimateSidebar = () => {
  const [breakpoint] = useBreakpoint();
  const currBreakpoint = breakpoint.key;
  const canAnimate = currBreakpoint === 'sm' || currBreakpoint === 'xs';
  const [open, setOpen] = useSidebarState();
  useEffect(() => {
    if (canAnimate) {
      setOpen(false);
    }
  }, [canAnimate, setOpen]);

  return (
    <AppSideBar
      sx={(theme) => theme.breakpoints(sidebarStyles)}
      animations={sidebarAnimations}
      disableAnimationDefaults
      animate={canAnimate ? (open ? 'opened' : 'closed') : null}
      withPlaceholder={canAnimate ? false : true}
      portal={appSidebarPortalName}
      asDrawer={canAnimate}
      opened={open}
      onClose={() => setOpen(false)}
      component={canAnimate ? SidebarWithModal : undefined}
    />
  );
};

const SidebarWithModal = React.forwardRef((props, ref) => {
  const { opened, onClose, ...rest } = props;
  return (
    <>
      <Box ref={ref} {...rest} />
      <ModalBackdrop open={opened} onPress={onClose} zIndex={({ theme }) => theme.zIndex.drawer - 1} />
    </>
  );
});

const sidebarStyles = {
  sm: {
    tx: (theme) => theme.sizes.sidebarWidth * -1.5,
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    maxHeight: '100%',
    zIndex: '$drawer',
  },
  md: {
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    maxHeight: '100%',
    zIndex: '$drawer',
  },
};

const sidebarAnimations = {
  opened: {
    tx: 0,
  },
  closed: {
    tx: ({ theme }) => theme.sizes.sidebarWidth * -1.5,
  },
};

const AppSideBar = ({ condensed = false, asDrawer = false, ...props }) => {
  const [, setOpen] = useSidebarState();
  return (
    <Sidebar width={condensed ? '$sidebarWidthSm' : '$sidebarWidth'} bg="$primary" overflow="hidden" {...props}>
      <ScrollView
        padX={condensed ? '$1' : '$1.75'}
        height="100%"
        minHeight="100%"
        maxHeight="100%"
        containerStyle={{
          minHeight: '100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: condensed ? 'center' : 'flex-start',
        }}
      >
        <LayoutBox width="100%" maxWidth="100%">
          <LayoutBox
            row
            layout={condensed ? 'center' : 'center-left'}
            width="100%"
            maxWidth="100%"
            sx={{
              padLeft: condensed ? 0 : asDrawer ? 4 : '$2',
              padTop: '$2',
              padBottom: '$2',
            }}
          >
            {condensed ? (
              <IconButton color="white" onPress={() => setOpen((o) => !o)}>
                <MenuIcon color="white" />
              </IconButton>
            ) : (
              <>
                {asDrawer ? (
                  <IconButton color="white" onPress={() => setOpen((o) => !o)}>
                    <MenuIcon color="white" />
                  </IconButton>
                ) : null}
                <Logo src="/images/understory_logo_white.png" marginRight="$2" />
                <Text medium uppercase color="white" letterSpacing={1} style={{ fontWeight: 300 }}>
                  Understory
                </Text>
              </>
            )}
          </LayoutBox>

          <DashboardTabs condensed={condensed} gap={condensed ? 16 : 0} />
        </LayoutBox>
        {condensed ? null : (
          <LayoutBox
            sx={{
              width: '100%',
              alignSelf: 'flex-end',
              padBottom: '$3.5',
              marginTop: '$3.5',
            }}
            layout="bottom-left"
          >
            <Box alignSelf="center" height={1} width="90%" bg="$primary.light" mb="$3" />
            <CollateralButtonAndModal
              variant="text"
              color="white"
              gap={4}
              endIcon={<ToolboxIcon size={20} color="white" />}
              sx={{ width: '100%', justifyContent: 'flex-start', padLeft: '$2.5' }}
              onPress={() => setOpen(false)}
            />
            {/* <Spacing vertical="1" /> */}
            {/* <Button
              variant="text"
              color="white"
              label="Help"
              sx={{ width: '100%', justifyContent: 'flex-start', padLeft: '$2.5' }}
            /> */}
          </LayoutBox>
        )}
      </ScrollView>
    </Sidebar>
  );
};

const DashboardTabs = ({ condensed = false, ...rest }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const match = useRouteMatch('/:tab');
  const tabMatch = match && match.params ? match.params.tab : null;
  const tabParam = Tabs[tabMatch] ? Tabs[tabMatch].name : null;
  const shouldNavigate = useRef(false);
  const [currentTab, setCurrentTab] = useState(tabParam);
  const [sidebarOpen, setSidebarOpen] = useSidebarState();

  useEffect(() => {
    if (tabParam && !currentTab) {
      setCurrentTab(tabParam);
    } else if (tabParam !== currentTab) {
      if (currentTab && shouldNavigate.current) {
        shouldNavigate.current = false;
        navigate.to({
          pathname: `/${currentTab}`,
          state: { tab: currentTab },
        });
      } else {
        setCurrentTab(tabParam ? tabParam : null);
      }
    }
  }, [currentTab, tabParam, navigate]);

  const handleSelect = useEventCallback(
    (tab) => {
      if (Tabs[tab]) {
        shouldNavigate.current = Tabs[tab].name !== tabParam;
        if (Tabs[tab].name !== currentTab || shouldNavigate.current) {
          setCurrentTab(Tabs[tab].name);
        }
      }
      if (sidebarOpen) {
        setSidebarOpen(false);
      }
    },
    [currentTab, tabParam, sidebarOpen, setSidebarOpen]
  );

  const tabs = useMemo(() => {
    const roles = user && Array.isArray(user.roles) ? user.roles : [];
    return Object.keys(Tabs).filter((key) => {
      if (Array.isArray(Tabs[key].roles)) {
        for (const role of Tabs[key].roles) {
          if (!roles.includes(role)) {
            return false;
          }
        }
      }
      return true;
    });
  }, [user]);

  const tabItems = useMemo(
    () =>
      tabs.map((key) => (
        <SideMenuButton
          condensed={condensed}
          tab={Tabs[key].name}
          icon={Tabs[key].icon}
          selected={currentTab === Tabs[key].name}
          onPress={() => {
            handleSelect(Tabs[key].name);
          }}
          key={Tabs[key].name}
        />
      )),
    [tabs, handleSelect, currentTab, condensed]
  );

  return (
    <LayoutBox marginTop="$4.5" width="100%" maxWidth="100%" alignSelf="flex-start" {...rest}>
      {tabItems}
    </LayoutBox>
  );
};

const SideMenuButton = ({ tab, selected, children, icon: Icon, condensed = false, ...rest }) => {
  if (!Tabs[tab]) {
    throw new Error(`Error: tab with name: ${tab} does not exist in Tabs`);
  }
  const label = Tabs[tab].label;
  return (
    <Box
      focusable
      sx={{
        padX: '$2',
        padY: '$1.5',
        borderRadius: '$circle',
        bg: 'transparent',
        zIndex: selected ? 2 : 1,
        flexDirection: 'row',
        alignItems: 'center',
        mb: '$0.75',
      }}
      gap={16}
      {...rest}
    >
      {Icon ? <Icon color="white" opacity={selected ? 1 : 0.64} /> : null}
      {!condensed ? (
        <Text size="medium" dim={selected ? 1 : 0.64} color="white" bold={selected} numLines={1}>
          {label}
        </Text>
      ) : null}
      <Box
        position="absolute"
        top={0}
        left={0}
        zIndex="-1"
        width="120%"
        height="100%"
        bg="$primary.dark"
        borderLeftRadius={12}
        opacity={0.5}
        display={selected ? 'flex' : 'none'}
        pointerEvents="none"
      />
    </Box>
  );
};

export { Dashboard };
