import { Platform } from 'react-native';
import { isWindowOverflowing, getScrollbarWidth } from '../../utils';

const isBrowser = Platform.OS === 'web' && typeof window !== `undefined`;
export class ModalManager {
  open = [];
  disableScrollLockModals = [];

  getIndex(modalId) {
    const index = modalId ? this.open.findIndex((el) => el.id === modalId) : -1;
    return index;
  }

  isTopModal(modalId) {
    return this.getIndex(modalId) === this.open.length - 1;
  }

  /*
    update modals on their active/top status (current focused modal) whenever a modal is added or removed
  */
  updateModalsWithCurrentTop() {
    const topModalId = this.open.length ? this.open[this.open.length - 1].id : -1;
    if (topModalId) {
      for (const modalDef of this.open) {
        const { updateTop, id: modalId } = modalDef || {};
        if (updateTop) {
          updateTop(modalId === topModalId);
        }
      }
    }
  }

  addToTop(modalDef, callback, options) {
    const { id: modalId } = modalDef;
    if (!modalId) return;
    const index = this.getIndex(modalId);
    if (index !== -1) {
      this.open.splice(index, 1);
    }
    this.open.push(modalDef);
    this.updateModalsWithCurrentTop();
    if (typeof callback === 'function') {
      callback();
    }
    this.handleOptionsForModalId(modalId, options);
  }

  add(modalDef, callback, options) {
    const { id: modalId } = modalDef;
    if (!modalId) return;
    this.remove(modalId);
    this.open.push(modalDef);
    this.updateModalsWithCurrentTop();
    if (typeof callback === 'function') {
      callback();
    }
    this.handleOptionsForModalId(modalId, options);
  }

  remove(modalId, callback) {
    if (!modalId) return;
    const index = this.getIndex(modalId);
    if (index !== -1) {
      this.open[index].updateTop(false);
      this.open.splice(index, 1);
    }
    this.updateModalsWithCurrentTop();
    if (typeof callback === 'function') {
      callback();
    }
    this.cleanupOptionsForModalId(modalId);
  }

  handleOptionsForModalId(modalId, options) {
    if (!modalId) return;
    const optionIndex = this.disableScrollLockModals.indexOf(modalId);
    if (options && options.disableScrollLock === false) {
      if (optionIndex === -1) {
        this.disableScrollLockModals.push(modalId);
      }
      this.disableScroll();
    } else if (optionIndex !== -1) {
      this.cleanupOptionsForModalId(modalId);
    }
  }

  cleanupOptionsForModalId(modalId) {
    if (modalId) {
      const optionIndex = this.disableScrollLockModals.indexOf(modalId);
      if (optionIndex !== -1) {
        this.disableScrollLockModals.splice(optionIndex, 1);
      }
    }
    if (this.open.length === 0 || this.disableScrollLockModals.length === 0) {
      this.disableScrollLockModals = [];
      this.enableScroll();
    }
  }

  enableScroll() {
    if (!isBrowser) return;
    document.body.style.paddingRight = `0px`;
    document.body.style.overflowY = 'auto';
  }

  disableScroll() {
    if (!isBrowser) return;
    if (isWindowOverflowing()) {
      const scrollbarSize = getScrollbarWidth();
      document.body.style.paddingRight = `${scrollbarSize}px`;
    }
    document.body.style.overflowY = 'hidden';
  }
}
