import { useMemo, createRef } from 'react';

export function useRefArray(items) {
  return useMemo(() => {
    const length = Array.isArray(items) ? items.length : 0;
    return Array(length)
      .fill()
      .map(() => createRef());
  }, [items]);
}
