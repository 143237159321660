import { MIN_MAX_RANGE, DATES_FROM_TO, SELECT, MULTI_SELECT, SWITCH, CHECKBOX } from './filterTypes';

export const applicationItemData = {
  address: {
    display: true,
    search: {
      name: 'address',
      label: 'Address',
    },
    type: 'string',
  },
  agent: {
    type: 'object',
    properties: {
      email: {
        display: true,
        sort: {
          name: 'agent_email',
          label: 'Agent Email',
        },
        search: {
          name: 'agent_email',
          label: 'Agent Email',
        },
        type: 'string',
      },
      name: {
        display: true,
        sort: {
          name: 'agent_name',
          label: 'Agent Name',
        },
        search: {
          name: 'agent_name',
          label: 'Agent Name',
        },
        type: 'string',
      },
      contactId: {
        type: 'number',
      },
      id: {
        type: 'number',
      },
      userId: {
        type: 'number',
      },
    },
  },
  appliedAt: {
    display: true,
    sort: {
      name: 'applied_at',
      label: 'Applied At',
      initial: { field: 'applied_at', by: 'DESC' },
    },
    type: 'number',
  },
  broker: {
    type: 'object',
    properties: {
      id: {
        type: 'number',
      },
      name: {
        display: true,
        sort: {
          name: 'broker_name',
          label: 'Broker Name',
        },
        search: {
          name: 'broker_name',
          label: 'Broker Name',
        },
        type: 'string',
      },
    },
  },
  country: {
    display: true,
    sort: {
      name: 'country',
      label: 'Country',
    },
    search: {
      name: 'country',
      label: 'Country',
    },
    type: 'string',
  },
  countryCode: {
    display: true,
    filter: {
      type: SELECT,
      name: 'country_code',
      label: 'Country Code',
      options: {},
      defaults: {
        value: null,
      },
      exclude: true,
    },
    search: {
      name: 'country_code',
      label: 'Country Code',
    },
    sort: {
      name: 'country_code',
      label: 'Country Code',
      exclude: true,
    },
    type: 'string',
  },
  customerId: {
    type: 'string',
  },
  details: {
    type: 'object',
    properties: {
      employees: {
        display: true,
        type: 'number',
      },
      files: {
        type: 'object',
        notes: 'the properties are the file ids which is weird...',
      },
      loss_history_download_link: {
        type: 'string',
      },
    },
  },
  id: {
    type: 'string',
  },
  insured: {
    display: true,
    sort: {
      name: 'insured',
      label: 'Insured',
    },
    search: {
      name: 'insured',
      label: 'Insured',
    },
    type: 'string',
  },
  isValid: {
    display: true,
    filter: {
      type: CHECKBOX,
      name: 'is_valid',
      label: 'Is Valid',
      defaults: {
        value: null,
      },
      exclude: true,
    },
    type: 'boolean',
  },
  lat: {
    type: 'number',
  },
  lon: {
    type: 'number',
  },
  locations: {
    type: 'array',
    properties: {
      length: {
        display: true,
        filter: {
          type: MIN_MAX_RANGE,
          name: 'locations_length',
          label: 'Locations Length',
          order: 10,
        },
        sort: {
          name: 'locations_length',
          label: 'Locations Length',
        },
        type: 'number',
      },
    },
    values: {
      type: 'object',
    },
  },
  namedInsureds: {
    type: 'array',
    properties: {
      length: {
        type: 'number',
      },
    },
    values: {
      display: true,
      search: {
        name: 'named_insureds',
        label: 'Named Insureds',
      },
      type: 'string',
    },
  },
  postcode: {
    display: true,
    sort: {
      name: 'postcode',
      label: 'Postcode',
    },
    search: {
      name: 'postcode',
      label: 'Postcode',
    },
    type: 'string',
  },
  quoted: {
    display: true,
    filter: {
      type: SWITCH,
      name: 'unquoted',
      label: 'Unquoted',
      options: {
        on: { value: true, label: 'Show only unquoted applications' },
        off: { value: false, label: 'Show only quoted applications' },
      },
      initial: { field: 'unquoted', value: true, operator: 'equals' },
      defaults: { value: true },
      order: 0,
    },
  },
  priorCancellation: {
    display: true,
    filter: {
      type: CHECKBOX,
      name: 'prior_cancellation',
      label: 'Prior Cancellation',
      options: {
        // this is the default result of using switch/checkbox with options undefined or only on defined
        // if off has a value then it will be included in query and payload otherwise treated as null and excluded
        on: { value: true },
        off: { value: null },
      },
      defaults: {
        value: null,
      },
    },
    type: 'string',
  },
  product: {
    type: 'object',
    properties: {
      carrierId: {
        type: 'number',
        notes: 'Carrier is also on quote so assuming this is duplicate data.',
      },
      countryCode: {
        type: 'string',
      },
      id: {
        type: 'string',
        notes: 'assuming that product.name is going to be filterd/sorted via id',
      },
      name: {
        display: true,
        filter: {
          type: MULTI_SELECT,
          name: 'product_name',
          label: 'Product',
          options: {},
          defaults: {
            value: null,
          },
        },
        sort: {
          name: 'product_name',
          label: 'Product',
        },
        type: 'string',
      },
      productType: {
        display: true,
        filter: {
          type: MULTI_SELECT,
          name: 'product_type',
          label: 'Product Type',
          options: {},
          defaults: {
            value: null,
          },
        },
        sort: {
          name: 'product_type',
          label: 'Product Type',
        },
        type: 'string',
      },
      triggerType: {
        type: 'string',
      },
      useMetric: {
        type: 'boolean',
      },
    },
  },
  productId: {
    type: 'string',
    notes: 'this is duplicated above in product.',
  },
  quotes: {
    type: 'array',
    properties: {
      length: {
        display: true,
        type: 'number',
      },
    },
    values: {
      type: 'object',
    },
  },
  region: {
    display: true,
    filter: {
      type: [MULTI_SELECT],
      name: 'region',
      label: 'Region',
      exclude: true,
    },
    sort: {
      name: 'region',
      label: 'Region',
    },
    search: {
      name: 'region',
      label: 'Region',
    },
    type: 'string',
    notes: 'filter assumes a list of all regions in any country. may just be sort. obviously USA states are ez',
  },
  regionCode: {
    display: true,
    sort: {
      name: 'region_code',
      label: 'Region Code',
      exclude: true,
    },
    search: {
      name: 'region_code',
      label: 'Region Code',
      exclude: true,
    },
    type: 'string',
  },
  targetEffectiveAt: {
    display: true,
    filter: {
      type: DATES_FROM_TO,
      name: 'target_effective_at',
      label: 'Target EffectiveAt',
      order: 1,
    },
    sort: {
      name: 'target_effective_at',
      label: 'Target EffectiveAt',
    },
    type: 'number',
  },
};
