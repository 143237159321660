import React from 'react';
import { ITEM_ROW_HEIGHT, DataListRenderer } from '../ListView';
import { ListDisplayTypes } from '../../constants';
import { useSearchApiContext, FILTERS_BAR_HEIGHT } from './contexts';

export const ListSearchDataRenderer = React.forwardRef((props, ref) => {
  const { styles, tableItemComponent, cardItemComponent, ...rest } = props;
  const { data, displayType = ListDisplayTypes.CARDS, loading, requestMoreData, requestingMore } = useSearchApiContext();

  const Component = displayType === ListDisplayTypes.TABLE ? tableItemComponent : cardItemComponent;

  return (
    <DataListRenderer
      ref={ref}
      data={data}
      displayType={displayType}
      loading={loading}
      requestMoreData={requestMoreData}
      requestingMore={requestingMore}
      itemComponent={Component}
      styles={[
        {
          root: {
            minHeight: 400,
            padTop: ({ theme }) => theme.sizes.appBarHeight + theme.spacing(1),
          },
          item: {
            height: displayType === ListDisplayTypes.CARDS ? ({ theme }) => theme.breakpoints({ xs: 312, sm: 186 }) : ITEM_ROW_HEIGHT,
            mb: displayType === ListDisplayTypes.CARDS ? '$3' : 0,
          },
          listLoading: {
            justifyContent: 'center',
          },
          header: {
            height: ({ theme }) =>
              theme.breakpoints({
                xs: 186 + theme.sizes.appBarHeight + theme.spacing(1),
                sm: 154 + theme.sizes.appBarHeight + FILTERS_BAR_HEIGHT + (displayType === ListDisplayTypes.TABLE ? ITEM_ROW_HEIGHT : 0),
              }),
          },
        },
        styles,
      ]}
      {...rest}
    />
  );
});
