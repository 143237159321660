import React, { useRef } from 'react';
import { Platform } from 'react-native';
import { useSpring } from '@react-spring/native';
import { flatten, flattenStyles } from './utils';
import { resolveAnimation } from './resolveAnimation';
import { useStyleSystemProps } from './useStyleSystemProps';
import { merge } from 'merge-anything';
import equal from 'fast-deep-equal/react';
import { isObject } from '../utils';

// NOTE: this was reintroduced in RNWEB 0.14.x
// worked in 0.12.x
const notAnimatable = { shadowOffsetX: true, shadowOffsetY: true, shadowOffset: true };

function parseNotAnimatable(a = {}) {
  if (!isObject(a)) {
    return [{}, null];
  }
  if (!a.to || Platform.OS !== 'web') {
    return [a, null];
  }
  const { to: animation, ...rest } = a;
  let next = { ...rest, to: {} };
  let removed = {};
  for (const key in animation) {
    if (notAnimatable[key]) {
      removed[key] = animation[key];
    } else {
      next.to[key] = animation[key];
    }
  }
  return [next, removed];
}

function useAnimate(props) {
  const { animations: anims, animate: a } = props;

  const styleSystemProps = useStyleSystemProps(props);
  const animations = flatten(anims, styleSystemProps);
  const { format } = animations || {};
  const lastRun = useRef(null);
  const initialAnimate = useRef(null);
  const hasChangedAnimate = useRef(false);

  let animation = {};
  let animate = [];
  if (animations) {
    if (Array.isArray(a) && a.length) {
      for (let i = 0; i < a.length; i += 1) {
        if (animations[a[i]]) {
          animate.push(a[i]);
        }
      }
    } else if (a && animations[a]) {
      animate.push(a);
    }
  }
  if (initialAnimate.current) {
    if (!hasChangedAnimate.current && !equal(initialAnimate.current, animate)) {
      hasChangedAnimate.current = true;
    }
  } else if (!hasChangedAnimate.current) {
    if (animations.initial === false && animate.length) {
      initialAnimate.current = [...animate];
    } else {
      hasChangedAnimate.current = true;
    }
  }
  if (animate.length) {
    if (animations.config) {
      animation.config = { ...animations.config };
    }
    for (let i = 0; i < animate.length; i += 1) {
      let next = resolveAnimation(animations[animate[i]], styleSystemProps);
      // TODO: instead of merging and combining the animations. Use parallel or sequence springs...research useSpring apis more for this
      animation = merge(animation, next);
    }
    // TODO: parse all other animation options and add if they dont exist on the animation itself...
  } else {
    animation = lastRun.current;
  }

  // if (props.debugAnimate) {
  //   console.log('ANIMATE PROPS', anims, a, animation);
  // }

  if (!lastRun.current) {
    lastRun.current = animation || {};
  }

  if (isObject(animation)) {
    if (!hasChangedAnimate.current) {
      animation.immediate = true;
    } else {
      animation.immediate = false;
    }
  }

  const [animatableAnimation, notAnimatableStyles] = parseNotAnimatable(animation);

  const [spring, api] = useSpring(() => animatableAnimation);

  if (!equal(lastRun.current, animation)) {
    lastRun.current = animation;
    api.stop();
    api.start(animatableAnimation);
  }

  const toRun = notAnimatableStyles ? { ...spring, ...notAnimatableStyles } : spring;
  return format ? format(toRun, animate) : flattenStyles(toRun);
}

const Animate = React.memo(function Animate(props) {
  const { style: styleProp, children } = props;
  const animation = useAnimate(props);
  // NOTE: animStyle takes higher precedence over passed styles.
  // NOTE: styles are flattened after because useSpring does not handle nested array/objects
  //       used for RN transforms (like rotate). So the conversion to RN transform array happens after
  if (typeof children === 'function') {
    return children({ props: animation });
  }

  const style = [styleProp, animation];
  return React.cloneElement(React.Children.only(children), { style });
});

export { useAnimate, Animate };
