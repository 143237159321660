import { MIN_MAX_RANGE, DATES_FROM_TO, SELECT, MULTI_SELECT } from './filterTypes';

const QuoteStatuses = {
  pending: 'pending',
  released: 'released',
  prebound: 'prebound',
  bound: 'bound',
  rejected: 'rejected',
  expired: 'expired',
};

export const quoteItemData = {
  adjustmentFactor: {
    type: 'number',
  },
  applicationId: {
    type: 'string',
  },
  agent: {
    type: 'object',
    properties: {
      email: {
        display: true,
        sort: {
          name: 'agent_email',
          label: 'Agent Email',
        },
        search: {
          name: 'agent_email',
          label: 'Agent Email',
        },
        type: 'string',
      },
      name: {
        display: true,
        sort: {
          name: 'agent_name',
          label: 'Agent Name',
        },
        search: {
          name: 'agent_name',
          label: 'Agent Name',
        },
        type: 'string',
      },
      contactId: {
        type: 'number',
      },
      id: {
        type: 'number',
      },
      userId: {
        type: 'number',
      },
    },
  },
  bindingForms: {
    type: 'array',
    notes:
      'should there be a api derived property from binding forms? If all forms are approved then Ready To Bind could be displayed. Otherwise up to UI to display that.',
    values: {
      type: 'object',
    },
    properties: {
      length: {
        type: 'number',
      },
    },
  },
  broker: {
    type: 'object',
    properties: {
      id: {
        type: 'number',
      },
      name: {
        display: true,
        sort: {
          name: 'broker_name',
          label: 'Broker Name',
        },
        search: {
          name: 'broker_name',
          label: 'Broker Name',
        },
        type: 'string',
      },
    },
  },
  carrier: {
    type: 'object',
    properties: {
      id: {
        type: 'number',
      },
      name: {
        display: true,
        sort: {
          name: 'carrier_name',
          label: 'Carrier Name',
        },
        search: {
          name: 'carrier_name',
          label: 'Carrier Name',
        },
        type: 'string',
      },
    },
  },
  coverageAt: {
    display: true,
    filter: {
      type: DATES_FROM_TO,
      name: 'coverage_at',
      label: 'Coverage At',
    },
    sort: {
      name: 'coverage_at',
      label: 'Coverage At',
    },
    type: 'number',
  },
  coverageUntil: {
    display: true,
    sort: {
      name: 'coverage_until',
      label: 'Coverage Until',
    },
    type: 'number',
  },
  createdAt: {
    display: true,
    sort: {
      name: 'created_at',
      label: 'Created At',
    },
    type: 'number',
  },
  currency: {
    type: 'object',
    properties: {
      currencyCode: {
        type: 'string',
      },
    },
  },
  customerId: {
    type: 'string',
  },
  id: {
    type: 'string',
  },
  insured: {
    display: true,
    sort: {
      name: 'insured',
      label: 'Insured',
    },
    search: {
      name: 'insured',
      label: 'Insured',
    },
    type: 'string',
  },
  lastChangeAt: {
    display: true,
    filter: {
      type: DATES_FROM_TO,
      name: 'last_change_at',
      label: 'Last Change At',
    },
    sort: {
      initial: { field: 'last_change_at', by: 'DESC' },
      name: 'last_change_at',
      label: 'Last Change At',
    },
    type: 'number',
    notes: "Not consistent? ...it's null upon creation and messed up sort. Should be equal to createdAt upon creation...",
  },
  locations: {
    type: 'array',
    values: { type: 'object' },
    properties: {
      length: {
        type: 'number',
        display: true,
        filter: {
          type: MIN_MAX_RANGE,
          name: 'locations_length',
          label: 'Location Count',
        },
        sort: {
          name: 'locations_length',
          label: 'Location Count',
        },
      },
    },
  },
  product: {
    type: 'object',
    properties: {
      carrierId: {
        type: 'number',
        notes: 'Carrier is also on quote so assuming this is duplicate data.',
      },
      countryCode: {
        type: 'string',
      },
      id: {
        type: 'string',
        notes: 'assuming that product.name is going to be filterd/sorted via id',
      },
      name: {
        display: true,
        filter: {
          type: MULTI_SELECT,
          defaults: { value: null },
          name: 'product_name',
          label: 'Product',
          options: {},
        },
        sort: {
          name: 'product_name',
          label: 'Product',
        },
        type: 'string',
      },
      productType: {
        display: true,
        filter: {
          type: MULTI_SELECT,
          defaults: { value: null },
          name: 'product_type',
          label: 'Product Type',
          options: {},
        },
        sort: {
          name: 'product_type',
          label: 'Product Type',
        },
        type: 'string',
      },
      triggerType: {
        type: 'string',
      },
      useMetric: {
        type: 'boolean',
      },
    },
  },
  quoteNumber: {
    display: true,
    sort: {
      name: 'quote_number',
      label: 'Quote Number',
    },
    search: {
      name: 'quote_number',
      label: 'Quote Number',
    },
    type: 'number',
  },
  status: {
    display: true,
    filter: {
      order: 1,
      initial: { field: 'status', value: 'open', operator: 'equals' },
      defaults: { value: null },
      type: SELECT,
      name: 'status',
      label: 'Status',
      options: {
        all: {
          label: 'All',
          name: 'all',
          value: null,
          fieldValue: null,
        },
        open: {
          label: 'Open',
          name: 'open',
          value: 'open',
          fieldValue: [
            { field: 'status', operator: 'notequals', value: QuoteStatuses.expired },
            { field: 'status', operator: 'notequals', value: QuoteStatuses.rejected },
            { field: 'status', operator: 'notequals', value: QuoteStatuses.bound },
          ],
        },
        pending: {
          label: 'Pending',
          name: 'pending',
          value: QuoteStatuses.pending,
          fieldValue: [{ field: 'status', value: QuoteStatuses.pending, operator: 'equals' }],
        },
        released: {
          label: 'Released',
          name: 'released',
          value: QuoteStatuses.released,
          fieldValue: [{ field: 'status', value: QuoteStatuses.released, operator: 'equals' }],
        },
        prebound: {
          label: 'Prebound',
          name: 'prebound',
          value: QuoteStatuses.prebound,
          fieldValue: [{ field: 'status', value: QuoteStatuses.prebound, operator: 'equals' }],
        },
        bound: {
          label: 'Bound',
          name: 'bound',
          value: QuoteStatuses.bound,
          fieldValue: [{ field: 'status', value: QuoteStatuses.bound, operator: 'equals' }],
        },
        rejected: {
          label: 'Rejected',
          name: 'rejected',
          value: QuoteStatuses.rejected,
          fieldValue: [{ field: 'status', value: QuoteStatuses.rejected, operator: 'equals' }],
        },
        expired: {
          label: 'Expired',
          name: 'expired',
          value: QuoteStatuses.expired,
          fieldValue: [{ field: 'status', value: QuoteStatuses.expired, operator: 'equals' }],
        },
      },
    },
    sort: {
      name: 'status',
      label: 'Status',
    },
    type: 'string',
  },
  thresholds: {
    type: 'array',
    values: { type: 'object' },
    properties: {
      length: {
        type: 'number',
      },
    },
  },
  underwriterId: {
    type: 'number',
  },
};
