import { MIN_MAX_RANGE, DATES_FROM_TO, SELECT, MULTI_SELECT, SWITCH, CHECKBOX } from '../../../constants/searchData/filterTypes';
import { DateRangeFilterControl, MinMaxFilterControl } from './RangeControls';
import { MultiSelectFilterControl, SelectFilterControl } from './SelectControls';
import { CheckboxFilterControl, SwitchFilterControl } from './SwitchControls';

export * from './useFilterControls';

export const FilterTypeComponents = {
  // MIN_MAX_RANGE field should have input object with min and max.
  [MIN_MAX_RANGE]: MinMaxFilterControl,
  // DATES_FROM_TO field
  [DATES_FROM_TO]: DateRangeFilterControl,
  // SELECT field should have options obj defined with each option having a name, label, and value
  // the value prop should be the selected option object or null.
  [SELECT]: SelectFilterControl,
  [MULTI_SELECT]: MultiSelectFilterControl,
  [SWITCH]: SwitchFilterControl,
  [CHECKBOX]: CheckboxFilterControl,
};
