import { useImperativeHandle } from 'react';
import { useMemoCompare } from './useMemoCompare';

export function useImperativeRefWithProps(forwardedRef, componentRef, props) {
  const memoedProps = useMemoCompare(props ? props : {});
  useImperativeHandle(
    forwardedRef,
    () => {
      if (componentRef.current) {
        for (const key in memoedProps) {
          componentRef.current[key] = memoedProps[key];
        }
      }
      return componentRef.current;
    },
    [memoedProps, componentRef]
  );
}
