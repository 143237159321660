import { Box } from '../Box';
import { styled } from '../../styling';
import { resolveVariantName } from '../../system';

const Content = styled(Box, { name: 'Content', filterProps: ['variantPrefix', 'variant', 'maxWidth'] })({
  position: 'relative',
  variant: ({ variant = '', variantPrefix = 'layout' }) => (variant ? resolveVariantName(variant, { variantPrefix: variantPrefix }) : null),
  width: (props) => props.width,
  maxWidth: (props) => props.maxWidth,
});

export { Content };
