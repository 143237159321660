import React, { useRef } from 'react';
import { Text as NativeText, Platform } from 'react-native';
import { styled, withStyles, animated } from '../../styling';
import { resolveVariantName, TextStylePropTypes } from '../../system';
import { isNull } from '../../utils';
import { useImperativeRefWithProps } from '../../hooks';

const TextContext = React.createContext({
  inherit: false,
  color: null,
  variant: null,
  variantPrefix: null,
});
const filterProps = Object.keys(TextStylePropTypes);

const AnimatedText = animated(NativeText);
AnimatedText.displayName = 'animated(NativeText)';

const TextBase = styled(
  React.forwardRef(function TextBase(props, forwardedRef) {
    const {
      component,
      accessibility: accessibilityProp,
      focusable: focusableProp = false,
      accessible: accessibleProp = false,
      children,
      value,
      ignoreValue = false,
      textContext,
      disabled = false,
      nativeID,
      id = props.nativeID,
      href: hrefProp,
      hrefAttrs,
      onPress,
      lang = 'en-US', // web only
      ...rest
    } = props;
    const Component = component || AnimatedText;
    const { color, variant, variantPrefix, size } = textContext;
    const context = React.useMemo(() => {
      return { inherit: true, color, variant, variantPrefix, size };
    }, [color, variant, variantPrefix, size]);

    const {
      focusable: accessibilityFocusable = false,
      accessible: accessibilityAccessible = false,
      ...accessibility
    } = accessibilityProp ? accessibilityProp : {};
    const focusable = focusableProp || accessibilityFocusable || accessibleProp || accessibilityAccessible;
    const platformProps = {};
    if (Platform.OS === 'web') {
      platformProps.lang = lang;
      if (hrefProp && !disabled) {
        const {
          target = '_blank',
          rel = 'noopener noreferrer',
          href,
          download,
        } = typeof hrefProp === 'object' ? { ...hrefProp, ...hrefAttrs } : { ...hrefAttrs, href: hrefProp };
        if (href) {
          platformProps.href = href;
          platformProps.hrefAttrs = { target, rel, download };
          if (onPress) {
            platformProps.onPress = (e) => {
              e.preventDefault();
              if (onPress) {
                onPress(e);
              }
            };
          }
        }
      }
      // web uses focusable prop
      accessibility.focusable = focusable;
    } else {
      // native uses accessible prop
      accessibility.accessible = focusable;
    }

    const ref = useRef(null);
    useImperativeRefWithProps(forwardedRef, ref, { focusable, disabled, accessible: focusable });

    return (
      <TextContext.Provider value={context}>
        <Component
          ref={ref}
          value={value}
          {...{ accessibilityRole: platformProps.href ? 'link' : 'text', ...accessibility }}
          disabled={disabled}
          nativeID={id}
          {...platformProps}
          {...rest}
        >
          {!ignoreValue && !children && !isNull(value) && typeof value === 'string' ? value : children}
        </Component>
      </TextContext.Provider>
    );
  }),
  { name: 'TextBase', filterProps }
)((props) => {
  const styles = {};
  for (const key in TextStylePropTypes) {
    if (props[key] !== undefined) {
      styles[key] = props[key];
    }
  }
  return styles;
});

// TODO: decide if style props should even be handled in props or just styled or withStyles
const defaultProps = {
  variantPrefix: 'text',
  variant: 'text',
  size: '',
  // variant size convenience bools
  xxSmall: false,
  xSmall: false,
  small: false,
  medium: false,
  large: false,
  xLarge: false,
  xxLarge: false,
  // font weight props
  weight: null,
  bold: false, // convenience shortcut for weight="bold"
  fontSize: null,
  // style props
  color: null,
  onColor: 'background',
  center: false, // convenience shortcut for align="center"
  maxLines: null,
  noWrap: false, // convenience shortcut for maxLines={1}
  uppercase: false, // sets textTransform to uppercase, default none
  lowercase: false, // ^^
  capitalize: false, // ^^
  underline: false,
  italic: false,
  textDecorationLine: 'none',
  spaceAfter: null,
  spaceBefore: null,
  lighten: null,
  darken: null,
  dim: null,
};

const TextProps = { ...defaultProps };

const getVariantSize = (props) => {
  if (props.size) return props.size;
  if (props.xxSmall) return 'xxSmall';
  if (props.xSmall) return 'xSmall';
  if (props.small) return 'small';
  if (props.medium) return 'medium';
  if (props.large) return 'large';
  if (props.xLarge) return 'xLarge';
  if (props.xxLarge) return 'xxLarge';
  return '';
};

const useTextProps = (props) => {
  const textContext = React.useContext(TextContext);
  if (textContext.inherit) {
    const result = { ...props, textContext };

    const size = getVariantSize(props);
    if (size && !props.variant) {
      result.size = size;
      result.variant = textContext.variant || defaultProps.variant;
    }
    if (props.variant && textContext.variant !== props.variant) {
      result.variant = props.variant;
      if (size) {
        result.size = size;
      }
    }

    return result;
  }
  return { ...defaultProps, ...props, textContext };
};

// TODO: add in compact, comfortable, and default lineheights which will enable spacebefore and spaceafter to always work (since it's a factor of lineheight)
const Text = withStyles(
  (props) => {
    const { textContext = {}, maxLines, noWrap } = props;
    let color;
    if (props.color) {
      color = props.color;
    } else if (props.onColor || !textContext.inherit) {
      color = props.theme.colors.on(props.onColor || props.theme.colors.background);
    }

    const variantPrefix = props.variantPrefix || textContext.variantPrefix || defaultProps.variantPrefix;

    const deconstructOnly = {};
    if (!isNull(props.lineHeight)) {
      deconstructOnly.lineHeight = props.lineHeight;
    }
    return {
      root: {
        variant: props.variant
          ? resolveVariantName(props.variant, {
              variantPrefix: variantPrefix,
              variantSize: getVariantSize(props),
            })
          : null,
        color,
        textAlign: props.align ? props.align : props.center ? 'center' : null,
        textTransform: (props) => {
          if (props.transform) return props.transform;
          if (props.uppercase) return 'uppercase';
          if (props.lowercase) return 'lowercase';
          if (props.capitalize) return 'capitalize';
        },
        fontStyle: props.fontStyle ? props.fontStyle : props.italic ? 'italic' : 'normal',
        textDecorationLine: props.underline ? 'underline' : props.textDecorationLine,
        fontWeight: props.weight ? props.weight : props.bold ? '$bold' : null,
        ...deconstructOnly,
        props: {
          numberOfLines: maxLines ? maxLines : noWrap ? 1 : null,
        },
      },
    };
  },
  {
    name: 'Text',
    filterProps: Object.keys(defaultProps),
    useProps: useTextProps,
    postApply: ({ styles, theme, spaceAfter, spaceBefore, lighten, darken, dim, textContext = {}, variant, variantPrefix, size }) => {
      const postStyles = {};
      const style = styles.root || {};

      let after = null;
      let before = null;
      let color = style.color || textContext.color;
      let originalColor = color || textContext.color;
      if (spaceAfter || spaceBefore) {
        if (!style.marginBottom) {
          if (!isNull(spaceAfter) && spaceAfter !== false) {
            const factor = typeof spaceAfter === 'number' ? spaceAfter : 0.5;
            after = (style.lineHeight || style.fontSize) * factor;
          }
        }
        if (!style.marginTop) {
          if (!isNull(spaceBefore) && spaceBefore !== false) {
            const factor = typeof spaceBefore === 'number' ? spaceBefore : 0.5;
            before = (style.lineHeight || style.fontSize) * factor;
          }
        }
      }
      if (color) {
        if (!isNull(lighten)) {
          color = theme.colors.lighten(color, typeof lighten === 'number' ? lighten : 1.5);
        }
        if (!isNull(darken)) {
          color = theme.colors.darken(color || originalColor, typeof darken === 'number' ? darken : 1.5);
        }
        if (!isNull(dim)) {
          color = theme.colors.dim(color || originalColor, typeof dim === 'number' ? dim : 0.5);
        }
      }
      if (after) postStyles.marginBottom = after;
      if (before) postStyles.marginTop = before;
      if (color) postStyles.color = color;
      return {
        root: postStyles,
        props: {
          root: {
            textContext: {
              ...textContext,
              color: color || originalColor,
              variant: variant || textContext.variant,
              variantPrefix: variantPrefix || textContext.variantPrefix || defaultProps.variantPrefix,
              size: size || textContext.size,
            },
          },
        },
      };
    },
  }
)(TextBase);

export { Text, TextProps };
