import { useState, useRef, useEffect, useCallback } from 'react';

function useControlledState({ controlledValue, defaultValue }) {
  const isControlled = useRef(controlledValue !== undefined);
  const [value, setValue] = useState(isControlled.current ? controlledValue : defaultValue);
  const state = isControlled.current ? controlledValue : value;

  useEffect(() => {
    if (isControlled.current !== (controlledValue !== undefined) && process.env.NODE_ENV !== 'production') {
      console.error('Element should not switch from uncontrolled to controlled. Decide which for the lifetime of the component.');
    }
    if (isControlled.current) {
      setValue(controlledValue);
    }
  }, [controlledValue]);

  const setStateIfUncontrolled = useCallback((nextValue) => {
    if (!isControlled.current) {
      setValue(nextValue);
    }
  }, []);

  return [state, setStateIfUncontrolled];
}

export { useControlledState };
