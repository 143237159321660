import { EditIcon } from '../components/icons';
import { currencyFormatter, PercentageFormatter } from '../pages/QuotePage/content/QuoteGrids/utils';
import { isEmptyArray, normalizeCamelCase } from '../ui/utils';
import { apiHelpers } from './apiHelpers';
import { calculateCoverageLimits, getDefaultCoverages } from './calculateCoverageLimits';

const formatRange = (range, coveredValue) => {
  const cleanedRange = range.replace(/^\(|\)$/g, '');
  const [min, max] = cleanedRange.split(',').map((value) => value.trim());
  const formatToMillions = (num) => (num ? `$${(parseInt(num, 10) / 1000000).toFixed(0)}M` : '');
  const minFormatted = formatToMillions(min) || '$0M';
  const maxFormatted = formatToMillions(max);

  if (min && max) {
    return `${minFormatted} to ${maxFormatted}`;
  } else if (min) {
    return `${minFormatted} and above`;
  } else if (max) {
    if (parseInt(max, 10) === 5000000 && coveredValue < 5000000) {
      return 'Under $5M';
    }
    return `Up to ${maxFormatted}`;
  } else {
    return '$0M and above';
  }
};

const getCoverageColDef = ({ isEditable, setValue, quotes, adjusting, setModalOpen, setMode, setLocationData }) => {
  const getColValues = (params, coverageType, key) => {
    const { data } = params;
    const coverage = data.coverages && data.coverages.length && data.coverages.find((each) => each.coverageType === coverageType);

    if (coverage) {
      let value = coverage[key];
      if (coverageType === 'flood' && key === 'payoutLimit') {
        const sublimitNeeded = data.requirements?.floodSublimitNeeded;
        if (sublimitNeeded) {
          value = Math.min(1000000, Number(data.coveredValue));
        } else {
          value = Math.min(data.coveredValue, 5000000);
        }
      }
      return value;
    }

    return '';
  };

  const getTotalPremium = ({ data }) => {
    return data.coverages && data.coverages.length ? data.coverages.reduce((acc, item) => (acc += item.netPremium), 0) : 0;
  };

  const getCSR = (params) => {
    const { data } = params;
    if (data.riskZones && data.riskZones[0]?.id) return `CSR - ${data.riskZones[0]?.id}`;
  };

  const getLocationIndex = (riskId) => quotes.locations.findIndex((location) => location.riskId === riskId);

  const changedRows = new Set();

  const isAverageInsuredChanged = (params) => {
    return changedRows.has(params.node.id);
  };

  const handleOnInsuredValueChange = (params) => {
    const riskId = params.data.riskId;
    const locationIndex = getLocationIndex(riskId);
    params.data.coveredValue = params.newValue;
    changedRows.add(params.node.id);
    if (!params.data.reinsurance) params.data.reinsurance = {};

    params.data.reinsurance.maxTotalInsuredValue = params.newValue;
    params.data.maxAvgTIV = (params.data.coveredValue / params.data.reinsurance.maxTotalInsuredValue) * 100;
    const avgTotalInsuredValue = params.newValue;
    if (isEmptyArray(params.data.coverages)) {
      params.data.coverages = getDefaultCoverages(quotes, params.data);
    }
    params.data.coverages.forEach((coverage, coverageIndex) => {
      coverage.payoutLimit = calculateCoverageLimits(coverage.coverageType, avgTotalInsuredValue);
      if (adjusting) {
        setValue(`quote.locations.${locationIndex}.coverages.${coverageIndex}.payoutLimit`, coverage.payoutLimit);
      }
    });
    if (adjusting) {
      setValue(`quote.locations.${locationIndex}.coveredValue`, params.newValue);
      setValue(`quote.locations.${locationIndex}.reinsurance.maxTotalInsuredValue`, params.newValue);
    }
    return true;
  };

  const handleMaxInsuredValueChange = (params) => {
    const riskId = params.data.riskId;
    const locationIndex = getLocationIndex(riskId);

    if (!params.data.reinsurance) {
      params.data.reinsurance = {};
    }
    params.data.reinsurance.maxTotalInsuredValue = +params.newValue;
    setValue(`quote.locations.${locationIndex}.reinsurance.maxTotalInsuredValue`, +params.newValue);
    params.data.maxAvgTIV = (params.newValue / params.data.coveredValue) * 100;
    return true;
  };

  const handleMaxAvgTIVChange = (params) => {
    const riskId = params.data.riskId;
    const locationIndex = getLocationIndex(riskId);

    if (isNaN(params.newValue)) return false;

    const newPercentage = params.newValue;
    params.data.maxAvgTIV = newPercentage;

    if (!params.data.reinsurance) params.data.reinsurance = {};

    const maxTotalInsuredValue = parseInt(((newPercentage / 100) * params.data.coveredValue).toFixed(2));
    params.data.reinsurance.maxTotalInsuredValue = maxTotalInsuredValue;
    setValue(`quote.locations.${locationIndex}.reinsurance.maxTotalInsuredValue`, maxTotalInsuredValue);

    return true;
  };

  const onHailAggDedPercentChanged = (params) => {
    const riskId = params.data.riskId;
    const locationIndex = getLocationIndex(riskId);

    const averageInsuredValue = params.data.coveredValue;
    const coverageId = params.column.userProvidedColDef.coverageId;
    const coverageIndex = params.data.coverages.findIndex((coverage) => coverage.coverageId === coverageId);
    const hailAggDedPercent = +params.newValue;
    const hailAgg = parseInt(((averageInsuredValue * hailAggDedPercent) / 100).toFixed(2));
    params.data.coverages[coverageIndex].aggregate = hailAgg;
    params.node.setDataValue('aggregate', hailAgg);
    setValue(`quote.locations.${locationIndex}.coverages.${coverageIndex}.aggregate`, hailAgg);
  };

  const setGridValues = (params, key, sublimit) => {
    const { data, newValue } = params;
    const riskId = params.data.riskId;
    const locationIndex = getLocationIndex(riskId);
    let coverageId = '';
    let editedField = '';
    if (key === 'flood') {
      coverageId = 21;
      editedField = 'payoutLimit';
    } else {
      coverageId = params.column.userProvidedColDef.coverageId;
      editedField = params.colDef.field;
    }
    const coverageIndex = data.coverages.findIndex((coverage) => coverage.coverageId === coverageId);
    let formattedValue = 0;
    if (key === 'flood') {
      if (sublimit) formattedValue = Math.min(1000000, Number(params.data.coveredValue));
      else formattedValue = Math.min(params.data.coveredValue, 5000000);
    } else formattedValue = isNaN(Number(newValue)) ? 0 : Number(newValue);
    if (params.colDef.headerName === 'Hail Agg Deductible') {
      const hailAggDedPercent = +((formattedValue / params.data.coveredValue) * 100).toFixed(2);
      params.data.requirements.hailAggDeductiblePerc = hailAggDedPercent;
      setValue(`quote.locations.${locationIndex}.requirements.hailAggDeductiblePerc`, hailAggDedPercent);
    }
    adjusting && setValue(`quote.locations.${locationIndex}.coverages.${coverageIndex}.${editedField}`, formattedValue);
    data.coverages[coverageIndex][editedField] = formattedValue;
    return true;
  };

  const cellRenderer = (params) => {
    const handleIconClick = (event) => {
      event.stopPropagation();
      setLocationData(params.data);
      setMode('EDIT');
      setModalOpen(true);
    };
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span onClick={handleIconClick} style={{ display: isEditable() ? 'flex' : 'none' }} className="edit-icon">
          <EditIcon />
        </span>
        <span>{params.value}</span>
      </div>
    );
  };

  const getTierValue = (params) => {
    const key = params.colDef.field;
    const riskZones = params.data?.riskZones;
    if (Array.isArray(riskZones) && riskZones.length > 0) {
      return riskZones[0][key] !== undefined ? riskZones[0][key] : false;
    }
    return false;
  };

  const getAdditionalColumns = (coverageType) => {
    switch (coverageType) {
      case 'comprehensive':
        return [{ field: 'details.crimeZone', headerName: 'Crime Index', isRequired: false, dataType: 'string' }];
      case 'flood':
        return [
          {
            headerName: 'Flood Zone',
            field: 'details.floodFactor',
            cellClassRules: {
              'red-background': (params) => params.value === 'AE - 100 Year Floodplain',
            },
            editable: isEditable,
            minWidth: 150,
          },
          {
            headerName: 'SWAP',
            field: 'swap',
            cellClassRules: {
              'ag-cell-editable': (params) => isEditable(params),
            },
            valueGetter: (params) => {
              if (params.data?.requirements?.hasOwnProperty('swapNeeded')) {
                return params.data.requirements.swapNeeded;
              } else return false;
            },
            valueSetter: (params) => {
              if (!params.data.requirements) {
                params.data.requirements = {};
              }
              params.data.requirements.swapNeeded = params.newValue;
              const riskId = params.data.riskId;
              const locationIndex = getLocationIndex(riskId);
              setValue(`quote.locations.${locationIndex}.requirements.swapNeeded`, params.newValue);
              return true;
            },
            editable: isEditable,
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            exportValueFormatter: (params) => (params.value ? 'Yes' : 'No'),
            minWidth: 100,
          },
          {
            headerName: '$1m Flood Sublimit',
            valueGetter: (params) => {
              if (params.data?.requirements?.hasOwnProperty('floodSublimitNeeded')) return params.data.requirements.floodSublimitNeeded;
              else return false;
            },
            valueSetter: (params) => {
              if (!params.data.requirements) {
                params.data.requirements = {};
              }
              params.data.requirements.floodSublimitNeeded = params.newValue;
              const riskId = params.data.riskId;
              const locationIndex = getLocationIndex(riskId);
              setValue(`quote.locations.${locationIndex}.requirements.floodSublimitNeeded`, params.newValue);
              setGridValues(params, 'flood', params.newValue);
              return true;
            },
            cellClassRules: {
              'ag-cell-editable': (params) => isEditable(params),
            },
            editable: isEditable,
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            exportValueFormatter: (params) => (params.value ? 'Yes' : 'No'),
            minWidth: 200,
          },
        ];
      case 'earthquake':
        return [
          {
            headerName: 'Earthquake Zone',
            field: 'earthquakeZone',
            valueGetter: (params) => (params.data.riskZones && params.data.riskZones[0]?.skywardEarthquake) || false,
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            exportValueFormatter: (params) => (params.value ? 'Yes' : 'No'),

            minWidth: 200,
          },
          {
            headerName: '$1m Indoor EQ Sublimit',
            field: 'eqSublimit',
            valueGetter: (params) => {
              if (params.data?.requirements?.hasOwnProperty('indoorEqLimitNeeded')) return params.data.requirements.indoorEqLimitNeeded;
              else return false;
            },
            valueSetter: (params) => {
              if (!params.data.requirements) {
                params.data.requirements = {};
              }
              params.data.requirements.indoorEqLimitNeeded = params.newValue;

              const riskId = params.data.riskId;
              const locationIndex = getLocationIndex(riskId);
              setValue(`quote.locations.${locationIndex}.requirements.indoorEqLimitNeeded`, params.newValue);

              return true;
            },
            cellClassRules: {
              'ag-cell-editable': (params) => isEditable(params),
            },
            valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
            exportValueFormatter: (params) => (params.value ? 'Yes' : 'No'),
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            editable: isEditable,
            minWidth: 200,
          },
        ];
      case 'hail':
        return [
          { headerName: 'CSR', field: 'csr', valueGetter: (params) => getCSR(params), minWidth: 100 },
          {
            headerName: 'Dot?',
            field: 'dot',
            cellClassRules: {
              'ag-cell-editable': (params) => isEditable(params),
            },
            editable: isEditable,
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',

            exportValueFormatter: (params) => (params.value ? 'Yes' : 'No'),
            valueGetter: (params) => {
              if (params.data?.requirements?.hasOwnProperty('dotNeeded')) return params.data.requirements.dotNeeded;
              else return false;
            },
            minWidth: 100,
            valueSetter: (params) => {
              if (!params.data.reinsurance) {
                params.data.reinsurance = {};
              }
              params.data.requirements.dotNeeded = params.newValue;
              const riskId = params.data.riskId;
              const locationIndex = getLocationIndex(riskId);
              setValue(`quote.locations.${locationIndex}.requirements.dotNeeded`, params.newValue);
              return true;
            },
          },
          {
            headerName: 'Hail Agg Deductible %',
            field: 'hailAggDedPercent',
            dataType: 'number',
            onCellValueChanged: onHailAggDedPercentChanged,
            cellClassRules: {
              'ag-cell-editable': (params) => isEditable(params),
            },
            editable: isEditable,
            valueSetter: (params) => {
              if (!params.data.reinsurance) {
                params.data.reinsurance = {};
              }
              params.data.requirements.hailAggDeductiblePerc = Number(params.newValue);
              const riskId = params.data.riskId;
              const locationIndex = getLocationIndex(riskId);
              setValue(`quote.locations.${locationIndex}.requirements.hailAggDeductiblePerc`, +params.newValue);
              return true;
            },
            valueGetter: (params) => params.data?.requirements?.hailAggDeductiblePerc || '0',
            valueFormatter: (field) => (field.value ? PercentageFormatter(field) : 'N/A'),
            coverageId: 9,
          },
        ];
      default:
        return [];
    }
  };

  const generateCoverageColumns = () => {
    const createCoverageColumns = (coverage) => {
      const { coverageType, coverageId } = coverage;
      const additionalColumns = getAdditionalColumns(coverageType);
      const shortName = apiHelpers.getShortCoveragesName(coverageType);
      let commonColumns = [];
      if (coverageType === 'falsePretense' || coverageType === 'terrorism') {
        commonColumns.push({
          headerName: `${shortName} Premium`,
          field: 'netPremium',
          dataType: 'number',
          valueGetter: (params) => getColValues(params, coverageType, 'netPremium'),
          valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
          coverageId,
          cellClassRules: {
            'gray-background': isAverageInsuredChanged,
          },
        });
      } else {
        commonColumns = [
          {
            headerName: `${shortName} Per Car Deductible`,
            field: 'deductible',
            dataType: 'number',
            valueGetter: (params) => getColValues(params, coverageType, 'deductible'),
            valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
            editable: isEditable,
            valueSetter: (params) => setGridValues(params),
            cellClassRules: { 'ag-cell-editable': (params) => isEditable(params) },
            coverageId,
          },
          {
            headerName: `${shortName} Agg Deductible`,
            field: 'aggregate',
            dataType: 'number',
            valueGetter: (params) => getColValues(params, coverageType, 'aggregate'),
            valueFormatter: (field) => {
              const value = field.value;
              return value === 0 || isNaN(value) ? 'N/A' : currencyFormatter({ field });
            },
            editable: isEditable,
            valueSetter: (params) => setGridValues(params),
            coverageId,
            cellClassRules: { 'ag-cell-editable': (params) => isEditable(params) },
          },
          {
            headerName: `${shortName} Payout Limit`,
            field: 'payoutLimit',
            dataType: 'number',
            valueGetter: (params) => getColValues(params, coverageType, 'payoutLimit'),
            valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
            editable: isEditable,
            valueSetter: (params) => setGridValues(params),
            cellClassRules: { 'ag-cell-editable': (params) => isEditable(params) },
            coverageId,
          },
          {
            headerName: `${shortName} Premium`,
            field: 'netPremium',
            dataType: 'number',
            valueGetter: (params) => getColValues(params, coverageType, 'netPremium'),
            valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
            coverageId,
            cellClassRules: {
              'gray-background': isAverageInsuredChanged,
            },
          },
        ];
      }

      return {
        headerName: normalizeCamelCase(coverageType),
        children: [...additionalColumns, ...commonColumns],
      };
    };

    return getDefaultCoverages(quotes).map(createCoverageColumns);
  };

  return [
    {
      headerName: 'Overview',
      children: [
        {
          headerName: 'Location',
          field: 'riskId',
          headerCheckboxSelection: isEditable(),
          checkboxSelection: isEditable(),
          isRequired: false,
          dataType: 'number',
          pinned: true,
          cellClassRules: {
            'yellow-background': (params) => isEditable(params),
          },
          maxWidth: 110,
        },
        {
          headerName: 'Name',
          field: 'name',
          cellRenderer: cellRenderer,
          isRequired: false,
          dataType: 'string',
          pinned: true,
          cellClassRules: {
            'yellow-background': (params) => isEditable(params),
          },
        },
        {
          headerName: 'Address',
          field: 'address',
          isRequired: false,
          dataType: 'string',
          pinned: true,
          minWidth: 250,
          cellClassRules: {
            'yellow-background': (params) => isEditable(params),
          },
        },
        {
          headerName: 'County',
          field: 'county',
          isRequired: false,
          dataType: 'string',
          minWidth: 150,
        },
        { headerName: 'State', field: 'regionCode', isRequired: false, dataType: 'string', minWidth: 100 },
        {
          headerName: 'Avg Total Insured Value',
          valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
          isRequired: false,
          dataType: 'number',
          exportValueFormatter: (params) => params.value,
          field: 'coveredValue',
          editable: isEditable,
          cellClassRules: {
            'yellow-background': (params) => isEditable(params),
          },
          minWidth: 200,
          valueSetter: (params) => handleOnInsuredValueChange(params),
        },
        {
          headerName: 'Max Total Insured Value',
          field: 'maxTotalInsuredValue',
          valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
          valueGetter: (params) => {
            const avgValue = params.data.coveredValue;
            return params.data.reinsurance?.maxTotalInsuredValue || avgValue;
          },
          isRequired: false,
          cellClassRules: {
            'ag-cell-editable': (params) => isEditable(params),
          },
          editable: isEditable,
          minWidth: 200,
          dataType: 'number',
          valueSetter: (params) => handleMaxInsuredValueChange(params),
          exportValueFormatter: (params) => params.value,
        },
        {
          headerName: 'Max/Average TIV',
          isRequired: false,
          field: 'maxAvgTIV',
          valueGetter: (params) => {
            const { coveredValue } = params.data;
            const { maxTotalInsuredValue = 0 } = params.data.reinsurance || {};
            const avgValue = coveredValue;
            const maxInsured = maxTotalInsuredValue || coveredValue;
            const maxInsuredPercentage = params.data.maxAvgTIV || (maxInsured / avgValue) * 100;
            return +parseFloat(maxInsuredPercentage).toFixed(2);
          },
          valueFormatter: (field) => (field.value ? PercentageFormatter(field) : 'N/A'),
          cellClassRules: {
            'ag-cell-editable': (params) => isEditable(params),
          },
          valueSetter: (params) => handleMaxAvgTIVChange(params),
          editable: isEditable,
          dataType: 'number',
          minWidth: 200,
        },
        {
          headerName: 'Total Premium',
          valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
          valueGetter: (params) => getTotalPremium(params),
          isRequired: false,
          dataType: 'number',
          exportValueFormatter: (params) => params.value,
          minWidth: 200,
          cellClassRules: {
            'gray-background': isAverageInsuredChanged,
          },
        },
        {
          headerName: 'Rate',
          valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
          valueGetter: (params) => (getTotalPremium(params) / params.data.coveredValue) * 100,
          isRequired: false,
          dataType: 'number',
          minWidth: 100,
        },
      ],
    },
    {
      headerName: 'Reinsurance',
      children: [
        {
          headerName: 'CRZ',
          field: 'riskZones.0.convectiveRatingZone',
          isRequired: false,
          valueGetter: (params) => params.data.riskZones && params.data.riskZones[0]?.convectiveRatingZone,
          dataType: 'string',
          minWidth: 100,
        },
        {
          headerName: 'TIV Band',
          field: 'riskZones.0.autoFacBand',
          isRequired: false,
          valueGetter: (params) => params.data?.riskZones?.[0]?.autoFacBand,
          dataType: 'string',
          minWidth: 150,
          valueFormatter: (params) => {
            const band = params.data?.riskZones && params.data.riskZones[0]?.autoFacBand;
            return band && formatRange(band, params.data?.coveredValue);
          },
          cellClassRules: {
            'gray-background': isAverageInsuredChanged,
          },
          exportValueFormatter: (params) => {
            const { node } = params;
            const band =
              node?.data?.riskZones && Array.isArray(node.data.riskZones) && node.data.riskZones.length > 0
                ? node.data.riskZones[0]?.autoFacBand
                : null;
            return band ? formatRange(band, node.data?.coveredValue) : '';
          },
        },

        {
          headerName: 'Tier 1 Wind (Ascot)',
          isRequired: false,
          field: 'ascotExcluded',
          dataType: 'boolean',
          minWidth: 150,
          valueGetter: getTierValue,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          exportValueFormatter: (params) => (params.value ? 'Yes' : 'No'),
        },
        {
          headerName: 'Tier 1 Wind (Arch)',
          isRequired: false,
          field: 'archExcluded',
          dataType: 'boolean',
          minWidth: 150,
          valueGetter: getTierValue,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          exportValueFormatter: (params) => (params.value ? 'Yes' : 'No'),
        },
        {
          headerName: 'Tier 1 Wind (Everest)',
          isRequired: false,
          field: 'everestExcluded',
          dataType: 'boolean',
          minWidth: 150,
          valueGetter: getTierValue,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          exportValueFormatter: (params) => (params.value ? 'Yes' : 'No'),
        },
        { field: 'details.wildfire', headerName: 'Wildfire Score', isRequired: false, dataType: 'string', minWidth: 150 },

        {
          headerName: 'Excluded ?',
          isRequired: false,
          field: 'excluded',
          colId: 'excluded',
          cellClassRules: {
            'ag-cell-editable': (params) => isEditable(params),
          },
          editable: isEditable,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          dataType: 'boolean',
          minWidth: 150,
          valueGetter: (params) => {
            if (params.data?.reinsurance.hasOwnProperty('excluded')) return params.data.reinsurance.excluded;
            else return false;
          },
          exportValueFormatter: (params) => (params.value ? 'Yes' : 'No'),
          valueSetter: (params) => {
            if (!params.data.reinsurance) {
              params.data.reinsurance = {};
            }
            if (params.data.reinsurance.initialAutoFacRate === undefined) {
              params.data.reinsurance.initialAutoFacRate = params.data.reinsurance.autoFacRate;
            }
            if (params.data.reinsurance.initialAutoFacPremium === undefined) {
              params.data.reinsurance.initialAutoFacPremium = params.data.reinsurance.autoFacPremium;
            }
            const initialAutoFacRate = params.data.reinsurance.initialAutoFacRate;
            const initialAutoFacPremium = params.data.reinsurance.initialAutoFacPremium;

            params.data.reinsurance.excluded = params.newValue;
            const riskId = params.data.riskId;
            const locationIndex = getLocationIndex(riskId);
            setValue(`quote.locations.${locationIndex}.reinsurance.excluded`, params.newValue);

            if (params.newValue) {
              params.data.reinsurance.autoFacRate = 0;
              params.data.reinsurance.autoFacPremium = 0;
              setValue(`quote.locations.${locationIndex}.reinsurance.autoFacRate`, 0);
              setValue(`quote.locations.${locationIndex}.reinsurance.autoFacPremium`, 0);
            } else {
              params.data.reinsurance.autoFacRate = initialAutoFacRate;
              params.data.reinsurance.autoFacPremium = initialAutoFacPremium;
              setValue(`quote.locations.${locationIndex}.reinsurance.autoFacRate`, initialAutoFacRate);
              setValue(`quote.locations.${locationIndex}.reinsurance.autoFacPremium`, initialAutoFacPremium);
            }

            return true;
          },
        },
        {
          headerName: 'Auto Fac Rate',
          isRequired: false,
          field: 'autoFacRate',
          valueGetter: (params) => {
            if (params.data?.reinsurance?.hasOwnProperty('autoFacRate') && !params.data.reinsurance.excluded) {
              return params.data.reinsurance.autoFacRate;
            } else if (params.data.reinsurance.excluded) return 0;
            else {
              const autoFacRate = params.data.riskZones && params.data.riskZones[0]?.convectiveRatingZone;
              return autoFacRate === 2 ? 900 : 1300;
            }
          },
          valueFormatter: (field) => {
            const value = field.value;
            return value === 0 ? 'N/A' : currencyFormatter({ field, withDecimals: true });
          },
          cellClassRules: {
            'gray-background': isAverageInsuredChanged,
          },
          minWidth: 150,
        },
        {
          headerName: 'Auto Fac Premium',
          colId: 'autoFacPremium',
          valueGetter: (params) => {
            const reinsurance = params.data.reinsurance || {};
            const coveredValue = params.data.coveredValue || 0;
            if (reinsurance.hasOwnProperty('autoFacPremium') && !params.data.reinsurance.excluded) {
              return reinsurance.autoFacPremium;
            } else if (params.data.reinsurance.excluded) return 0;
            else {
              const autoFacRate = reinsurance.autoFacRate || 0;
              const maxTotalInsuredValue = reinsurance.maxTotalInsuredValue || coveredValue;
              if (autoFacRate > 0) {
                const excessValue = Math.max(maxTotalInsuredValue - 5000000, 0.0);
                const calculatedValue = Math.max((excessValue / 1000000) * autoFacRate, 0.0);
                return calculatedValue;
              } else {
                return 0.0;
              }
            }
          },
          valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
          valueSetter: (params) => {
            if (!params.data.reinsurance) {
              params.data.reinsurance = {};
            }
            params.data.reinsurance.autoFacRate = params.newValue;
            const riskId = params.data.riskId;
            const locationIndex = getLocationIndex(riskId);
            setValue(`quote.locations.${locationIndex}.reinsurance.autoFacRate`, params.newValue);
            return true;
          },
          cellClassRules: {
            'gray-background': isAverageInsuredChanged,
          },
          isRequired: false,
          dataType: 'number',
          minWidth: 150,
        },
        {
          headerName: 'Additional Fac Premium',
          colId: 'additionalFacPremium',
          valueGetter: (params) => {
            const { coveredValue } = params.data;
            const { maxTotalInsuredValue, additionalFacPremium } = params.data.reinsurance || {};
            if (additionalFacPremium != null) {
              return additionalFacPremium;
            }
            const calculatedValue = Math.max((((maxTotalInsuredValue || coveredValue) - 15000000) / 1000000) * 1000, 0);
            return calculatedValue;
          },
          valueSetter: (params) => {
            if (!params.data.reinsurance) {
              params.data.reinsurance = {};
            }
            params.data.reinsurance.additionalFacPremium = params.newValue;
            const riskId = params.data.riskId;
            const locationIndex = getLocationIndex(riskId);
            setValue(`quote.locations.${locationIndex}.reinsurance.additionalFacPremium`, Number(params.newValue));
            return true;
          },
          valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
          cellClassRules: {
            'ag-cell-editable': (params) => isEditable(params),
          },
          editable: isEditable,
          isRequired: false,
          dataType: 'number',
          minWidth: 150,
        },
        {
          headerName: 'Total Fac',
          colId: 'totalFac',
          valueGetter: (params) => {
            const autoFacPremium = params.getValue('autoFacPremium');
            const additionalFacPremium = params.getValue('additionalFacPremium');
            return Number(autoFacPremium) + Number(additionalFacPremium);
          },
          valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
          isRequired: false,
          dataType: 'number',
          minWidth: 150,
        },
        {
          headerName: 'Fac % GWP',
          valueGetter: (params) => {
            const totalFac = params.getValue('totalFac');
            const totalPremium = getTotalPremium(params);
            return (Number(totalPremium) && (Number(totalFac) / Number(totalPremium)) * 100) || 0;
          },
          isRequired: false,
          dataType: 'number',
          minWidth: 150,
          valueFormatter: PercentageFormatter,
        },
      ],
    },
    ...generateCoverageColumns(),
  ];
};
export default getCoverageColDef;
