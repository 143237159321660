import React, { useState, useRef, useCallback, useMemo } from 'react';
import { ActivityIndicator } from 'react-native';
import moment from 'moment-timezone';
import { Box, Text, Button, Heading, TextField, Modal, MenuItem, Switch, Menu } from '../../ui';
// import { withStyles } from '../../ui/styling';
// import { capitalize } from '../../ui/utils';
// import { getAddressLines } from '../../utils';
import { useApi, useAsync } from '../../hooks';
import {
  // TextData,
  Dialog,
  NavBar,
  Page,
  Main,
  // PageContent,
  NavBackButton,
  NavRow,
  NavGroup,
  LayoutBox,
  // AppActionsGroup,
  ContentCard,
} from '../../components';
import { ProductTypes, ProductNames } from '../../constants';
import { DeleteIcon } from '../../components/icons';

export const CollateralPage = ({ backPath = '/' }) => {
  const { getAllProductContent } = useApi();
  const { value: data } = useAsync(getAllProductContent);
  // const { value: products, status: pStatus, error: pError } = useAsync(getProducts);
  const [editContent] = useState(false);
  // const [editContent, setEditContent] = useState(false);
  const currentEdit = useRef('addItem');
  currentEdit.current = typeof editContent === 'object' ? editContent.id : editContent === true ? 'addItem' : currentEdit.current;
  // const [deleteContent, setDeleteContent] = useState(false);
  // const handleClose = useCallback(() => setEditContent(false), [setEditContent]);
  console.log(data);
  return (
    <Page padTop={({ theme: { sizes } }) => sizes.appBarHeight}>
      <NavBar padTop="$1">
        <NavRow>
          <NavGroup start>
            <NavBackButton onPress={(navigate) => (navigate.canGoBack ? navigate.back() : navigate.back(backPath))} />
          </NavGroup>
        </NavRow>
      </NavBar>
      <Main padTop={({ theme }) => theme.breakpoints({ sm: theme.spacing(24), md: theme.spacing(13) })} padBottom={164}>
        <CollateralView />
      </Main>
    </Page>
  );
};

export const CollateralView = (props) => {
  const { getAllProductContent, getProducts } = useApi();
  const { value: data, status, execute: update } = useAsync(getAllProductContent);
  const { value: products } = useAsync(getProducts);
  const [editContent, setEditContent] = useState(false);
  const currentEdit = useRef('addItem');
  currentEdit.current = typeof editContent === 'object' ? editContent.id : editContent === true ? 'addItem' : currentEdit.current;
  const [deleteContent, setDeleteContent] = useState(false);
  const handleClose = useCallback(() => setEditContent(false), [setEditContent]);
  console.log(data);
  return (
    <LayoutBox width="100%" flex={1} {...props}>
      <LayoutBox width="100%" row justifyContent="space-between" alignItems="center" mb="$6">
        <Heading level={2} small>
          Collateral
        </Heading>
        {products ? <Button onPress={() => setEditContent(true)} label="Add Item" /> : null}
      </LayoutBox>
      <ContentList data={data} status={status} update={update} editContent={setEditContent} deleteContent={setDeleteContent} />
      {products ? (
        <EditContentDialog key={`${currentEdit.current}`} open={editContent} onClose={handleClose} products={products} update={update} />
      ) : null}
      <DeleteContentDialog open={deleteContent ? true : false} item={deleteContent} onClose={() => setDeleteContent(false)} update={update} />
    </LayoutBox>
  );
};

export const CollateralModal = ({ onClose, ...props }) => {
  return (
    <Modal
      onClose={onClose}
      {...props}
      scrollable
      ScrollProps={{
        padLeft: ({ theme }) => theme.layout.centeredOverlayLargeWindow.marginLeft,
        padTop: 50,
        padBottom: 100,
      }}
    >
      <Main pointerEvents="box-none" layout="center" minHeight="100%">
        <ContentCard bg="white" padTop="0">
          <NavBar disablePlaceholder mb="$2" padX="0" padTop="$2">
            <NavRow>
              <NavGroup start>
                <NavBackButton
                  label="Close"
                  icon={DeleteIcon}
                  onPress={onClose}
                  styles={{
                    icon: {
                      marginRight: '$1',
                      props: {
                        size: 24,
                      },
                    },
                  }}
                />
              </NavGroup>
            </NavRow>
          </NavBar>
          <CollateralView />
        </ContentCard>
      </Main>
    </Modal>
  );
};

export const CollateralButtonAndModal = ({ href = '/collateral', onPress, ButtonComponent = Button, ...props }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ButtonComponent
        href={href}
        onPress={() => {
          if (onPress) {
            onPress();
          }
          setOpen(!open);
        }}
        {...props}
      >
        Collateral
      </ButtonComponent>
      <CollateralModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

const DeleteContentDialog = ({ onClose: onCloseProp, item, update, ...rest }) => {
  const { deleteContentItem } = useApi();
  const handleDelete = useCallback(async () => {
    await deleteContentItem(item);
    await update();
    onCloseProp();
  }, [deleteContentItem, item, onCloseProp, update]);
  const { execute, status } = useAsync(handleDelete, { immediate: false });
  const onClose = useCallback(() => {
    if (status === 'pending') {
      return;
    }
    onCloseProp();
  }, [onCloseProp, status]);

  return (
    <Dialog
      onClose={onClose}
      heading="Delete Item"
      prompt="Are you sure you want to delete this item?"
      actions={
        <LayoutBox row layout="center-end" gap={16}>
          <Button onPress={execute} disabled={status === 'pending'}>
            {status === 'pending' ? <ActivityIndicator /> : 'Delete'}
          </Button>
          <Button outlined color="$primary" disabled={status === 'pending'} onPress={onClose}>
            Cancel
          </Button>
        </LayoutBox>
      }
      {...rest}
    />
  );
};
const EditContentDialog = ({ open: openProp, onClose: onCloseProp, products, update, ...rest }) => {
  const data = openProp && typeof openProp === 'object' ? openProp : null;
  const open = openProp ? true : false;
  const initialData = useRef(null);
  if (!initialData.current) {
    initialData.current = {
      productId: null,
      title: '',
      description: '',
      filename: '',
      file: null,
      ...data,
    };
  }

  const { updateContentItem, addContentItem } = useApi();
  const handleSubmit = useCallback(
    async (content) => {
      const { productId, title, description, filename, file, id } = content;
      console.log(productId, title, description, filename, file, id);
      if (!productId) {
        throw { productId: 'Please select a product' };
      }
      if (!title) {
        throw { title: 'Required' };
      }
      if (!file && !filename && !id) {
        throw { file: 'Required', filename: 'Required' };
      }
      if (id) {
        const response = await updateContentItem(content);
        console.log('updated', response);
      } else {
        const response = await addContentItem(content);
        console.log('added', response);
      }
      await update();
      onCloseProp();
    },
    [updateContentItem, addContentItem, onCloseProp, update]
  );

  const { execute: submit, status, error } = useAsync(handleSubmit, { immediate: false });

  const onClose = useCallback(() => {
    if (status === 'pending') {
      return;
    }
    onCloseProp();
  }, [onCloseProp, status]);

  const errors = useMemo(() => {
    if (error && typeof error === 'object') {
      return error;
    } else {
      return { error };
    }
  }, [error]);

  const [productId, setProductId] = useState(initialData.current.productId);
  const [title, setTitle] = useState(initialData.current.title);
  const [description, setDescription] = useState(initialData.current.description);
  const [filename, setFilename] = useState(initialData.current.filename);
  const [file, setFile] = useState(initialData.current.file);
  const [showFileUrlInput, setShowFileUrlInput] = useState(
    initialData.current.file || (initialData.current.mimeType && initialData.current.mimeType !== 'text/uri-list')
      ? false
      : initialData.current.filename
      ? true
      : false
  );

  return (
    <Dialog
      {...rest}
      open={open}
      onClose={onClose}
      heading={data ? 'Edit Collateral' : 'Add Collateral'}
      actions={
        <Button
          large
          onPress={() => submit({ ...initialData.current, productId, title, description, filename, file })}
          disabled={status === 'pending'}
        >
          {status === 'pending' ? <ActivityIndicator /> : 'Submit'}
        </Button>
      }
    >
      <TextField
        value={productId}
        label="Product"
        type="select"
        onChangeValue={(v) => setProductId(v)}
        error={errors.productId}
        helperText={errors.productId}
      >
        {products.map((product) => {
          const { name, id } = product;
          return (
            <MenuItem key={id} value={id}>
              {ProductNames[name] ? ProductNames[name] : name}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField value={title} label="Title" onChangeValue={(v) => setTitle(v)} error={errors.title} helperText={errors.title} />
      <TextField value={description} label="Description" onChangeValue={(v) => setDescription(v)} />
      <LayoutBox row pb="$1.5">
        <Switch alignSelf="flex-start" onPress={() => setShowFileUrlInput((v) => !v)} checked={showFileUrlInput} label="URL" />
      </LayoutBox>
      {showFileUrlInput ? (
        <TextField
          value={filename}
          onChangeValue={(v) => setFilename(v)}
          label="URL to content"
          error={errors.filename}
          helperText={errors.filename}
        />
      ) : (
        <>
          <LayoutBox row alignItems="center" gap={12}>
            <Box>
              <Button large outlined disabled>
                {file ? 'Update' : 'Upload'}
              </Button>
              <label style={{ opacity: 0, width: '100%', height: '100%', cursor: 'pointer', position: 'absolute', left: 0, top: 0, zIndex: 1 }}>
                <input
                  type="file"
                  style={{
                    width: 0.1,
                    height: 0.1,
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: -1,
                  }}
                  accept="*"
                  onChange={(e) => {
                    if (e && e.target && e.target.files && e.target.files.length) {
                      setFile(e.target.files[0]);
                    }
                  }}
                />
              </label>
            </Box>
            {file ? <Text>{file.name}</Text> : null}
          </LayoutBox>
          <Text small bold color="$error" py="$2">
            {errors.file ? errors.file : ''}
          </Text>
        </>
      )}
    </Dialog>
  );
};

const ContentList = ({ data: dataProp = undefined, status: statusProp, update: updateProp, editContent, deleteContent }) => {
  const { getAllProductContent } = useApi();
  const handleGetContent = useCallback(async () => {
    if (dataProp !== undefined) {
      return dataProp;
    }
    return await getAllProductContent();
  }, [getAllProductContent, dataProp]);
  const { value: data, status: dataStatus, execute } = useAsync(handleGetContent);
  const status = statusProp && statusProp !== 'idle' ? statusProp : dataStatus;
  const update = useMemo(() => {
    if (dataProp !== undefined) {
      return updateProp;
    }
    return execute;
  }, [dataProp, updateProp, execute]);
  return (
    <LayoutBox
      layout={(!data && status === 'pending') || !data ? 'center' : 'top-left'}
      stretch
      width="100%"
      gap={2}
      gapBorder={{ width: 1, color: '$gray.200' }}
    >
      <LayoutBox padY="$1.5" stretch row justifyContent="space-between" alignItems="center" width="100%">
        <LayoutBox flex={0.35}>
          <Text uppercase bold dim small>
            Document Name/Description
          </Text>
        </LayoutBox>
        <LayoutBox flex={0.25} layout="bottom-left">
          <Text uppercase bold dim small>
            Product Name
          </Text>
        </LayoutBox>
        <LayoutBox flex={0.2} layout="bottom-left">
          <Text uppercase bold dim small>
            Last Updated
          </Text>
        </LayoutBox>
        <LayoutBox flex={0.2} layout="bottom-right">
          <Text uppercase bold dim small></Text>
        </LayoutBox>
      </LayoutBox>
      {!data && status === 'pending' ? (
        <ActivityIndicator style={{ marginTop: 50 }} />
      ) : !data || !Array.isArray(data) || !data.length ? (
        <Text py="$4" dim>
          No collateral is available
        </Text>
      ) : (
        data.map((item) => {
          return <ContentItem key={`${item.id}`} data={item} update={update} editContent={editContent} deleteContent={deleteContent} />;
        })
      )}
    </LayoutBox>
  );
};

const ContentItem = ({ data, update, editContent, deleteContent }) => {
  const { title, description, updatedAt, mimeType, filename, id, productName, productType, productId } = data;
  const product = useMemo(() => {
    return {
      name: ProductNames[productName] ? ProductNames[productName] : productName,
      type: ProductTypes[productType] ? ProductTypes[productType] : productType,
    };
  }, [productName, productType]);
  const lastUpdateTime = useMemo(() => (updatedAt ? moment(updatedAt).format('MM-DD-YYYY') : ''), [updatedAt]);
  const { getURLForContentItem } = useApi();
  const handleDownloadItem = useCallback(async () => {
    if (mimeType === 'text/uri-list') {
      window.open(filename, '_blank');
    } else {
      const url = await getURLForContentItem({ id, productId });
      window.location = url.url;
    }
  }, [getURLForContentItem, mimeType, filename, id, productId]);
  const { execute: downloadItem, status: downloadStatus } = useAsync(handleDownloadItem, { immediate: false });

  const btnRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <LayoutBox padY="$2.5" stretch row justifyContent="space-between" alignItems="center" width="100%">
      <LayoutBox flex={0.35}>
        <Heading level={5}>{title}</Heading>
        <Text small>{description}</Text>
      </LayoutBox>
      <Text small bold uppercase flex={0.25}>
        {product.name}
      </Text>
      <Text flex={0.2} small>{`${lastUpdateTime}`}</Text>
      <LayoutBox row gap={8} flex={0.2} layout="center-right">
        <Button onPress={downloadItem} disabled={downloadStatus === 'pending'}>
          {downloadStatus === 'pending' ? <ActivityIndicator /> : 'Download'}
        </Button>
        <Button outlined color="$primary" onPress={() => setOpen((v) => !v)} ref={btnRef}>
          More
        </Button>
      </LayoutBox>
      <Menu anchorNode={btnRef.current} open={open} onClose={() => setOpen(false)}>
        <MenuItem
          onPress={() => {
            setOpen(false);
            editContent(data);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onPress={() => {
            setOpen(false);
            deleteContent(data);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </LayoutBox>
  );
};

// const Contents = ({ data }) => {
//   const { id, status, policy, location, claimsType, claimsSubtype, incurredAt, openedAt, closedAt } = data;

//   const { insured, product } = policy;
//   const { address, region, postcode, name } = location;

//   const statusText = capitalize(status);
//   const coverageTypeName = CoverageNames[claimsType];
//   const coverageSubtypeName = capitalize(CoverageTypeSubTypes[claimsType][claimsSubtype]);
//   const productType = ProductTypes[product.productType];
//   const productName = ProductNames[product.name];
//   const addressLines = getAddressLines(address, region, postcode);
//   const incurredDate = moment(incurredAt).format('MM-DD-YYYY');
//   const openedDate = moment(openedAt).format('MM-DD-YYYY');
//   const closedDate = closedAt ? moment(closedAt).format('MM-DD-YYYY') : null;

//   const displayData = [
//     {
//       data: statusText,
//       label: 'Status',
//     },
//     ...(closedDate ? [{ data: closedDate, label: 'Closed At' }] : []),
//     {
//       data: coverageTypeName,
//       label: 'Claim Category',
//       size: 'small',
//     },
//     {
//       data: coverageSubtypeName,
//       label: 'Claim Type',
//     },
//     {
//       data: incurredDate,
//       label: 'Date Event Incurred',
//     },
//     {
//       data: openedDate,
//       label: 'Date Claim Opened',
//     },
//     {
//       data: name,
//       label: 'Name of Incident Location',
//     },
//     {
//       data: addressLines,
//       label: 'Address',
//       size: 'small',
//     },
//     {
//       data: insured,
//       label: 'Policy Insured',
//     },
//     {
//       label: 'Product Type',
//       data: productType,
//     },
//     {
//       data: productName,
//       label: 'Product Name',
//     },
//   ];
//   return (
//     <PageContent gap="16">
//       {displayData.map((p, i) => {
//         return <TextData key={`claimdisplaydata${i}`} {...p} />;
//       })}
//     </PageContent>
//   );
// };

// const LoadingPage = withStyles(({ theme }) => ({
//   root: { props: { layout: 'center' } },
// }))(({ styles, ...rest }) => {
//   return (
//     <Page {...rest}>
//       <ActivityIndicator />
//     </Page>
//   );
// });
