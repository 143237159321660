import { styled } from '../../ui/styling';
import { LayoutBox } from './LayoutBox';

export const Site = styled(LayoutBox)({
  flex: 1,
  // minHeight: '100vh',
  maxWidth: '100vw',
  backgroundColor: '$background',
  overflow: 'hidden',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',

  position: 'absolute',
  top: 0,
  left: 0,
  maxHeight: '100%',
  minWidth: '100%',
  minHeight: '100%',

  props: { disableAnimate: true },
});
