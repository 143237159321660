import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Text, TextField, Spacing, IconButton } from '../../../ui';
import { inputTests } from '../../../ui/utils';
import { BulletList, ReviewCard, ReviewCardContentList, ReviewItem, Section } from './components';
import { useApi } from '../../../hooks';
import { useSiteChecklistContext } from '../context';
import { CollapseArrowIcon } from '../../../components/icons';

export const PointOfContactData = React.forwardRef((props, ref) => {
  const { editing, setEditing, ...rest } = props;
  const { data, bindForm, submitRequest, status, errors, updateData, editingEnabled, internalViewer } = useSiteChecklistContext();
  const open = editing === 'point-of-contact' && editingEnabled;
  const { pocName, pocPhone, pocEmail } = data || {};
  const [name, setName] = useState(pocName || '');
  const [phone, setPhone] = useState(pocPhone || '');
  const [email, setEmail] = useState(pocEmail || '');
  const hasInputError = errors && typeof errors === 'object' && errors.inputError;

  const lastData = useRef({ pocName, pocPhone, pocEmail });
  useEffect(() => {
    if (pocName !== lastData.current.pocName) {
      setName(pocName || '');
    }
    if (pocPhone !== lastData.current.pocPhone) {
      setPhone(pocPhone || '');
    }
    if (pocEmail !== lastData.current.pocEmail) {
      setEmail(pocEmail || '');
    }
    lastData.current = { pocName, pocPhone, pocEmail };
  }, [pocName, pocPhone, pocEmail]);

  useEffect(() => {
    if (!open) {
      setName(lastData.current.pocName || '');
      setPhone(lastData.current.pocPhone || '');
      setEmail(lastData.current.pocEmail || '');
    }
  }, [open]);

  const { submitDetailsOnBindingForm } = useApi();
  const submit = useMemo(() => {
    return ({ pocName, pocPhone, pocEmail }) => {
      return submitRequest(async () => {
        let isValid = true;
        const errors = { inputError: true };
        if (typeof pocName !== 'string' || !pocName.trim()) {
          isValid = false;
          errors.pocName = 'Required';
        }
        if (typeof pocPhone !== 'string' || !pocPhone.trim()) {
          isValid = false;
          errors.pocPhone = 'Required';
        } else if (!inputTests.phone(pocPhone)) {
          isValid = false;
          errors.pocPhone = 'Invalid phone number';
        }
        if (typeof pocEmail !== 'string' || !pocEmail.trim()) {
          isValid = false;
          errors.pocEmail = 'Required';
        } else if (!inputTests.email(pocEmail)) {
          isValid = false;
          errors.pocEmail = 'Invalid email address';
        }
        if (!isValid) {
          throw errors;
        } else {
          await submitDetailsOnBindingForm(
            {
              ...data,
              pocName,
              pocPhone,
              pocEmail,
            },
            bindForm,
            internalViewer
          );
          await updateData();
          setEditing(false);
        }
      });
    };
  }, [data, submitRequest, submitDetailsOnBindingForm, setEditing, internalViewer, bindForm, updateData]);

  return (
    <ReviewCard
      ref={ref}
      heading="Installation Contact"
      subheading={open ? 'Please provide a primary point of contact at this location for the installation.' : null}
      ActionComponent={open && editingEnabled ? IconButton : undefined}
      opacity={!editingEnabled || !editing || open ? 1 : 0.77}
      headerAction={
        !open && editingEnabled
          ? {
              label: 'Edit',
              onPress: () => setEditing('point-of-contact'),
            }
          : open && editingEnabled
          ? {
              children: <CollapseArrowIcon />,
              onPress: () => setEditing(false),
            }
          : undefined
      }
      footerActions={
        open &&
        editingEnabled && {
          right: [
            {
              label: 'Save',
              onPress: () => submit({ pocName: name, pocPhone: phone, pocEmail: email }),
              disabled: status === 'pending',
              loading: status === 'pending',
            },
          ],
        }
      }
      {...rest}
    >
      {open ? (
        <>
          <Section
            size="xSmall"
            heading="This is someone who:"
            marginX="$0.75"
            backgroundColor={({ theme }) => theme.colors.alpha('$gray.100', 0.5)}
            padX="$2"
            padTop="$2"
            padBottom="$3"
            borderRadius={8}
            borderWidth={1}
            borderColor="$gray.100"
          >
            <BulletList
              items={[
                'Has the authority to make decisions about this location.',
                'We can call or email if we need to clarify details about where the Dot should be placed. ',
                'Will be present at the location the day of the installation.',
                'Can provide access to the installation site if necessary.',
              ]}
              maxWidth={640}
            />
          </Section>
          <Section heading="Contact Information" padTop="$6" size="small" maxWidth={400}>
            <TextField
              label="Name"
              value={name}
              onChangeValue={(v) => setName(v)}
              disabled={status === 'pending'}
              error={hasInputError && errors.pocName}
              helperText={hasInputError && errors.pocName ? errors.pocName : null}
            />
            <TextField
              label="Phone Number"
              value={phone}
              onChangeValue={(v) => setPhone(v)}
              disabled={status === 'pending'}
              error={hasInputError && errors.pocPhone}
              helperText={hasInputError && errors.pocPhone ? errors.pocPhone : null}
            />
            <TextField
              label="Email"
              value={email}
              onChangeValue={(v) => setEmail(v)}
              disabled={status === 'pending'}
              error={hasInputError && errors.pocEmail}
              helperText={hasInputError && errors.pocEmail ? errors.pocEmail : null}
            />
          </Section>
        </>
      ) : (
        <ReviewCardContentList>
          <ReviewItem heading="Name">
            <Text bold>{pocName}</Text>
          </ReviewItem>
          <ReviewItem heading="Phone">
            <Text bold>{pocPhone}</Text>
          </ReviewItem>
          <ReviewItem heading="Email">
            <Text bold>{pocEmail}</Text>
          </ReviewItem>
          <Spacing vertical={2} />
        </ReviewCardContentList>
      )}
    </ReviewCard>
  );
});
