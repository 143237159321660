import React, { useState, useMemo } from 'react';
import { Box, TextField, Button } from '../../../ui';
import { Section } from './components';
import { useApi } from '../../../hooks';

export const DeploymentTypeControlForInternalUse = React.forwardRef((props, ref) => {
  const { siteChecklistContext } = props;
  const { data, internalViewer, editingEnabled } = siteChecklistContext;
  // const { submitDetailsOnBindingForm } = useApi();

  // const submit = useMemo(() => {
  //   return (inputs = {}) => {
  //     return submitRequest(async () => {
  //       const { roofDeployment, groundDeployment } = inputs;
  //       await submitDetailsOnBindingForm(
  //         {
  //           ...data,
  //           roofDeployment,
  //           groundDeployment,
  //         },
  //         bindForm,
  //         internalViewer
  //       );
  //       await updateData();
  //     });
  //   };
  // }, [data, submitRequest, submitDetailsOnBindingForm, internalViewer]);

  const { roofDeployment } = data;
  if (!internalViewer || !editingEnabled) {
    return null;
  }

  return (
    <Section
      heading="Deployment Type"
      subheading="INTERNAL USE ONLY. By default this is set to roof deployment. NOTE: Changing this to ground will also change the form requirements and update the view for internal and client users."
      padTop="$6"
      size="small"
      maxWidth={400}
      ref={ref}
    >
      <Button>{roofDeployment ? 'Change to Ground Deployment' : 'Change to Roof Deployment'}</Button>
    </Section>
  );
});

export const NotesForInternalUse = React.forwardRef((props, ref) => {
  const { siteChecklistContext } = props;
  const { data, submitRequest, bindForm, updateData, internalViewer, canInternalMakeChanges } = siteChecklistContext;
  const { submitDetailsOnBindingForm } = useApi();

  const submit = useMemo(() => {
    return (inputs = {}) => {
      return submitRequest(async () => {
        const { internalNotes } = inputs;
        await submitDetailsOnBindingForm(
          {
            ...data,
            internalNotes,
          },
          bindForm,
          internalViewer
        );
        await updateData();
      });
    };
  }, [data, submitRequest, submitDetailsOnBindingForm, internalViewer, bindForm, updateData]);

  const { internalNotes } = data;
  const [input, setInput] = useState(internalNotes || '');

  if (!internalViewer) {
    return null;
  }

  return (
    <>
      <Box sx={{ width: '100%', height: 1, bg: '$gray.200' }} />
      <Section
        heading="Notes (Admin)"
        subheading="INTERNAL USE ONLY. Not visible to client."
        marginTop="$5.5"
        padTop="$3"
        size="small"
        padX="$4"
        maxWidth={'100%'}
        bg="$gray.50"
        width="100%"
        ref={ref}
      >
        <TextField
          InputProps={{ multiline: true }}
          multiline
          height={280}
          width="100%"
          maxWidth="100%"
          placeholder="Any notes on this site checklist..."
          value={input}
          onChangeValue={(v) => setInput(v)}
          disabled={!canInternalMakeChanges}
          onBlur={() => {
            if (!canInternalMakeChanges) {
              return;
            }
            if (input.trim() === (internalNotes || '').trim()) {
              return;
            }
            submit({ internalNotes: input });
          }}
        />
      </Section>
    </>
  );
});
