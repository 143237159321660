import React from 'react';
import { Dimensions } from 'react-native';
import { Popover } from '../Popover';
import { ScrollView } from '../ScrollView';
import { MenuList } from '../MenuList';
import { withStyles } from '../../styling';
import { useEventCallback } from '../../hooks';

const Menu = withStyles(
  ({ marginThreshold = 16 }) => ({
    root: { props: { marginThreshold } },
    menuList: {
      props: {
        maxHeight: Dimensions.get('window').height < 500 ? '100%' : 500,
        flexGrow: 1,
        flexShrink: 0,
        marginBottom: marginThreshold,
      },
    },
  }),
  { name: 'Menu' }
)(
  React.forwardRef(function Menu(props, ref) {
    const {
      MenuListProps = {},
      variant = 'menu',
      autoFocus = true,
      disableAutoFocusItem = false, // TODO: Fix when true. When true - this should focus the MenuList and as a result have keyboardEvents captured from it however the focus seems to be mucked by ModalTrapFocus
      disableKeyboardFocusing = false,
      disabledItemsFocusable = false,
      disableListWrap = false,
      ContentContainerProps = null,
      children,
      open,
      onClose,
      accessibility,
      styles,
      ScrollComponent = ScrollView,
      ScrollProps = null,
      ...rest
    } = props;

    const menuListProps =
      MenuListProps && MenuListProps.style
        ? {
            ...styles.props.menuList,
            ...MenuListProps,
            style: [styles.menuList, MenuListProps.style],
          }
        : { ...styles.props.menuList, ...MenuListProps, style: styles.menuList };

    let activeItemIndex = -1;
    React.Children.forEach(children, (child, index) => {
      if (!React.isValidElement(child)) {
        return;
      }
      if (!child.props.disabled) {
        if (variant === 'select' && child.props.selected) {
          activeItemIndex = index;
        } else if (activeItemIndex === -1) {
          activeItemIndex = index;
        }
      }
    });

    const autoFocusItem = autoFocus && !disableAutoFocusItem && open;

    const handleMenuListOnKeyDown = useEventCallback((e) => {
      if (!e || !e.nativeEvent || !e.nativeEvent.key || e.preventHandlerDefault) {
        return;
      }
      if (e.key === 'Tab' && e.preventDefault) {
        e.preventDefault();
        e.preventHandlerDefault = true;
        if (onClose) {
          onClose(e, 'tabKeyDown');
        }
      }
    });

    return (
      <Popover
        ref={ref}
        open={open}
        accessibility={{
          accessibilityExpanded: open,
          accessibilityState: { expanded: open },
          ...accessibility,
        }}
        ContentContainerProps={{ maxHeight: '100%', ...ContentContainerProps }}
        onClose={onClose}
        {...rest}
      >
        <MenuList
          variant={variant}
          autoFocus={autoFocus && (activeItemIndex === -1 || disableAutoFocusItem)}
          autoFocusItem={autoFocusItem}
          scrollable
          ScrollProps={ScrollProps}
          ScrollComponent={ScrollComponent}
          onKeyDown={handleMenuListOnKeyDown}
          disableKeyboardFocusing={disableKeyboardFocusing}
          disabledItemsFocusable={disabledItemsFocusable}
          disableListWrap={disableListWrap}
          {...menuListProps}
        >
          {children}
        </MenuList>
      </Popover>
    );
  })
);

export { Menu };
