import { useEffect, useRef, useState } from 'react';
import { isNull, uniqueId } from '../utils';

function useId(value, fallbackPrefix = 'zuiEle_') {
  const prefix = useRef(fallbackPrefix);
  const [defaultId, setDefaultId] = useState(() => {
    if (isNull(value) || value === false) {
      return uniqueId(prefix.current);
    }
    return null;
  });
  const id = value || defaultId;
  useEffect(() => {
    if ((isNull(value) || value === false) && isNull(defaultId)) {
      setDefaultId(uniqueId(prefix.current));
    }
  }, [value, defaultId]);
  return id;
}

export { useId };
