import React from 'react';
import { TextInput } from '../TextInput';
import { Text } from '../Text';
import { Box } from '../Box';

const CountContainer = (props) => (
  <Box position="absolute" width="100%" height="100%" justifyContent="flex-end" alignItems="flex-end" pointerEvents="box-none" {...props} />
);

const CountText = (props) => <Text size="xSmall" color="$gray.400" maxLines={1} {...props} />;

const TextCount = ({ value = '', maxLength }) => {
  if (!maxLength || !value || typeof value !== 'string') return null;
  return (
    <CountContainer>
      <CountText>{`${value.length} / ${maxLength}`}</CountText>
    </CountContainer>
  );
};
// textAlignVertical is android only spec
// TODO: check if inputSize is needed now that flex={1} (i think it can be ignored)
export const TextArea = ({ maxLength, value, height = 200, inputSize, children, style, onChangeText, ...rest }) => {
  // inputSize={{ height, ...inputSize }}
  return (
    <TextInput
      value={value}
      multiline
      height={height}
      minHeight={height}
      alignItems="flex-start"
      justifyContent="flex-start"
      flex={1}
      textAlignVertical="top"
      onChangeText={(value) => {
        if (maxLength && value) {
          if (value.length <= maxLength) {
            if (onChangeText && typeof onChangeText === 'function') {
              onChangeText(value);
            }
          }
        } else if (onChangeText && typeof onChangeText === 'function') {
          onChangeText(value);
        }
      }}
      {...rest}
    >
      <TextCount value={value} maxLength={maxLength} />
      {children}
    </TextInput>
  );
};
