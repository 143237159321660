import { Platform } from 'react-native';
import { useEffect } from 'react';
import { useEventCallback } from './useEventCallback';

export function useOnKeyPress(targetKey, handler) {
  // If pressed key is our target key then set to true
  const downHandler = useEventCallback((event) => {
    if (event.key === targetKey) {
      handler && handler(event);
    }
  });

  // Add event listeners
  useEffect(() => {
    const isBrowser = Platform.OS === 'web' && typeof window !== `undefined`;
    if (!isBrowser) {
      return;
    }

    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]); // Empty array ensures that effect is only run on mount and unmount
}
