import React, { useCallback } from 'react';
import { useTransition as useSpringTransition, Transition as SpringTransition } from '@react-spring/native';
import { flattenStyles } from './utils';
import { resolveAnimation } from './resolveAnimation';
import { useMemoCompare } from '../hooks';
import { useStyleSystemProps } from './useStyleSystemProps';
import { isNull } from '../utils';
/*
was react-spring "^8.0.27"
*/
function useTransition(itemState, transitionOptions, props) {
  const config = useTransitionConfig(transitionOptions, props);
  const springTransition = useSpringTransition(itemState, config);
  const transition = useCallback(
    (render) => {
      return springTransition((animProps, item, transitionObject, index) => {
        const style = flattenStyles(animProps);
        return render(style, item, transitionObject, index);
      });
    },
    [springTransition]
  );
  return transition;
}

function useTransitionConfig(transitionOptions, props) {
  const styleSystemProps = useStyleSystemProps(props);
  const config = useMemoCompare(resolveAnimation(transitionOptions, styleSystemProps, true));
  return config;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  const {
    items,
    showHide = false, // use if not using render function and items is true/false
    keys = null,
    animations = {},
    transitionRef,
    children,
    ...rest
  } = props;

  if (items === undefined) {
    console.warn('<Transition> expects items prop to be defined');
  }

  const transitionProps = useTransitionConfig(
    [
      {
        ...(!isNull(keys) && typeof keys !== 'function' ? { key: keys } : { keys }),
        ...rest,
        ...animations,
        ref: ref || transitionRef,
      },
    ],
    rest
  );

  return (
    <SpringTransition items={items} ref={ref} {...transitionProps}>
      {(animProps, item, transitionObject, index) => {
        const style = flattenStyles(animProps);
        if (children) {
          if (typeof children === 'function') {
            return children(style, item, transitionObject, index);
          } else if (React.isValidElement(children)) {
            if (showHide && item === false) {
              return null;
            }
            return React.cloneElement(children, {
              animation: children.props.animation ? [children.props.animation, style] : [style],
            });
          }
        }
        return null;
      }}
    </SpringTransition>
  );
});

export { Transition, useTransition, flattenStyles };
