const toolbarDateFormats = {
  en: 'ddd, MMM Do',
  notEn: 'ddd, Do MMM', // TODO: how to handle localizing order by default...
};

const toolbarYearFormat = 'YYYY';

const toolbarFormatYear = (date) => {
  if (date) {
    return date.format(toolbarYearFormat);
  }
  return ' ';
};

const toolbarFormatDate = (date) => {
  return date ? date.format(toolbarDateFormats.en) : ' ';
};

export { toolbarDateFormats, toolbarFormatYear, toolbarFormatDate, toolbarYearFormat };
