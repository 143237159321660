import { Platform } from 'react-native';

export const paddingAliases = {
  p: 'padding',
  pad: 'padding',
  pt: 'paddingTop',
  padTop: 'paddingTop',
  pTop: 'paddingTop',
  pr: 'paddingRight',
  padRight: 'paddingRight',
  pRight: 'paddingRight',
  pb: 'paddingBottom',
  padBottom: 'paddingBottom',
  pBottom: 'paddingBottom',
  pl: 'paddingLeft',
  padLeft: 'paddingLeft',
  pLeft: 'paddingLeft',
  px: 'paddingX',
  padX: 'paddingX',
  padHorizontal: 'paddingX',
  paddingHorizontal: 'paddingX',
  py: 'paddingY',
  padY: 'paddingY',
  padVertical: 'paddingY',
  paddingVertical: 'paddingY',
};

export const marginAliases = {
  m: 'margin',
  mt: 'marginTop',
  mTop: 'marginTop',
  mr: 'marginRight',
  mRight: 'marginRight',
  mb: 'marginBottom',
  mBottom: 'marginBottom',
  ml: 'marginLeft',
  mLeft: 'marginLeft',
  mx: 'marginX',
  my: 'marginY',
  marginHorizontal: 'marginX',
  marginVertical: 'marginY',
};

export const backgroundColorAliases = {
  bg: 'backgroundColor',
  background: 'backgroundColor',
};

export const borderRadiusAliases = {
  bRadius: 'borderRadius',
  blRadius: 'borderLeftRadius',
  btRadius: 'borderTopRadius',
  brRadius: 'borderRightRadius',
  bbRadius: 'borderBottomRadius',
};

export const borderWidthAliases = {
  bWidth: 'borderWidth',
  blWidth: 'borderLeftWidth',
  brWidth: 'borderRightWidth',
  btWidth: 'borderTopWidth',
  bbWidth: 'borderBottomWidth',
  bxWidth: 'borderXWidth',
  byWidth: 'borderYWidth',
};

export const borderColorAliases = {
  bColor: 'borderColor',
  blColor: 'borderLeftColor',
  brColor: 'borderRightColor',
  btColor: 'borderTopColor',
  bbColor: 'borderBottomColor',
  bxColor: 'borderXColor',
  byColor: 'borderYColor',
};

export const borderStyleAliases = {
  bStyle: 'borderStyle',
  blStyle: 'borderLeftStyle',
  brStyle: 'borderRightStyle',
  btStyle: 'borderTopStyle',
  bbStyle: 'borderBottomStyle',
  bxStyle: 'borderXStyle',
  byStyle: 'borderYStyle',
};

export const translateAliases = {
  tx: 'translateX',
  ty: 'translateY',
};

export const aliases = {
  fontWeight: Platform.OS !== 'web' ? 'fontFamily' : 'fontWeight',
  ...borderStyleAliases,
  ...borderColorAliases,
  ...borderWidthAliases,
  ...borderRadiusAliases,
  ...backgroundColorAliases,
  ...paddingAliases,
  ...marginAliases,
  // custom,
  ...translateAliases,
};
