import React, { useRef, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppState, useAuth } from '../hooks';
import { useEventCallback, useLayoutEffect } from '../ui/hooks';
import { AccessDeniedPage } from '../pages/AccessDeniedPage';
import { LoginPage } from '../pages';

const ProtectedRoute = React.forwardRef(function ProtectedRoute(props, ref) {
  const {
    validate,
    valid = false,
    public: publicRoute = false,
    redirectTo,
    componentInvalid: InvalidComponent,
    renderInvalid,
    children,
    component,
    render,
    ...rest
  } = props;
  const routeIsValid = useEventCallback(({ auth, appState }) => {
    if (validate && typeof validate === 'function') {
      const response = validate({ auth, appState });
      if (typeof response === 'boolean') {
        return response;
      }
    }
    return publicRoute || (auth && auth.loggedIn && auth.user) ? true : false;
  });
  const auth = useAuth();
  const notLoggedIn = !auth || !auth.loggedIn;
  const [appState] = useAppState();
  const [isValid, setIsValid] = useState(valid ? true : routeIsValid({ auth, appState }));
  const mounting = useRef(true);

  useLayoutEffect(() => {
    if (mounting.current) {
      mounting.current = false;
    } else if (valid) {
      setIsValid(true);
    } else {
      setIsValid(routeIsValid({ auth, appState }));
    }
  }, [routeIsValid, auth, appState, valid]);

  return (
    <Route ref={ref} render={isValid && render ? render : undefined} component={isValid && component ? component : undefined} {...rest}>
      {isValid && children ? (
        children
      ) : !isValid ? (
        redirectTo ? (
          <Redirect to={redirectTo} />
        ) : renderInvalid ? (
          renderInvalid({ auth, appState })
        ) : InvalidComponent !== undefined ? (
          <InvalidComponent auth={auth} appState={appState} />
        ) : notLoggedIn ? (
          <LoginPage />
        ) : (
          <AccessDeniedPage />
        )
      ) : undefined}
    </Route>
  );
});

export { ProtectedRoute };
