import React from 'react';
import { Text } from '../Text';
import { useHoverable } from '../Hoverable';
import { withStyles } from '../../styling';
import { useEventCallback } from '../../hooks';

const Link = withStyles(
  {
    root: {},
    hovered: {
      props: {
        color: '$secondary',
        underline: true,
      },
    },
  },
  { name: 'Link' }
)(
  React.forwardRef(function Link(props, ref) {
    const { href, handler, accessibility, styles, style, onPress: onPressProp, ...textProps } = props;

    const onPress = useEventCallback((e) => {
      if (onPressProp) {
        onPressProp(e);
      }
      if (handler && typeof handler === 'function') {
        handler(href);
      }
    });

    const { hovered, ...hoverProps } = useHoverable();

    return (
      <Text
        ref={ref}
        {...textProps}
        onPress={handler || onPressProp ? onPress : null}
        href={href}
        focusable
        accessibility={
          href
            ? {
                accessibilityRole: 'link',
                ...accessibility,
              }
            : {
                accessibilityRole: 'button',
                ...accessibility,
              }
        }
        style={[style, hovered ? styles.hovered : null]}
        {...(hovered ? styles.props.hovered : null)}
        {...hoverProps}
      />
    );
  })
);

// backwards compatability with website.
// TODO: remove use of this
const TextLink = Link;

export { Link, TextLink };
