import React, { useEffect, useCallback, useRef } from 'react';
import { AppState } from 'react-native';
import { useEventCallback } from '../../hooks';
import { getDeviceInsets } from '../../utils';
import { withStyles } from '../../styling';
import { Box } from '../Box';
import { Modal } from '../Modal';
import { ToastContent } from '../ToastContent';

const Toast = withStyles(
  (props) => {
    const { breakpoints, spacing } = props.theme;

    return {
      root: {
        flexDirection: 'column',
        padding: props.padding || breakpoints({ xs: spacing(1), sm: spacing(3) }),
      },
      center: {
        alignItems: 'center',
      },
      left: {
        alignItems: 'flex-start',
      },
      right: {
        alignItems: 'flex-end',
      },
      top: {
        justifyContent: 'flex-start',
      },
      bottom: {
        justifyContent: 'flex-end',
      },
    };
  },
  {
    name: 'Toast',
    postApply: ({ styles, disableDeviceInsets = false }) => {
      if (disableDeviceInsets) {
        return {};
      }
      const insets = getDeviceInsets();
      const { paddingLeft = 0, paddingRight = 0, paddingBottom = 0, paddingTop = 0 } = styles.root;
      return {
        root: {
          paddingLeft: paddingLeft + insets.left,
          paddingRight: paddingRight + insets.right,
          paddingBottom: paddingBottom + insets.bottom,
          paddingTop: paddingTop + insets.top,
        },
      };
    },
  }
)(
  React.forwardRef(function Toast(props, ref) {
    const {
      anchorOrigin = {},
      autoHideDuration,
      resumeHideDuration,
      disableAppStateListener = false,
      disableDeviceInsets,
      action,
      message,
      ContentProps,
      children,
      styles,
      style,
      // modal props
      open,
      onClose,
      onMouseEnter,
      onMouseLeave,
      zIndex = '$toast',
      ...rest
    } = props;

    const { vertical = 'bottom', horizontal = 'left' } = anchorOrigin;
    const computedStyle = [styles[vertical], styles[horizontal], style];

    const timerAutoHide = useRef();

    const handleClose = useEventCallback((...args) => {
      if (onClose) {
        onClose(...args);
      }
    });

    const setAutoHideTimer = useEventCallback((duration) => {
      if (!onClose || duration == null) {
        return;
      }

      clearTimeout(timerAutoHide.current);
      timerAutoHide.current = setTimeout(() => {
        handleClose(null, 'timeout');
      }, duration);
    });

    useEffect(() => {
      if (open) {
        setAutoHideTimer(autoHideDuration);
      }

      return () => {
        clearTimeout(timerAutoHide.current);
      };
    }, [open, autoHideDuration, setAutoHideTimer]);

    // pause timer duration user interaction with toast or app in background
    const handlePause = () => {
      clearTimeout(timerAutoHide.current);
    };

    // restarts timer after interaction ends or app is in foreground again
    const handleResume = useCallback(() => {
      if (autoHideDuration != null) {
        setAutoHideTimer(resumeHideDuration != null ? resumeHideDuration : autoHideDuration * 0.5);
      }
    }, [autoHideDuration, resumeHideDuration, setAutoHideTimer]);

    const handleMouseEnter = (e) => {
      if (onMouseEnter) {
        onMouseEnter(e);
      }
      handlePause();
    };

    const handleMouseLeave = (e) => {
      if (onMouseLeave) {
        onMouseLeave(e);
      }
      handleResume();
    };

    const handleAppStateChange = useCallback(
      (appState) => {
        if (appState === 'active') {
          handleResume();
        } else if (appState === 'background') {
          // TODO: better way to reuse handlePause ?
          clearTimeout(timerAutoHide.current);
        }
      },
      [handleResume]
    );

    useEffect(() => {
      if (!disableAppStateListener && open) {
        const listener = AppState.addEventListener('change', handleAppStateChange);

        return () => {
          listener.remove();
        };
      }
      return undefined;
    }, [disableAppStateListener, handleAppStateChange, open]);

    return (
      <Modal ref={ref} open={open} onClose={handleClose} style={computedStyle} BackdropProps={{ invisible: true }} zIndex={zIndex} {...rest}>
        <Box disableAnimationDefaults onPress={() => {}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {children || <ToastContent message={message} action={action} {...ContentProps} />}
        </Box>
      </Modal>
    );
  })
);

export { Toast };
