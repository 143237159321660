import { isNull } from './isNull';

export function isEmpty(obj) {
  if (process.env.NODE_ENV !== 'production') {
    if (typeof obj !== 'object' || isNull(obj)) {
      console.warn('The object passed to isEmpty() should be a plain object or array and not null or undefined');
      return false;
    }
  }
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
