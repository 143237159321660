import { useRef, useEffect } from 'react';
import equal from 'fast-deep-equal/react';

export function useMemoCompare(value, compare = equal, log = false) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;
  const compareFunc = compare && typeof compare === 'function' ? compare : equal;
  // Pass previous and new value to compare function
  const isEqual = compareFunc(previous, value);

  // If not equal update previous to new value (for next render)
  // and then return new new value below.
  useEffect(() => {
    if (!isEqual) {
      if (log) console.log('NOT EQUAL', previousRef.current, value);
      previousRef.current = value;
    } else {
      if (log) console.log('EQUAL');
    }
  });

  return isEqual ? previous : value;
}
