import { firstDefined, isObject } from '../utils';

export const objects = {
  border: (value) => {
    const styles = {};
    let {
      width = 0,
      style = 'solid',
      color = null,
      all = {},
      left = {},
      top = {},
      right = {},
      bottom = {},
      vertical = {},
      y,
      horizontal = {},
      x,
    } = value;
    all = { width, style, color, ...all };
    y = y || vertical;
    x = x || horizontal;
    bottom = { ...all, ...y, ...bottom };
    top = { ...all, ...y, ...top };
    left = { ...all, ...x, ...left };
    right = { ...all, ...x, ...right };
    styles.borderTopWidth = top.width;
    styles.borderTopColor = top.color;
    styles.borderTopStyle = top.style;
    styles.borderBottomWidth = bottom.width;
    styles.borderBottomColor = bottom.color;
    styles.borderBottomStyle = bottom.style;
    styles.borderLeftWidth = left.width;
    styles.borderLeftColor = left.color;
    styles.borderLeftStyle = left.style;
    styles.borderRightWidth = right.width;
    styles.borderRightColor = right.color;
    styles.borderRightStyle = right.style;
    return styles;
  },
  radius: (value) => {
    const styles = {};
    const { topLeft, topRight, bottomLeft, bottomRight, top, bottom, left, right, all } = value;
    styles.borderBottomLeftRadius = firstDefined([bottomLeft, bottom, left, all]);
    styles.borderBottomRightRadius = firstDefined([bottomRight, bottom, right, all]);
    styles.borderTopLeftRadius = firstDefined([topLeft, top, left, all]);
    styles.borderTopRightRadius = firstDefined([topRight, top, right, all]);
    return styles;
  },
  padding: (value) => {
    const styles = {};
    let { left, right, top, bottom, x, y, horizontal, vertical } = value;
    left = firstDefined([left, x, horizontal]);
    right = firstDefined([right, x, horizontal]);
    top = firstDefined([top, y, vertical]);
    bottom = firstDefined([bottom, y, vertical]);
    styles.pl = left;
    styles.pr = right;
    styles.pt = top;
    styles.pb = bottom;
    return styles;
  },
  margin: (value) => {
    let { left, right, top, bottom, x, y, horizontal, vertical } = value;
    const styles = {};
    left = firstDefined([left, x, horizontal]);
    right = firstDefined([right, x, horizontal]);
    top = firstDefined([top, y, vertical]);
    bottom = firstDefined([bottom, y, vertical]);
    styles.ml = left;
    styles.mr = right;
    styles.mt = top;
    styles.mb = bottom;
    return styles;
  },
  flex: (value) => {
    const { grow, shrink, basis } = value;
    return {
      flexGrow: grow,
      flexShrink: shrink,
      flexBasis: basis,
    };
  },
  shadow: (value, theme) => {
    let {
      color,
      elevation,
      offset = 0,
      offsetY = 0,
      offsetX = 0,
      opacity = -1,
      radius = -1,
      invert = false,
      shadowColor = null,
      shadowOpacity = null,
      shadowRadius = null,
      shadowOffsetX = null,
      shadowOffsetY = null,
    } = value;
    const shadowOffset = isObject(offset) ? objects.shadowOffset(offset) : { shadowOffsetX: offset, shadowOffsetY: offset };
    shadowOffset.shadowOffsetY = shadowOffsetY !== null ? shadowOffsetY : offsetY || shadowOffset.shadowOffsetY;
    shadowOffset.shadowOffsetX = shadowOffsetX !== null ? shadowOffsetX : offsetX || shadowOffset.shadowOffsetX;
    if (elevation && theme && theme.shadows && typeof theme.shadows.elevation === 'function') {
      let elShadow = theme.shadows.elevation(elevation, {
        theme,
        color,
        opacity,
        offsetX: shadowOffset.shadowOffsetX,
        invert,
      });
      if (elShadow) {
        return elShadow;
      }
    }
    if (invert) {
      shadowOffset.shadowOffsetY *= -1;
      shadowOffset.shadowOffsetX *= -1;
    }

    return {
      ...shadowOffset,
      shadowColor: shadowColor !== null ? shadowColor : color,
      shadowOpacity: shadowOpacity !== null ? shadowOpacity : opacity === -1 ? 1 : opacity,
      shadowRadius: shadowRadius !== null ? shadowRadius : radius === -1 ? 0 : radius,
    };
  },
  shadowOffset: (value) => {
    const { x, y, width, height } = value;
    return {
      shadowOffsetY: y || height || 0,
      shadowOffsetX: x || width || 0,
    };
  },
  textShadow: (value) => {
    const { color, offset, radius } = value;
    return {
      textShadowColor: color,
      textShadowOffset: offset,
      textShadowRadius: radius,
    };
  },
  color: (value, prop = 'color') => {
    if (value.default) {
      return { [prop]: value.default };
    }
    return { [prop]: value };
  },
  backgroundColor: (value) => objects.color(value, 'backgroundColor'),
  borderColor: (value) => objects.color(value, 'borderColor'),
  borderLeftColor: (value) => objects.color(value, 'borderLeftColor'),
  borderRightColor: (value) => objects.color(value, 'borderRightColor'),
  borderTopColor: (value) => objects.color(value, 'borderTopColor'),
  borderBottomColor: (value) => objects.color(value, 'borderBottomColor'),
  tintColor: (value) => objects.color(value, 'tintColor'),
};
