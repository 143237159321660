import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { Svg } from '../Svg';
import { useControlledState, useEventCallback, useLayout, useMemoCompare, useId, useRefArray } from '../../hooks';
import { Box } from '../Box';
import { ActivityIndicator } from '../ActivityIndicator';
import { Menu } from '../Menu';
import { MenuItem } from '../MenuItem';
import { isObject } from '../../utils';
import { withStyles } from '../../styling';
import { useInputListItems } from '../ListInput';

/* Implemention is heavily borrowed from material-ui@5.2 Autocomplete & useAutocomplete */

// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
// Give up on IE11 support for this feature
function stripDiacritics(string) {
  return typeof string.normalize !== 'undefined' ? string.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : string;
}

export function createFilterOptions(config = {}) {
  const { ignoreAccents = true, ignoreCase = true, limit, matchFrom = 'any', stringify, trim = false } = config;

  return (options, { inputValue, getOptionLabel }) => {
    let input = trim ? inputValue.trim() : inputValue;
    if (ignoreCase) {
      input = input.toLowerCase();
    }
    if (ignoreAccents) {
      input = stripDiacritics(input);
    }

    const filteredOptions = options.filter((option) => {
      let candidate = (stringify || getOptionLabel)(option);
      if (typeof candidate !== 'string') {
        candidate = '';
      }
      if (ignoreCase) {
        candidate = candidate.toLowerCase();
      }
      if (ignoreAccents) {
        candidate = stripDiacritics(candidate);
      }

      return matchFrom === 'start' ? candidate.indexOf(input) === 0 : candidate.indexOf(input) > -1;
    });

    return typeof limit === 'number' ? filteredOptions.slice(0, limit) : filteredOptions;
  };
}

const defaultFilterOptions = createFilterOptions();

const Autocomplete = withStyles(
  {
    root: {},
    listBox: {},
    textBox: ({ theme, disableClear, disabled, freeInput, forcePopupIcon, loading, vertical = false, multiple = false }) => {
      let paddingRight = theme.spacing(2);
      const includeClearIconPadding = !disableClear && !disabled;
      const includePopupOrLoadingPadding = loading || ((!freeInput || forcePopupIcon === true) && forcePopupIcon !== false);
      if (includePopupOrLoadingPadding) {
        paddingRight += 28;
      }
      if (includeClearIconPadding) {
        paddingRight += 28;
      }
      if (multiple && vertical) {
        return {
          flexWrap: 'nowrap',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          paddingRight,
        };
      }
      return { paddingRight };
    },
    option: {},
    controls: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '100%',
      maxHeight: '100%',
      position: 'absolute',
      top: 0,
      padTop: '$0.5',
      right: '$1.25',
    },
    iconButton: {
      minWidth: 28,
      alignSelf: 'stretch',
      justifyContent: 'center',
      alignItems: 'center',
    },
    popupButtonOpened: { rotate: '180deg' },
  },
  { name: 'Autocomplete' }
)(
  React.forwardRef(function Autocomplete(props, ref) {
    const {
      renderInput,
      options: optionsProp,
      getOptionLabel: getOptionLabelProp,
      getOptionDisabled,
      renderOption: renderOptionProp,
      filterOptions: filterOptionsProp, // func to filter the list based on the input string
      filterSelectedOptions = false, // if true then options list is filtered to what's not been selected
      isOptionEqualToValue: isOptionEqualToValueProp, // is the selected value(s) equal to an option. useful for ^^ particularly
      value: valueControlled, // array if multiple = true
      defaultValue = undefined,
      multiple = false, // indicate that multiple values can be chosen
      freeInput, // input text itself is considered a value
      inputValue: inputValueControlled, // to control the the input string set this
      // onChange,
      onChangeValue,
      onSelectOption,
      // onInputChange,
      onInputChangeValue,

      // props for when multiple = true and passed to useInputListItems
      renderItem,
      getItemLabel,
      focusedItem: focusedItemControlled,
      onChangeFocusedItem,
      itemProps,
      onAddItem,
      onRemoveItem,
      onUpdateItem,
      onItemKeyDown,
      onItemBlur,
      onItemFocus,
      onItemPress,
      onItemDelete,
      vertical = false,

      autoComplete = false, // show the rest of the selected text after current text in input not typed in for the selected option (selected being via autoHighlight or keyboard, not hover)
      autoHighlight = false, // automatically highlight/select the first option
      autoSelect = false, // automatically use the highlighted/selected option value if the input blurs (selected is by keyboard or autoHighlight)
      onHighlightChange, // when option in list is highlighted either via keyboard, mouseover, or autoHighlight
      includeInputInList = false, // when keyboard highlighting up and at the top index and up again the input will highlight (i.e. -1)
      disableListWrap = props.multiple && props.vertical ? true : false, // dont wrap from start to end when up (and opposite) when keyboarding highlighting options
      pageSize = 5, // how many times to skip with onPageUp and onPageDown keyboard events

      openOnFocus = false, // when tabbing should this open
      selectOnFocus = !props.freeInput, // highlight the text when focused (useful to immediately delete the entry)
      blurOnSelect = false, // blurOnSelect (blur the text input after selecting or keep the text input focused) <- different from keeping the menu open
      blurOnSubmit = false, // freeInput only -> blur the text input after entering a new value
      clearOnBlur = !props.freeInput, // (if not freeInput and true then clear if no value selected) false if for some reason you dont?
      clearOnEscape = false,
      disableClear = false, // disable remove clear button/functionality
      disableCloseOnSelect = false, // if true then the menu stays open after a selection.
      disableCloseOnSubmit = false, // freeSolo only. similar to blurOnSubmit
      disabled = false,
      forcePopupIcon = 'auto', // if freeInput it will not show, but setting this to true will force that. otherwise if false it will not show
      clearableOnEmptyValue = false,

      loading = false, // indicate options are loading
      loadingText = 'Loading...', // displayed in list when loading = true and options is empty arrray
      noOptionsText = 'No options', // displayed in list when filterOptions yields nothing
      loadingIndicator = <ActivityIndicator />, // loading indicator element,
      popupIcon = <CaretDownIcon />, // popupIcon element (open/close menu),
      clearIcon = <ClearIcon />, // clear text element,
      onPress, // dont pass
      onKeyPress,
      onOpen,
      onClose,
      styles,
      id: propsId = props.nativeID,
      ...rest
    } = props;

    const id = useId(propsId, 'zuiAutocomplete');
    const options = useMemoCompare(optionsProp);
    const filterOptions = useEventCallback((...args) => {
      if (filterOptionsProp) {
        return filterOptionsProp(...args);
      } else {
        return defaultFilterOptions(...args);
      }
    });
    const isOptionEqualToValue = useEventCallback((option, value) => {
      if (isOptionEqualToValueProp) {
        return isOptionEqualToValueProp(option, value);
      } else {
        return option === value;
      }
    });
    const getOptionLabel = useEventCallback((option) => {
      if (getOptionLabelProp) {
        return getOptionLabelProp(option);
      }
      return isObject(option) ? (option.label ? option.label : `${option}`) : option;
    });

    const [inputValue, setInputValueState] = useControlledState({ controlledValue: inputValueControlled, defaultValue: '' });
    const [value, setValueState] = useControlledState({
      controlledValue: valueControlled,
      defaultValue: defaultValue !== undefined ? defaultValue : multiple ? [] : null,
    });
    const [inputHovered, setInputHovered] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);
    const [listOpen, setListOpen] = useState(false);
    const [inputPristine, setInputPristine] = useState(true);
    const inputRef = useRef();
    const textBoxRef = useRef();
    const menuRef = useRef();
    const defaultHighlightedIndex = autoHighlight ? 0 : -1;
    const highlightedIndex = useRef(defaultHighlightedIndex);
    const ignoreFocus = useRef(false);
    const firstFocus = useRef(true);
    const isActive = inputFocused || inputHovered || listOpen;
    const dirty =
      (freeInput && inputValue.length > 0) || (clearableOnEmptyValue && inputValue.length > 0) || (multiple ? value.length > 0 : value !== null);
    const includeClearIcon = !disableClear && !disabled && dirty && isActive;
    const includePopupIcon = (!freeInput || forcePopupIcon === true) && forcePopupIcon !== false;

    const inputValueIsSelectedValue = !multiple && value !== null && inputValue === getOptionLabel(value);

    const filteredOptions = useMemo(() => {
      if (!listOpen || !options.length) {
        return [];
      }
      return filterOptions(
        options.filter((option) => {
          if (filterSelectedOptions && (multiple ? value : [value]).some((value2) => value2 !== null && isOptionEqualToValue(option, value2))) {
            return false;
          }
          return true;
        }),
        {
          inputValue: inputValueIsSelectedValue && inputPristine ? '' : inputValue,
          getOptionLabel,
        }
      );
    }, [
      listOpen,
      options,
      inputValue,
      filterSelectedOptions,
      multiple,
      value,
      isOptionEqualToValue,
      inputValueIsSelectedValue,
      inputPristine,
      getOptionLabel,
      filterOptions,
    ]);

    const scrollRef = useRef();
    const didScrollToOption = useRef(false);
    const optionRefs = useRefArray(filteredOptions);

    /* -- handle highlight options */
    const validOptionIndex = (index, direction) => {
      if (index === -1 || !optionRefs.length) {
        return -1;
      }

      let nextFocus = index;

      while (true) {
        if ((direction === 'next' && nextFocus === filteredOptions.length) || (direction === 'previous' && nextFocus === -1) || nextFocus < 0) {
          return -1;
        }
        const option = nextFocus === -1 ? null : optionRefs[nextFocus].current;

        if (!option || !option.focusable || option.disabled) {
          nextFocus += direction === 'next' ? 1 : -1;
        } else {
          return nextFocus;
        }
      }
    };

    const scrollToOption = useEventCallback((option) => {
      if (scrollRef.current && option) {
        let scrollY = 0;
        let scrollHeight = 0;
        let clientHeight = 0;
        if (scrollRef.current.scrollEventManager.latest.onScroll) {
          scrollY = scrollRef.current.scrollEventManager.latest.onScroll.nativeEvent.contentOffset.y;
          scrollHeight = scrollRef.current.scrollEventManager.latest.onScroll.nativeEvent.contentSize.height;
          clientHeight = scrollRef.current.scrollEventManager.latest.onScroll.nativeEvent.layoutMeasurement.height;
        } else if (scrollRef.current.scrollEventManager.latest.onLayout) {
          clientHeight = scrollRef.current.scrollEventManager.latest.onLayout.nativeEvent.layout.height;
        }

        if (option.measure) {
          option.measure((x, y, width, height, pageX, pageY) => {
            if (!scrollHeight) {
              scrollHeight = height * filteredOptions.length;
            }
            if (scrollHeight > clientHeight) {
              const scrollBottom = clientHeight + scrollY;
              const elementBottom = y + height;
              if (elementBottom > scrollBottom) {
                scrollRef.current.scrollTo({ y: elementBottom - clientHeight, animated: false });
              } else if (y < scrollY) {
                scrollRef.current.scrollTo({ y, animated: false });
              }
            }
          });
        }
        return true;
      }
      return false;
    });

    const prevHighlightedOption = useRef(null);
    const setHighlightedIndex = useEventCallback((index, skipScroll = false) => {
      highlightedIndex.current = index;
      if (index === -1) {
        // remove activeDescendent=prevHighlightedOptionId from input for accessibility
      } else {
        // set activeDescendent accessibility prop for the input to be the id of this option
      }

      if (onHighlightChange) {
        onHighlightChange(index === -1 ? null : filteredOptions[index]);
      }

      const prevIndex =
        prevHighlightedOption.current && filteredOptions.length
          ? filteredOptions.findIndex((o) => isOptionEqualToValue(o, prevHighlightedOption.current))
          : -1;
      const prev = prevIndex > -1 && prevIndex < optionRefs.length ? optionRefs[prevIndex].current : null;
      if (prev && prev.hasVisibleFocus && prev.focusVisible) {
        prev.focusVisible(false);
      }
      if (index === -1 || index > filteredOptions.length - 1) {
        prevHighlightedOption.current = null;
      } else {
        prevHighlightedOption.current = filteredOptions[index];
      }

      const option = index > -1 && index < optionRefs.length ? optionRefs[index].current : null;

      if (!option) {
        return;
      }

      if (option.focusVisible) {
        option.focusVisible(true);
      }

      didScrollToOption.current = skipScroll ? true : scrollToOption(option);
    });

    const changeHighlightedIndex = useEventCallback((diff, direction) => {
      if (!listOpen) {
        return;
      }

      const maxIndex = filteredOptions.length - 1;
      let newIndex = null;
      if (diff === 'reset') {
        newIndex = defaultHighlightedIndex;
      } else if (diff === 'start') {
        newIndex = 0;
      } else if (diff === 'end') {
        newIndex = maxIndex;
      } else {
        newIndex = highlightedIndex.current + diff;
        if (newIndex < 0) {
          if (newIndex === -1 && includeInputInList) {
            newIndex = -1;
          } else if ((disableListWrap && highlightedIndex.current !== -1) || Math.abs(diff) > 1) {
            newIndex = 0;
          } else {
            newIndex = maxIndex;
          }
        } else if (newIndex > maxIndex) {
          if (newIndex === maxIndex + 1 && includeInputInList) {
            newIndex = -1;
          } else if (disableListWrap || Math.abs(diff) > 1) {
            newIndex = maxIndex;
          } else {
            newIndex = 0;
          }
        }
      }

      newIndex = validOptionIndex(newIndex, direction);

      setHighlightedIndex(newIndex);

      if (autoComplete && diff !== 'reset' && inputRef.current) {
        if (newIndex === -1) {
          inputRef.current.value = inputValue;
        } else {
          const option = getOptionLabel(filteredOptions[newIndex]);
          inputRef.current.value = option;
          const index = option.toLowerCase().indexOf(inputValue.toLowerCase());
          if (inputRef.current && index === 0 && inputValue.length > 0) {
            inputRef.current.setSelectionRange(inputValue.length, option.length);
          }
        }
      }
      // here update the input's value if autoComplete = true and diff !== 'reset'
      // TODO ^^
    });

    const syncHighlightedIndex = useCallback(() => {
      if (!listOpen) {
        didScrollToOption.current = false;
        return;
      }
      const valueItem = multiple ? value[0] : value;
      if (filteredOptions.length === 0 || valueItem == null) {
        changeHighlightedIndex('reset');
        return;
      }

      if (!scrollRef.current) {
        return;
      }

      if (valueItem != null) {
        const currIndex = highlightedIndex.current;
        const currentOption = filteredOptions[currIndex];
        if (multiple && currentOption && value.findIndex((v) => isOptionEqualToValue(currentOption, v)) !== -1) {
          if (!didScrollToOption.current) {
            const option = currIndex < optionRefs.length ? optionRefs[currIndex].current : null;
            if (option) {
              didScrollToOption.current = scrollToOption(option);
            }
          }
          return;
        }

        const itemIndex = filteredOptions.findIndex((o) => isOptionEqualToValue(o, valueItem));
        if (itemIndex === -1) {
          changeHighlightedIndex('reset');
        } else {
          setHighlightedIndex(itemIndex);
        }
        return;
      }

      if (highlightedIndex.current >= filteredOptions.length - 1) {
        setHighlightedIndex(filteredOptions.length - 1);
        return;
      }

      setHighlightedIndex(highlightedIndex.current);
    }, [changeHighlightedIndex, setHighlightedIndex, listOpen, multiple, filteredOptions, isOptionEqualToValue, optionRefs, scrollToOption, value]);

    const handleScrollLayout = useEventCallback((e) => {
      syncHighlightedIndex();
    });

    useEffect(() => {
      syncHighlightedIndex();
    }, [syncHighlightedIndex]);
    /* --------------- */

    const handleChangeValue = (newValue) => {
      if (value === newValue) {
        return;
      }
      setValueState(newValue);
      if (onChangeValue) {
        onChangeValue(newValue);
      }
    };

    const handleOpen = (e, skipFocus = false) => {
      if (listOpen) {
        return;
      }
      setListOpen(true);
      setInputPristine(true);
      if (!skipFocus && !inputFocused && inputRef.current) {
        inputRef.current.focus();
        if (selectOnFocus && firstFocus.current && inputRef.current.selectionEnd - inputRef.current.selectionStart === 0) {
          inputRef.current.select();
        }
      }
      if (onOpen) {
        onOpen(e);
      }
    };

    const handleClose = useEventCallback((e) => {
      if (!listOpen) {
        return;
      }

      setListOpen(false);

      if (onClose) {
        onClose(e);
      }
    });

    const togglePopup = useEventCallback((e) => {
      if (listOpen) {
        handleClose(e);
      } else {
        handleOpen(e);
      }
    });

    const handleClear = useEventCallback(() => {
      ignoreFocus.current = true;
      setInputValueState('');

      if (onInputChangeValue) {
        onInputChangeValue('');
      }

      handleChangeValue(multiple ? [] : null);

      if (!inputFocused && inputRef.current) {
        inputRef.current.focus();
      }
    });

    const resetInputValue = useEventCallback((newValue) => {
      const isOptionSelected = multiple ? value.length < newValue.length : newValue !== null;
      if (!isOptionSelected && !clearOnBlur) {
        return;
      }
      let newInputValue;
      if (multiple) {
        newInputValue = '';
      } else if (newValue == null) {
        newInputValue = '';
      } else {
        const optionLabel = getOptionLabel(newValue);
        newInputValue = typeof optionLabel === 'string' ? optionLabel : typeof optionLabel === 'number' ? `${optionLabel}` : '';
      }
      if (inputValue === newInputValue) {
        return;
      }

      setInputValueState(newInputValue);

      if (onInputChangeValue) {
        onInputChangeValue(newInputValue);
      }
    });

    const prevValue = useRef();

    useEffect(() => {
      const valueChanged = value !== prevValue.current;
      prevValue.current = value;
      if (inputFocused && !valueChanged) {
        return;
      }
      if (freeInput && !valueChanged) {
        return;
      }
      resetInputValue(value);
    }, [value, options, resetInputValue, inputFocused, freeInput]);

    const selectNewValue = useEventCallback((option, reason) => {
      let newValue = option;
      if (multiple) {
        newValue = Array.isArray(value) ? value.slice() : [];
        const itemIndex = newValue.findIndex((val) => isOptionEqualToValue(option, val));
        if (itemIndex === -1) {
          newValue.push(option);
        } else if (reason !== 'inputBlur' && reason !== 'submitInput') {
          newValue.splice(itemIndex, 1);
        }
      }

      handleChangeValue(newValue);
      resetInputValue(newValue);

      if (freeInput && reason === 'submitInput') {
        if (!disableCloseOnSubmit) {
          handleClose();
        }
      } else if (!disableCloseOnSelect) {
        handleClose();
      }

      if (!(freeInput && reason === 'submitInput') && blurOnSelect && inputRef.current) {
        inputRef.current.blur();
      }
    });

    const handleOptionOnPress = useEventCallback((option, e) => {
      if (onSelectOption) {
        onSelectOption(option);
      }
      selectNewValue(option, 'selectOption');
    });

    const handleInputChangeValue = useEventCallback((newValue) => {
      if (inputValue !== newValue) {
        setInputValueState(newValue);
        setInputPristine(false);
        if (onInputChangeValue) {
          onInputChangeValue(newValue);
        }
      }
      if (newValue === '') {
        if (!disableClear && !multiple) {
          handleChangeValue(null);
        }
      } else {
        handleOpen();
      }
    });

    const handleInputFocus = useEventCallback(() => {
      setInputFocused(true);
      if (openOnFocus && !ignoreFocus.current) {
        handleOpen();
      }
    });

    const handleInputHoverChange = useEventCallback((hovered) => {
      setInputHovered(hovered);
    });

    const handleInputOnPress = useEventCallback(() => {
      if (inputValue === '' || !listOpen) {
        togglePopup();
      }
      if (inputRef.current) {
        inputRef.current.focus();
        if (selectOnFocus && firstFocus.current && inputRef.current.selectionEnd - inputRef.current.selectionStart === 0) {
          inputRef.current.select();
        }
      }
      firstFocus.current = false;
    });

    const endAdornment = useMemo(() => {
      if (includeClearIcon || includePopupIcon || loading) {
        return (
          <Box {...styles.toProps('controls')}>
            {!disableClear && includePopupIcon ? (
              <Box onPress={includeClearIcon && !disabled ? handleClear : null} {...styles.toProps('iconButton')}>
                {includeClearIcon && clearIcon}
              </Box>
            ) : null}
            {loading ? (
              <Box onPress={!disabled && togglePopup} {...styles.toProps('iconButton')}>
                {loadingIndicator}
              </Box>
            ) : includePopupIcon ? (
              <Box onPress={!disabled && togglePopup} {...styles.toProps({ iconButton: true, popupButtonOpened: listOpen })}>
                {popupIcon}
              </Box>
            ) : null}
            {!disableClear && !includePopupIcon ? (
              <Box onPress={includeClearIcon && !disabled ? handleClear : null} {...styles.toProps('iconButton')}>
                {includeClearIcon && clearIcon}
              </Box>
            ) : null}
          </Box>
        );
      }
      return null;
    }, [
      includeClearIcon,
      includePopupIcon,
      disableClear,
      listOpen,
      disabled,
      handleClear,
      clearIcon,
      loading,
      togglePopup,
      loadingIndicator,
      popupIcon,
      styles,
    ]);

    const inputStyles = useMemo(() => {
      // TODO just omit this based on a list of known styles not to be passed to TextInput for Autocomplete (to do as well)
      const { root, listBox, option, controls, iconButton, popupButtonOpened, props: stylesProps, ...otherStyles } = styles;
      const { root: rootProps, listBox: lb, option: op, controls: cp, iconButton: ib, popupButtonOpened: pbo, ...otherProps } = stylesProps;
      return {
        ...otherStyles,
        props: otherProps,
      };
    }, [styles]);

    const { onLayout, width: inputWidth } = useLayout();

    const focusInput = useEventCallback((force = false) => {
      if ((!inputFocused || force === true) && inputRef.current) {
        inputRef.current.focus();
      }
    });

    const blurInput = useEventCallback(() => {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    });

    const { items, focusedItem, handleKeyPress } = useInputListItems(
      !multiple
        ? EMPTY_PROPS
        : {
            value,
            inputValue,
            inputFocused,
            disabled,
            focusInput,
            blurInput,
            onChangeValue: handleChangeValue,
            onChangeInputValue: handleInputChangeValue,
            getItemLabel: getItemLabel ? getItemLabel : getOptionLabel,
            renderItem,
            vertical,
            onItemPress: (e, item) => {
              const { index = null } = item || {};
              if (onItemPress) {
                onItemPress(e, item);
              }
              if (inputFocused) {
                ignoreFocus.current = true;
              } else if (!inputFocused && index !== null && focusedItem !== index) {
                focusInput();
              }
            },
            onItemDelete: (...args) => {
              if (onItemDelete) {
                onItemDelete(...args);
              }
              if (inputFocused) {
                ignoreFocus.current = true;
              }
            },
            focusedItem: focusedItemControlled,
            onChangeFocusedItem,
            itemProps,
            onAddItem: (item) => {
              if (onAddItem) {
                onAddItem(item);
              }
              selectNewValue(item.value, 'submitInput');
              return true; // cancels useInputListItems handler by returning true
            },
            onRemoveItem,
            onUpdateItem,
            onItemKeyDown,
            onItemBlur,
            onItemFocus,
          }
    );

    const handleInputBlur = useEventCallback((e) => {
      if (Platform.OS === 'web' && !disabled && focusedItem === -1) {
        let shouldFocus = false;
        if (
          menuRef.current &&
          (menuRef.current.parentElement.contains(document.activeElement) ||
            (e && e.relatedTarget && menuRef.current.parentElement.contains(e.relatedTarget)))
        ) {
          shouldFocus = true;
        } else if (
          textBoxRef.current &&
          (textBoxRef.current.parentElement.contains(document.activeElement) ||
            (e && e.relatedTarget && textBoxRef.current.parentElement.contains(e.relatedTarget)))
        ) {
          shouldFocus = true;
        }
        if (shouldFocus) {
          if (inputRef.current) {
            inputRef.current.focus();
          }
          return;
        }
      }
      setInputFocused(false);
      firstFocus.current = true;
      ignoreFocus.current = false;

      if (autoSelect && highlightedIndex.current !== -1 && listOpen) {
        selectNewValue(filteredOptions[highlightedIndex.current], 'inputBlur');
      } else if (autoSelect && freeInput && inputValue !== '') {
        selectNewValue(inputValue, 'inputBlur');
      } else if (clearOnBlur) {
        resetInputValue(value);
      }

      handleClose();
    });

    const handleKeyEvents = useEventCallback((e) => {
      if (e && e.key) {
        if (!e.nativeEvent) {
          e.nativeEvent = { key: e.key };
        } else if (!e.nativeEvent.key) {
          e.nativeEvent.key = e.key;
        }
      }
      if (onKeyPress) {
        onKeyPress(e);
      }
      if (!e || !e.nativeEvent || !e.nativeEvent.key || e.preventHandlerDefault) {
        return;
      }

      if (multiple) {
        const canHandleArrowUp =
          !vertical ||
          e.nativeEvent.key !== 'ArrowUp' ||
          (inputValue === '' && (!listOpen || highlightedIndex.current === -1 || (highlightedIndex.current === 0 && disableListWrap)));
        const canHandleArrowDown = !vertical || e.nativeEvent.key !== 'ArrowDown' || (inputValue === '' && focusedItem !== -1);
        const canHandleEnter = e.nativeEvent.key !== 'Enter' || (freeInput && highlightedIndex.current === -1);
        if (canHandleArrowUp && canHandleArrowDown && canHandleEnter) {
          const handledByInputList = handleKeyPress(e);
          if (handledByInputList) {
            return;
          }
        }
      }
      switch (e.nativeEvent.key) {
        case 'Home':
          if (listOpen) {
            if (e.preventDefault) {
              e.preventDefault();
            }
            changeHighlightedIndex('start', 'next');
          }
          break;
        case 'End':
          if (listOpen) {
            if (e.preventDefault) {
              e.preventDefault();
            }
            changeHighlightedIndex('end', 'previous');
          }
          break;
        case 'PageUp':
          if (e.preventDefault) {
            e.preventDefault();
          }
          changeHighlightedIndex(pageSize * -1, 'previous');
          break;
        case 'PageDown':
          if (e.preventDefault) {
            e.preventDefault();
          }
          changeHighlightedIndex(pageSize, 'next');
          break;
        case 'ArrowDown':
          if (e.preventDefault) {
            e.preventDefault();
          }
          changeHighlightedIndex(1, 'next');
          handleOpen(e, true);
          break;
        case 'ArrowUp':
          if (e.preventDefault) {
            e.preventDefault();
          }
          changeHighlightedIndex(-1, 'previous');
          handleOpen(e, true);
          break;
        case 'Enter':
          if (highlightedIndex.current !== -1 && listOpen) {
            const option = filteredOptions[highlightedIndex.current];
            const disabled = getOptionDisabled ? getOptionDisabled(option) : false;

            if (e.preventDefault) {
              e.preventDefault();
            }

            if (disabled) {
              return;
            }
            selectNewValue(option, 'highlight');
            if (autoComplete && inputRef.current) {
              inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
            }
          }
          break;
        case 'Escape':
          if (listOpen) {
            if (e.preventDefault) {
              e.preventDefault();
            }
            if (e.stopPropagation) {
              e.stopPropagation();
            }
            handleClose(e);
          } else if (clearOnEscape && (inputValue !== '' || (multiple && value.length > 0))) {
            if (e.preventDefault) {
              e.preventDefault();
            }
            if (e.stopPropagation) {
              e.stopPropagation();
            }
            handleClear();
          }
          break;
        case 'Tab':
          if (listOpen) {
            if (e.preventDefault) {
              e.preventDefault();
            }
            if (e.stopPropagation) {
              e.stopPropagation();
            }
            handleClose(e);
          }
          break;
        default:
      }
    });

    const inputProps = {
      value: inputValue,
      onChangeValue: handleInputChangeValue,
      onBlur: handleInputBlur,
      onFocus: handleInputFocus,
      onHoverChange: handleInputHoverChange,
      inputRef,
      textBoxRef,
      disabled,
      styles: inputStyles,
      startAdornment: items,
      endAdornment,
      onPress: handleInputOnPress,
      onLayout,
      onKeyPress: handleKeyEvents,
      multiline: false,
      filled: multiple ? value.length > 0 || inputValue !== '' : undefined,
      blurOnSubmit,
    };

    const getOptionProps = ({ index, option }) => {
      const selected = (multiple ? value : [value]).some((value2) => value2 !== null && isOptionEqualToValue(option, value2));
      const optionDisabled = getOptionDisabled ? getOptionDisabled(option) : false;
      const optionStyles = styles.toProps('option');

      return {
        key: getOptionLabel(option),
        selected,
        disabled: optionDisabled,
        hovered: false,
        id: `${id}-opt-${index}`,
        role: 'option',
        focusable: true,
        onPress: (e) => handleOptionOnPress(option, e),
        onHoverIn: (e) => {
          setHighlightedIndex(index, true);
        },
        ref: optionRefs[index],
        ...optionStyles,
      };
    };
    const renderOption = renderOptionProp
      ? renderOptionProp
      : (optionProps, option) => <MenuItem {...optionProps}>{getOptionLabel(option)}</MenuItem>;
    const renderListOption = (option, index) => {
      const optionProps = getOptionProps({ index, option });
      return renderOption(optionProps, option, { selected: optionProps.selected, inputValue });
    };
    const listItems =
      filteredOptions.length > 0
        ? filteredOptions.map((option, index) => {
            return renderListOption(option, index);
          })
        : null;

    if (disabled && inputFocused) {
      handleInputBlur();
    }

    return (
      <>
        <Box
          ref={ref}
          id={id}
          accessibility={{ accessibilityRole: 'combobox' }} // TODO: why isnt this showing up on the div as role="combobox"?
          {...rest}
        >
          {renderInput(inputProps)}
        </Box>
        <Menu
          ref={menuRef}
          disableTrapFocus
          disableScrollLock
          allowPositionOffscreen
          disablePortal
          hideBackdrop
          renderInline
          open={listOpen}
          ScrollProps={{ ref: scrollRef, onLayout: handleScrollLayout }}
          anchorNode={textBoxRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          anchorOffset={{ vertical: -4, horizontal: 0 }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleClose}
          MenuListProps={inputWidth && listOpen ? { width: inputWidth, maxWidth: inputWidth, role: 'listbox' } : { role: 'listbox' }}
          {...styles.toProps('listBox')}
          autoFocus={false}
          disableAutoFocusItem
          disableKeyboardFocusing
        >
          {loading && !filteredOptions.length ? (
            <MenuItem opacity={0.7} role="" {...styles.toProps('option')} onPress={() => null}>
              {loadingText}
            </MenuItem>
          ) : !filteredOptions.length && !freeInput ? (
            <MenuItem opacity={0.7} role="" {...styles.toProps('option')} onPress={() => null}>
              {noOptionsText}
            </MenuItem>
          ) : filteredOptions.length ? (
            listItems
          ) : null}
        </Menu>
      </>
    );
  })
);

const EMPTY_PROPS = {};

const CaretDownIcon = ({ fill, color = '$gray.400', ...props }) => {
  return (
    <Svg fill={color} color={color} size="24" viewBox="0 0 24 24" {...props}>
      <Svg.Path d="M 7 10 L 12 15 L 17 10 Z" />
    </Svg>
  );
};

const ClearIcon = ({ fill, color = '$gray.400', ...props }) => {
  return (
    <Svg fill={color} color={color} size="18" viewBox="0 0 24 24" {...props}>
      <Svg.Path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
    </Svg>
  );
};

Autocomplete.PopupIcon = CaretDownIcon;
Autocomplete.ClearIcon = ClearIcon;

export { Autocomplete };
