import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import { ActivityIndicator, Box, Text } from '../../ui';
import { useQuoteExpiration } from '../../hooks';
import { Page, NavBar, NavRow, NavBackButton, NavGroup, AppBarSpacer, ErrorBoundary } from '../../components';
import moment from 'moment-timezone';
import { ProvideQuoteData, useQuoteData } from './context/QuoteDataContext';
import { ProvideQuotePageContext } from './context/QuotePageContext';
import { QuoteView } from './content/QuoteView';
import { QuoteActions } from './content/QuoteView/QuoteActions';
import { useAdjustmentState } from './context/QuoteAdjustment/AdjustmentContext';
import { Prompt } from 'react-router-dom';
import { QuoteNav } from './content/QuoteView/QuoteNav';
import { QuoteTabs } from './content/QuoteView/QuoteTabs';

export function QuotePage() {
  const printRef = useRef();

  const { id } = useParams();

  const renderLoading = useCallback(() => <ActivityIndicator />, []);
  const renderEmpty = useCallback(() => <Text>Error getting quote</Text>, []);

  return (
    <Page padTop={({ theme }) => theme.breakpoints({ xs: 0, sm: theme.sizes.appBarHeight })}>
      <ErrorBoundary>
        <ProvideQuoteData id={id} renderLoading={renderLoading} renderEmpty={renderEmpty}>
          <ProvideQuotePageContext>
            <QuotePageContent printRef={printRef} />
          </ProvideQuotePageContext>
        </ProvideQuoteData>
      </ErrorBoundary>
    </Page>
  );
}

const QuotePageContent = memo(function QuotePageContent({ printRef }) {
  const { data, productCoverages, macavity, concentrations, update, updating, lastUpdated } = useQuoteData();
  return (
    <>
      <Nav data={data} update={update} updating={updating} lastUpdated={lastUpdated} printRef={printRef} />
      <Box>
        <QuoteView
          data={data}
          update={update}
          updating={updating}
          lastUpdated={lastUpdated}
          productCoverages={productCoverages}
          macavity={macavity}
          concentrations={concentrations}
          printRef={printRef}
        />
        <QuoteTabs
          data={data}
          productCoverages={productCoverages}
          concentrations={concentrations}
          macavity={macavity}
          update={update}
          updating={updating}
          lastUpdated={lastUpdated}
        />
        {/* <BindFormsView data={data} update={update} updating={updating} lastUpdated={lastUpdated} /> */}
      </Box>
      <RenderExitPromptIfEditing />
    </>
  );
});

const Nav = memo(function Nav({ data, updating, update, lastUpdated, printRef }) {
  const historyDisplayText = useMemo(() => {
    if (data) {
      // const lastTimestamp = moment().subtract(339, 'days');
      const lastTimestamp = data.lastChangeAt ? moment(data.lastChangeAt) : data.createdAt ? moment(data.createdAt) : null;
      if (lastTimestamp) {
        const now = moment();
        const dayDiff = now.diff(lastTimestamp, 'days');
        if (dayDiff < 2) {
          const hourDiff = now.diff(lastTimestamp, 'hours');
          if (hourDiff < 1) {
            const minuteDiff = now.diff(lastTimestamp, 'minutes');
            if (minuteDiff <= 1) {
              return `Updated < 1 minute ago`;
            }
            return `Updated ${minuteDiff} minutes ago`;
          }
          return `Updated ${hourDiff} hour${hourDiff > 1 ? 's' : ''} ago`;
        } else if (dayDiff >= 9) {
          const monthDiff = now.diff(lastTimestamp, 'months');
          if (monthDiff >= 6) {
            return `Last updated ${lastTimestamp.format('M-D-YYYY')}`;
          }
          return `Last updated ${lastTimestamp.format('MMM Do')}`;
        }
        return `Updated ${now.diff(lastTimestamp, 'days')} days ago`;
      }
    }

    return 'Update History';
  }, [data]);

  const expiration = useQuoteExpiration(data ? data : {});

  return (
    <NavBar padX={({ theme }) => theme.breakpoints({ sm: '$0', md: 0 })} padTop="$1">
      <AppBarSpacer />
      <NavRow xsHeight={48} padX="$5" borderBottomWidth={1} borderBottomColor="#DFE3E8" width={'100%'}>
        <NavGroup start>
          <NavBackButton
            path="/quotes"
            color="$gray.300"
            label={
              <Text>
                Quotes /<Text color="$gray.700">{` # ${data?.quoteNumber}`}</Text>
              </Text>
            }
          />
        </NavGroup>
        <NavGroup end>
          <Text xSmall bold uppercase color="$gray.400">
            {expiration.text}
          </Text>
        </NavGroup>
      </NavRow>
      {data ? (
        <Box padY="$3" xsHeight={64} bg="#F9FAFB">
          <NavRow alignSelf={'flex-start'} alignItems={'flex-start'} padX="$5">
            <NavGroup start alignSelf={'flex-start'} alignItems={'flex-start'}>
              <QuoteNav data={data} expiration={expiration.text} historyDisplayText={historyDisplayText} />
            </NavGroup>
            <NavGroup end alignSelf={'flex-start'} alignItems={'flex-start'}>
              <QuoteActions quote={data} refreshQuoteData={update} printRef={printRef} meow />
            </NavGroup>
          </NavRow>
        </Box>
      ) : null}
    </NavBar>
  );
});

const RenderExitPromptIfEditing = memo(function RenderExitPromptIfEditing() {
  const { adjusting, disable } = useAdjustmentState();

  useEffect(() => {
    return () => {
      if (adjusting) {
        disable();
      }
    };
  }, [adjusting, disable]);

  return <Prompt when={adjusting} message="If you leave all your edits will be lost" />;
});
