import { getStyles, getStyle } from '../../styling';
import { isNull, isObject } from '../../utils';

const ListItemTypes = {
  ITEM: 'item',
  HEADER: 'header',
  FOOTER: 'footer',
  SECTION_HEADER: 'section_header',
  SECTION_FOOTER: 'section_footer',
  BETWEEN: 'between',
  SPACER: 'spacer',
  EMPTY: 'empty',
  LOADING: 'loading',
};

function getNumberFromStyle(val) {
  if (val && !isNaN(parseFloat(val) && isFinite(val))) {
    return val;
  }
  return 0;
}

function getRenderInsets(styles, renderInsets = null) {
  let top = 0;
  let bottom = 0;
  if (!isNull(renderInsets) && isObject(renderInsets)) {
    top = getNumberFromStyle(renderInsets.top);
    bottom = getNumberFromStyle(renderInsets.bottom);
  } else if (styles) {
    const s = getStyles(styles);
    if (s && s.renderInsets) {
      return getRenderInsets(null, s.renderInsets);
    }
  }
  return { top, bottom };
}

function getPaddingInsets(rootStyle) {
  const { paddingTop, paddingBottom, paddingLeft, paddingRight } = getStyle(rootStyle);
  return {
    top: getNumberFromStyle(paddingTop),
    bottom: getNumberFromStyle(paddingBottom),
    left: getNumberFromStyle(paddingLeft),
    right: getNumberFromStyle(paddingRight),
  };
}

export { ListItemTypes, getNumberFromStyle, getRenderInsets, getPaddingInsets };
