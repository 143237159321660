import React from 'react';
import { Box } from '../Box';
import { Image } from '../Image';

const defaultPlaceholderImage = require('./placeholder_pattern.png');

export const ListSpacerItem = React.memo((props) => {
  const { height, renderPlaceholder, placeholderStyle, placeholderImage = -1, listStyles, ...rest } = props;
  return (
    <Box width="100%" height={height} disableAnimate {...rest}>
      {renderPlaceholder && typeof renderPlaceholder === 'function' ? (
        renderPlaceholder({
          height,
          image: placeholderImage,
          style: placeholderStyle,
          styles: listStyles,
        })
      ) : (
        <Image
          pointerEvents="none"
          src={placeholderImage !== -1 ? placeholderImage : defaultPlaceholderImage}
          resizeMode="repeat"
          sx={{
            position: 'absolute',
            overflow: 'visible',
            backfaceVisibility: 'visible',
            flex: 1,
            height: '100%',
            width: '100%',
          }}
          disableAnimate
          style={placeholderStyle}
        />
      )}
    </Box>
  );
});
