import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useNavigate } from './useNavigate';
import { useEventCallback } from '../ui/hooks';
import { isNull } from '../ui/utils';
import { getBooleanFromQueryValue } from '../utils/searchApi/queryUtils';

export function useQuery() {
  const search = useLocation().search;
  return useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
}

export const QUERY_ARRAY_COMMA = ',|Rc';

export function useQueryParam(param, defaultValue = '', valueType) {
  const query = useQuery();

  let value = param && query.has(param) ? query.get(param) : '';
  if ((typeof value !== 'string' && typeof value !== 'boolean' && typeof value !== 'number') || isNull(value) || value === '') {
    value = defaultValue;
  } else if (valueType) {
    switch (valueType) {
      case 'string':
        if (typeof value !== 'string') {
          value = `${value}`;
        }
        break;
      case 'boolean':
        value = getBooleanFromQueryValue(value);
        break;
      case 'number':
        if (typeof value !== 'number') {
          value = Number(value);
        }
        break;
      default:
        break;
    }
  }

  const navigate = useNavigate();

  const get = useEventCallback(
    (val, p) => {
      let result = '';
      const queryParam = p ? p : param;
      if ((typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean') && !isNull(val) && val !== '') {
        result = val;
        query.set(queryParam, val);
      } else if (Array.isArray(val) && val.length) {
        for (let i = 0; i < val.length; i += 1) {
          const v = val[i];
          if (!isNull(v) && v !== '' && (typeof v === 'string' || typeof v === 'number' || typeof v === 'boolean')) {
            if (i === 0) {
              result = `${result}${v}`;
            } else {
              result = `${result}${QUERY_ARRAY_COMMA}${v}`;
            }
          }
        }
        if (result) {
          query.set(queryParam, result);
        } else {
          query.delete(queryParam);
        }
      } else {
        query.delete(queryParam);
      }
      const queryString = query.toString();
      const navigateTo = (replace) => navigate.search(queryString, replace);

      return { query, queryString, navigateTo, value: result, param: queryParam };
    },
    [query, param, navigate]
  );

  const set = useEventCallback(
    (val, p, replace = true) => {
      const { navigateTo } = get(val, p);
      navigateTo(replace);
    },
    [get]
  );

  return [value, set, get];
}
