import { isNull, isObject } from '../../ui/utils';
import { MIN_MAX_RANGE, DATES_FROM_TO, SELECT, MULTI_SELECT, SWITCH, CHECKBOX } from '../../constants/searchData/filterTypes';
import { getBooleanFromQueryValue, getRangeFromQueryString } from './queryUtils';
import moment from 'moment-timezone';

export function getSelectValueOption(value, fieldProp) {
  if (isNull(value)) {
    return null;
  }
  if (fieldProp && isObject(fieldProp.options)) {
    for (const key in fieldProp.options) {
      const option = fieldProp.options[key];
      if (isObject(option) && option.value === value) {
        return option;
      }
    }
  }
  return value;
}

export function getMultiSelectValueOptions(fieldValue, fieldProp) {
  let options = [];
  const strValue = typeof fieldValue === 'string' ? fieldValue.trim() : null;
  if (strValue) {
    const arrValues = strValue.split(',');
    for (const value of arrValues) {
      const option = getSelectValueOption(value, fieldProp);
      if (option !== null) {
        options.push(option);
      }
    }
  }
  return options;
}

export function getSwitchFieldValue(value, fieldProp) {
  if (isNull(value)) {
    return null;
  }
  return getBooleanFromQueryValue(value);
}

// takes decoded array of fields or field object (fields from filter[], search[], or sort{}) from the URL search query
// returns what to actually have the value as for use locally (displaying/editing)
export const createDefaultGetValue = (fieldPropConstant) => {
  return (query, overrideFieldProp) => {
    const fieldProp = overrideFieldProp ? overrideFieldProp : fieldPropConstant;
    if (Array.isArray(query)) {
      const result = query.filter((val) => isObject(val) && val.field === fieldProp.name);
      if (result.length && result[0].value !== 'null') {
        // in case null was encoded into the string
        const value = result[0].value;
        switch (fieldProp.type) {
          case MIN_MAX_RANGE: {
            return getRangeFromQueryString(value);
          }
          case DATES_FROM_TO: {
            const range = getRangeFromQueryString(value);
            const validRange = [null, null];
            if (range[0] !== null && moment(range[0]).isValid()) {
              validRange[0] = range[0];
            }
            if (range[1] !== null && moment(range[1]).isValid()) {
              validRange[1] = range[1];
            }
            return validRange;
          }
          case SELECT: {
            return getSelectValueOption(value, fieldProp);
          }
          case MULTI_SELECT: {
            return getMultiSelectValueOptions(value, fieldProp);
          }
          case SWITCH: {
            return getSwitchFieldValue(value, fieldProp);
          }
          case CHECKBOX: {
            console.warn('dont use checkbox for now');
            return getSwitchFieldValue(value, fieldProp);
          }
          default:
            return value;
        }
      }
    }
    return null;
  };
};
