import React from 'react';
import { CountryListItem } from './CountryListItem';
import { localeUtils } from '../../utils';

export const CountryList = ({ onSelect, itemProps, selected }) => {
  return localeUtils
    .getCountryCodeListWithValidCodes()
    .map((code, index) => (
      <CountryListItem key={`${index}-${code}`} onPress={onSelect} index={index} value={code} selected={code === selected} {...itemProps} />
    ));
};
export const countryListItems = localeUtils
  .getCountryCodeListWithValidCodes()
  .map((code, index) => <CountryListItem key={`${index}-${code}`} index={index} value={code} />);
