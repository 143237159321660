import React from 'react';
import { Platform } from 'react-native';
import { styled, animated } from '../../styling';
import { filterProps } from '../../styling/utils';
import { ViewStylePropTypes } from '../../system/StylePropTypes';
import { isNull } from '../../utils';
import BaseSvg, {
  Circle,
  Ellipse,
  G,
  Text,
  TSpan,
  TextPath,
  Path,
  Polygon,
  Polyline,
  Line,
  Rect,
  Use,
  Image,
  Symbol,
  Defs,
  Marker,
  LinearGradient,
  RadialGradient,
  Stop,
  ClipPath,
  Pattern,
  Mask,
  ForeignObject,
  SvgCssUri,
  SvgXml,
} from 'react-native-svg';

import { FontPropTypes, TransformPropTypes, SvgPropTypes, CSSPropTypes, ColorPropTypes } from './types';
import * as config from './stylingConfig';

const fontFilterProps = Object.keys(FontPropTypes);

// fixes platform variance in rendering for <SVG /> with font style properties
function fixFontProps(props) {
  if (Platform.OS === 'web') {
    return props;
  }
  // remove all styled font props on native for SVG component
  // font props do not pass down to components specifically for <Svg/> unless as a prop of font as an object prop
  const computedProps = filterProps(props, fontFilterProps);

  let hasFontProps = false;
  const font = {};
  for (const key in FontPropTypes) {
    if (!isNull(props[key])) {
      font[key] = props[key];
      hasFontProps = true;
    }
  }

  if (hasFontProps) {
    computedProps.font = font;
  }
  return computedProps;
}

// currently react-native-svg for web does not handle onPress and other touchable events correctly
// TODO: update when fixed or add missing touchEvents (onLongPress, onPressIn, etc...)
function adjustEventProps(props) {
  const { onPress, ...rest } = props;
  if (onPress && Platform.OS === 'web') {
    rest.onClick = onPress;
    return rest;
  }
  return props;
}

const SvgStyleProps = {
  ...SvgPropTypes,
  ...ViewStylePropTypes,
};
// <SVG> has variance between platforms for SVG TransformPropTypes so just ignore them and
// use normal <View> transforms
const svgFilterProps = Object.keys({
  ...filterProps(ViewStylePropTypes, Object.keys(SvgPropTypes)),
  ...SvgStyleProps,
  ...TransformPropTypes,
});

const AnimatedSvg = animated(BaseSvg);
const AnimatedSvgUri = SvgCssUri ? animated(SvgCssUri) : AnimatedSvg;
const AnimatedSvgXml = SvgXml ? animated(SvgXml) : AnimatedSvg;

const Svg = styled(
  React.forwardRef(function Svg(props, ref) {
    const {
      component,
      xml,
      uri,
      focusable = false,
      accessibility,
      onResponderGrant, // not supported
      onResponderRelease, // not supported
      ...rest
    } = props;
    let Component = component;
    if (!component) {
      if (xml) {
        Component = AnimatedSvgXml;
      } else if (uri) {
        Component = AnimatedSvgUri;
      } else {
        Component = AnimatedSvg;
      }
    }
    const computedProps = fixFontProps(rest);
    return <Component ref={ref} tabIndex="-1" {...{ accessible: focusable, ...accessibility }} {...computedProps} />;
  }),
  {
    name: 'Svg',
    filterProps: (props) => adjustEventProps(filterProps(props, svgFilterProps)),
    asProp: SvgPropTypes,
    scales: config.scales,
    objects: {
      fill: config.objects.fill,
      stroke: config.objects.stroke,
      stopColor: config.objects.stopColor,
      font: config.objects.font,
    },
  }
)((props) => {
  const styles = {};
  for (const key in SvgStyleProps) {
    if (props[key] !== undefined) {
      styles[key] = props[key];
    }
  }
  // TODO: move below to postApply in case size is resolved instead of width/height
  if ((styles.width || styles.size) && !styles.minWidth) {
    styles.minWidth = styles.width || styles.size;
  }
  if ((styles.height || styles.size) && !styles.minHeight) {
    styles.minHeight = styles.height || styles.size;
  }
  return styles;
});

const SvgComponentStyleSystemProps = {
  ...FontPropTypes,
  ...CSSPropTypes,
  ...ColorPropTypes,
};
const svgComponentFilterProps = Object.keys(SvgComponentStyleSystemProps);

const sharedConfig = {
  filterProps: (props) => {
    return adjustEventProps(filterProps(props, svgComponentFilterProps));
  },
  asProp: SvgComponentStyleSystemProps,
  ...config,
};

function createStyledSvgComponent(component, name) {
  const AnimatedSvgComponent = animated(component);
  AnimatedSvgComponent.displayName = `Animated(${name})`;

  const StyledSvgComponent = styled(AnimatedSvgComponent, {
    name,
    ...sharedConfig,
  })((props) => {
    const styles = {};
    for (const key in SvgComponentStyleSystemProps) {
      if (props[key] !== undefined) {
        styles[key] = props[key];
      }
    }
    return {
      ...styles,
      props: { tabIndex: '-1' },
    };
  });

  return StyledSvgComponent;
}

const components = {
  Circle,
  Ellipse,
  G,
  Text,
  TSpan,
  TextPath,
  Path,
  Polygon,
  Polyline,
  Line,
  Rect,
  Use,
  Image,
  Symbol,
  Defs,
  Marker,
  LinearGradient,
  RadialGradient,
  Stop,
  ClipPath,
  Pattern,
  Mask,
  ForeignObject,
};

for (const key in components) {
  Svg[key] = createStyledSvgComponent(components[key], key);
}

export { Svg };
