import { TextInput } from './TextInput';
import { withStyles } from '../../styling';
import { backgroundColor, border, padding } from '../../system/props';

const filterProps = [...backgroundColor.filterProps, ...border.filterProps, ...padding.filterProps, 'color'];

export const FilledInput = withStyles(
  ({ color = '$secondary', onColor = '$background', includeLabelSpacing = false, theme, ...props }) => {
    const fillColor = theme.colors.on(onColor);
    const bg = theme.colors.alpha(fillColor, 0.064);
    const borderBottomColor = theme.colors.alpha(fillColor, 0.1);
    const padY = theme.spacing(2);
    let padTop = padY;
    let padBottom = padY;
    if (includeLabelSpacing) {
      padTop = padTop * 1.5;
      padBottom = padBottom * 0.7;
    }
    return {
      root: { props: { onColor } },
      textBox: {
        backgroundColor: bg,
        ...padding({
          padX: '$2',
          padTop,
          padBottom: padBottom,
          ...props,
        }),
        ...border({
          borderTopRadius: 4,
          borderBottomRadius: 1,
          ...props,
          borderBottomColor,
          borderTopWidth: 0,
          borderBottomWidth: 2,
          borderXWidth: 0,
          borderTopColor: 'transparent',
          borderXColor: 'transparent',
        }),
        animations: {
          default: {
            borderBottomColor,
          },
          focused: {
            borderBottomColor: theme.colors.alpha(color, 1),
          },
          hovered: {
            borderBottomColor: theme.colors.alpha(color, 0.5),
          },
          error: {
            borderBottomColor: '$error',
          },
        },
      },
      focused: {
        // padBottom: padBottom,
        // borderBottomWidth: 2,
      },
    };
  },
  { name: 'FilledInput', filterProps, forward: true }
)(TextInput);
