import React, { useMemo } from 'react';
import { Box, TextField, MenuItem, Heading } from '../../../ui';
import { isNull, isObject } from '../../../ui/utils';

// SELECT field should have options obj defined with each option having a name, label, and value
// the value prop should be the selected option object or null.
export const SelectFilterControl = React.forwardRef(function SelectFilterControl(props, ref) {
  const { field, value = null, setQuery, active } = props;
  const optionKeys = useMemo(() => (isObject(field.options) ? Object.keys(field.options) : []), [field]);
  const controlProps = useMemo(() => {
    const { input = {}, option = {} } = field.controls || {};
    return {
      input: {
        helperText: input.helperText ? input.helperText : null,
        placeholder: input.placeholder ? input.placeholder : 'Any',
        ...input,
      },
      option: { ...option },
    };
  }, [field]);
  // if prop value is null and field has defaults.value then find option match to defaults.value
  const selectedOption = useMemo(() => {
    if (value === null && field.defaults && field.defaults.value !== undefined) {
      if (optionKeys.length) {
        for (const key of optionKeys) {
          if (field.options[key].value === field.defaults.value) {
            return field.options[key];
          }
        }
      }
      return field.defaults.value;
    }
    return value;
  }, [field, optionKeys, value]);
  // get array of selectable components using field.options
  const optionElements = useMemo(() => {
    if (optionKeys.length) {
      const elements = [];
      for (let i = 0; i < optionKeys.length; i += 1) {
        const optKey = optionKeys[i];
        const option = field.options[optKey];
        const isObjValue = option !== null && typeof option === 'object';
        elements.push(
          <MenuItem key={optKey} value={option} {...controlProps.option}>
            {isObjValue ? option.label : !isNull(option) ? option : optKey}
          </MenuItem>
        );
      }
      return elements;
    }
    return null;
  }, [field, optionKeys, controlProps]);

  return (
    <Box width="100%">
      {field.label ? (
        <Heading level={6} dim={active ? 0.8 : true}>
          {field.label}
        </Heading>
      ) : null}
      <TextField
        ref={ref}
        type="select"
        value={selectedOption}
        disabled={!optionKeys.length}
        onChangeValue={(value) => {
          if (field.setField && field.options) {
            field.setField(value, setQuery, field);
          }
        }}
        {...controlProps.input}
      >
        {optionElements}
      </TextField>
    </Box>
  );
});

export const MultiSelectFilterControl = React.forwardRef(function MultiSelectFilterControl(props, ref) {
  const { field, value: valueProp = [], setQuery, active } = props;
  const value = useMemo(() => (Array.isArray(valueProp) ? valueProp : []), [valueProp]);

  const [optionKeys, optionValues] = useMemo(() => {
    if (isObject(field.options)) {
      const optionKeys = Object.keys(field.options);
      const optionValues = optionKeys.map((key) => {
        if (isObject(field.options[key])) {
          return field.options[key].value;
        }
        return field.options[key];
      });
      return [optionKeys, optionValues];
    }
    return [[], []];
  }, [field]);
  const controlProps = useMemo(() => {
    const { input = {}, option = {} } = field.controls || {};
    return {
      input: {
        helperText: input.helperText ? input.helperText : null,
        placeholder: input.placeholder ? input.placeholder : 'Any',
        ...input,
      },
      option: { ...option },
    };
  }, [field]);
  // if prop value is null and field has defaults.value then find option match to defaults.value
  const selectedOptions = useMemo(() => {
    if (!value.length && field.defaults && Array.isArray(field.defaults.value)) {
      if (optionKeys.length && field.defaults.value.length) {
        const defaultOptions = [];
        for (const defaultVal of field.defaults.value) {
          const indexOfValue = optionValues.indexOf(defaultVal);
          if (indexOfValue !== -1) {
            const key = optionKeys[indexOfValue];
            defaultOptions.push(field.options[key]);
          }
        }
        return defaultOptions;
      }
      return field.defaults.value;
    }
    return value;
  }, [field, optionKeys, optionValues, value]);
  // get array of selectable components using field.options
  const optionElements = useMemo(() => {
    if (optionKeys.length) {
      const elements = [];
      for (let i = 0; i < optionKeys.length; i += 1) {
        const optKey = optionKeys[i];
        const option = field.options[optKey];
        const isObjValue = option !== null && typeof option === 'object';
        elements.push(
          <MenuItem key={optKey} value={option} {...controlProps.option}>
            {isObjValue ? option.label : !isNull(option) ? option : optKey}
          </MenuItem>
        );
      }
      return elements;
    }
    return null;
  }, [field, optionKeys, controlProps]);

  return (
    <Box width="100%">
      {field.label ? (
        <Heading level={6} dim={active ? 0.8 : true}>
          {field.label}
        </Heading>
      ) : null}
      <TextField
        ref={ref}
        type="select"
        multiple
        value={selectedOptions}
        disabled={!optionKeys.length}
        onChangeValue={(value) => {
          if (field.setField && field.options) {
            field.setField(value, setQuery, field);
          }
        }}
        {...controlProps.input}
      >
        {optionElements}
      </TextField>
    </Box>
  );
});
