import React, { useMemo } from 'react';
import { useMemoCompare } from '../../ui/hooks';
import { getStyle } from '../../ui/styling';
import { isNull } from '../../ui/utils';

const containerContext = React.createContext({
  flexDirection: 'column',
  backgroundColor: 'transparent',
});

function useContainerContext() {
  return React.useContext(containerContext);
}

const ProvideContainerContext = ({ children, style, styleKeys = [], include }) => {
  const keyStyles = ['flexDirection', 'backgroundColor', ...styleKeys];
  const containerStyle = getStyle(style);
  const contextStyles = {};
  for (const key of keyStyles) {
    contextStyles[key] = containerStyle[key];
  }
  const contextStylesMemoed = useMemoCompare(contextStyles);
  const parent = useContainerContext();
  const parentBg = !isNull(parent) && parent.backgroundColor ? parent.backgroundColor : 'transparent';
  const value = useMemo(() => {
    const { flexDirection, backgroundColor } = contextStylesMemoed;
    const fd = flexDirection ? flexDirection : 'column';
    const bg = !isNull(backgroundColor) && backgroundColor !== 'transparent' ? backgroundColor : parentBg;

    return {
      ...contextStylesMemoed,
      flexDirection: fd,
      backgroundColor: bg,
      ...include,
    };
  }, [contextStylesMemoed, include, parentBg]);
  return <containerContext.Provider value={value}>{children}</containerContext.Provider>;
};

const withContainerContext = (Component) => {
  return React.forwardRef(function ComponentWithContainerContext(props, ref) {
    const parentContainer = useContainerContext();
    return <Component ref={ref} parentContainer={parentContainer} {...props} />;
  });
};

export { ProvideContainerContext, useContainerContext, withContainerContext, containerContext };
