export const exportToCsv = (gridReference) => {
  if (gridReference && gridReference.api && !gridReference.api.isDestroyed()) {
    gridReference.api.exportDataAsCsv({
      processCellCallback: (params) => {
        const colDef = params.column.getColDef();
        const value = params.value;

        if (colDef.exportValueFormatter) {
          return colDef.exportValueFormatter(params);
        }

        // If the column type is 'number', clean the value and return as number
        if (colDef.dataType === 'number') {
          if (typeof value === 'string') {
            const cleanedValue = value.replace(/[^0-9.-]/g, '');
            return parseFloat(cleanedValue);
          }
          return typeof value === 'number' ? value : parseFloat(value);
        }

        // Apply valueFormatter if available
        if (colDef.valueFormatter) {
          return colDef.valueFormatter({
            value: value,
            data: params.data,
            node: params.node,
            colDef: colDef,
            column: params.column,
            api: gridReference.api,
            columnApi: gridReference.columnApi,
            context: gridReference.context,
          });
        }

        return value;
      },
      processHeaderCallback: (params) => {
        return params.column.getColDef().headerName || params.column.getId();
      },
      columnWidth: (params) => {
        return params.column.getColDef().minWidth || 200;
      },
      defaultStyle: {
        font: {
          size: 12,
        },
      },
    });
  }
};
