import React from 'react';
import { View } from 'react-native';
import * as MapboxGL from 'mapbox-gl';
import equal from 'fast-deep-equal/react';
import { helpers } from '@turf/turf';
import { Mapbox } from './Mapbox';
import { MapContext } from './Contexts';
import { loadImage } from './Images';
import { debounce } from '../../utils';

export function mapLngLatToArray(obj) {
  if (obj && typeof obj === 'object') {
    if (Array.isArray(obj) && obj.length === 2) {
      if (typeof obj[0] === 'number') {
        return obj;
      }
    } else if (obj.lng !== null && obj.lng !== undefined) {
      const { lng, lat } = obj;
      return [lng, lat];
    } else if (obj.longitude !== null && obj.longitude !== undefined) {
      const { longitude, latitude } = obj;
      return [longitude, latitude];
    }
  }
  return null;
}

export function mapBoundsToArray(bounds) {
  if (bounds) {
    const ne = mapLngLatToArray(bounds._ne);
    const sw = mapLngLatToArray(bounds._sw);
    return [ne, sw];
  }
  return null;
}

export const eventData = {
  fromMouseEventToPoint: (evt) => {
    if (evt) {
      const { lng, lat } = evt.lngLat;
      const feature = helpers.point([lng, lat], {
        screenPointX: evt.point.x,
        screenPointY: evt.point.y,
      });
      return feature;
    }
  },
  fromMapToRegion: (evt, map) => {
    if (map && map.mounted && map.mounted.current) {
      const zoomLevel = map.getZoom();
      const visibleBounds = mapBoundsToArray(map.getBounds());
      const center = mapLngLatToArray(map.getCenter());
      if (center) {
        const feature = helpers.point(center, {
          visibleBounds,
          zoomLevel,
        });
        return feature;
      }
    }
  },
};

const defaultOtherMapOptions = {
  keyboard: false,
  logoPosition: 'bottom-left',
  boxZoom: false,
  doubleClickZoom: false,
};

const Map = React.forwardRef(function Map(props, ref) {
  const {
    style = null,
    styleURL = Mapbox.StyleURL.Satellite, // style url for map,
    zoomEnabled = true,
    scrollEnabled = true,
    pitchEnabled = false,
    rotateEnabled = false,
    attributionEnabled = true, // mapbox TOS, etc. Required to exist somewhere in the app if false. To disable on iOS: add MGLMapboxMetricsEnabledSettingShownInApp=YES to your Info.plist
    onPress = null,
    onRegionWillChange = null,
    onRegionIsChanging = null,
    onRegionDidChange = null,
    onWillStartLoadingMap = null,
    onDidFinishLoadingMap = null,
    onWillStartRenderingMap = null,
    onDidFinishRenderingMap = null,
    onDidFinishLoadingStyle = null,
    regionWillChangeDebounceTime = 10,
    regionDidChangeDebounceTime = 300,
    staticMap = false,
    children,
    // TODO: implement these...
    cursor,
  } = props;
  const mounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  const mapContainerRef = React.useRef(null);
  const [map, setMap] = React.useState(null);
  const options = React.useRef(null);
  const handlers = React.useRef(null);
  const listeners = React.useRef(null);
  const cursorType = React.useRef(null);

  React.useEffect(() => {
    if (!map || !mounted.current || !options.current) {
      return;
    }
    if (!equal(options.current.style, styleURL)) {
      map.setStyle(styleURL);
    }
  }, [
    map,
    styleURL,
    // staticMap,
    // scrollEnabled,
    // zoomEnabled,
    // rotateEnabled,
    // pitchEnabled,
    // attributionEnabled,
  ]);

  cursorType.current = cursor ? cursor : '';

  if (map && mounted.current) {
    map.getCanvas().style.cursor = cursorType.current;
  }

  options.current = {
    style: styleURL,
    interactive: !staticMap,
    dragPan: scrollEnabled,
    scrollZoom: zoomEnabled,
    dragRotate: rotateEnabled,
    touchZoomRotate: zoomEnabled && rotateEnabled,
    touchPitch: pitchEnabled,
    pitchWithRotate: pitchEnabled,
    attributionControl: attributionEnabled,
  };

  handlers.current = {
    onPress, // click
    onWillStartLoadingMap, // load
    onDidFinishLoadingMap, // load
    onWillStartRenderingMap, // load
    onDidFinishRenderingMap, // load
    onDidFinishLoadingStyle, // styledata
    onRegionDidChange, // resize, zoomend, rotateend, moveend,
    onRegionWillChange, // zoomstart, rotatestart, movestart,
    onRegionIsChanging, // zoom, rotate, move,
    regionWillChangeDebounceTime,
    regionDidChangeDebounceTime,
  };

  const initialBounds = React.useRef(null);
  if (initialBounds.current === null) {
    initialBounds.current = false;
    React.Children.map(children, (child) => {
      if (child && child.props && child.props._isMapboxCamera) {
        if (child.props.bounds) {
          const b = child.props.bounds;
          if (b && Array.isArray(b.ne) && Array.isArray(b.sw)) {
            initialBounds.current = {
              bounds: [b.sw, b.ne],
              fitBoundsOptions: {
                padding: {
                  top: b.paddingTop || 0,
                  bottom: b.paddingBottom || 0,
                  left: b.paddingLeft || 0,
                  right: b.paddingRight || 0,
                },
              },
            };
          }
        }
      }
    });
  }
  React.useEffect(() => {
    if (handlers.current.onWillStartLoadingMap) {
      handlers.current.onWillStartLoadingMap();
    }
    const initialBoundsOptions = initialBounds.current ? initialBounds.current : null;
    const isStatic = options.current && options.current.interactive ? false : true;
    const _map = new MapboxGL.Map({
      container: mapContainerRef.current,
      ...defaultOtherMapOptions,
      ...options.current,
      ...initialBoundsOptions,
    });
    if (handlers.current.onWillStartRenderingMap) {
      handlers.current.onWillStartRenderingMap();
    }

    /*
     * CREATE AND ATTACH LISTENERS ON LOAD
     */
    const createdHandlers = {};
    const createHandlerFor = (handlerType, evtResolver, debounceTime = 0) => {
      if (!handlerType) {
        return null;
      }
      if (createdHandlers[handlerType]) {
        // TODO: subdivide these further by checking if evtResolver is the same as well
        return createdHandlers[handlerType];
      }
      const evtHandler = (evt) => {
        if (mounted.current && handlerType && handlers.current && handlers.current[handlerType]) {
          const propHandler = handlers.current[handlerType];
          if (propHandler) {
            return propHandler(evtResolver ? evtResolver(evt, _map) : null);
          }
        }
      };
      createdHandlers[handlerType] = debounceTime ? debounce(evtHandler, debounceTime) : evtHandler;
      return evtHandler;
    };

    listeners.current = {
      click: createHandlerFor('onPress', eventData.fromMouseEventToPoint),
      mousemove: createHandlerFor('onMouseMove', eventData.fromMouseEventToPoint),
      mouseover: createHandlerFor('onMouseOver', eventData.fromMouseEventToPoint),
      mouseout: createHandlerFor('onMouseOut', eventData.fromMouseEventToPoint),
      resize: createHandlerFor('onRegionDidChange', eventData.fromMapToRegion, handlers.current.regionDidChangeDebounceTime),
      zoomend: createHandlerFor('onRegionDidChange', eventData.fromMapToRegion, handlers.current.regionDidChangeDebounceTime),
      rotateend: createHandlerFor('onRegionDidChange', eventData.fromMapToRegion, handlers.current.regionDidChangeDebounceTime),
      moveend: createHandlerFor('onRegionDidChange', eventData.fromMapToRegion, handlers.current.regionDidChangeDebounceTime),
      zoomstart: createHandlerFor('onRegionWillChange', eventData.fromMapToRegion, handlers.current.regionWillChangeDebounceTime),
      rotatestart: createHandlerFor('onRegionWillChange', eventData.fromMapToRegion, handlers.current.regionWillChangeDebounceTime),
      movestart: createHandlerFor('onRegionWillChange', eventData.fromMapToRegion, handlers.current.regionWillChangeDebounceTime),
      zoom: createHandlerFor('onRegionIsChanging', eventData.fromMapToRegion, 5),
      rotate: createHandlerFor('onRegionIsChanging', eventData.fromMapToRegion, 5),
      move: createHandlerFor('onRegionIsChanging', eventData.fromMapToRegion, 5),
      styledata: createHandlerFor('onDidFinishLoadingStyle'),
      dragstart: () => {
        if (mounted.current) {
          _map.getCanvas().style.cursor = '';
        }
      },
      dragend: () => {
        if (mounted.current) {
          _map.getCanvas().style.cursor = cursorType.current;
        }
      },
    };

    _map.on('load', () => {
      if (mounted.current) {
        _map.mounted = mounted;
        _map.isStatic = isStatic;
        if (Mapbox.defaultImages) {
          for (const key in Mapbox.defaultImages) {
            const src = Mapbox.defaultImages[key];
            loadImage(key, src, _map);
          }
        }
        setMap(_map);
        _map.getCanvas().style.cursor = cursorType.current;
        if (handlers.current.onDidFinishLoadingMap) {
          handlers.current.onDidFinishLoadingMap();
        }
        if (handlers.current.onDidFinishRenderingMap) {
          handlers.current.onDidFinishRenderingMap();
        }
        Object.keys(listeners.current).forEach((key) => {
          if (listeners.current[key]) {
            _map.on(key, listeners.current[key]);
          }
        });
      }
    });
    options.current = null;
    return () => {
      if (listeners.current) {
        Object.keys(listeners.current).forEach((key) => {
          if (listeners.current[key]) {
            _map.off(key, listeners.current[key]);
          }
        });
      }
      _map.remove();
    };
  }, []);

  React.useImperativeHandle(
    ref,
    () => ({
      getVisibleBounds: async () => {
        if (map) {
          const bounds = mapBoundsToArray(map.getBounds());
          return bounds;
        }
        return null;
      },
      getCenter: async () => {
        if (map) {
          const center = mapLngLatToArray(map.getCenter());
          return center;
        }
        return null;
      },
      getZoom: async () => {
        if (map) {
          const zoom = map.getZoom();
          return zoom;
        }
        return null;
      },
      getPointInView: async (coordinate) => {
        // TODO: implement
        return null;
      },
      getCoordinateFromView: async (point) => {
        // TODO: implement
        return null;
      },
      queryRenderedFeaturesAtPoint: async (coordinate, filter, layerIds) => {
        // TODO: implement
        return null;
      },
      queryRenderedFeaturesInRect: async (bbox, filter, layerIds) => {
        // TODO: implement
        return null;
      },
      takeSnap: async (writeToDisk) => {
        // TODO: implement
        return null;
      },
      showAttribution: () => {
        // TODO: implement
        return null;
      },
      setSourceVisibility: async (visible, sourceId, sourceLayerId) => {
        // TODO: implement
        return null;
      },
    }),
    [map]
  );

  return (
    <MapContext.Provider value={map}>
      <View style={style} pointerEvents={staticMap ? 'none' : 'box-none'}>
        <div style={mapContainerStyle} ref={mapContainerRef}>
          {map && children}
        </div>
      </View>
    </MapContext.Provider>
  );
});

const mapContainerStyle = {
  width: '100%',
  minWidth: '100%',
  maxWidth: '100%',
  height: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  display: 'flex',
  margin: 0,
  padding: 0,
  boxSizing: 'border-box',
  position: 'relative',
  flex: 1,
  overflow: 'hidden',
  // alignItems: 'stretch',
  borderWidth: 0,
  borderColor: 'black',
  borderStyle: 'solid',
};
/* METHODS
getPointInView(coordinate)
Converts a geographic coordinate to a point in the given view’s coordinate system.
const pointInView = await this._map.getPointInView([-37.817070, 144.949901]);

getCoordinateFromView(point)
Converts a point in the given view’s coordinate system to a geographic coordinate.
const coordinate = await this._map.getCoordinateFromView([100, 100]);

getVisibleBounds()
The coordinate bounds(ne, sw) visible in the users’s viewport.
const visibleBounds = await this._map.getVisibleBounds();

queryRenderedFeaturesAtPoint(coordinate, filter, layerIds)
Returns an array of rendered map features that intersect with a given point.
this._map.queryRenderedFeaturesAtPoint([30, 40], ['==', 'type', 'Point'], ['id1', 'id2'])
^^ filter and layerIDs not required

queryRenderedFeaturesInRect(bbox[, filter][, layerIDs])
Returns an array of rendered map features that intersect with the given rectangle,
restricted to the given style layers and filtered by the given predicate.
this._map.queryRenderedFeaturesInRect([30, 40, 20, 10], ['==', 'type', 'Point'], ['id1', 'id2'])

takeSnap(writeToDiskBoolean)
Takes snapshot of map with current tiles and returns a URI to the image
If true will create a temp file, otherwise it is in base64

getZoom()
Returns the current zoom of the map view.
const zoom = await this._map.getZoom();

getCenter()
Returns the map's geographical centerpoint
const zoom = await this._map.getCenter();

setSourceVisibility(visible, sourceId[, sourceLayerId])
visible is rquired, sourceId is required, sourceLayerId is not required
await this._map.setSourceVisibility(false, 'composite', 'building')

showAttribution()
Show the attribution and telemetry action sheet.
If you implement a custom attribution button, you should add this action to the button.

*/

export { Map };
