import React, { useRef } from 'react';
import { Platform } from 'react-native';
import { withStyles } from '../../styling';
import { Box } from '../Box';
import { Text } from '../Text';
import { Svg } from '../Svg';
import { Hoverable } from '../Hoverable';
import { useEventCallback, useForkRef } from '../../hooks';

function isDeleteKeyboardEvent(keyboardEvent) {
  return keyboardEvent.key === 'Backspace' || keyboardEvent.key === 'Delete';
}

const Chip = withStyles(
  (props) => {
    const {
      theme,
      size = 'medium',
      variant = 'filled',
      color: colorProp = 'default',
      onColor = undefined,
      onPress,
      onDelete,
      disabled = false,
      labelProps = null,
    } = props;

    const defaultBackgroundColor = '$gray.200';
    const defaultColor = '$gray.700';
    const mediumSize = 30;
    const smallSize = 24;

    let backgroundColor = colorProp && colorProp !== 'default' ? colorProp : defaultBackgroundColor;
    let color = colorProp && colorProp !== 'default' ? backgroundColor : defaultColor;

    if (onColor && colorProp === 'default') {
      backgroundColor = theme.colors.on(onColor);
      if (!backgroundColor) {
        backgroundColor = defaultBackgroundColor;
      }
      if (variant === 'filled') {
        color = theme.colors.on(backgroundColor);
      } else {
        color = backgroundColor;
      }
    } else if (colorProp !== 'default' && variant === 'filled') {
      color = theme.colors.on(backgroundColor);
    }

    return {
      root: {
        height: mediumSize,
        borderRadius: '$circle',
        borderWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor,
        borderColor: backgroundColor,
        overflow: 'hidden',
        cursor: disabled ? 'default' : !onPress ? (onDelete ? 'default' : 'inherit') : undefined,
        props: {
          small: size !== 'medium',
          outlined: variant !== 'filled',
        },
      },
      small: {
        height: smallSize,
      },
      outlined: {
        backgroundColor: 'transparent',
        borderColor: colorProp === 'default' && color === defaultColor ? theme.colors.alpha(color, 0.5) : color,
      },
      disabled: {
        opacity: 0.6,
      },
      label: {
        padX: '$1.5',
        color,
        props: {
          maxLines: 1,
          align: 'center',
          size: 'xSmall',
          bold: false,
          ...labelProps,
        },
      },
      labelOutlined: {
        props: {
          bold: true,
          ...labelProps,
        },
      },
      labelSmall: {
        padX: '$1',
      },
      labelDisabled: {},
      icon: {
        marginLeft: '$0.5',
        marginRight: '$-0.75',
        props: { color, size: mediumSize - theme.spacing(1) },
      },
      iconSmall: {
        marginLeft: '$0.25',
        marginRight: '$-0.5',
        props: { size: smallSize - theme.spacing(0.75) },
      },
      iconOutlined: {},
      iconDisabled: {},
      deleteIcon: {
        cursor: 'pointer',
        marginLeft: '$-0.75',
        marginRight: '$0.5',
        opacity: 0.5,
        props: {
          color: color,
          size: mediumSize - theme.spacing(1.25),
        },
      },
      deleteIconSmall: {
        marginLeft: '$-0.5',
        marginRight: '$0.25',
        props: { size: smallSize - theme.spacing(0.66) },
      },
      deleteIconOutlined: {},
      deleteIconHovered: {
        opacity: 0.64,
      },
      deleteIconDisabled: {
        cursor: 'default',
      },
    };
  },
  { name: 'Chip', filterProps: ['size', 'variant', 'color', 'onColor', 'labelProps'], preserveStyleProp: true }
)(
  React.forwardRef(function Chip(props, ref) {
    const {
      outlined,
      small,
      icon: iconProp,
      deleteIcon: deleteIconProp,
      label,
      pressable: pressableProp = undefined,
      disabled = false,
      onPress,
      onDelete,
      onKeyUp,
      onKeyDown,
      onFocus,
      onBlur,
      styles,
      style,
      value, // for press and delete context
      index, // for press and delete context
      ...rest
    } = props;

    const pressable = pressableProp !== undefined && typeof pressableProp === 'boolean' ? pressableProp : onPress ? true : false;

    const focusable = onPress || pressable || onDelete ? true : false;

    const chipRef = useRef(null);
    const handleRef = useForkRef(chipRef, ref);

    const eventProps = { value, index, label };
    const handleOnPressDeleteIcon = useEventCallback((e) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
      if (onDelete) {
        onDelete(e, eventProps);
      }
    });

    const handleKeyDown = useEventCallback((e) => {
      if (e.currentTarget === e.target && isDeleteKeyboardEvent(e)) {
        e.preventDefault();
      }

      if (onKeyDown) {
        onKeyDown(e, eventProps);
      }
    });

    const handleKeyUp = useEventCallback((e) => {
      if (e.currentTarget === e.target) {
        if (onDelete && isDeleteKeyboardEvent(e)) {
          onDelete(e, eventProps);
        } else if (e.key === 'Escape' && chipRef.current && chipRef.current.blur) {
          chipRef.current.blur();
        }
      }

      if (onKeyUp) {
        onKeyUp(e, eventProps);
      }
    });

    const handleOnPress = useEventCallback((e) => {
      if (onPress) {
        onPress(e, eventProps);
      }
    });

    const handleOnFocus = useEventCallback((e) => {
      if (onFocus) {
        onFocus(e, eventProps);
      }
    });

    const handleOnBlur = useEventCallback((e) => {
      if (onBlur) {
        onBlur(e, eventProps);
      }
    });

    let icon = null;
    if (iconProp && React.isValidElement(iconProp)) {
      icon = React.cloneElement(
        iconProp,
        styles.toProps({
          icon: true,
          iconSmall: small,
          iconOutlined: outlined,
          iconDisabled: disabled,
        })
      );
    }

    let deleteIcon = null;
    if (onDelete) {
      const renderDeleteIcon = ({ hovered }) => {
        const deleteIconProps = {
          ...styles.toProps({
            deleteIcon: true,
            deleteIconSmall: small,
            deleteIconOutlined: outlined,
            deleteIconDisabled: disabled,
            deleteIconHovered: hovered && !disabled, // <- TODO
          }),
          onPress: !disabled ? handleOnPressDeleteIcon : null,
        };
        if (deleteIconProp && React.isValidElement(deleteIconProp)) {
          return React.cloneElement(deleteIconProp, deleteIconProps);
        } else {
          return <DeleteIcon {...deleteIconProps} />;
        }
      };
      deleteIcon = <Hoverable>{renderDeleteIcon}</Hoverable>;
    }

    return (
      <Box
        ref={handleRef}
        disableAnimate={!pressable}
        onPress={onPress || pressable ? handleOnPress : undefined}
        disabled={disabled}
        focusable={focusable}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        {...(Platform.OS === 'web'
          ? {
              onKeyUp: handleKeyUp,
              onKeyDown: handleKeyDown,
            }
          : null)}
        {...styles.toProps(
          {
            root: true,
            small,
            outlined,
            disabled,
          },
          style
        )}
        {...rest}
      >
        {icon}
        <Text
          {...styles.toProps({
            label: true,
            labelSmall: small,
            labelOutlined: outlined,
            labelDisabled: disabled,
          })}
        >
          {label}
        </Text>
        {deleteIcon}
      </Box>
    );
  })
);

const DeleteIcon = ({ fill, color = 'black', ...props }) => {
  return (
    <Svg fill={color} color={color} size="24" viewBox="0 0 24 24" {...props}>
      <Svg.Path d="M12,2C6.47,2,2,6.47,2,12s4.47,10,10,10s10-4.47,10-10S17.53,2,12,2z M17,15.59L15.59,17L12,13.41L8.41,17L7,15.59 L10.59,12L7,8.41L8.41,7L12,10.59L15.59,7L17,8.41L13.41,12L17,15.59z" />
    </Svg>
  );
};

export { Chip };
