import { get } from '../utils';
import { parseSystemStyle, parseDefault } from './utils';

// TODO: this funciton is dangerous when variants have subvariants (text: { h1: variant: large })
// because the variantsuffix, prefix, and size is applied recursively. it should either only be applied to the very first instance or this should be removed and left to components to prefix and suffix variant name;
export function resolveVariantName(name = '', { variantPrefix: prefix = '', variantSuffix: suffix = '', variantSize: size = '' } = {}) {
  let variantName = parseSystemStyle(name);
  if (variantName && typeof variantName === 'string') {
    if (prefix && !variantName.startsWith(prefix)) {
      variantName = `${prefix}.${name}`;
    }
    if (suffix && !variantName.endsWith(suffix)) {
      variantName = `${variantName}.${suffix}`;
    }
    if (size && !variantName.endsWith(size)) {
      variantName = `${variantName}.${size}`;
    }
  }
  return variantName;
}

export function getVariant(name, props, variants) {
  const { theme } = props;
  const variantStyle = parseDefault(get(variants || theme, resolveVariantName(name, props), null));
  return variantStyle;
}

export function variant({ prop = 'variant', variants = {}, default: defaultVariant = 'default' } = {}) {
  const resolver = (props) => {
    if (props[prop] && variants[props[prop]]) {
      return variants[props[prop]];
    } else if (variants[defaultVariant]) {
      return variants[defaultVariant];
    }
    return null;
  };
  resolver.isVariantResolver = true;
  resolver.prop = prop;
  const destructureObj = {};
  destructureObj[`variantResolverFor${prop}`] = resolver;
  return destructureObj;
}
