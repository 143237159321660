import { isNull, isObject } from '../../ui/utils';
import { MIN_MAX_RANGE, DATES_FROM_TO, SELECT, MULTI_SELECT, SWITCH, CHECKBOX } from '../../constants/searchData/filterTypes';
import { getQueryStringForRange } from './queryUtils';
import moment from 'moment-timezone';

export function setRangeField(fieldValueRange, setField, fieldProp, forUnixDate = false) {
  let fieldValue = Array.isArray(fieldValueRange) ? [...fieldValueRange] : fieldValueRange;
  if (forUnixDate) {
    if (Array.isArray(fieldValueRange) && fieldValueRange.length === 2) {
      const from = moment(fieldValueRange[0]);
      const to = moment(fieldValueRange[1]);
      fieldValue[0] = from.isValid() ? from.valueOf() : null;
      fieldValue[1] = to.isValid() ? to.valueOf() : null;
    }
  }
  const value = getQueryStringForRange(fieldValue);
  if (value) {
    setField({ field: fieldProp.name, value });
  } else {
    setField({ field: fieldProp.name, value: null });
  }
}

export function setSwitchField(fieldValue, setField, fieldProp) {
  if (!isNull(fieldValue)) {
    const option = fieldValue ? 'on' : 'off';
    if (option === 'off' && (isNull(fieldProp.options) || isNull(fieldProp.options[option]) || isNull(fieldProp.options[option].value))) {
      return setField({ field: fieldProp.name, value: null });
    }
    setField({ field: fieldProp.name, value: fieldValue ? '1' : '0' });
  } else {
    setField({ field: fieldProp.name, value: null });
  }
}
// takes the value of a field + the function to set the query.
// value (fieldValue) should be transformed, if needed, into the value that will be encoded into the search query.
// when the query is updated the value will be parsed and/or transformed next by (transformField) and then getValue
export const createDefaultSetField = (fieldPropConstant) => {
  return (fieldValue, setField, overrideFieldProp) => {
    const fieldProp = overrideFieldProp ? overrideFieldProp : fieldPropConstant;
    switch (fieldProp.type) {
      case MIN_MAX_RANGE: {
        setRangeField(fieldValue, setField, fieldProp);
        break;
      }
      case DATES_FROM_TO: {
        setRangeField(fieldValue, setField, fieldProp, true);
        break;
      }
      case SELECT: {
        let selectValue = null;
        if (!isNull(fieldValue)) {
          if (isObject(fieldValue)) {
            selectValue = !isNull(fieldValue.value) ? fieldValue.value : null;
          } else if (isObject(fieldProp.options)) {
            // usually the case
            if (fieldProp.options[fieldValue]) {
              // assume fieldValue is option key and test if key exists.
              selectValue = fieldValue;
            } // if not then keep null
          } else {
            selectValue = fieldValue;
          }
        }
        if (isNull(selectValue)) {
          setField({ field: fieldProp.name, value: null });
        } else {
          setField({ field: fieldProp.name, value: selectValue });
        }
        break;
      }
      case MULTI_SELECT: {
        if (!Array.isArray(fieldValue) || !fieldValue.length) {
          setField({ field: fieldProp.name, value: null });
        } else {
          const justValues = [];
          for (const f of fieldValue) {
            if (!isNull(f)) {
              if (isObject(f)) {
                if (!isNull(f.value)) {
                  justValues.push(f.value);
                }
              } else if (isObject(fieldProp.options)) {
                if (fieldProp.options[f]) {
                  justValues.push(f);
                }
              }
            }
          }
          if (justValues.length) {
            setField({ field: fieldProp.name, value: justValues.toString() });
          } else {
            setField({ field: fieldProp.name, value: null });
          }
        }
        break;
      }
      case SWITCH: {
        setSwitchField(fieldValue, setField, fieldProp);
        break;
      }
      case CHECKBOX: {
        console.warn('dont use checkbox for now');
        setSwitchField(fieldValue, setField, fieldProp);
        break;
      }
      default: {
        setField({ field: fieldProp.name, value: fieldValue });
      }
    }
  };
};
