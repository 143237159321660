import { DEFAULT_FILTER_ORDER } from '../../constants/searchData/filterTypes';
import { createDefaultGetValue } from './getFieldValues';
import { createDefaultSetField } from './setFields';
import { createDefaultTransformField } from './transformFields';

export function getSearchOptionsFromDataItem(dataItem, useName = true) {
  const isInvalidOrWillBe = (item) => !item || typeof item !== 'object' || item.replace === true || item.remove === true;
  const shouldExclude = (item, capability) => (item && item[capability] && item[capability].exclude === true ? true : false);
  const exclude = (item, capability) => isInvalidOrWillBe(item) || shouldExclude(item, capability);

  const filter = getOnlyPropertiesWithCapability(dataItem, 'filter', { exclude, useName });
  const search = getOnlyPropertiesWithCapability(dataItem, 'search', { exclude, useName });
  const sort = getOnlyPropertiesWithCapability(dataItem, 'sort', { exclude, useName });
  const sortKeys = Object.keys(sort);
  const searchKeys = Object.keys(search);
  const filterKeys = Object.keys(filter);

  const handleKeySortOrder = (objType, length) => (a, b) => {
    const obj = objType === 'filter' ? filter : objType === 'search' ? search : sort;
    const o1 = typeof obj[a].order === 'number' ? obj[a].order : length;
    const o2 = typeof obj[b].order === 'number' ? obj[b].order : length;
    if (o1 < o2) {
      return -1;
    } else if (o1 > o2) {
      return 1;
    }
    if (objType === 'filter') {
      const typeIndex1 = DEFAULT_FILTER_ORDER.indexOf(obj[a].type);
      const typeIndex2 = DEFAULT_FILTER_ORDER.indexOf(obj[b].type);
      if (typeIndex1 !== -1 && typeIndex2 !== -1 && typeIndex1 !== typeIndex2) {
        if (typeIndex1 < typeIndex2) {
          return -1;
        }
        return 1;
      }
    }
    const label1 = typeof obj[a].label === 'string' ? obj[a].label.toUpperCase() : null;
    const label2 = typeof obj[b].label === 'string' ? obj[b].label.toUpperCase() : null;
    if (label1 !== null && label2 !== null && label1 !== label2) {
      if (label1 < label2) {
        return -1;
      }
      return 1;
    }
    return 0;
  };

  sortKeys.sort(handleKeySortOrder('sort', sortKeys.length));
  searchKeys.sort(handleKeySortOrder('search', searchKeys.length));
  filterKeys.sort(handleKeySortOrder('filter', filterKeys.length));

  sortKeys.forEach((key) => {
    if (typeof sort[key] === 'object') {
      if (!sort[key].getValue) {
        sort[key].getValue = createDefaultGetValue(sort[key]);
      }
    }
  });
  searchKeys.forEach((key) => {
    if (typeof search[key] === 'object') {
      if (!search[key].getValue) {
        search[key].getValue = createDefaultGetValue(search[key]);
      }
      if (!search[key].transformField) {
        search[key].transformField = createDefaultTransformField(search[key], 'search');
      }
    }
  });
  filterKeys.forEach((key) => {
    if (typeof filter[key] === 'object') {
      if (!filter[key].getValue) {
        filter[key].getValue = createDefaultGetValue(filter[key]);
      }
      if (!filter[key].transformField) {
        filter[key].transformField = createDefaultTransformField(filter[key]);
      }

      if (!filter[key].setField) {
        filter[key].setField = createDefaultSetField(filter[key]);
      }
    }
  });

  const result = {
    sort,
    filter,
    search,
    sortKeys,
    searchKeys,
    filterKeys,
  };

  result.initialSearchConfig = getInitialSearchConfigForOptions(result);

  return result;
}

export function getOnlyPropertiesWithCapability(dataItem, capability, { exclude = () => false, prefix = '', useName = false } = {}) {
  let next = {};
  if (!capability || typeof capability !== 'string') {
    return next;
  }
  const getPropKey = (prop, propItem) => {
    if (useName && propItem && typeof propItem === 'object') {
      if (propItem.name && typeof propItem.name === 'string') {
        return propItem.name;
      }
      if (propItem[capability] && typeof propItem[capability] === 'object') {
        if (propItem[capability].name && typeof propItem[capability].name === 'string') {
          return propItem[capability].name;
        }
      }
    }
    return prefix ? prefix + '.' + prop : prop;
  };
  Object.keys(dataItem).forEach((prop) => {
    const propItem = dataItem[prop];
    if (propItem && typeof propItem === 'object') {
      const { type: propItemType, properties, values } = propItem;
      if (!exclude(propItem, capability)) {
        if (propItem[capability]) {
          next[getPropKey(prop, propItem)] = propItem[capability];
        }
        if (propItemType === 'object' || propItemType === 'array') {
          if (properties && typeof properties === 'object') {
            next = {
              ...next,
              ...getOnlyPropertiesWithCapability(properties, capability, {
                exclude,
                prefix: useName ? '' : `${prefix ? prefix + '.' + prop : prop}`,
                useName,
              }),
            };
          }
          if (values && typeof values === 'object') {
            next = {
              ...next,
              ...getOnlyPropertiesWithCapability(values, capability, {
                exclude,
                prefix: useName ? '' : `${prefix ? prefix + '.' + prop : prop}`,
                useName,
              }),
            };
          }
        }
      }
    }
  });

  return next;
}

export function getInitialSearchConfigForOptions(config) {
  const { sort, filter, search, filterKeys, searchKeys, sortKeys } = config;
  const initialSearchConfig = {
    filters: [],
    search: [],
    sort: null,
  };
  for (let i = 0; i < sortKeys.length; i += 1) {
    const sortProp = sort[sortKeys[i]];
    if (typeof sortProp === 'object') {
      if (sortProp.initial && typeof sortProp.initial === 'object') {
        initialSearchConfig.sort = { field: sortProp.name, by: sortProp.by ? sortProp.by : 'DESC' };
        break;
      } else if (!initialSearchConfig.sort) {
        initialSearchConfig.sort = { field: sortProp.name, by: sortProp.by ? sortProp.by : 'DESC' };
      }
    }
  }
  const addFilterOrSearchDefaults = (fs, fsKeys = [], arr) => {
    fsKeys.forEach((key) => {
      const fsProp = fs[key];
      if (typeof fsProp === 'object') {
        if (fsProp.initial && typeof fsProp.initial === 'object') {
          if (Array.isArray(fsProp.initial)) {
            for (const item of fsProp.initial) {
              if (typeof item === 'object' && item.field && item.value !== undefined) {
                arr.push(item);
              }
            }
          } else if (fsProp.initial.field && fsProp.initial.value !== undefined) {
            arr.push(fsProp.initial);
          }
        }
      }
    });
  };
  addFilterOrSearchDefaults(filter, filterKeys, initialSearchConfig.filters);
  addFilterOrSearchDefaults(search, searchKeys, initialSearchConfig.search);

  return initialSearchConfig;
}
