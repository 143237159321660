import React from 'react';
import { Box, Text } from '../ui';

const DataLabel = ({ invert, ...props }) => {
  return <Text maxWidth="100%" size="medium" color="$gray.500" bold={invert ? false : true} maxLines={2} {...props} />;
};

const DataText = ({ invert, ...props }) => {
  return <Text maxWidth="100%" size="medium" color="$gray.800" maxLines={2} bold={invert ? true : false} {...props} />;
};

export const TextData = ({
  label = '',
  data = '',
  asRow = false,
  empty = false,
  size = 'medium',
  labelProps,
  textProps,
  invert = false,
  ...rest
}) => {
  const containerProps = asRow
    ? {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 24,
        ...rest,
      }
    : {
        ...rest,
      };

  const lineHeightAdjustment = size === 'medium' ? { lineHeight: 20 } : null;
  if (empty) {
    containerProps.opacity = containerProps.opacity || 0;
  }

  return (
    <Box {...containerProps}>
      <Box flex={asRow ? 1 : null}>
        <DataLabel invert={invert} size={size} {...labelProps}>
          {label}
        </DataLabel>
      </Box>
      <Box flex={asRow ? 1.5 : null}>
        {Array.isArray(data) && data.length ? (
          <Box flex={asRow ? 1 : null} flexDirection="column">
            {data.map((line, i) => (
              <DataText invert={invert} size={size} {...lineHeightAdjustment} {...textProps} key={`${i}${line}`}>
                {line}
              </DataText>
            ))}
          </Box>
        ) : (
          <DataText invert={invert} size={size} {...lineHeightAdjustment} {...textProps}>
            {data}
          </DataText>
        )}
      </Box>
    </Box>
  );
};

TextData.Label = DataLabel;
TextData.Text = DataText;
