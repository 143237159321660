import { merge } from 'merge-anything';

function flatten(stylesDef, props, more) {
  if (Array.isArray(stylesDef)) {
    return stylesDef.reduce((merged, styles) => {
      const isFunc = typeof styles === 'function';
      return merge(merged, flatten(isFunc ? styles(props, more) : styles, props, more));
    }, {});
  }
  if (typeof stylesDef === 'function') {
    return flatten(stylesDef(props), props, more);
  }
  return stylesDef;
}

export { flatten };
