import React, { useEffect, useState } from 'react';
import { Box } from '../../../../ui';
import { ViewTabs } from '../../../../components/ViewTabs';
import { Coverages } from '../QuoteGrids/Coverages';
import { RiskConcentration } from '../QuoteGrids/RiskConcentration';
import { CATModeling } from '../QuoteGrids/CATModeling';
import { BindFormsView } from '../BindFormsView';
import { Fees } from '../QuoteGrids/Fees';

export const QuotesViewTabs = {
  FEES: 'FEES',
  COVERAGES: 'COVERAGES',
  CAT_MODELING: 'CAT_MODELING',
  RISK_CONCENTRATION: 'RISK_CONCENTRATION',
  BIND_FORMS: 'BIND_FORMS',
};

const QuotesGridView = ({
  tab,
  data,
  productCoverages,
  concentrations,
  macavity,
  setGridReference,
  onSelectionChanged,
  update,
  updating,
  lastUpdated,
  modalOpen,
  setModalOpen,
  quoteData,
  setQuoteData,
  gridReference,
  importModal,
  setImportModal,
  setActiveExportFunction,
}) => {
  switch (tab) {
    case QuotesViewTabs.FEES:
      return (
        <Fees
          quotes={quoteData}
          setGridReference={setGridReference}
          onSelectionChanged={onSelectionChanged}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setQuoteData={setQuoteData}
          gridReference={gridReference}
          importModal={importModal}
          setImportModal={setImportModal}
          setExportHandler={(exportFn) => setActiveExportFunction(exportFn, QuotesViewTabs.FEES)}
        />
      );
    case QuotesViewTabs.COVERAGES:
      return (
        <Coverages
          quotes={quoteData}
          productCoverages={productCoverages}
          setGridReference={setGridReference}
          onSelectionChanged={onSelectionChanged}
          modalOpen={modalOpen}
          setQuoteData={setQuoteData}
          setModalOpen={setModalOpen}
          importModal={importModal}
          gridReference={gridReference}
          setImportModal={setImportModal}
          concentrations={concentrations}
          setExportHandler={(exportFn) => setActiveExportFunction(exportFn, QuotesViewTabs.COVERAGES)}
        />
      );
    case QuotesViewTabs.CAT_MODELING:
      return (
        <CATModeling
          macavity={macavity}
          quote={data}
          setGridReference={setGridReference}
          gridReference={gridReference}
          setExportHandler={(exportFn) => setActiveExportFunction(exportFn, QuotesViewTabs.CAT_MODELING)}
        />
      );
    case QuotesViewTabs.RISK_CONCENTRATION:
      return (
        <RiskConcentration
          quotes={data}
          concentrations={concentrations}
          setGridReference={setGridReference}
          gridReference={gridReference}
          setExportHandler={(exportFn) => setActiveExportFunction(exportFn, QuotesViewTabs.RISK_CONCENTRATION)}
        />
      );
    case QuotesViewTabs.BIND_FORMS:
      return <BindFormsView data={data} update={update} updating={updating} lastUpdated={lastUpdated} />;
    default:
      return 'default';
  }
};

export const QuoteTabs = React.forwardRef((props, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [gridParams, setGridParams] = useState({});
  const [selectedFields, setSelectedFields] = useState({});
  const [exportHandlers, setExportHandlers] = useState({});

  const setActiveExportFunction = (exportFn, gridType) => {
    setExportHandlers((prevHandlers) => ({
      ...prevHandlers,
      [gridType]: exportFn,
    }));
  };

  // Trigger export for the currently active grid
  const handleExport = (gridType) => {
    if (exportHandlers[gridType]) {
      exportHandlers[gridType]();
    } else {
      console.error('No export function available for this grid type.');
    }
  };
  const setGridReference = (params) => {
    setGridParams(params);
  };

  const onSelectionChanged = (context) => {
    if (context) {
      const fields = context.api.getSelectedRows();
      setSelectedFields(fields);
    }
  };
  const initialTab = QuotesViewTabs.FEES;
  const [viewTab, setViewTab] = useState(initialTab || QuotesViewTabs.FEES);
  useEffect(() => {
    setModalOpen(false);
  }, [viewTab]);

  const getLabel = (name, label) => {
    if (name === QuotesViewTabs.FEES) {
      return 'Fees';
    }
    if (name === QuotesViewTabs.COVERAGES) {
      return `Coverages`;
    }
    if (name === QuotesViewTabs.CAT_MODELING) {
      return `CAT Modeling`;
    }
    if (name === QuotesViewTabs.RISK_CONCENTRATION) {
      return `Risk Concentration`;
    }
    if (name === QuotesViewTabs.BIND_FORMS) return `Bind Forms`;
    return label;
  };
  const quoteTabsKeys = Object.keys(QuotesViewTabs);
  const [quoteData, setQuoteData] = useState(props.data);
  const [importModal, setImportModal] = useState(false);

  useEffect(() => {
    setQuoteData(props.data);
  }, [props.data]);

  return (
    <>
      <Box
        backgroundColor={'white'}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor="#DFE3E8"
        padding={'0px 18px'}
        justifyContent="space-between"
        display="flex"
        paddingLeft={10}
      >
        <Box display="flex" flex={1} flexWrap="wrap" columnGap="24px" rowGap="8px">
          <ViewTabs
            getLabel={getLabel}
            tabs={quoteTabsKeys}
            onTabChanged={setViewTab}
            gridReference={gridParams}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            quoteData={quoteData}
            setQuoteData={setQuoteData}
            importModal={importModal}
            setImportModal={setImportModal}
            quotesView={true}
            onExport={handleExport}
            {...props}
          />
        </Box>
      </Box>
      <QuotesGridView
        tab={viewTab}
        {...props}
        setGridReference={setGridReference}
        onSelectionChanged={onSelectionChanged}
        modalOpen={modalOpen}
        quoteData={quoteData}
        gridReference={gridParams}
        setQuoteData={setQuoteData}
        setModalOpen={setModalOpen}
        importModal={importModal}
        setImportModal={setImportModal}
        setActiveExportFunction={setActiveExportFunction}
      />
    </>
  );
});
