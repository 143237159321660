import { FilledInput } from './FilledInput';
import { UnderlinedInput } from './UnderlinedInput';
import { OutlinedInput } from './OutlinedInput';

export * from './TextInput';

const inputComponentVariants = {
  filled: FilledInput,
  underlined: UnderlinedInput,
  outlined: OutlinedInput,
};

export { FilledInput, UnderlinedInput, OutlinedInput, inputComponentVariants };
