import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import { Dimensions } from 'react-native';
import { useScrollPositionEffect, useEventCallback, useForkRef } from '../../hooks';
import { resolveNode } from '../../utils';
import { Box } from '../Box';

const WindowScrollView = React.forwardRef(function WindowScrollView(props, ref) {
  const {
    onScroll,
    scrollEventThrottle = 5,
    scrollEnabled = true,
    onLayout,
    // dont pass
    removeClippedSubviews,
    contentInset,
    keyboardShouldPersistTaps,
    keyboardDismissMode,
    // style props to mimic ScrollView. All get merged in this case.
    style: styleProp,
    containerStyle, // scroll view prop
    contentContainerStyle, // scroll view prop
    ...rest
  } = props;

  const style = useMemo(() => [styleProp, containerStyle], [styleProp, containerStyle]);

  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleOnScroll = useCallback(
    ({ currPos, prevPos }, containerOffset) => {
      if (onScroll && mounted.current) {
        const layout = Dimensions.get('window');
        onScroll({
          nativeEvent: {
            layoutMeasurement: { ...layout },
            contentOffset: {
              y: (currPos.y + containerOffset) * -1,
              x: currPos.x * -1,
            },
            contentSize: {
              height: currPos.scrollHeight,
              width: currPos.scrollWidth,
            },
            containerOffset,
          },
          currPos,
          prevPos,
          ...currPos,
        });
      }
    },
    [onScroll]
  );

  useScrollPositionEffect(
    ({ currPos, prevPos }) => {
      if (mounted.current) {
        const container = resolveNode(containerRef.current);
        if (container && container.measure) {
          container.measure((x, y, width, height, pageX, pageY) => {
            const containerOffset = pageY ? pageY - currPos.y : 0;
            handleOnScroll({ currPos, prevPos }, containerOffset);
          });
        } else {
          handleOnScroll({ currPos, prevPos }, 0);
        }
      }
    },
    [handleOnScroll],
    { disabled: !scrollEnabled, wait: scrollEventThrottle }
  );

  const handleLayout = useEventCallback(
    (event) => {
      if (onLayout && mounted.current) {
        const layout = Dimensions.get('window');
        onLayout({
          nativeEvent: {
            layout: { ...layout },
            contentLayout: { ...event.nativeEvent.layout },
          },
        });
      }
    },
    [onLayout]
  );

  const containerRef = useRef();
  const handleRef = useForkRef(containerRef, ref);
  return <Box ref={handleRef} onLayout={handleLayout} style={style} {...rest} />;
});

export { WindowScrollView };
