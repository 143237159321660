import React, { useCallback, useState, useMemo, memo, useEffect, useRef } from 'react';
import { Box, Text, Button, Spacing, TextField, MenuItem, DatePicker, FileInput } from '../../../ui';
import { LayoutBox, Dialog, TextData } from '../../../components';
import { useApi, useAsync, useAuth } from '../../../hooks';
// import { QuoteList } from '../../QuotesListView';
// import { ViewTabs } from '../../../components/ViewTabs';
// import { ApplicationDetails } from './ApplicationDetails';
// import { ApplicationDocuments } from './ApplicationDocuments';
import { useEventCallback } from '../../../ui/hooks';
import moment from 'moment-timezone';
import { TrashIcon } from '../../../components/icons';
import { capitalize } from '../../../ui/utils';
import { formatMoney } from '../../../utils';

const ApplicationLossHistory = memo(function ApplicationLossHistory(props) {
  const { application, refreshApplicationData, enableControls, refreshing = false } = props;

  const auth = useAuth();
  const canEditAndCreate = enableControls && (auth.user.isUnderwriter || auth.user.isPolicyAnalyst || auth.user.isAdmin);
  const { getLossCauses } = useApi();
  const handleGetLossCauses = useCallback(async () => {
    if (!enableControls) {
      return [];
    }
    return await getLossCauses(application.product.id);
  }, [getLossCauses, application.product.id, enableControls]);

  const { value: lossCauses, error: loadLossCausesError } = useAsync(handleGetLossCauses);

  /*
    if currView = 'create' then view state for AddEditLossDetailModal is in 'create' mode
    otherwise currView is the id of the lossHistory item or null if nothing selected
  */
  const [currView, setCurrView] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const openView = useCallback(() => setViewOpen(true), [setViewOpen]);
  const closeView = useCallback(() => setViewOpen(false), [setViewOpen]);
  const [inputValue, setInputValue] = useState(null);
  const lastInputValue = useRef(inputValue);

  const { uploadLossHistory } = useApi();
  const uploadDocument = useCallback(
    async (document) => {
      await uploadLossHistory(application.id, {
        document,
      });
      await refreshApplicationData(true);
    },
    [uploadLossHistory, application.id, refreshApplicationData]
  );

  const { execute, error } = useAsync(uploadDocument, { immediate: false });

  const errors = useMemo(() => {
    if (error && (error.validationError || error.data)) {
      return error;
    }
    return {};
  }, [error]);

  useEffect(() => {
    closeView();
    setCurrView(null);
  }, [application.id, closeView]);

  useEffect(() => {
    // loss history csv upload
    if (inputValue && inputValue !== lastInputValue.current) {
      lastInputValue.current = inputValue;

      execute(inputValue);
    }
  }, [inputValue, execute]);

  const lossHistory = useMemo(() => {
    return Array.isArray(application.lossHistory) ? application.lossHistory : [];
  }, [application]);
  /*
    function helper for modal to get the currView data if it's an id || otherwise just an empty object
  */
  const getCurrViewData = useCallback(
    (view, lastData) => {
      if (view === 'create' || view === null) {
        return (
          lastData || {
            occurredAt: moment().valueOf(),
          }
        );
      }
      const data = lossHistory.find((v) => v.id === view);

      return data ? data : lastData ? lastData : null;
    },
    [lossHistory]
  );

  return (
    <LayoutBox layout={!lossHistory.length ? 'center' : 'top-left'} stretch minHeight="400">
      <Spacing vertical="5" />
      {canEditAndCreate && loadLossCausesError ? (
        <LayoutBox position="absolute" height="40" layout="center">
          <Text color="$error" bold small>
            {'Error loading available Loss Causes. Adding or Editing a loss cause may not work.'}
          </Text>
        </LayoutBox>
      ) : null}
      {!lossHistory.length ? (
        <>
          <Text center mb="$2">
            No records found
          </Text>
        </>
      ) : null}
      {canEditAndCreate ? (
        <Box flexDirection="row" justifyContent="flex-start" gap="16" alignItems="left">
          <Button
            alignSelf={!lossHistory.length ? 'center' : 'flex-start'}
            label="Add Loss Detail"
            onPress={() => {
              setCurrView('create');
              openView();
            }}
            disabled={refreshing}
          />
          <FileInput
            emptyText="Upload a complete loss history CSV (replaces all)"
            value={inputValue}
            onChangeValue={(files) => {
              if (files.length) {
                setInputValue(files[0]);
              }
            }}
          />
        </Box>
      ) : null}
      {errors ? (
        <>
          <Text center mb="$2">
            {errors.data}
          </Text>
        </>
      ) : null}
      <Spacing vertical={3} />
      {lossHistory.map((data, i) => (
        <LossHistoryDetail key={data.id} data={data} setCurrView={setCurrView} openView={openView} />
      ))}
      <Box>
        <TextData data={`${lossHistory.length} losses`} />
      </Box>
      <LossHistoryDetailModal
        canEditAndCreate={canEditAndCreate}
        currView={currView}
        setCurrView={setCurrView}
        open={viewOpen && currView}
        closeView={closeView}
        getCurrViewData={getCurrViewData}
        refreshApplicationData={refreshApplicationData}
        lossCauses={lossCauses}
        application={application}
        refreshing={refreshing}
      />
    </LayoutBox>
  );
});

const LossHistoryDetail = memo(function LossHistoryDetail(props) {
  const { data, setCurrView, openView } = props;
  const {
    id, // for setting currView
    // carrier,
    claimNumber,
    status,
    lossCause,
    lossCauseOther,
    // expense,
    // recovery,
    incurred,
    occurredAt,
    // description,
    // createdAt,
    // updatedAt,
  } = data;

  const handleOnPress = useCallback(() => {
    setCurrView(id);
    openView();
  }, [id, setCurrView, openView]);

  const tdProps = {
    labelProps: { size: 'small' },
    invert: true,
    flex: 1,
  };

  return (
    <LayoutBox
      row
      layout="center"
      justifyContent="space-between"
      onPress={handleOnPress}
      padding="$1.5"
      border={{
        width: 1,
        color: '$gray.200',
      }}
      borderRadius={8}
      mb="$1.5"
    >
      <TextData label="Claim No." data={claimNumber} {...tdProps} flex="1.25" />
      <TextData label="Loss Cause" data={lossCause || lossCauseOther} {...tdProps} />
      <TextData label="Incurred" data={incurred ? '$' + incurred : '--'} {...tdProps} />
      <TextData label="Occurred" data={occurredAt ? moment(occurredAt).format('MM-DD-YYYY') : '--'} {...tdProps} />
      <TextData label="Status" data={capitalize(status)} {...tdProps} flex="0.75" />
    </LayoutBox>
  );
});

const LossHistoryDetailModal = memo(function LossHistoryDetailModal(props) {
  const {
    getCurrViewData,
    refreshApplicationData,
    canEditAndCreate = false,
    open,
    closeView,
    currView,
    setCurrView,
    application,
    lossCauses,
    refreshing,
  } = props;

  const [data, setData] = useState({});
  const [editing, setEditing] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const createMode = currView === 'create';

  const rawData = useMemo(() => {
    return getCurrViewData(currView);
  }, [getCurrViewData, currView]);

  const lastRawDataRef = useRef(rawData);
  useEffect(() => {
    lastRawDataRef.current = rawData;
    setData(rawData);
  }, [rawData]);

  useEffect(() => {
    if (open && currView !== 'create') {
      setEditing(false);
    }
  }, [open, currView]);

  const { createApplicationLossHistoryDetail, updateApplicationLossHistoryDetail, deleteApplicationLossHistoryDetailWithId } = useApi();

  const handleDeleteLossDetail = useCallback(
    async (d) => {
      await deleteApplicationLossHistoryDetailWithId(application.id, d.id);
      setConfirmOpen(false);
      closeView();
      setCurrView(null);
      refreshApplicationData(true);
    },
    [deleteApplicationLossHistoryDetailWithId, setConfirmOpen, closeView, setCurrView, refreshApplicationData, application.id]
  );

  const handleAddOrUpdateLossDetail = useCallback(
    async (d) => {
      if (createMode) {
        const result = await createApplicationLossHistoryDetail(application.id, d);
        if (result && result.id) {
          setConfirmOpen(false);
          closeView();
          setCurrView(null);
          refreshApplicationData(true);
        } else {
          throw 'failed to create detail';
        }
      } else {
        await updateApplicationLossHistoryDetail(d);
        setConfirmOpen(false);
        closeView();
        setCurrView(null);
        refreshApplicationData(true);
      }
    },
    [
      createMode,
      updateApplicationLossHistoryDetail,
      createApplicationLossHistoryDetail,
      setConfirmOpen,
      closeView,
      setCurrView,
      refreshApplicationData,
      application.id,
    ]
  );

  const { execute: deleteLossDetail, status: deleteStatus } = useAsync(handleDeleteLossDetail, { immediate: false });
  const {
    execute: addOrUpdateLossDetail,
    status: addUpdateStatus,
    // error: addUpdateError,
  } = useAsync(handleAddOrUpdateLossDetail, {
    immediate: false,
  });

  const handleChangeValue = useEventCallback((valObj) => {
    setData((curr) => ({ ...curr, ...valObj }));
  });

  const handleLossCauseChange = useEventCallback((valObj) => {
    setData((curr) => ({ ...curr, ...valObj, lossCauseOther: null }));
  });

  const handleLossCauseOtherChange = useEventCallback((valObj) => {
    setData((curr) => ({ ...curr, ...valObj, lossCause: 'other' }));
  });

  const handleLocationChange = useEventCallback((valObj) => {
    if (valObj.locationId === '') {
      valObj.locationId = null;
    }

    setData((curr) => ({ ...curr, ...valObj }));
  });

  const readOnly = !(editing || createMode);

  const onConfirm = useEventCallback((confirmed = false) => {
    if (confirmed) {
      if (confirmOpen === 'DELETE') {
        deleteLossDetail(data);
      } else {
        setConfirmOpen(false);
        closeView();
        setCurrView(null);
      }
    } else {
      setConfirmOpen(false);
    }
  });

  const handleClose = useEventCallback(() => {
    if (!readOnly && lastRawDataRef.current !== data && confirmOpen === false) {
      setConfirmOpen('CLOSE');
    } else {
      closeView();
    }
  });

  const onPressDelete = useEventCallback(() => setConfirmOpen('DELETE'));

  const onPressEditOrSaveOrAdd = useEventCallback(() => {
    if (!createMode && !editing) {
      setEditing(true);
      setConfirmOpen(false);
    } else {
      addOrUpdateLossDetail(data);
    }
  });

  const proccessingRequests = deleteStatus === 'pending' || addUpdateStatus === 'pending' || refreshing;

  return (
    <>
      <Dialog
        open={open}
        heading={createMode ? 'Add Loss Detail' : editing ? 'Edit Loss Detail' : 'Loss Detail'}
        styles={{
          dialogBox: {
            marginLeft: ({ theme }) => theme.layout.centeredOverlay.marginLeft,
            width: ({ theme }) => theme.breakpoints({ sm: 640, md: '$mainWidth' }),
            maxWidth: '100%',
            padX: ({ theme }) => theme.breakpoints({ xs: '$2', sm: '$4' }),
          },
        }}
        onClose={handleClose}
        actions={
          <LayoutBox row layout="center-right" justifyContent="space-between" width="100%">
            <LayoutBox row>
              {canEditAndCreate && !createMode ? (
                <Button
                  variant="text"
                  color="$error"
                  onPress={onPressDelete}
                  alignSelf="flex-start"
                  label="Delete"
                  startIcon={<TrashIcon color="$error" />}
                  disabled={proccessingRequests}
                />
              ) : null}
            </LayoutBox>
            <LayoutBox row layout="center-right">
              <Button
                variant="outlined"
                color="$primary"
                onPress={handleClose}
                label={readOnly ? 'Close' : 'Cancel'}
                disabled={proccessingRequests}
              />
              {canEditAndCreate ? (
                <>
                  <Spacing horizontal="3" />
                  <Button
                    label={createMode ? 'Add to application' : !editing ? 'Edit' : 'Save Changes'}
                    alignSelf="flex-end"
                    onPress={onPressEditOrSaveOrAdd}
                    loading={addUpdateStatus === 'pending'}
                    disabled={proccessingRequests}
                  />
                </>
              ) : null}
            </LayoutBox>
          </LayoutBox>
        }
      >
        <Box flex={1} flexDirection="row" flexWrap="wrap" alignSelf="stretch">
          <CustomInput
            label="Carrier"
            value={data.carrier || ''}
            propName="carrier"
            onChangeValue={handleChangeValue}
            hideHelperText
            readOnly={readOnly}
          />
          <CustomInput
            label="Claim Number"
            value={data.claimNumber || ''}
            propName="claimNumber"
            onChangeValue={handleChangeValue}
            hideHelperText
            readOnly={readOnly}
          />
          <CustomInput
            label="Occurred At"
            value={data.occurredAt}
            inputComponent={DateInput}
            propName="occurredAt"
            formatDisplay={formatDateDisplay}
            onChangeValue={handleChangeValue}
            hideHelperText
            readOnly={readOnly}
          />
          <CustomInput
            label="Status"
            value={data.status || ''}
            propName="status"
            type="select"
            onChangeValue={handleChangeValue}
            hideHelperText
            readOnly={readOnly}
            marginTop="$1"
          >
            <MenuItem value="open" selected={data.status === 'open'}>
              Open
            </MenuItem>
            <MenuItem value="closed" selected={data.status === 'closed'}>
              Closed
            </MenuItem>
          </CustomInput>
        </Box>
        <Box>
          <CustomInput
            label="Location"
            value={data.locationId || ''}
            propName="locationId"
            type="select"
            onChangeValue={handleLocationChange}
            formatDisplay={(locId) => {
              const loc = application.locations.find((loc) => loc.id === locId);

              return loc && loc.name;
            }}
            hideHelperText
            readOnly={readOnly}
            marginTop="$1"
            InputProps={{ width: '96%' }}
          >
            {Array.isArray(application.locations)
              ? application.locations.map((loc) => (
                  <MenuItem key={loc.id} value={loc.id} selected={loc.id === data.locationId}>
                    {loc.name}
                  </MenuItem>
                ))
              : null}
            <MenuItem key="no-location" value="" selected={data.locationId === null}>
              n/a
            </MenuItem>
          </CustomInput>
        </Box>
        <Box flex={1} flexDirection="row" flexWrap="wrap" alignSelf="stretch">
          <CustomInput
            label="Loss Cause"
            value={data.lossCause || ''}
            propName="lossCause"
            type="select"
            onChangeValue={handleLossCauseChange}
            hideHelperText
            readOnly={readOnly}
            marginTop="$1"
          >
            {Array.isArray(lossCauses)
              ? lossCauses.map((lc) => (
                  <MenuItem key={lc.id} value={lc.lossCause} selected={lc.lossCause === data.lossCause}>
                    {lc.lossCause}
                  </MenuItem>
                ))
              : null}
            <MenuItem key="other" value="other" selected={data.lossCause === null}>
              Other (enter)
            </MenuItem>
          </CustomInput>
          <CustomInput
            label="Other Cause"
            value={data.lossCauseOther || ''}
            propName="lossCauseOther"
            type="text"
            onChangeValue={handleLossCauseOtherChange}
            hideHelperText
            readOnly={readOnly}
          />
          <CustomInput
            label="Expense"
            value={data.expense || null}
            propName="expense"
            type="number"
            formatDisplay={formatPrice}
            onChangeValue={handleChangeValue}
            hideHelperText
            readOnly={readOnly}
            minWidth="32%"
            InputProps={{ width: '10.5em' }}
          />
          <CustomInput
            label="Recovery"
            value={data.recovery || null}
            propName="recovery"
            type="number"
            formatDisplay={formatPrice}
            onChangeValue={handleChangeValue}
            hideHelperText
            readOnly={readOnly}
            minWidth="32%"
            InputProps={{ width: '10.5em' }}
          />
          <CustomInput
            label="Incurred"
            value={data.incurred || null}
            propName="incurred"
            type="number"
            formatDisplay={formatPrice}
            onChangeValue={handleChangeValue}
            hideHelperText
            readOnly={readOnly}
            minWidth="32%"
            InputProps={{ width: '10.5em' }}
          />
        </Box>
        <Box>
          <CustomInput
            label="Description"
            value={data.description || ''}
            propName="description"
            onChangeValue={handleChangeValue}
            hideHelperText
            readOnly={readOnly}
            InputProps={{ multiline: true, width: '96%' }}
          />
        </Box>
      </Dialog>
      <Dialog
        open={confirmOpen ? true : false}
        heading={confirmOpen === 'DELETE' ? 'Delete Loss History Detail' : 'Discard Edits'}
        prompt={
          confirmOpen === 'DELETE'
            ? 'Please confirm you would like to remove this loss history detail from the application.'
            : 'Your progress will be lost.'
        }
        styles={{
          dialogBox: {
            marginLeft: ({ theme }) => theme.layout.centeredOverlay.marginLeft,
            // width: ({ theme }) => theme.breakpoints({ sm: 640, md: '$mainWidth' }),
            // maxWidth: '100%',
            // padX: ({ theme }) => theme.breakpoints({ xs: '$2', sm: '$4' })
          },
        }}
        onClose={() => {
          if (!proccessingRequests) {
            onConfirm(false);
          }
        }}
        actions={
          <>
            <Button variant="text" color="$primary" onPress={() => onConfirm(false)} disabled={proccessingRequests} label={'Cancel'} />
            <Button
              color="$primary"
              onPress={() => onConfirm(true)}
              disabled={proccessingRequests}
              loading={proccessingRequests}
              label={confirmOpen === 'DELETE' ? 'Yes, DELETE IT' : 'Discard and Close'}
            />
          </>
        }
      />
    </>
  );
});

const formatDateDisplay = (val) => {
  if (val) {
    return moment(val).format('MM-DD-YYYY');
  } else {
    return '--';
  }
};

const formatPrice = (val) => {
  if (val) {
    return formatMoney(val, { prefix: '$' });
  }
  return '--';
};

const CustomInput = memo(function CustomInput(props) {
  const {
    inputComponent = TextField,
    readOnlyComponent = TextData,
    readOnly = false,
    propName,
    onChangeValue: changeValue,
    type = undefined,
    hideHelperText,
    label,
    value,
    formatDisplay,
    minWidth = '48%',
    ...rest
  } = props;

  const onChangeValue = useEventCallback((v) => {
    const next = { [propName]: v };
    changeValue(next);
  });

  const Component = readOnly ? readOnlyComponent : inputComponent;

  const componentProps = {
    styles: { root: { minWidth } },
    label,
    ...(readOnly
      ? {
          data: formatDisplay ? formatDisplay(value) : value,
          invert: true,
          labelProps: { size: 'small' },
        }
      : {
          onChangeValue,
          hideHelperText,
          value,
          type,
        }),
    ...rest,
  };

  return (
    <Box minWidth={minWidth} padding="$1">
      <Component {...componentProps} />
    </Box>
  );
});

const DateInput = memo(({ value, onChangeValue, ...rest }) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const momentValue = useMemo(() => {
    if (value) {
      return moment(value);
    } else {
      return moment();
    }
  }, [value]);
  return (
    <>
      <TextField value={momentValue.format('MM-DD-YYYY')} onPress={() => setDatePickerOpen(true)} {...rest} />
      <Dialog open={datePickerOpen} onClose={() => setDatePickerOpen(false)}>
        <DatePicker
          date={momentValue}
          onChange={(date) => {
            if (datePickerOpen) {
              onChangeValue(date.valueOf());
              setDatePickerOpen(false);
            }
          }}
        />
      </Dialog>
    </>
  );
});

export { ApplicationLossHistory };
