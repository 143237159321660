import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from '../history';
import {
  ApplicationPage,
  QuotePage,
  SiteChecklistPage,
  Dashboard,
  ClaimPage,
  PolicyPage,
  SubmitClaimPage,
  CollateralPage,
  Page404,
  LoginPage,
  SubmitApplicationPage,
} from '../pages';
import { AuthProvider, useApi, useAppState, ProvideNavigateContext, useAuth } from '../hooks';
import { useAppWindowState, useMemoCompare, useAsync } from '../ui/hooks';
import { AppBarPortal, LayoutBox, ProvideSidebarState } from '../components';
import { isObject, LocalStorage, SessionStorage } from '../ui/utils';
import { UserRoles, ASYNC_USER_CACHE_KEY_PREFIX, ASYNC_SETTINGS_KEY_PREFIX, DEFAULT_APP_SETTINGS } from '../constants';
import { ActivityIndicator, ModalContainer } from '../ui';
import { ProtectedRoute } from './ProtectedRoute';
import { cloneDeep } from 'lodash';
import { merge } from 'merge-anything';
import { AdminView } from '../pages/AdminPage/AdminView';

const SIGNIN_REDIRECT_STORAGE_KEY = '@dunvegan:signinredirect';

const AppRoutes = ({ auth }) => {
  return (
    <ApplicationState auth={auth}>
      <AppRouter />
    </ApplicationState>
  );
};

const ApplicationState = ({ auth, children }) => {
  const [loginAttempted, setLoginAttempted] = useState(false);
  const hasUserData = useRef(false);
  // login/auth user data
  const { getAuthState, signinRedirectCallback, renewSession, login, logout } = auth;
  const { getUserData } = useApi();
  const handleLogin = useCallback(async () => {
    if (window && window.location) {
      const currPath = window.location.pathname;
      if (currPath && currPath !== '/' && currPath !== '/login' && currPath !== '/404') {
        await LocalStorage.setItem(SIGNIN_REDIRECT_STORAGE_KEY, currPath);
      }
    }
    login();
  }, [login]);

  const handleLogout = useCallback(async () => {
    try {
      await LocalStorage.setItem(SIGNIN_REDIRECT_STORAGE_KEY, window && window.location ? window.location.pathname : '/');
      const storageKeys = await LocalStorage.getAllKeys();
      if (storageKeys && Array.isArray(storageKeys)) {
        const userCacheKeys = storageKeys.filter((k) => k.startsWith(ASYNC_USER_CACHE_KEY_PREFIX));
        if (userCacheKeys.length) {
          await LocalStorage.multiRemove(userCacheKeys);
        }
      }
      await SessionStorage.clear();
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('async storage error', err);
      }
    }
    logout();
  }, [logout]);
  const getUserState = useCallback(async () => {
    let user = null;
    let loggedIn = false;
    if (window.location.search.includes('state')) {
      const redirectPathname = await LocalStorage.getItem(SIGNIN_REDIRECT_STORAGE_KEY);
      await signinRedirectCallback(window.location.href);
      history.push(redirectPathname ? redirectPathname : '/');
    }
    let { authenticated, expired, renewable } = await getAuthState();
    if (authenticated && expired && renewable) {
      try {
        if (process.env.NODE_ENV !== 'production') {
          console.log('User session expired. Attempting to renew session');
        }
        await renewSession();
        const renewedState = await getAuthState();
        authenticated = renewedState.authenticated;
        expired = renewedState.expired;
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Renew session error', e);
        }
      }
    }
    if (authenticated && !expired) {
      user = await getUserData();
      loggedIn = true;
    }
    return { loggedIn, user };
  }, [getAuthState, renewSession, getUserData, signinRedirectCallback]);

  const { value, error, status, execute } = useAsync(getUserState);
  const userStateData = useMemoCompare(value);
  const userState = useMemo(() => {
    const { user, ...rest } = userStateData ? cloneDeep(userStateData) : {};
    if (user) {
      const permissions = user.roles.map((r) => r.permissions).flat();
      const roles = user.roles.map((r) => r.role);
      user.permissions = permissions;
      user.roles = roles;
      user.isAdmin = roles.includes(UserRoles.admin);
      user.isUnderwriter = roles.includes(UserRoles.underwriter);
      user.isBroker = roles.includes(UserRoles.broker);
      user.isInternal = roles.includes(UserRoles.internal) || user.isAdmin;
      user.isPolicyAnalyst = roles.includes(UserRoles.policyAnalyst);
      user.storageKey = `${ASYNC_USER_CACHE_KEY_PREFIX}:${user.userId}`;
    }
    return { ...rest, user };
  }, [userStateData]);

  // check that the user is still logged in when the app window comes back into focus
  const windowState = useAppWindowState();
  const lastWindowState = useRef(windowState);
  useEffect(() => {
    if (windowState === 'active' && lastWindowState.current !== windowState && status !== 'pending') {
      lastWindowState.current = windowState;
      execute();
    } else {
      lastWindowState.current = windowState;
    }
  }, [windowState, execute, status]);

  // if error with login or relogging in then logout otherwise indicate that the initial login was attempted whether successful or not
  useEffect(() => {
    if (status === 'error' && error && hasUserData.current) {
      handleLogout();
    } else if (status !== 'pending' && !loginAttempted) {
      setLoginAttempted(true);
    }
  }, [status, error, handleLogout, loginAttempted]);

  // update appstate based on user data
  const [settingAppState, setSettingAppState] = useState(true);
  const settings = useRef({});
  const [, setAppState] = useAppState();
  const handleSetAppState = useCallback(
    async (userState) => {
      const globalSettingsKey = `${ASYNC_SETTINGS_KEY_PREFIX}:global`;
      const globalSettings = await LocalStorage.getItem(globalSettingsKey);
      const appSettings = merge(DEFAULT_APP_SETTINGS, globalSettings ? JSON.parse(globalSettingsKey) : {}) || {};
      if (isObject(userState) && isObject(userState.user)) {
        const userSettingsKey = `${ASYNC_SETTINGS_KEY_PREFIX}:${userState.user.userId}`;
        let userSettings = await LocalStorage.getItem(userSettingsKey);
        userSettings = userSettings ? JSON.parse(userSettings) : {};
        if (!isObject(userSettings)) {
          userSettings = {};
        }
        settings.current = merge(appSettings, userSettings);
        setAppState((curr) => ({
          ...curr,
          canViewAdjustmentFactor: userState.user.isUnderwriter || userState.user.isInternal,
          canBind: userState.user.isUnderwriter || userState.user.isInternal,
          canReviewBindForms: userState.user.isInternal,
          canReviewPSCL: userState.user.isInternal,
          canCompletePSCL: userState.user.isBroker,
          settings,
          saveSettings: async (newSettingsObj, global = false) => {
            let newSettings = newSettingsObj;
            if (typeof newSettingsObj === 'function') {
              if (global) {
                newSettings = newSettingsObj(appSettings);
              } else {
                newSettings = newSettingsObj(userSettings);
              }
            }
            if (!isObject(newSettings)) {
              return;
            }
            settings.current = merge(settings.current, newSettings);
            if (process.env.NODE_ENV !== 'production') {
              console.log('DEV: App State Changed:', userSettingsKey, merge(userSettings, newSettings));
            }
            if (global) {
              await LocalStorage.setItem(globalSettingsKey, JSON.stringify(merge(appSettings, newSettings)));
            } else {
              await LocalStorage.setItem(userSettingsKey, JSON.stringify(merge(userSettings, newSettings)));
            }
          },
        }));
      } else {
        settings.current = cloneDeep(appSettings);
        setAppState((curr) => ({
          ...curr,
          canViewAdjustmentFactor: false,
          canBind: false,
          canReviewBindForms: false,
          canReviewPSCL: false,
          canCompletePSCL: true, // assume client. Use case: client needs to fill and submit PSCL without an account
          settings,
          saveSettings: async (newSettingsObj, global = false) => {
            let newSettings = newSettingsObj;
            if (typeof newSettingsObj === 'function') {
              if (global) {
                newSettings = newSettingsObj(appSettings);
              }
            }
            if (!isObject(newSettings)) {
              return;
            }
            settings.current = merge(settings.current, newSettings);
            if (global) {
              await LocalStorage.setItem(globalSettingsKey, JSON.stringify(merge(appSettings, newSettings)));
            }
          },
        }));
      }
      setSettingAppState(false);
      if (process.env.NODE_ENV !== 'production') {
        console.log('DEV: Current App State:', settings.current);
      }
    },
    [setAppState]
  );

  useEffect(() => {
    setSettingAppState(true);
    handleSetAppState(userState);
    if (process.env.NODE_ENV !== 'production') {
      console.log('DEV: User State:', userState);
    }
  }, [userState, handleSetAppState]);
  return (
    <AuthProvider {...auth} login={handleLogin} logout={handleLogout} {...userState}>
      {userState && loginAttempted && !settingAppState ? (
        children
      ) : status === 'pending' || settingAppState ? (
        <LayoutBox absolute size="100%" layout="center">
          <ActivityIndicator />
        </LayoutBox>
      ) : null}
    </AuthProvider>
  );
};

const AppRouter = React.memo(() => {
  const { loggedIn } = useAuth();
  return (
    <Router history={history}>
      <ProvideNavigateContext>
        <ProvideSidebarState open={false}>
          <ModalContainer>
            <AppBarPortal />
            <Switch>
              <Route path="/login">{loggedIn ? <Redirect to="/" /> : <LoginPage />}</Route>
              <Route path="/404">
                <Page404 />
              </Route>
              <ProtectedRoute
                public
                path={[
                  '/site-checklist/:quoteId/:formId/:step/:substep',
                  '/site-checklist/:quoteId/:formId/:step',
                  '/site-checklist/:quoteId/:formId',
                ]}
              >
                <SiteChecklistPage />
              </ProtectedRoute>
              {/* <Route exact path="/">
                <Redirect to="/quotes" />
              </Route> */}
              <ProtectedRoute path={['/', '/:tab']}>
                <Dashboard>
                  <DashboardRoutes />
                </Dashboard>
              </ProtectedRoute>
            </Switch>
          </ModalContainer>
        </ProvideSidebarState>
      </ProvideNavigateContext>
    </Router>
  );
});

const DashboardRoutes = React.memo(() => {
  return (
    <Switch>
      <ProtectedRoute exact path="/applications/:id">
        <ApplicationPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/quotes/:id">
        <QuotePage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/submitapplication">
        <SubmitApplicationPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/collateral">
        <CollateralPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/submitclaim">
        <SubmitClaimPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/claims/:claimId">
        <ClaimPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/policies/:policyId/submitclaim">
        <SubmitClaimPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/policies/:policyId">
        <PolicyPage />
      </ProtectedRoute>
      <ProtectedRoute validate={({ auth }) => auth.loggedIn && auth.user.isInternal} path={['/admin/:tab', '/admin']}>
        <AdminView />
      </ProtectedRoute>
    </Switch>
  );
});

export { AppRoutes };
