import React, { useMemo, useRef, useState } from 'react';
import { Box, Image, Text, Button, Portal, PortalDestination, Menu, MenuItem, IconButton } from '../ui';
import { withStyles, styled, getStyle } from '../ui/styling';
import { layout } from '../ui/system/props';
import { useAuth, useNavigate } from '../hooks';
import { LayoutBox } from './Layout';
import { useSystemValue, useBreakpoint } from '../ui/hooks';
import { BackArrowIcon, ExpandArrowIcon, UserIcon2 } from './icons';
import { isNull } from '../ui/utils';
import { CollateralModal } from '../pages/CollateralPage';

export const AppBar = React.forwardRef(function AppBar(props, ref) {
  const { children, hide = false, disablePortal = false, ...rest } = props;
  const shouldHide = useSystemValue(hide, { asBreakpoint: true, breakpointDirection: 'down' });
  if (!children || shouldHide) {
    return null;
  }
  const contents = (
    <NavBar ref={ref} position="fixed" padTop="$1" {...rest}>
      {children}
    </NavBar>
  );
  if (disablePortal) {
    return contents;
  }
  return <Portal name="_app_bar_">{contents}</Portal>;
});

export const appSidebarPortalName = '_app_side_bar';

export const AppBarPortal = ({ children }) => {
  return (
    <>
      {children}
      <PortalDestination name="_app_bar_" />
      <PortalDestination name={appSidebarPortalName} />
    </>
  );
};

export const Logo = (props) => {
  return <Image src="/images/understory_logo_primary.png" width={45} height={45} {...props} />;
};

export const LogoNavGroup = ({ onPress, title = '', children, ...rest }) => {
  const navigate = useNavigate();
  const handlePress = onPress !== undefined ? (typeof onPress === 'function' ? onPress : null) : () => navigate.to('/');
  return (
    <NavGroup start onPress={handlePress} {...rest}>
      {children}
      <Logo marginRight={title ? '$1' : 0} />
      <Text medium uppercase letterSpacing={1} style={{ fontWeight: 300 }}>
        {title}
      </Text>
    </NavGroup>
  );
};

export const AppBarSpacer = (props) => {
  return (
    <Box
      width="100%"
      height={({ theme }) => theme.sizes.appBarHeight}
      bg={({ theme }) => theme.colors.background}
      display={({ theme }) => theme.breakpoints({ xs: 'flex', sm: 'none' })}
      {...props}
    />
  );
};

export const NavBar = withStyles(
  ({
    minHeight = '$appBarHeight',
    top = 0,
    height = null,
    padX,
    position = 'sticky',
    maxWidth = '100vw',
    transparent = false,
    theme: { colors, spacing, zIndex, breakpoints },
  }) => ({
    root: {
      backgroundColor: transparent ? 'transparent' : colors.background,
      position,
      top,
      minHeight,
      height,
      padX: !isNull(padX) ? padX : breakpoints({ xs: spacing(1.5), sm: spacing(4) }),
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      alignSelf: 'stretch',
      width: '100%',
      maxWidth,
      zIndex: zIndex.header,
      props: {
        pointerEvents: 'box-none',
      },
    },
    placeholder: {
      width: '100%',
      maxWidth,
      height,
      minHeight,
      props: {
        pointerEvents: 'none',
      },
    },
  })
)(
  React.forwardRef(function NavBar(props, ref) {
    const { styles, position, disablePlaceholder = false, ...rest } = props;
    const navbarStyle = getStyle(styles.root);
    const pos = navbarStyle.position ? navbarStyle.position : position;
    if (disablePlaceholder || pos === 'sticky') {
      return <Box ref={ref} {...rest} />;
    }
    return (
      <>
        <Box style={styles.placeholder} {...styles.props.placeholder} />
        <Box ref={ref} {...rest} />
      </>
    );
  })
);

export const NavRow = styled(Box, { filterProps: [...layout.filterProps, 'xsHeight'] })(
  ({ theme, height = '$appBarHeight', xsHeight = undefined, ...props }) => ({
    ...layout({
      maxWidth: '100%',
      width: '100%',
      flexDirection: 'row',
      height: xsHeight !== undefined ? theme.breakpoints({ xs: xsHeight, sm: height }) : height,
      justifyContent: 'space-between',
      alignItems: 'center',
      alignSelf: 'stretch',
      ...props,
    }),
    props: {
      pointerEvents: 'box-none',
    },
  })
);

export const NavGroup = styled(LayoutBox, { filterProps: ['start', 'center', 'end'] })(
  ({ theme: { spacing, breakpoints }, center = false, start = false, end = false, onPress, ...props }) => ({
    ...layout({
      padRight: start
        ? breakpoints({ xs: spacing(1), sm: spacing(6.5) })
        : end
        ? breakpoints({ xs: spacing(2.5), sm: spacing(0.25) })
        : spacing(0.25),
      padLeft: end ? breakpoints({ xs: spacing(1), sm: spacing(5) }) : spacing(0.25),
      padY: spacing(0.5),
      flex: start || end ? null : 1,
      flexDirection: 'row',
      alignSelf: 'stretch',
      ...props,
    }),
    props: {
      layout: center ? 'center' : end ? 'center-right' : 'center-left',
      pointerEvents: onPress ? 'auto' : 'box-none',
    },
  })
);

NavBar.Row = NavRow;
NavBar.Group = NavGroup;

export const TabButton = withStyles(({ selected = false, colorSelected = '$primary', color = '$text', theme: { colors, spacing, breakpoints } }) => ({
  root: {
    padX: spacing(2.25),
    padY: spacing(1.5),
    borderRadius: 8,
    backgroundColor: 'transparent',
    zIndex: selected ? 2 : 1,
  },
  text: {
    props: breakpoints({
      xs: {
        size: 'small',
        dim: selected ? 1 : 0.64,
        color: selected ? colorSelected : color,
        numLines: 1,
      },
      sm: {
        size: 'medium',
        dim: selected ? 1 : 0.64,
        color: selected ? colorSelected : color,
        numLines: 1,
      },
    }),
    fontWeight: selected ? 600 : 500,
  },
}))(
  React.forwardRef(function TabButton(props, ref) {
    const { selected, children, styles, ...rest } = props;
    return (
      <Box {...rest} ref={ref}>
        <Text style={styles.text} {...styles.props.text}>
          {children}
        </Text>
      </Box>
    );
  })
);

export const NavButton = styled(Button)((props) => ({
  props: {
    variant: props.variant || 'outlined',
    size: props.size || 'small',
    color: props.color || '$gray.400',
  },
}));

export const NavBackButton = withStyles(({ theme, color = '$text' }) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    opacity: 0.85,
    alignSelf: 'stretch',
    height: '100%',
    props: theme.breakpoints({
      xs: { breakpoint: 'xs' },
      sm: { breakpoint: 'sm' },
    }),
  },
  icon: { props: { color, marginLeft: '$-0.75', size: 28 } },
  text: { props: { color, padLeft: theme.spacing(0.5), bold: true } },
}))(
  React.forwardRef(function NavBackButton(props, ref) {
    const { autoHideLabel = false, path, breakpoint, onPress, styles, label = 'Back', icon: Icon = BackArrowIcon, ...rest } = props;
    const navigate = useNavigate();
    if (!path && !onPress && !navigate.canGoBack) {
      return null;
    }
    return (
      <Box ref={ref} onPress={onPress ? () => onPress(navigate) : () => navigate.back(path)} {...rest}>
        <Icon {...styles.toProps('icon')} />
        {breakpoint === 'xs' && autoHideLabel ? null : <Text {...styles.toProps('text')}>{label}</Text>}
      </Box>
    );
  })
);

export const UserButton = ({ iconOnly = false, iconOnlyBreakpoint = 'xs', ...props }) => {
  const { logout, user } = useAuth();
  const email = user && user.email ? user.email : '...';
  const label = useMemo(() => {
    return email.split('@')[0];
  }, [email]);

  const btnRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [collateralModalOpen, setCollateralModalOpen] = useState(false);
  const [breakpoint] = useBreakpoint();
  const displayIconOnly = iconOnly ? true : breakpoint.key === iconOnlyBreakpoint;
  return (
    <React.Fragment>
      <Menu open={menuOpen} anchorNode={btnRef.current} onClose={() => setMenuOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <MenuItem
          onPress={() => {
            setMenuOpen(false);
            setCollateralModalOpen(true);
          }}
        >
          Collateral
        </MenuItem>
        <MenuItem
          onPress={() => {
            window.open(`${process.env.REACT_APP_KALIDASA_BASE_URI}`, '_blank');
          }}
        >
          Account
        </MenuItem>
        <MenuItem onPress={logout}>Logout</MenuItem>
      </Menu>
      <CollateralModal open={collateralModalOpen} onClose={() => setCollateralModalOpen(false)} />
      {displayIconOnly ? (
        <IconButton
          ref={btnRef}
          onPress={() => setMenuOpen((c) => !c)}
          color="$gray.500"
          // borderColor="$gray.200"
          // borderWidth="1"
          borderRadius={4}
          maxSize="44"
          {...props}
        >
          <UserIcon2 size="28" />
        </IconButton>
      ) : (
        <NavButton
          ref={btnRef}
          label={label}
          TextProps={{ maxWidth: 100, maxLines: 1 }}
          bg="$white"
          startIcon={<UserIcon2 color="$gray.500" size="28" />}
          endIcon={<ExpandArrowIcon color="$gray.300" size="20" />}
          onPress={() => setMenuOpen((c) => !c)}
          color="$gray.500"
          borderColor="$white"
          {...props}
        />
      )}
    </React.Fragment>
  );
};

// const NotificationsButton = () => {
//   return (
//     <IconButton onPress={() => null} color="$gray.500" borderRadius={4} maxSize="44">
//       <NotificationBellIcon2 size="24" />
//     </IconButton>
//   );
// };

export const AppActionsGroup = ({ children, ...props }) => {
  // return null;
  return (
    <NavGroup end gap="2" {...props}>
      {children}
      {/* <NotificationsButton /> */}
      <UserButton />
    </NavGroup>
  );
};
