import React, { useState, useCallback } from 'react';
import { Box, Text, TextField, Button, Spacing } from '../../ui';
import { inputTests } from '../../ui/utils';
import { Dialog } from '../../components';

export const ReviewSubmitDialog = (props) => {
  const { open, onClose, onSubmit, status, errors, internalSubmission = false } = props;

  const [email, setEmail] = useState('');
  const submitDisabled = !open || (!internalSubmission && (!email || !email.trim()));
  const [error, setError] = useState(null);
  const validate = () => {
    if (!internalSubmission) {
      if (!email || !email.trim()) {
        setError('Required');
        return false;
      } else if (!inputTests.email(email)) {
        setError('Invalid email address');
        return false;
      }
    }
    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (!open || status === 'pending' || !validate()) {
      return;
    }
    onSubmit(email);
  };

  return (
    <Dialog
      open={open}
      onClose={status === 'pending' ? null : onClose}
      heading="Submit Form"
      prompt={
        !internalSubmission
          ? "Please enter a contact email address we may use to update you on the status of your submission. If any issues with the data you've provided are found we will contact you."
          : 'You are about to submit this form and any changes on behalf of the customer. This will put the form in the review stage and they will no longer be able to make changes unless the submission is rejected.'
      }
      actions={
        <Box flexDirection="row" alignItems="center" gap={18}>
          <Button
            label="Cancel"
            variant="text"
            color={status === 'pending' ? '$gray.300' : '$primary'}
            disabled={status === 'pending'}
            onPress={status !== 'pending' ? onClose : null}
          />
          <Button
            label="Submit"
            disabled={submitDisabled || status === 'pending'}
            onPress={submitDisabled || status === 'pending' ? null : handleSubmit}
            loading={status === 'pending'}
            color={submitDisabled ? '$gray.300' : '$primary'}
          />
        </Box>
      }
    >
      {errors && errors.acceptReject ? (
        <>
          <Spacing vertical={1.25} />
          <Text color="$error" bold small>
            {errors.acceptReject}
          </Text>
        </>
      ) : null}
      <Spacing vertical={1} />
      {!internalSubmission ? (
        <TextField
          value={email}
          onChangeValue={(v) => {
            if (status === 'pending') {
              return;
            }
            setEmail(v);
            if (error) {
              validate();
            }
          }}
          disabled={status === 'pending'}
          onBlur={() => validate()}
          label="Email for updates"
          variant="filled"
          error={error}
          helperText={error ? error : null}
        />
      ) : null}
    </Dialog>
  );
};

export const ApproveDialog = (props) => {
  const { open, onClose, onConfirm, status, errors } = props;

  const handleConfirm = useCallback(() => {
    if (!open || status === 'pending') {
      return;
    }
    onConfirm();
  }, [open, status, onConfirm]);

  return (
    <Dialog
      open={open}
      onClose={status === 'pending' ? null : onClose}
      heading="Accept Site Checklist"
      prompt="Please confirm that you would like to approve this Site Checklist."
      actions={
        <Box flexDirection="row" alignItems="center" gap={18}>
          <Button
            label="Cancel"
            variant="text"
            color={status === 'pending' ? '$gray.300' : '$primary'}
            disabled={status === 'pending'}
            onPress={status !== 'pending' ? onClose : null}
          />
          <Button
            label="Confirm"
            disabled={!open || status === 'pending'}
            onPress={status === 'pending' ? null : handleConfirm}
            loading={status === 'pending'}
            color="$primary"
          />
        </Box>
      }
    >
      {errors && errors.acceptReject ? (
        <>
          <Spacing vertical={1.25} />
          <Text color="$error" bold small>
            {errors.acceptReject}
          </Text>
        </>
      ) : null}
    </Dialog>
  );
};

export const RejectDialog = (props) => {
  const { open, onClose, onSubmit, status, errors } = props;

  const [message, setMessage] = useState('');
  const submitDisabled = !open || !message || !message.trim();
  const [error, setError] = useState(null);
  const validate = () => {
    if (!message || !message.trim()) {
      setError('Required');
      return false;
    }
    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (!open || status === 'pending' || !validate()) {
      return;
    }
    onSubmit(message);
  };

  return (
    <Dialog
      open={open}
      onClose={status === 'pending' ? null : onClose}
      heading="Reject Site Checklist"
      prompt="Please provide a reason for the rejection and instructions on how to resolve."
      actions={
        <Box flexDirection="row" alignItems="center" gap={18}>
          <Button
            label="Cancel"
            variant="text"
            color={status === 'pending' ? '$gray.300' : '$primary'}
            disabled={status === 'pending'}
            onPress={status !== 'pending' ? onClose : null}
          />
          <Button
            label="Submit"
            disabled={submitDisabled || status === 'pending'}
            onPress={submitDisabled || status === 'pending' ? null : handleSubmit}
            loading={status === 'pending'}
            color={submitDisabled ? '$gray.300' : '$primary'}
          />
        </Box>
      }
    >
      {errors && errors.acceptReject ? (
        <>
          <Spacing vertical={1.25} />
          <Text color="$error" bold small>
            {errors.acceptReject}
          </Text>
        </>
      ) : null}
      <Spacing vertical={1} />
      <TextField
        value={message}
        onChangeValue={(v) => {
          if (status === 'pending') {
            return;
          }
          setMessage(v);
          if (error) {
            validate();
          }
        }}
        disabled={status === 'pending'}
        onBlur={() => validate()}
        label="Reject Reason"
        variant="filled"
        error={error}
        helperText={error ? error : null}
      />
    </Dialog>
  );
};
