const calculateTotalGrossWrittenPremium = (locations = []) => {
  return locations.reduce((total, location) => {
    return total + location.coverages.reduce((subTotal, coverage) => subTotal + coverage.netPremium, 0);
  }, 0);
};

const getTotalAllInCostValue = (quote) => {
  const collateralFee = quote?.fees.find((fee) => /collateral/.test(fee.name.toLowerCase()));
  const brokerageFee = quote?.fees.find((fee) => /broker/.test(fee.name.toLowerCase()));

  const locations = quote?.locations;
  const collateral = collateralFee?.amount || 0;
  const commission = brokerageFee?.amount || 0;
  const totalGrossPremium = calculateTotalGrossWrittenPremium(locations);
  return +collateral + +commission + +totalGrossPremium;
};

export default getTotalAllInCostValue;
