import React from 'react';
import { Box, Stepper, Step, StepLabel, StepContent, StepConnector, StepIcon, useStepContext } from '../ui';
import { withStyles } from '../ui/styling';

const StepBackdrop = withStyles(({ theme }) => ({
  root: {
    ...theme.layout.absoluteFill,
    backgroundColor: 'white',
    zIndex: -1,
    borderRadius: 10,
    marginLeft: 12,
    shadow: theme.shadows.elevation(30, { opacity: 0.1 }),
    borderWidth: 1,
    borderColor: '$gray.100',
  },
}))((props) => {
  const { styles, ...rest } = props;
  const { active } = useStepContext();
  if (!active) {
    return null;
  }
  return <Box {...rest} />;
});

const CustomStep = React.forwardRef(function CustomStep(props, ref) {
  const { children, ...rest } = props;
  return (
    <Step ref={ref} minHeight="60" {...rest}>
      <StepBackdrop />
      {children}
    </Step>
  );
});

const CustomLabel = React.forwardRef(function CustomLabel(props, ref) {
  return <StepLabel ref={ref} {...props} />;
});

/*

const TStepLabel = withStyles(({ theme }) => ({
  // root: {
  //   backgroundColor: '$white',
  //   shadow: theme.shadows.elevation(30, { opacity: 0.1 }),
  // },
}), { forward: true })(StepLabel);


const StepBackdrop = styled(Box)(({ theme }) => ({
  ...theme.layout.absoluteFill,
  bg: 'white',
  zIndex: -1,
  borderRadius: 10,
  marginLeft: 28,
  shadow: theme.shadows.elevation(30, { opacity: 0.1 }),
  borderWidth: 1,
  borderColor: '$gray.100',
}))
const TestStepper = () => {
  return (
    <Stepper vertical nonLinear activeStep={1}>
      <Step>
        <TStepLabel subtext="Asdfnen">Location Details</TStepLabel>
        <StepBackdrop />
      </Step>
      <Step>
        <StepBackdrop />
        <TStepLabel
          subtext="laksjdlkf sldkfj"
          adornment="expires soon"
          iconColor="orange"
          color="red"
        >Point of Contact</TStepLabel>
        <StepContent>
          {
            // <Text maxWidth="90%" numLines={4} size="small">
            //   You need to do this many things to win at the pre-bind step
            // </Text>
          }
          {
            <Stepper hideIcon vertical activeStep={1}>
              <Step>
                <StepLabel subtext="Asdfnen">Location Details</StepLabel>
              </Step>
              <Step>
                <StepLabel
                  subtext="laksjdlkf sldkfj"
                  adornment="expires soon"
                >Point of Contact</StepLabel>
              </Step>
            </Stepper>
          }

        </StepContent>
      </Step>
      <Step>
        <TStepLabel>Roof Information</TStepLabel>
      </Step>
    </Stepper>
  )
}
*/
export { Stepper, CustomStep as Step, CustomLabel as StepLabel, StepContent, StepConnector, StepIcon };
