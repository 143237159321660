import React from 'react';
import { withStyles } from '../../../../ui/styling';
import { Box, Heading, Paragraph } from '../../../../ui';
import { formatMoney } from '../../../../utils';

export const QuoteStat = withStyles(({ theme }) => ({
  root: {},
  orderStatsRow: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    padding: 16,
    marginBottom: 16,
    flexWrap: 'wrap',
    marginTop: 20,
    textAlign: 'center',
  },
  orderStat: { borderRightWidth: 1, borderRightStyle: 'dotted', borderRightColor: '#919EAB3D' },
  dot: {
    width: 8,
    height: 8,
    backgroundColor: '#1CB24F',
    display: 'inline-block',
    borderRadius: 50,
    textAlignVertical: 'middle',
    marginRight: 2,
    marginBottom: 4,
  },
  orderHeading: {
    color: '#919EAB',
    fontSize: 14,
    lineHeight: 22,
    fontWeight: 500,
  },
  orderText: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 36,
  },
}))(
  React.forwardRef(function QuoteStat(props, ref) {
    const { styles, data, totalAllInCost } = props;
    const { netPremiumTotal, fees, quoteRate, locations } = data;

    const totalInsuredValue = locations.reduce((total, location) => {
      return total + (location.coveredValue || 0);
    }, 0);

    const QuoteData = [
      {
        title: 'Gross Written Premium',
        text: `${formatMoney(netPremiumTotal, { prefix: '$', withDecimals: true })}`,
      },
      {
        title: 'Total All-In-Cost',
        text: `${formatMoney(totalAllInCost, { prefix: '$', withDecimals: true })}`,
      },
      {
        title: 'Rate',
        text: quoteRate ? (Math.abs(quoteRate) / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 3 }) : '',
      },
      {
        title: '∑ Avg Total Insured Value',
        text: `${formatMoney(totalInsuredValue, { prefix: '$', withDecimals: true })}`,
        style: styles.dot,
      },
    ];
    return (
      <Box style={styles.orderStatsRow} borderRadius={8}>
        {QuoteData.map(({ title, text, style }) => (
          <Box style={!style && styles.orderStat} width="25%" key={title}>
            <Heading style={styles.orderHeading} level={6}>
              {title}
            </Heading>
            <Paragraph style={styles.orderText}>
              <Box style={style}></Box> {text}
            </Paragraph>
          </Box>
        ))}
      </Box>
    );
  })
);
