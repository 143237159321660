export function filterProps(props = {}, filterProps = []) {
  // dont include styles or sx by default
  const { styles, sx, ...rest } = props;
  let filtered = {};
  for (const key in rest) {
    if (key[0] === '$') continue;
    if (filterProps.includes(key)) continue;
    filtered[key] = rest[key];
  }
  return filtered;
}
