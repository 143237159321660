import React, { useEffect, useState } from 'react';
import { Grid } from '../../../../ui/components/Grid';
import { currencyFormatter } from './utils';
import { exportToCsv } from '../../../../utils';

export const RiskConcentration = React.forwardRef((props, ref) => {
  const { quotes, concentrations, setGridReference, gridReference, setExportHandler } = props;
  const [rowData, setRowData] = useState([]);

  const columnDefs = [
    {
      headerName: 'Overview',
      children: [
        { field: 'riskId', headerName: 'Location', pinned: true, lockPinned: true },
        { field: 'name', headerName: 'Name', minWidth: 200, pinned: true, lockPinned: true },
        { field: 'address', headerName: 'Address', minWidth: 250, pinned: true, lockPinned: true },
      ],
    },
    {
      headerName: 'Region',
      children: [
        { headerName: 'CSR', field: 'CSR', width: 170 },
        { headerName: 'CRZ', field: 'CRZ', width: 170 },
      ],
    },
    {
      headerName: 'Concentration',
      children: [
        {
          headerName: '5 Miles',
          field: 'miles5',
          valueFormatter: (field) => currencyFormatter({ field }),
          isRequired: false,
          dataType: 'number',
          exportValueFormatter: (params) => params.value,
        },
        {
          headerName: '10 Miles',
          field: 'miles10',
          valueFormatter: (field) => currencyFormatter({ field }),
          isRequired: false,
          dataType: 'number',
        },
        {
          headerName: '25 Miles',
          field: 'miles25',
          valueFormatter: (field) => currencyFormatter({ field }),
          isRequired: false,
          dataType: 'number',
          exportValueFormatter: (params) => params.value,
        },
        {
          headerName: '50 Miles',
          field: 'miles50',
          valueFormatter: (field) => currencyFormatter({ field }),
          isRequired: false,
          dataType: 'number',
          exportValueFormatter: (params) => params.value,
        },
        {
          headerName: '100 Miles',
          field: 'miles100',

          valueFormatter: (field) => currencyFormatter({ field }),
          isRequired: false,
          dataType: 'number',
          exportValueFormatter: (params) => params.value,
        },
      ],
    },
  ];

  const getRiskConcentarionData = (locations, concentrations) => {
    // Map over each location to create the transformed data array
    return locations.map((location) => {
      const locationId = location.id;
      const riskZone = location.riskZones[0];
      const concentrationData = concentrations[locationId] || []; // Get concentration data for the location

      // Create a map of concentration values by radius
      const concentrationMap = concentrationData.reduce((acc, item) => {
        acc[item.radius_mi] = item.local_coverage; // Map radius to local coverage value
        return acc;
      }, {});

      // Return the transformed object for the current location
      return {
        locationId: locationId,
        riskId: location.riskId,
        name: location.name,
        address: location.address,
        CSR: riskZone.id || '',
        CRZ: riskZone.convectiveRatingZone || '',
        miles5: concentrationMap[5] || 0,
        miles10: concentrationMap[10] || 0,
        miles25: concentrationMap[25] || 0,
        miles50: concentrationMap[50] || 0,
        miles100: concentrationMap[100] || 0,
      };
    });
  };

  useEffect(() => {
    if (gridReference && gridReference.api && !gridReference.api.isDestroyed()) {
      const handleGridPreDestroy = () => {
        setGridReference(null);
      };
      gridReference.api.addEventListener('gridPreDestroy', handleGridPreDestroy);

      return () => {
        if (gridReference && gridReference.api && !gridReference.api.isDestroyed()) {
          gridReference.api.removeEventListener('gridPreDestroy', handleGridPreDestroy);
        }
      };
    }
  }, [gridReference]);

  useEffect(() => {
    if (gridReference && gridReference.api && !gridReference.api.isDestroyed()) {
      setExportHandler(() => exportToCsv(gridReference));
    }
  }, [gridReference, rowData]);

  useEffect(() => {
    const transformedData = getRiskConcentarionData(quotes.locations, concentrations);
    setRowData(transformedData);
  }, [quotes, concentrations]);

  return (
    <div className="ag-theme-quartz" style={{ maxHeight: '500px' }}>
      <Grid data={rowData} columns={columnDefs} floatingFilter={false} setGridReference={setGridReference} shouldShowPagination={false} />
    </div>
  );
});
