import { Box } from '../ui';
import { styled } from '../ui/styling';
import { padding, layout, appearance } from '../ui/system/props';
// import { isNull } from '../ui/utils';

// const PageContent = styled(Box, { filterProps: [...padding.filterProps] })((props) => ({
//   borderColor: '$gray.100',
//   borderWidth: 1,
//   borderRadius: 8,
//   ...padding({
//     px: props.theme.breakpoints({ xs: props.theme.spacing(3), sm: props.theme.spacing(7) }),
//     padTop: '$5',
//     padBottom: '$10',
//     ...props,
//   }),
//   flex: 1,
//   alignItems: 'stretch',
//   width: '100%',
//   alignSelf: 'stretch',
//   shadow: props.theme.shadows.elevation(30, { opacity: 0.1 }),
// }));

const PageContent = styled(Box, { filterProps: [...padding.filterProps] })((props) => ({
  borderColor: '$gray.200',
  borderWidth: 1,
  borderRadius: 8,
  ...padding({
    px: props.theme.breakpoints({ xs: props.theme.spacing(3), sm: props.theme.spacing(7) }),
    padTop: '$5',
    padBottom: '$10',
    ...props,
  }),
  flex: 1,
  alignItems: 'stretch',
  width: '100%',
  alignSelf: 'stretch',
  // shadow: props.theme.shadows.elevation(30, { opacity: 0.1 }),
}));

const ContentCard = styled(Box, { filterProps: [...layout.filterProps, ...appearance.filterProps] })((props) => ({
  ...appearance({
    shadow: props.theme.shadows.elevation(30, { opacity: 0.1 }),
    borderColor: '$gray.100',
    borderWidth: 1,
    borderRadius: 8,
    ...props,
  }),
  ...layout({
    px: props.theme.breakpoints({ xs: props.theme.spacing(3), sm: props.theme.spacing(5.5) }),
    padTop: '$6',
    padBottom: '$8',
    alignItems: 'stretch',
    width: '100%',
    alignSelf: 'stretch',
    ...props,
  }),
}));

export { PageContent, ContentCard };
