import React, { useState } from 'react';
import { Platform } from 'react-native';
import { useEventCallback } from '../../hooks';
import { isHoverEnabled } from '../../utils';

function useHoverable({
  onHoverOut,
  onHoverIn,
  onHoverChange,
  onResponderGrant: propOnResponderGrant,
  onResponderRelease: propOnResponderRelease,
  hovered: forceHovered = undefined,
} = {}) {
  const [isHovered, setIsHovered] = useState(false);
  const [showHover, setShowHover] = useState(true);
  const onMouseEnter = useEventCallback((...args) => {
    if (isHoverEnabled() && !isHovered) {
      setIsHovered(true);
      if (onHoverIn) {
        onHoverIn(...args);
      }
      if (onHoverChange) {
        onHoverChange(true);
      }
    }
  });

  const onMouseLeave = useEventCallback((...args) => {
    if (isHovered) {
      setIsHovered(false);
      if (onHoverOut) {
        onHoverOut(...args);
      }
      if (onHoverChange) {
        onHoverChange(false);
      }
    }
  });

  const onResponderGrant = useEventCallback((...args) => {
    setShowHover(false);
    if (propOnResponderGrant) {
      propOnResponderGrant(...args);
    }
  });

  const onResponderRelease = useEventCallback((...args) => {
    setShowHover(true);
    if (propOnResponderRelease) {
      propOnResponderRelease(...args);
    }
  });

  const hovered = typeof forceHovered === 'boolean' ? forceHovered : isHovered && showHover;

  return Platform.OS === 'web'
    ? {
        hovered,
        onMouseEnter,
        onMouseLeave,
        onResponderGrant,
        onResponderRelease,
      }
    : { hovered };
}

const Hoverable = (props) => {
  const { onHoverIn, onHoverOut, onHoverChange, onResponderGrant, onResponderRelease, hovered: forceHovered, children } = props;

  const { hovered, ...handlers } = useHoverable({
    onHoverIn,
    onHoverOut,
    onHoverChange,
    onResponderGrant,
    onResponderRelease,
    hovered: forceHovered,
  });

  const child = typeof children === 'function' ? children({ hovered }) : children;

  return React.cloneElement(React.Children.only(child), { ...handlers });
};

Hoverable.displayName = 'Hoverable';

export { Hoverable, useHoverable };
