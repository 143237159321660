import React, { useMemo } from 'react';
import { Box, Text, Heading, Button, ScrollView, ActivityIndicator, Checkbox } from '../../../ui';
import { useMemoCompare } from '../../../ui/hooks';
import { withStyles } from '../../../ui/styling';
import { padding } from '../../../ui/system/props';
import { isObject, isNull } from '../../../ui/utils';
import { useNavigate } from '../../../hooks';
import { ContentCard } from '../../../components';

export const Section = withStyles(
  ({ theme, heading, subheading, size = 'medium', scroll = false, maxWidth, ...props }) => {
    const small = size === 'small';
    const xSmall = size === 'xSmall';
    const medium = size !== 'small' && size !== 'xSmall';
    return {
      root: {
        flexDirection: 'column',
        maxWidth,
      },
      contentPadding: {
        ...padding({
          padTop: theme.spacing(!medium ? 0.5 : 1.5),
          // padTop: theme.spacing(!medium ? 0.5 : 1.5),
          // padBottom: theme.spacing(!medium ? 4 : 7),
          ...props,
        }),
      },
      heading: {
        padBottom: !subheading ? theme.spacing(xSmall ? 1.5 : small ? 2.5 : 3.5) : theme.spacing(xSmall ? 0 : small ? 0.75 : 1),
        props: {
          level: xSmall ? 4 : small ? 3 : 3,
          size: !medium ? 'small' : 'large',
        },
      },
      subheading: {
        padBottom: theme.spacing(!medium ? 2.5 : 3.5),
        maxWidth: maxWidth ? maxWidth : 700,
        props: {
          size: xSmall ? 'small' : small ? 'medium' : 'large',
        },
      },
    };
  },
  { filterProps: [...padding.filterProps], preserveStyleProp: true }
)(
  React.forwardRef(function Section(props, ref) {
    const { heading, subheading, styles, style, children, actions = null, overrideActions = null, scroll = false, ...rest } = props;
    const ContentWrapper = scroll ? ScrollView : Box;
    const contentWrapperProps = scroll
      ? { ...rest, contentContaineStyle: styles.contentPadding, ref }
      : { ...rest, style: [styles.root, styles.contentPadding, style], disableAnimationDefaults: true, ref };
    return (
      <ContentWrapper {...contentWrapperProps}>
        {heading ? (
          <Heading style={styles.heading} {...styles.props.heading}>
            {heading}
          </Heading>
        ) : null}
        {subheading ? (
          <Text style={styles.subheading} {...styles.props.subheading}>
            {subheading}
          </Text>
        ) : null}
        {children}
        {isObject(actions) || isObject(overrideActions) ? <SectionActions {...actions} {...overrideActions} /> : null}
      </ContentWrapper>
    );
  })
);

const SectionActions = withStyles(({ theme }) => {
  return {
    root: {
      width: '100%',
      padX: theme.spacing(1.5),
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padTop: '$3',
      marginTop: '$4',
      borderTopWidth: 1,
      borderTopColor: '$gray.200',
    },
    actionsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      props: {
        gap: theme.spacing(1.5),
      },
    },
  };
})(({ left, right, styles, onPressAction, onPressLeft: onPressLeftProp, onPressRight, ButtonProps, ...rest }) => {
  const navigate = useNavigate();
  const defaultActions = useMemo(
    () => ({
      left: [
        {
          label: '←   Back',
          variant: 'text',
        },
      ],
      right: [
        {
          label: 'Continue',
        },
      ],
      onPressLeft: () => navigate.back(),
    }),
    [navigate]
  );

  const propActions = useMemoCompare({ left, right });
  const buttonProps = { size: 'large', ...ButtonProps };
  const onPressLeft = onPressLeftProp ? onPressLeftProp : defaultActions.onPressLeft;

  const actions = useMemo(() => {
    let leftActions = [];
    let rightActions = [];
    if (!Array.isArray(propActions.left)) {
      leftActions = defaultActions.left;
    } else {
      for (let i = 0; i < propActions.left.length; i += 1) {
        if (i < defaultActions.left.length) {
          leftActions.push({ ...defaultActions.left[i], ...propActions.left[i] });
        } else {
          leftActions.push(propActions.left[i]);
        }
      }
    }
    if (!Array.isArray(propActions.right)) {
      rightActions = defaultActions.right;
    } else {
      for (let i = 0; i < propActions.right.length; i += 1) {
        if (i < defaultActions.right.length) {
          rightActions.push({ ...defaultActions.right[i], ...propActions.right[i] });
        } else {
          rightActions.push(propActions.right[i]);
        }
      }
    }
    return {
      left: leftActions,
      right: rightActions,
    };
  }, [defaultActions, propActions]);

  return (
    <Box {...rest}>
      <Box {...styles.toProps('actionsContainer')}>
        {actions.left.map(({ component: Component = Button, ...rest }, i) => (
          <Component
            key={`${i}leftActions`}
            onPress={onPressLeft && (() => onPressLeft(i))}
            {...(Component === Button ? buttonProps : null)}
            {...rest}
          />
        ))}
      </Box>
      <Box {...styles.toProps('actionsContainer')}>
        {actions.right.map(({ component: Component = Button, ...rest }, i) => (
          <Component
            key={`${i}rightActions`}
            onPress={onPressRight && (() => onPressRight(i))}
            {...(Component === Button ? buttonProps : null)}
            {...rest}
          />
        ))}
      </Box>
    </Box>
  );
});

export const ButtonOptionList = withStyles(({ theme, centered }) => ({
  root: {
    alignItems: centered ? 'center' : 'flex-start',
    props: {
      gap: theme.spacing(1.5),
    },
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padLeft: centered ? theme.spacing(6) : theme.spacing(2),
    padRight: theme.spacing(6),
    padY: theme.spacing(3),
    shadow: theme.shadows.elevation(10, { opacity: 0.12 }),
    borderWidth: 2,
    borderColor: 'transparent',
    borderRadius: 8,
    backgroundColor: '#ffffff',
  },
  selected: {
    borderColor: theme.colors.secondary,
    backgroundColor: theme.colors.alpha('$secondary', 0.15),
  },
  label: {
    props: {
      align: centered ? 'center' : null,
      size: 'large',
      bold: false,
    },
  },
}))(({
  options,
  selected,
  disableSelectedIndicator = false,
  onSelect,
  styles,
  centered,
  disabled = false,
  loading = false,
  disableDeselect = false,
  ...rest
}) => {
  return (
    <Box {...rest}>
      {options.map((option, i) => {
        const label = typeof option === 'string' ? option : option.label;
        const name = typeof option === 'string' ? option : !isNull(option.name) ? option.name : label;
        const value = typeof option === 'string' ? option : !isNull(option.value) ? option.value : name;
        const isSelected = name === selected || value === selected;
        return (
          <Box
            key={`optionitem${name}${i}`}
            {...styles.toProps({ button: true, selected: !disableSelectedIndicator && isSelected })}
            disabled={disabled}
            onPress={() => {
              if (onSelect && !disabled) {
                if (isSelected && !disableDeselect) {
                  onSelect(null);
                } else {
                  onSelect(value);
                }
              }
            }}
          >
            <Text {...styles.toProps('label')}>{label}</Text>
            {loading && isSelected ? <ActivityIndicator /> : null}
          </Box>
        );
      })}
    </Box>
  );
});

export const Checklist = ({ items, checkboxProps, ...rest }) => {
  return (
    <Box alignItems="flex-start" maxWidth={600} padLeft="$1.5" {...rest}>
      {items.map((item, i) => (
        <Checkbox label={item} key={`checklistboxitem${i}`} {...checkboxProps} />
      ))}
    </Box>
  );
};

export const BulletList = ({ items = [], color, textProps, itemProps, ...rest }) => {
  return (
    <Box gap={16} padLeft="$1.5" {...rest}>
      {items.map((item, i) => (
        <BulletListItem color={color} textProps={textProps} key={`bulletListItem${i}`} {...itemProps}>
          {item}
        </BulletListItem>
      ))}
    </Box>
  );
};

export const BulletListItem = ({ children, textProps, color = '$gray.500' }) => {
  return (
    <Box flexDirection="row" alignItems="center" gap={16}>
      <Box size="5" backgroundColor={color} borderRadius="$circle" opacity={0.75} />
      {typeof children === 'string' ? (
        <Text small bold color={color} {...textProps}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Box>
  );
};

export const ReviewItem = withStyles(({ theme, action, ActionComponent = Button }) => ({
  root: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    alignSelf: 'stretch',
    padY: theme.spacing(1),
    bg: 'white',
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  header: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  heading: {
    props: {
      level: 6,
      size: 'small',
      weight: '$extraBold',
      uppercase: true,
      color: '$gray.300',
    },
  },
  content: {
    flex: 1,
  },
  action: {
    props:
      action && ActionComponent === Button
        ? {
            variant: 'outlined',
            color: '$primary',
            // TextProps: { uppercase: true },
          }
        : null,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    padLeft: theme.spacing(2),
  },
}))(
  React.forwardRef(function ReviewItem(props, ref) {
    const { styles, heading, action, ActionComponent = Button, children, ...rest } = props;

    return (
      <Box {...rest} ref={ref}>
        <Box {...styles.toProps('contentContainer')}>
          <Box {...styles.toProps('content')}>
            {heading ? (
              <Box {...styles.toProps('header')}>
                <Heading {...styles.toProps('heading')}>{heading}</Heading>
              </Box>
            ) : null}
            {children}
          </Box>
          <Box {...styles.toProps('actionContainer')}>
            {typeof action === 'object' && ActionComponent ? <ActionComponent {...styles.toProps('action')} {...action} /> : action}
          </Box>
        </Box>
      </Box>
    );
  })
);

export const ReviewCard = withStyles(({ theme, children, heading, subheading, headerAction, ActionComponent = Button }) => {
  return {
    root: {
      px: theme.spacing(6),
      py: theme.spacing(4),
      bg: 'white',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padBottom: 2,
      minHeight: 50,
    },
    subHeader: {
      padTop: theme.spacing(2),
    },
    content: {
      padTop: children && (heading || subheading || headerAction) ? theme.spacing(3) : 0,
      alignSelf: 'stretch',
      props: {
        gap: theme.spacing(2),
        gapBorder: { width: 1, color: '$gray.200' },
      },
    },
    heading: {
      props: {
        level: 4,
      },
    },
    headingActionContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      padLeft: theme.spacing(2),
    },
    action: {
      props:
        headerAction && ActionComponent === Button
          ? {
              variant: 'outlined',
              color: '$primary',
              size: 'large',
              // TextProps: { uppercase: true },
            }
          : null,
    },
    footerActions: {
      width: '100%',
      padX: theme.spacing(1.5),
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padTop: '$3',
      marginTop: '$4',
      borderTopWidth: 1,
      borderTopColor: '$gray.200',
    },
    actionsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      props: {
        gap: theme.spacing(1.5),
      },
    },
  };
})(
  React.forwardRef(function ReviewCard(props, ref) {
    const { styles, heading, headerAction, ActionComponent = Button, subheading, footerActions, children, ...rest } = props;

    return (
      <ContentCard ref={ref} {...rest}>
        {heading || headerAction ? (
          <Box {...styles.toProps('header')}>
            <Heading {...styles.toProps('heading')}>{heading}</Heading>
            <Box {...styles.toProps('headingActionContainer')}>
              {typeof headerAction === 'object' && ActionComponent ? (
                <ActionComponent {...styles.toProps('action')} {...headerAction} />
              ) : (
                headerAction
              )}
            </Box>
          </Box>
        ) : null}
        {subheading ? (
          <Box {...styles.toProps('subhHeader')}>
            <Text maxWidth="85%">{subheading}</Text>
          </Box>
        ) : null}
        <Box {...styles.toProps('content')}>{children}</Box>
        {footerActions ? (
          <Box {...styles.toProps('footerActions')}>
            <Box {...styles.toProps('actionsContainer')}>
              {footerActions.left
                ? footerActions.left.map(({ component: Component = Button, ...rest }, i) => <Component key={`${i}leftActions`} {...rest} />)
                : null}
            </Box>
            <Box {...styles.toProps('actionsContainer')}>
              {footerActions.right
                ? footerActions.right.map(({ component: Component = Button, ...rest }, i) => <Component key={`${i}rightActions`} {...rest} />)
                : null}
            </Box>
          </Box>
        ) : null}
      </ContentCard>
    );
  })
);

export const ReviewCardContentList = (props) => (
  <Box
    alignSelf="stretch"
    gap={16}
    gapBorder={{
      width: 1,
      color: '$gray.200',
    }}
    {...props}
  />
);
