import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { useApi } from '../../../../hooks/useApi';
import { useAsync } from '../../../../hooks/useAsync';
import { useEventCallback } from '../../../../ui/hooks';
import { debounce, isEmpty, isNull } from '../../../../ui/utils';
import getQuoteWithCoverages from '../getQuoteWithCoverages';
import getAdjustmentPayload from './getAdjustmentPayload';
import { AdjustmentContext } from './AdjustmentContext';

const AdjustmentPreviewContext = createContext(null);

function useAdjustmentPreview() {
  return useContext(AdjustmentPreviewContext) || {};
}

function ProvideAdjustmentPreview({ defaultData, quoteId, submitting, children }) {
  const { canPreview } = useContext(AdjustmentContext);
  const { isDirty } = useFormState();
  const watchedData = useWatch({ defaultValue: defaultData });
  // const valid = useMemo(() => {
  //   if (isValid) {
  //     return true;
  //   }
  //   console.log('VALID', errors.note && !errors.quote);
  //   if (errors && errors.note && !errors.quote) {
  //     return true;
  //   }
  //   // TODO: ^^ adding a location without prefilled data results in isValid = false, but no errors are included? (so errors.note && !errors.quote results in true, but should be false with errors.quote)
  //   return false;
  // }, [isValid, errors]);
  // const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const formData = useDebounce(watchedData, 600);
  const wasDirty = useRef(isDirty);
  //const canPreview = 0; // (isDirty || wasDirty.current) && valid && !submitting && !isSubmitting && !submitDialogOpen;

  const lastValue = useRef(defaultData && defaultData.quote ? defaultData.quote : null);
  const { getPreviewOfAdjustmentOnQuoteId } = useApi();
  const previewAdjustmentRequest = useCallback(
    async (data) => {
      if (!data || !data.quote) {
        console.log('ERROR with adjustment preview. No data', data);
        return null;
      }
      const { notes, quote } = data;
      console.log(data);
      const payload = getAdjustmentPayload({ notes, quote });
      console.log('submitting preview adjustment with payload', payload);
      const adjustment = await getPreviewOfAdjustmentOnQuoteId(quoteId, payload);
      console.log('adjustment?', adjustment);
      if (!isNull(adjustment) && !isEmpty(adjustment) && adjustment.id) {
        return getQuoteWithCoverages(adjustment);
      }
      return null;
    },
    [quoteId, getPreviewOfAdjustmentOnQuoteId]
  );

  const { value, status, execute, error } = useAsync(previewAdjustmentRequest, { immediate: false });

  useEffect(() => {
    if (!isNull(value) && !isEmpty(value)) {
      lastValue.current = value;
    } else if (!lastValue.current && defaultData && defaultData.quote) {
      lastValue.current = defaultData.quote;
    }
  }, [value, defaultData]);

  const getIsDirty = useEventCallback(() => isDirty);
  useEffect(() => {
    if (canPreview) {
      wasDirty.current = getIsDirty();
      execute(formData);
    }
  }, [canPreview, formData, execute, getIsDirty]);

  const context = useMemo(() => {
    return {
      preview: value,
      lastPreview: lastValue.current,
      status,
      error,
      // setSubmitDialogOpen,
    };
  }, [value, status, error]);

  return <AdjustmentPreviewContext.Provider value={context}>{children}</AdjustmentPreviewContext.Provider>;
}

function useDebounce(value, delay) {
  const mounted = useRef(true);
  useEffect(() => {
    return () => (mounted.current = false);
  }, []);

  const [state, setState] = useState(value);

  useEffect(() => {
    const debounceSetState = debounce(() => {
      mounted.current && setState(value);
    }, delay);

    debounceSetState();

    return () => {
      debounceSetState.clear();
    };
  }, [value, delay]);

  return state;
}

export { AdjustmentPreviewContext, useAdjustmentPreview, ProvideAdjustmentPreview };
