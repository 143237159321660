import React from 'react';
import { Box, Text } from '../../ui';
import { withStyles } from '../../ui/styling';
import { ListHead, ListHeading, ListHeadRow, Main } from '../../components';
import { useApi, useAsync } from '../../hooks';
import { formatMoney } from '../../utils';

export const AnalyticsView = withStyles(({ theme }) => ({
  progressOverlay: {
    ...theme.layout.absoluteFill,
    bottom: 0,
    top: null,
    height: 3,
    borderRadius: 6,
  },
}))(({ styles, props }) => {
  const { getClaims, getPolicies, getProducts } = useApi();
  const { value } = useAsync(getClaims);
  const { value: policyValue } = useAsync(getPolicies);
  const { value: productValue } = useAsync(getProducts);

  const claims = value ? value : [];
  const policies = policyValue ? policyValue : [];
  const products = productValue ? productValue : [];

  claims.forEach((c) => {
    c.policy = policies.find((p) => p.id === c.policyId);
    c.location = c.policy ? c.policy.locations.find((l) => l.id === c.locationId) : null;
  });

  products.forEach((p) => {
    p.policies = policies.filter((pol) => pol.product.id === p.id);
    p.claims = claims.filter((c) => (c.policy ? c.policy.product.id === p.id : false));
    p.premium = p.policies.reduce((a, p) => a + p.netPremium, 0);
  });
  products.sort((a, b) => (b.premium > a.premium ? 1 : b.premium < a.premium ? -1 : a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

  const totalPremium = policies.reduce((a, p) => a + p.netPremium, 0);

  return (
    <Main padTop={({ theme }) => theme.sizes.appBarHeight + theme.spacing(1)}>
      <ListHead
        sx={({ spacing, sizes, colors }) => ({
          bg: colors.white,
          padTop: sizes.appBarHeight,
          position: 'sticky',
          top: 0,
          zIndex: '$header',
        })}
      >
        <ListHeadRow>
          <ListHeading>Analytics</ListHeading>
        </ListHeadRow>
      </ListHead>
      <ProductAnalyticsList product={{ name: 'Portfolio', policies: policies, claims: claims }} totalPremium={totalPremium} />
      {products.map((product) => (
        <ProductAnalyticsList product={product} totalPremium={totalPremium} key={product.id} />
      ))}
    </Main>
  );
});

const ProductAnalyticsList = withStyles(({ theme }) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.colors.opacity('$gray.300', 0.3),
    px: theme.spacing(3),
    py: theme.spacing(2.75),
    marginBottom: theme.spacing(3),
    bg: theme.colors.white,
    // shadow: theme.shadows.elevation(30, { opacity: 0.1 }),
    props: {
      gap: 0,
    },
  },
  progressOverlay: {
    ...theme.layout.absoluteFill,
    bottom: 0,
    top: null,
    height: 3,
    borderRadius: 6,
  },
}))(({ styles, product, totalPremium }) => {
  const closedClaims = product.claims.filter((c) => c.status === 'accepted');
  const openClaims = product.claims.filter((c) => c.status === 'open' || c.status === 'adjusting');

  const premium = product.policies.reduce((a, p) => a + p.netPremium, 0);
  const losses = closedClaims.reduce((a, c) => a + c.finalDamage, 0);
  const openLosses = openClaims.reduce((a, c) => a + c.expectedDamage, 0);
  const lossRatio = premium > 0 ? losses / premium : 0;

  const closeTime =
    closedClaims.length > 0
      ? Math.round(closedClaims.reduce((a, c) => a + (c.closedAt - c.openedAt), 0) / closedClaims.length / 1000 / 60 / 60 / 24)
      : 0;

  return (
    <Box flexDirection="column" style={styles.root}>
      <Text variant="h4" marginBottom="$4">
        {product.name}
      </Text>
      <Box flexDirection="row">
        <Box flexDirection="column" marginRight="$4">
          <Text style={{ fontWeight: 600 }}>Loss Ratio</Text>
          <Text
            variant="h1"
            size="small"
            style={{ fontWeight: 600 }}
            color={lossRatio >= 0.65 ? '$orange.main' : lossRatio >= 0.35 ? '$orange.light' : '$secondary.main'}
          >
            {lossRatio < 0.05 ? Math.round(lossRatio * 1000) / 10 : Math.round(lossRatio * 100)}
            <Text variant="h3">%</Text>
          </Text>
        </Box>
        <Box flexDirection="column" marginRight="$4">
          <Text style={{ fontWeight: 600 }}>Premium</Text>
          <Text variant="h1" size="small" style={{ fontWeight: 600 }} color={premium === 0 ? '$orange.light' : '$secondary.main'}>
            {formatMoney(premium, { prefix: '$' })}
          </Text>
        </Box>
      </Box>
      <Box flexDirection="row" marginTop="$4">
        <Box flexDirection="column" marginRight="$4">
          <Text style={{ fontWeight: 600 }}>Closed Claims</Text>
          <Text variant="h1" size="small" style={{ fontWeight: 600 }} color="$primary">
            {closedClaims.length}
          </Text>
        </Box>
        <Box flexDirection="column" marginRight="$4">
          <Text style={{ fontWeight: 600 }}>Closed Claim Losses</Text>
          <Text variant="h1" size="small" style={{ fontWeight: 600 }} color="$primary">
            {formatMoney(losses, { prefix: '$' })}
          </Text>
        </Box>
        <Box flexDirection="column" marginRight="$4">
          <Text style={{ fontWeight: 600 }}>Open Claims</Text>
          <Text variant="h1" size="small" style={{ fontWeight: 600 }} color="$primary">
            {openClaims.length}
          </Text>
        </Box>
        <Box flexDirection="column" marginRight="$4">
          <Text style={{ fontWeight: 600 }}>Open Claim Losses</Text>
          <Text variant="h1" size="small" style={{ fontWeight: 600 }} color="$primary">
            {formatMoney(openLosses, { prefix: '$' })}
          </Text>
        </Box>
        <Box flexDirection="column" marginRight="$4">
          <Text style={{ fontWeight: 600 }}>Avg Claim Close Time</Text>
          <Text variant="h1" size="small" style={{ fontWeight: 600 }} color={closeTime >= 30 ? '$orange.light' : '$secondary.main'}>
            {closeTime} <Text variant="h3">days</Text>
          </Text>
        </Box>
      </Box>
      <Box
        style={styles.progressOverlay}
        {...styles.props.progressOverlay}
        bg={'$primary'}
        maxWidth={`${Math.round((premium / totalPremium) * 100)}%`}
      />
    </Box>
  );
});
