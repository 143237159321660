import React, { useMemo, useRef, useState } from 'react';
import { View, StyleSheet, unstable_createElement, Platform } from 'react-native';
import { Pressable } from '../Pressable';
import { Touchable } from '../Touchable';
import { styled, getStyle, withStyles, animated, isPossibleStyle } from '../../styling';
import { isNull, isObject } from '../../utils';
import { ViewStylePropTypes } from '../../system';
import { merge } from 'merge-anything';
import { useEventCallback, useId, useImperativeRefWithProps } from '../../hooks';

const baseStyles = StyleSheet.create({
  view: {
    alignItems: 'stretch',
    // borderTopWidth: 0,
    // borderBottomWidth: 0,
    // borderLeftWidth: 0,
    // borderRightWidth: 0,
    // borderTopColor: 'transparent',
    // borderBottomColor: 'transparent',
    // borderLeftColor: 'transparent',
    // borderRightColor: 'transparent',
    // borderTopStyle: 'solid',
    // borderLeftStyle: 'solid',
    // borderRightStyle: 'solid',
    // borderBottomStyle: 'solid',
    display: 'flex',
    // flexBasis: 'auto',
    flexDirection: 'column',
    // flexShrink: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    minWidth: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative',
    zIndex: 0,
    ...(Platform.OS === 'web' ? { boxSizing: 'border-box' } : {}),
  },
  web: { textDecorationLine: 'none' },
});

const webStyle = Platform.OS === 'web' ? baseStyles.web : null;

const AnimatedView = animated(View);
AnimatedView.displayName = 'animated(View)';

const BaseDiv = ({ innerRef, styles, accessibility, className, domClassName, log, ...props }) => {
  let classes = typeof className === 'string' ? className : '';
  if (domClassName) {
    classes = `${domClassName} ${classes}`;
  }
  return <div {...accessibility} {...props} className={classes} ref={innerRef} />;
};

const RDiv = (props) => unstable_createElement(BaseDiv, props);
export const AnimatedDiv = animated(RDiv);
AnimatedDiv.displayName = 'animated(div)';

export const BoxDiv = styled(AnimatedDiv)({
  flexBasis: 'auto',
  // alignItems: 'stretch',
  // boxSizing: 'border-box',
  // border: '0 solid black',
  // display: 'flex',
  // flexBasis: 'auto',
  // flexDirection: 'column',
  // flexShrink: 0,
  // margin: 0,
  // minHeight: 0,
  // minWidth: 0,
  // padding: 0,
  // position: 'relative',
  // zIndex: 0
});

const Box = withStyles(
  {
    root: (props) => {
      const stylesPassed = getStyle(props.style);
      const defaultOpacity = !isNull(stylesPassed.opacity) ? stylesPassed.opacity : !isNull(props.opacity) ? props.opacity : 1;
      const styles = {
        // flexBasis: 'auto',
        ...(Platform.OS === 'web' ? { cursor: props.cursor || (props.onPress ? 'pointer' : null) } : {}),
        animations: props.disableAnimationDefaults
          ? props.animations
          : merge(
              {
                initial: false,
                hovered: {
                  opacity: defaultOpacity >= 0.74 ? 0.7 : 0.85,
                },
                pressed: {
                  opacity: defaultOpacity >= 0.74 ? 0.5 : 1,
                },
                focused: {},
                default: {
                  opacity: defaultOpacity,
                  delay: 80,
                },
                ...props.animations,
              },
              props.animations && isObject(props.animations) ? props.animations : {}
            ),
      };
      for (const key in ViewStylePropTypes) {
        if (props[key] !== undefined) {
          styles[key] = props[key];
        }
      }
      return styles;
    },
    focusVisible: {},
  },
  { name: 'Box', filterProps: Object.keys(ViewStylePropTypes) }
)(
  React.forwardRef((props, forwardedRef) => {
    const {
      onPress,
      onPressIn,
      onPressOut,
      onLongPress,
      onHoverIn,
      onHoverOut,
      onFocusVisible,
      disabled,
      gap,
      style,
      gapBorder,
      debug,
      children,
      debugMe,
      renderBefore,
      renderAfter,
      component: Component = AnimatedView,
      disableAnimationDefaults,
      className, // only for rendering a regular div (BoxDiv). RNWeb ignores className so need to pass as domClassName
      hovered = undefined, // if parent is <Hoverable> apply to animate if null
      href: hrefProp,
      hrefAttrs,
      accessibility: accessibilityProp,
      focusable: focusableProp = false,
      accessible: accessibleProp = false,
      id = props.nativeID, // translates to RN nativeId
      nativeID, // prefer id ^^
      onFocus,
      onBlur,
      ...boxProps
    } = props;
    if (debugMe) {
      console.log(props);
    }
    if (Component === BoxDiv && className !== null && className !== '') {
      boxProps.domClassName = className;
    }

    const boxId = useId(id, 'zuiBox');
    const ref = useRef(null);
    const focusedRef = useRef(false);

    const pressable = useMemo(() => onPress || onPressIn || onPressOut || onLongPress, [onPress, onPressIn, onPressOut, onLongPress]);

    const [focusVisible, setFocusVisibleState] = useState(false);
    const setFocusVisible = useEventCallback((v = false) => {
      if (onFocusVisible) {
        onFocusVisible(v);
      }
      if (onFocus) {
        onFocus();
      }
      setFocusVisibleState(v);
    });
    const handleOnFocus = useEventCallback((e) => {
      focusedRef.current = true;
      if (onFocus) {
        onFocus(e);
      }
    });
    const handleOnBlur = useEventCallback((e) => {
      focusedRef.current = false;
      if (onBlur) {
        onBlur(e);
      }
    });
    boxProps.onFocus = handleOnFocus;
    boxProps.onBlur = handleOnBlur;
    const isFocused = useEventCallback(() => {
      return focusedRef.current;
    });

    boxProps.style =
      Component === BoxDiv
        ? [baseStyles.view, webStyle, ...(Array.isArray(style) ? style : [style])]
        : [webStyle, ...(Array.isArray(style) ? style : [style])];
    if (focusVisible) {
      if (boxProps.styles && boxProps.styles.focusVisible) {
        boxProps.style.push(boxProps.styles.focusVisible);
      }
      if (isPossibleStyle(focusVisible)) {
        boxProps.style.push(focusVisible);
      }
      if (!pressable) {
        if (boxProps.animate) {
          if (Array.isArray(boxProps.animate)) {
            boxProps.animate = hovered ? ['focused', 'hovered', ...boxProps.animate] : ['focused', ...boxProps.animate];
          } else {
            boxProps.animate = hovered ? ['focused', 'hovered', boxProps.animate] : ['focused', boxProps.animate];
          }
        } else {
          boxProps.animate = hovered ? ['focused', 'hovered'] : 'focused';
        }
      }
    } else if (hovered) {
      if (boxProps.animate) {
        if (Array.isArray(boxProps.animate)) {
          boxProps.animate = ['hovered', ...boxProps.animate];
        } else {
          boxProps.animate = ['hovered', boxProps.animate];
        }
      } else {
        boxProps.animate = 'hovered';
      }
    }
    if (id) {
      boxProps.nativeID = id;
    }

    // TODO: rethink this
    // if (
    //   hovered &&
    //   boxProps.animations &&
    //   boxProps.animations.hovered
    // ) {
    //   if (boxProps.animate) {
    //     if (Array.isArray(boxProps.animate)) {
    //       if (!boxProps.animate.includes('hovered')) {
    //         boxProps.animate = ['hovered', ...boxProps.animate];
    //       }
    //     } else if (boxProps.animate !== 'hovered' && boxProps.animate === 'default') {
    //       boxProps.animate = [boxProps.animate, 'hovered'];
    //     }
    //   } else {
    //     boxProps.animate = 'hovered';
    //   }
    // }

    const {
      focusable: accessibilityFocusable = false,
      accessible: accessibilityAccessible = false,
      ...accessibility
    } = accessibilityProp ? accessibilityProp : {};
    let focusable = focusableProp || accessibilityFocusable || accessibleProp || accessibilityAccessible;
    if (Platform.OS === 'web') {
      if (hrefProp && !disabled) {
        const {
          target = '_blank',
          rel = 'noopener noreferrer',
          href,
          download,
        } = typeof hrefProp === 'object' ? { ...hrefProp, ...hrefAttrs } : { ...hrefAttrs, href: hrefProp };
        if (href) {
          boxProps.href = href;
          boxProps.hrefAttrs = { target, rel, download };
          if (onPress) {
            boxProps.onPress = (e) => {
              e.preventDefault();
              if (onPress) {
                onPress(e);
              }
            };
            boxProps.onPressIn = (e) => {
              e.preventDefault();
              if (onPressIn) {
                onPressIn(e);
              }
            };
            boxProps.onPressOut = (e) => {
              e.preventDefault();
              if (onPressOut) {
                onPressOut(e);
              }
            };
          }
        }
      }
      // web uses focusable prop
      accessibility.focusable = focusable;
    } else {
      // native uses accessible prop
      accessibility.accessible = focusable;
    }

    useImperativeRefWithProps(forwardedRef, ref, {
      focusable,
      accessible: focusable,
      disabled,
      hasVisibleFocus: focusVisible,
      focusVisible: setFocusVisible,
      isFocused,
    });

    if (pressable) {
      if (Component === AnimatedView || Component === Pressable) {
        return (
          <Pressable
            onPress={onPress}
            onPressIn={onPressIn}
            onPressOut={onPressOut}
            onLongPress={onLongPress}
            onHoverIn={onHoverIn}
            onHoverOut={onHoverOut}
            disabled={disabled}
            debugMe={debugMe}
            accessibility={accessibility}
            hovered={hovered}
            {...boxProps}
            focused={focusVisible ? true : boxProps.focused}
            ref={ref}
          >
            {(pressableState) => {
              let contents = typeof children === 'function' ? children(pressableState) : children;

              if (gap) {
                contents = [];
                let firstIndex;
                React.Children.forEach(children, (child, index) => {
                  if (child) {
                    if (child.props && child.props.boxGapIgnore) {
                      // do nothing
                    } else if (firstIndex === undefined) {
                      firstIndex = index;
                    } else {
                      contents.push(
                        <Gap
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${boxId}-gap-${index}`}
                          boxStyle={props.style}
                          debug={debug}
                          gap={gap}
                          gapBorder={gapBorder}
                        />
                      );
                    }
                  }
                  contents.push(child);
                });
              }
              return (
                <>
                  {renderBefore}
                  {contents}
                  {renderAfter}
                </>
              );
            }}
          </Pressable>
        );
      }

      return (
        <Touchable onPress={onPress} onPressIn={onPressIn} onPressOut={onPressOut} onLongPress={onLongPress} disabled={disabled}>
          {(pressableState) => {
            let contents = typeof children === 'function' ? children(pressableState) : children;

            if (gap) {
              contents = [];
              let firstIndex;
              React.Children.forEach(children, (child, index) => {
                if (child) {
                  if (child.props && child.props.boxGapIgnore) {
                    // do nothing
                  } else if (firstIndex === undefined) {
                    firstIndex = index;
                  } else {
                    contents.push(
                      <Gap
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${boxId}-gap-${index}`}
                        boxStyle={props.style}
                        debug={debug}
                        gap={gap}
                        gapBorder={gapBorder}
                      />
                    );
                  }
                }
                contents.push(child);
              });
            }
            return (
              <Component
                {...{
                  accessibilityDisabled: disabled,
                  ...accessibility,
                  accessibilityState: {
                    disabled: disabled,
                    ...accessibility.accessibilityState,
                  },
                }}
                ref={ref}
                {...boxProps}
              >
                {renderBefore}
                {contents}
                {renderAfter}
              </Component>
            );
          }}
        </Touchable>
      );
    }

    let contents =
      typeof children === 'function'
        ? // ? children(...args)
          children() // <- ^ what are the arguments supposed to be? ...args is undefined
        : children;

    if (gap) {
      contents = [];
      let firstIndex;
      React.Children.forEach(children, (child, index) => {
        if (child) {
          if (child.props && child.props.boxGapIgnore) {
            // do nothing
          } else if (firstIndex === undefined) {
            firstIndex = index;
          } else {
            contents.push(
              <Gap
                // eslint-disable-next-line react/no-array-index-key
                key={`${boxId}-gap-${index}`}
                boxStyle={props.style}
                debug={debug}
                gap={gap}
                gapBorder={gapBorder}
              />
            );
          }
        }
        contents.push(child);
      });
    }

    return (
      <Component ref={ref} accessibility={accessibility} {...boxProps}>
        {renderBefore}
        {contents}
        {renderAfter}
      </Component>
    );
  })
);

function canRenderGap({ gap, boxStyle }) {
  if (gap && !isNull(gap)) {
    const box = getStyle(boxStyle);
    // if direction is wrap then this will not render properly
    if (box && box.flexWrap && box.flexWrap === 'wrap') {
      return false;
    }
    if (isObject(gap) && !gap.spacing) {
      return false;
    }
    return true;
  }
  return false;
}

const Gap = withStyles(
  {
    root: (props) => {
      if (canRenderGap(props)) {
        const { gap } = props;
        const box = props.boxStyle ? getStyle(props.boxStyle) : null;

        let gapStyle;
        let spacing;
        if (isObject(gap)) {
          const { spacing: gapSpacing, border, ...rest } = gap;
          spacing = gapSpacing;
          gapStyle = rest;
        } else {
          spacing = gap;
          gapStyle = {};
        }
        const isColumnLayout = !box || !box.flexDirection || !box.flexDirection.startsWith('row');
        const width = isColumnLayout ? 'auto' : spacing;
        const height = isColumnLayout ? spacing : 'auto';
        const flexBasis = isColumnLayout ? height : width;

        return {
          width,
          height,
          flexBasis,
          justifyContent: 'center',
          alignItems: 'center',
          ...gapStyle,
        };
      }
      return null;
    },
    gapBorder: (props) => {
      if (canRenderGap(props)) {
        const { gap, gapBorder } = props;
        const box = props.boxStyle ? getStyle(props.boxStyle) : null;
        let border;
        if (isObject(gap)) {
          if (gapBorder || gap.border) {
            border = { ...gap.border, ...gapBorder };
          }
        } else {
          border = gapBorder;
        }

        if (!border) return null;

        const isColumnLayout = !box || !box.flexDirection || !box.flexDirection.startsWith('row');

        return {
          width: isColumnLayout ? '100%' : 1,
          height: isColumnLayout ? 1 : '100%',
          border: isColumnLayout ? { bottom: border } : { right: border },
        };
      }
      return null;
    },
  },
  { name: 'Gap' }
)((props) => {
  const { styles } = props;
  if (!styles.root) return null;
  return (
    <AnimatedView style={styles.root} {...styles.props.root}>
      {styles.gapBorder && <View style={styles.gapBorder} {...styles.props.gapBorder} />}
    </AnimatedView>
  );
});

export { Box };
