import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Grid } from '../../../../ui/components/Grid';
import { LocationDialog } from '../../../SubmitApplicationPage/LocationDialog';
import { useAdjustmentForm } from '../../context/QuoteAdjustment/AdjustmentFormContext';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';
import { calculateCoverageLimits, exportToCsv, getDefaultCoverages, validateCSVData } from '../../../../utils';
import { Box, Button } from '../../../../ui';
import { LayoutBox } from '../../../../components';
import { ImportModal } from '../../../../components/ImportModal';
import { useApi, useAsync, useAuth } from '../../../../hooks';
import getCoverageColDef from '../../../../utils/getCoverageColDef';
import ResetQuoteModal from '../../../../components/ResetQuoteModal';

export const Coverages = React.forwardRef((props, ref) => {
  const auth = useAuth();
  const { adjusting } = useAdjustmentState();
  const { setValue } = useAdjustmentForm();
  const [selectedFileRows, setSelectedFileRows] = useState([]);
  const { addOrUpdateCustomerLocation } = useApi();
  const [errors, setErrors] = useState([]);
  const canEdit = auth && (auth.user.isUnderwriter || auth.user.isPolicyAnalyst || auth.user.isAdmin);
  const {
    quotes,
    setGridReference,
    onSelectionChanged,
    modalOpen,
    setModalOpen,
    setQuoteData,
    importModal,
    setImportModal,
    gridReference,
    setExportHandler,
  } = props;
  const [locationData, setLocationData] = useState({});
  const [mode, setMode] = useState('CREATE');
  const [colDefs, setColDefs] = useState([]);

  const reinsurance = {
    maxTotalInsuredValue: 5000000,
    autoFacRate: 0,
    autoFacPremium: 0,
    additionalFacPremium: 0,
    excluded: true,
  };
  const requirements = {
    swapNeeded: false,
    dotNeeded: false,
    floodSublimitNeeded: false,
    indoorEqLimitNeeded: true,
  };
  const data = quotes && quotes.locations ? quotes.locations : [];

  const isEditable = () => adjusting;

  const handleUpdateLocation = useCallback(
    async ({ id, location: data, index, shouldResetLocation }) => {
      const newLocation = await addOrUpdateCustomerLocation(id, data, shouldResetLocation);

      if (index) {
        newLocation['coverages'] = getDefaultCoverages(quotes, newLocation);
        newLocation['reinsurance'] = {
          ...reinsurance,
          locationId: newLocation.id,
          maxTotalInsuredValue: newLocation.coveredValue,
        };
        newLocation['requirements'] = { ...requirements, locationId: newLocation.id };
        setQuoteData((pre) => {
          return { ...pre, locations: [...pre.locations, newLocation] };
        });
        setValue(`quote.locations.${index}`, newLocation);
      } else {
        newLocation['reinsurance'] = {
          ...newLocation.reinsurance,
          maxTotalInsuredValue: newLocation.coveredValue,
        };
        const locationIndex = quotes.locations.findIndex((location) => location.riskId === newLocation.riskId || location.id === newLocation.id);
        newLocation.coverages.forEach((coverage) => {
          coverage.locationId = newLocation.id;
        });
        setQuoteData((prev) => {
          const updatedLocations = [...prev.locations];
          updatedLocations[locationIndex] = newLocation;
          return { ...prev, locations: updatedLocations };
        });
        setValue(`quote.locations.${locationIndex}`, newLocation);
      }
      setModalOpen(false);
      setMode('CREATE');
    },
    [addOrUpdateCustomerLocation, quotes, reinsurance, requirements]
  );

  const { execute: executeUpdate, status: updateStatus } = useAsync(handleUpdateLocation, { immediate: false });

  const handleSubmit = (data, e, shouldResetLocation) => {
    if (mode === 'CREATE') {
      e.preventDefault();
      const index = quotes.locations.length;
      executeUpdate({ id: quotes.id, location: data, index, shouldResetLocation });
    } else {
      const updatedLocations = quotes.locations.map((location) => {
        if (location.id === data.id) {
          const oldCoveredValue = location.coveredValue;
          const updatedLocation = { ...location, ...data };

          // Only update coverages and their payoutLimit if coveredValue is changed
          if (data.coveredValue !== oldCoveredValue) {
            const coverages = getDefaultCoverages(quotes, data);

            const riskId = location.riskId;
            const locationIndex = quotes.locations.findIndex((location) => location.riskId === riskId || location.id === data.id);
            updatedLocation.coverages = coverages.map((coverage, coverageIndex) => {
              const updatedCoverage = {
                ...coverage,
                payoutLimit: calculateCoverageLimits(coverage.coverageType, data.coveredValue),
              };
              adjusting && setValue(`quote.locations.${locationIndex}.coverages.${coverageIndex}.payoutLimit`, updatedCoverage.payoutLimit);
              return updatedCoverage;
            });
          }
          executeUpdate({ id: quotes.id, location: updatedLocation, shouldResetLocation });

          return updatedLocation;
        }
        return location;
      });
      setQuoteData((pre) => {
        return { ...pre, locations: updatedLocations };
      });
      adjusting && setValue(`quote.locations`, updatedLocations);
    }
  };

  const validateData = (data) => {
    const newErrors = validateCSVData(colDefs, data);
    setErrors(newErrors);
  };

  const dataTypeConversion = (value) => {
    if (typeof value === 'string' && (value.includes('%') || value.includes('$'))) {
      return parseFloat(value.replace(/\$|,/g, '').replace(/%/g, ''));
    }
    return parseFloat(value) ?? 0;
  };

  const addImportedFileRows = () => {
    let updatedQuoteData = { ...quotes };
    const mappedData = selectedFileRows.map((item) => {
      const id = uuid();
      if (!item) {
        return null;
      }
      let coverageCount = 0;
      for (const key in item) {
        if (key.startsWith('Coverage_')) {
          coverageCount++;
        }
      }
      const allCoverages = ['comprehensive', 'flood', 'earthquake', 'hail', 'collision', 'falsePretense', 'terrorism'];
      return {
        id,
        address: item['Address'] ?? '',
        country: item['Country'] ?? '',
        coverages: [
          ...(item['Coverage']
            ? [
                {
                  aggregate: dataTypeConversion(item['Aggregate Deductible']),
                  deductible: dataTypeConversion(item[`Deductible`]),
                  coverageType: allCoverages[0] ?? '',
                  isIncluded: item[`IsIncluded_0`],
                  locationId: id,
                  netPremium: dataTypeConversion(item['Premium']),
                  payoutLimit: dataTypeConversion(item['Payout Limit']),
                },
              ]
            : []),
          ...Array.from({ length: coverageCount }, (_, i) => ({
            aggregate: dataTypeConversion(item[`Aggregate Deductible_${i + 1}`]),
            deductible: dataTypeConversion(item[`Deductible_${i + 1}`]),
            coverageId: item[`CoverageId_${i + 1}`],
            coverageType: allCoverages[i + 1] ?? '',
            isIncluded: item[`IsIncluded_${i + 1}`],
            locationId: id,
            netPremium: dataTypeConversion(item[`Premium_${i + 1}`]),
            payoutLimit: dataTypeConversion(item[`Payout Limit_${i + 1}`]),
          })),
        ],
        details: {
          crimeZone: item['Crime Index'] ?? '',
          earthquake: item['Earthquake Zone'] ?? '',
        },
        name: item['Name'] ?? '',
      };
    });

    updatedQuoteData.locations = [...updatedQuoteData.locations, ...mappedData];
    adjusting && setValue('quote.locations', updatedQuoteData.locations);
    setQuoteData(updatedQuoteData);
  };

  useEffect(() => {
    const colDefs = getCoverageColDef({
      isEditable,
      setValue,
      quotes,
      adjusting,
      setModalOpen,
      setMode,
      setLocationData,
    });
    setColDefs(colDefs);
  }, [quotes]);

  useEffect(() => {
    if (gridReference && gridReference.api && !gridReference.api.isDestroyed()) {
      const handleGridPreDestroy = () => {
        setGridReference(null);
      };

      gridReference.api.addEventListener('gridPreDestroy', handleGridPreDestroy);

      return () => {
        if (gridReference && gridReference.api && !gridReference.api.isDestroyed()) {
          gridReference.api.removeEventListener('gridPreDestroy', handleGridPreDestroy);
        }
      };
    }
  }, [gridReference]);

  useEffect(() => {
    if (gridReference && gridReference.api && !gridReference.api.isDestroyed()) {
      setExportHandler(() => exportToCsv(gridReference));
    }
  }, [gridReference, data]);

  return (
    <Box>
      <LayoutBox>
        <Grid
          data={data}
          columns={colDefs}
          setGridReference={setGridReference}
          rowSelection="multiple"
          onSelectionChanged={onSelectionChanged}
          setQuoteData={setQuoteData}
          floatingFilter={false}
          shouldShowPagination={false}
          enableRangeSelection={true}
          enableFillHandle={true}
        />
      </LayoutBox>

      <LocationDialog
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setLocationData([]);
          setMode('CREATE');
        }}
        onSubmit={handleSubmit}
        canEdit={canEdit}
        mode={mode}
        product={quotes.product}
        data={locationData}
        shouldAddRiskId={true}
        locations={quotes.locations}
        processing={updateStatus === 'pending'}
        quoteId={quotes.id}
      />
      {adjusting && (
        <LayoutBox row justifyContent="space-between">
          <Box display="flex" alignItems="start" justifyContent="center" flexWrap="wrap" backgroundColor="white" height={70}>
            <Box border="1px solid #919EAB52" borderRadius={8} marginLeft="$2">
              <Button
                color="#000"
                variant="text"
                label="Add Location"
                paddingX={16}
                onPress={() => {
                  setModalOpen(true);
                }}
              />
            </Box>
          </Box>
          <ResetQuoteModal quoteId={quotes.id} />
        </LayoutBox>
      )}

      <LayoutBox Row>
        <ImportModal
          importModal={importModal}
          setImportModal={setImportModal}
          setSelectedFileRows={(data) => {
            setSelectedFileRows(data);
            validateData(data);
          }}
          selectedFileRows={selectedFileRows}
          errors={errors}
          setErrors={setErrors}
          addImportedFileRows={addImportedFileRows}
        />
      </LayoutBox>
    </Box>
  );
});
