import React, { useCallback, useMemo } from 'react';
import { Text } from '../Text';
import { Box } from '../Box';
import { useTheme } from '../../hooks';
import moment from 'moment-timezone';
import { CalendarViewTypes, CALENDAR_HEADER_HEIGHT, CALENDAR_HEADER_SPACING } from './constants';
import { ChevRonLeftIcon, ChevRonRightIcon } from '../../../components/icons';
// import Svg, { Path, G } from 'react-native-svg';

// const LeftArrowIcon = (props) => (
//   <Text center color="$gray.400" selectable={false} {...props}>
//     {'<'}
//   </Text>
// );

// const RightArrowIcon = (props) => (
//   <Text center color="$gray.400" selectable={false} {...props}>
//     {'>'}
//   </Text>
// );

const ArrowBtn = (props) => (
  <Box
    width={CALENDAR_HEADER_HEIGHT}
    height={CALENDAR_HEADER_HEIGHT}
    justifyContent="center"
    alignItems="center"
    opacity={props.disabled ? 0 : 1}
    {...props}
    onPress={props.disabled ? null : props.onPress}
  />
);

const defaultDateFormat = (view) => {
  if (view === CalendarViewTypes.day) {
    return 'MMMM YYYY';
  }
  if (view === CalendarViewTypes.month) {
    return 'YYYY';
  }
};

const CalendarHeader = React.forwardRef(function CalendarHeader(props, ref) {
  const { date, view, currentMonth, changeView, minDate, maxDate, onMonthChange, format, dateFormat = defaultDateFormat, ...rest } = props;

  const changeMonthOrYear = useCallback(
    (forward = false, viewType) => {
      let to = null;
      const dType = viewType === CalendarViewTypes.month ? 'year' : 'month';
      if (forward) {
        to = moment(currentMonth, 'YYYYMM').add(1, dType).format('YYYYMM');
        if (maxDate && to > maxDate.format('YYYYMM')) {
          return;
        }
      } else {
        to = moment(currentMonth, 'YYYYMM').subtract(1, dType).format('YYYYMM');
        if (minDate && to < minDate.format('YYYYMM')) {
          return;
        }
      }
      if (onMonthChange) {
        onMonthChange(to);
      }
    },
    [currentMonth, maxDate, minDate, onMonthChange]
  );

  const canGoBack = useMemo(() => {
    if (currentMonth) {
      return !minDate || currentMonth > minDate.format('YYYYMM');
    }
    return false;
  }, [currentMonth, minDate]);

  const canGoForward = useMemo(() => {
    if (currentMonth) {
      return !maxDate || currentMonth < maxDate.format('YYYYMM');
    }
    return false;
  }, [currentMonth, maxDate]);

  const text = useMemo(() => {
    if (format) {
      const currMonthDate = currentMonth ? moment(currentMonth, 'YYYYMM') : currentMonth;
      return format(currMonthDate, { view, currentMonth });
    } else if (currentMonth) {
      if (typeof dateFormat === 'string') {
        return moment(currentMonth, 'YYYYMM').format(dateFormat);
      }
      return moment(currentMonth, 'YYYYMM').format(dateFormat(view, currentMonth));
    }
  }, [currentMonth, view, format, dateFormat]);

  const handleViewChange = useCallback(() => {
    if (view === CalendarViewTypes.day) {
      return changeView(CalendarViewTypes.month);
    }
    if (view === CalendarViewTypes.month) {
      return changeView(CalendarViewTypes.year);
    }
  }, [view, changeView]);

  const theme = useTheme();
  const height = CALENDAR_HEADER_HEIGHT + theme.spacing(CALENDAR_HEADER_SPACING);
  return (
    <Box flexDirection="row" alignItems="center" justifyContent="space-between" height={height} {...rest}>
      <Box flex={2} maxWidth="60%" onPress={handleViewChange}>
        <Text large bold selectable={false} center>
          {text}
        </Text>
      </Box>
      <ArrowBtn disabled={!canGoBack} onPress={() => changeMonthOrYear(false, view)}>
        <ChevRonLeftIcon />
      </ArrowBtn>
      <ArrowBtn disabled={!canGoForward} onPress={() => changeMonthOrYear(true, view)}>
        <ChevRonRightIcon />
      </ArrowBtn>
    </Box>
  );
});

export { CalendarHeader };
