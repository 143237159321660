export function validateCSVData(schema, data) {
  const newErrors = [];
  if (!data.length) {
    newErrors.push('File Data is Empty');
    return newErrors;
  }
  data.forEach((row, rowIndex) => {
    Object.entries(row).forEach(([field, value]) => {
      const column = field.split('_')[0];
      if (value !== null && value !== undefined) {
        const schemaItem = schema.find((s) => s.headerName === column);
        if (schemaItem) {
          const expectedType = schemaItem.dataType;
          const data =
            typeof value === 'string' && (value.includes('%') || value.includes('$'))
              ? parseFloat(value.replace(/\$|,/g, '').replace(/%/g, ''))
              : value;

          if (typeof data !== expectedType) {
            newErrors.push(`Invalid data type in ${column} at row ${rowIndex + 1}`);
          }
        } else {
          newErrors.push(`Field ${column} header not found`);
        }
      } else {
        const schemaItem = schema.find((s) => s.headerName === column);
        if (schemaItem && schemaItem.isRequired) {
          newErrors.push(`Field ${column} is required at row ${rowIndex + 1}`);
        }
      }
    });
  });

  return newErrors;
}
