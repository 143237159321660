import React from 'react';
import { Box, Text } from '../ui';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      console.log('Error caught by <ErrorBoundary>', this.state.error, this.state.errorInfo);
      return (
        <Box flex={1} width="100%" justifyContent="center" alignItems="center" padX="$5" padY="$5">
          <Text bold>Something went wrong</Text>
        </Box>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
