import React, { useCallback, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import { Box, Text, Heading, Button } from '../../ui';
import { withStyles } from '../../ui/styling';
import { capitalize } from '../../ui/utils';
import {
  TextData,
  Main,
  ItemRow,
  Chip,
  ColumnsAndSettingsProvider,
  ListSearchControls,
  ProvideSearchAPIContext,
  ListSearchDataRenderer,
} from '../../components';
import { useNavigate, useApi, useAddProductOptionsToSearchOptions } from '../../hooks';
import { Colors, QuoteStatuses } from '../../constants';
import { apiHelpers, formatMoney } from '../../utils';
import { NULL_STYLE } from '../../ui/system';
import { useEventCallback } from '../../ui/hooks';
import { applicationSearchOptions } from '../../constants/searchData/searchOptions';

const tableColumnsConfig = [
  {
    options: ['insured', 'agent.name', 'broker.name'],
    defaultOption: 'insured',
    label: ['Insured', 'Agent', 'Broker'],
    value: ({ insured, agent, broker }, o, index) => [insured, agent.name, broker.name][index],
    defaultValue: 'N/A',
    props: { flex: 1, TextProps: { bold: true } },
  },
  {
    label: 'Product',
    value: ({ product }) => product.labels.name,
    props: { flex: 1 },
  },
  {
    options: ['address', 'region', 'country'],
    defaultOption: 'region',
    label: ['Street Address', 'Region', 'Country'],
    value: ({ address = 'N/A', region, countryCode, postcode }, o, i) =>
      [
        address,
        `${region || 'N/A'}${postcode && region ? ', ' : ''}${postcode || ''}`,
        `${region || 'N/A'}${countryCode && region ? ', ' : ''}${countryCode || ''}`,
      ][i],
    defaultValue: 'N/A',
    props: { flex: 0.8 },
  },
  {
    options: ['appliedAt', 'targetEffectiveAt'],
    defaultOption: 'appliedAt',
    label: ['Applied', 'Target Date'],
    value: ({ appliedAt, targetEffectiveAt }, o, i) =>
      [appliedAt ? moment(appliedAt).format('MM-DD-YYYY') : null, targetEffectiveAt ? moment(targetEffectiveAt).format('MM-DD-YYYY') : null][i],
    defaultValue: 'N/A',
    props: { flex: 0.5 },
  },
  {
    options: ['totalValue', 'numLocations'],
    defaultOption: 'totalValue',
    label: ['Total Value', 'Num. Locations'],
    value: ({ totalValue, numLocations }, o, i) => [`$${formatMoney(totalValue)}`, `${numLocations} Locations`][i],
    props: { flex: 0.5 },
  },
  {
    label: 'Status',
    value: ({ statusChips }) =>
      statusChips.length ? (
        <Chip color={statusChips[0].color ? statusChips[0].color : undefined}>{statusChips[statusChips.length - 1].text}</Chip>
      ) : null,
    props: { flex: 0.8, contentMaxWidth: null, layout: 'center-right' },
  },
];

export const ApplicationsListView = React.memo(({ applications = undefined, searchPath, onSelect = undefined, ...rest }) => {
  const listRef = useRef();
  const { getApplications } = useApi();
  const handleGetApplications = useCallback(
    async (search) => {
      let unquoted = false;
      if (search && Array.isArray(search.filters)) {
        const newSearch = { ...search, filters: [...search.filters] };
        const quotedIndex = newSearch.filters.findIndex((f) => f.field === 'unquoted');
        if (quotedIndex !== -1) {
          if (newSearch.filters[quotedIndex].value === true) {
            unquoted = true;
          }
          newSearch.filters.splice(quotedIndex, 1);
        }
        return await getApplications(newSearch, unquoted);
      }
      return await getApplications(search, unquoted);
    },
    [getApplications]
  );
  const navigate = useNavigate();
  const navigateToApplication = useEventCallback(({ id } = {}) => {
    if (id) {
      navigate.to({
        pathname: `/applications/${id}`,
      });
    }
  });

  const navigateToSubmitApplication = useEventCallback(() => navigate.to('/submitapplication'));
  const renderHeader = useMemo(
    () =>
      ({ height, key, style }) => {
        return (
          <ListSearchControls
            key={key}
            height={height}
            style={style}
            heading="Applications"
            searchPlaceholder="Search for applications"
            headingRight={<Button label="+  New Application" onPress={navigateToSubmitApplication} />}
          />
        );
      },
    [navigateToSubmitApplication]
  );

  const searchOptions = useAddProductOptionsToSearchOptions(applicationSearchOptions);

  return (
    <Main>
      <ProvideSearchAPIContext
        data={applications}
        searchPath={searchPath}
        searchOptions={searchOptions}
        getData={handleGetApplications}
        appSettingsKey="ApplicationsListView"
        cacheKey="applicationlist"
        compareWithout="url"
        listRef={listRef}
      >
        <ColumnsAndSettingsProvider viewKey="ApplicationsListView" config={tableColumnsConfig}>
          <ListSearchDataRenderer
            ref={listRef}
            onSelect={onSelect !== undefined ? onSelect : navigateToApplication}
            emptyMessage="No applications to show"
            renderHeader={renderHeader}
            tableItemComponent={ApplicationRowItem}
            cardItemComponent={ApplicationCardItem}
            stickyHeaderEnabled
            scrollNode
            {...rest}
          />
        </ColumnsAndSettingsProvider>
      </ProvideSearchAPIContext>
    </Main>
  );
});

const ApplicationRowItem = React.forwardRef(function ApplicationRowItem(props, ref) {
  const { item, styles, onPress, ...rest } = props;
  const application = useFormatApplicationData(item);
  const { statusChips, progress } = application;

  const progressBar = useMemo(
    () => (
      <Box
        sx={(theme) => ({
          ...theme.layout.absoluteFill,
          bottom: 0,
          top: null,
          height: 3,
          borderRadius: 6,
        })}
        bg={progress && statusChips.length ? statusChips[0].color : 'transparent'}
        maxWidth={progress}
      />
    ),
    [statusChips, progress]
  );

  const handleOnPress = useEventCallback(() => {
    onPress(application);
  });

  return (
    <ItemRow ref={ref} item={application} onPress={onPress ? handleOnPress : undefined} {...rest}>
      {progressBar}
    </ItemRow>
  );
});

const ApplicationCardItem = withStyles(({ theme }) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.colors.opacity('$gray.300', 0.3),
    px: theme.spacing(3),
    py: theme.spacing(2.75),
    bg: theme.colors.white,
    // shadow: theme.shadows.elevation(30, { opacity: 0.1 }),
    props: {
      gap: 0,
    },
  },
  progressOverlay: {
    ...theme.layout.absoluteFill,
    bottom: 0,
    top: null,
    height: 3,
    borderRadius: 6,
  },
  topContainer: {
    flexDirection: theme.breakpoints({ xs: 'column', sm: 'row' }),
    justifyContent: theme.breakpoints({ xs: 'flex-start', sm: 'space-between' }),
  },
  statusChips: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    padBottom: theme.spacing(1),
    padTop: theme.spacing(0.5),
    props: {
      gap: 8,
    },
  },
  contentLeft: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 1.25 }),
    props: {
      gap: 0,
    },
  },
  contentRight: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 0.75 }),
    flexDirection: theme.breakpoints({ xs: 'row', sm: 'column' }),
    justifyContent: 'flex-start',
    alignItems: theme.breakpoints({ xs: 'flex-start', sm: 'flex-end' }),
    props: theme.breakpoints({
      xs: {
        gap: 24,
      },
      sm: {
        gap: 6,
      },
    }),
    padTop: theme.spacing(1),
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    padTop: theme.spacing(1.5),
  },
  bottomSpacing: {
    flexGrow: 0.1,
    flexShrink: 0,
    flexBasis: '10%',
  },
  bottomContent: {
    flexGrow: 0.9,
    flexShrink: 0,
    flexBasis: 'auto',
  },
}))(
  React.forwardRef(function ApplicationListItem(props, ref) {
    const { item: application, styles, onPress, ...rest } = props;

    const { id, insured, appliedAt, product, targetEffectiveAt, numLocations, regions, totalValue, statusChips, progress } =
      useFormatApplicationData(application);

    const handleOnPress = useEventCallback(() => {
      onPress(application);
    });

    return (
      <Box ref={ref} onPress={onPress ? handleOnPress : undefined} {...rest}>
        <Box style={styles.topContainer} {...styles.props.topContainer}>
          <Box style={styles.contentLeft} {...styles.props.contentLeft}>
            <Box style={styles.statusChips} {...styles.props.statusChips}>
              {statusChips.map(({ text, ...p }, i) => (
                <Chip key={`${id}chip${i}`} {...p}>
                  {text}
                </Chip>
              ))}
            </Box>
            <Heading level={4}>{capitalize(insured)}</Heading>
            <Text small bold>
              {product.labels.combined}
            </Text>
          </Box>
          <Box style={styles.contentRight} {...styles.props.contentRight}>
            <TextData label="Target Effective Date" data={moment.utc(targetEffectiveAt).format('MM-DD-YYYY')} alignItems="inherit" />
            <TextData label="Applied At" data={moment.utc(appliedAt).format('MM-DD-YYYY')} alignItems="inherit" size="small" padTop={3} />
          </Box>
        </Box>
        <Box style={styles.bottomContainer} {...styles.props.bottomContainer}>
          <Box style={styles.bottomContent} {...styles.props.bottomContent}>
            <Text small>{`${numLocations} Locations`}</Text>
          </Box>
          {regions.length && (
            <>
              <Box style={styles.bottomSpacing} {...styles.props.bottomSpacing} />
              <Box style={styles.bottomContent} {...styles.props.bottomContent}>
                <Text small>{regions.map((r, i) => (i > 0 ? `, ${r}` : r))}</Text>
              </Box>
            </>
          )}
          {totalValue && (
            <>
              <Box style={styles.bottomSpacing} {...styles.props.bottomSpacing} />
              <Box style={styles.bottomContent} {...styles.props.bottomContent}>
                <Text small>{`$${formatMoney(totalValue)}`}</Text>
              </Box>
            </>
          )}
        </Box>
        <Box
          style={styles.progressOverlay}
          {...styles.props.progressOverlay}
          bg={progress ? statusChips[0].color : '$gray.300'}
          maxWidth={progress}
        />
      </Box>
    );
  })
);

function useFormatApplicationData(application = {}) {
  const { locations, product: p, quotes, agent, broker } = application;

  const product = useMemo(() => {
    return apiHelpers.resolveProduct(p);
  }, [p]);

  const { numLocations, regions, totalValue } = useMemo(() => {
    let numLocations = 0;
    const regions = [];
    let totalValue = 0;
    if (Array.isArray(locations)) {
      numLocations = locations.length;
      for (const location of locations) {
        if (location.region && !regions.includes(location.region)) {
          regions.push(location.region);
        }
        if (location.coveredValue) {
          totalValue += location.coveredValue;
        }
      }
    }
    return { numLocations, regions, totalValue };
  }, [locations]);

  const { preboundQuotes, releasedQuotes, pendingQuotes, boundQuotes, rejectedQuotes, expiredQuotes } = useMemo(() => {
    const preboundQuotes = [];
    const releasedQuotes = [];
    const pendingQuotes = [];
    const boundQuotes = [];
    const rejectedQuotes = [];
    const expiredQuotes = [];
    if (Array.isArray(quotes)) {
      for (const quote of quotes) {
        switch (quote.status) {
          case QuoteStatuses.pending:
            pendingQuotes.push(quote);
            break;
          case QuoteStatuses.released:
            releasedQuotes.push(quote);
            break;
          case QuoteStatuses.bound:
            boundQuotes.push(quote);
            break;
          case QuoteStatuses.prebound:
            preboundQuotes.push(quote);
            break;
          case QuoteStatuses.rejected:
            rejectedQuotes.push(quote);
            break;
          case QuoteStatuses.expired:
            expiredQuotes.push(quote);
            break;
          default:
        }
      }
    }
    return {
      preboundQuotes,
      releasedQuotes,
      pendingQuotes,
      boundQuotes,
      rejectedQuotes,
      expiredQuotes,
    };
  }, [quotes]);

  const { statusChips, progress } = useMemo(() => {
    // first chip defaults (main state of application)
    const chips = [
      {
        color: Colors.quoteStatus.pending,
        text: 'In Review',
      },
    ];

    let progress = null;
    if (quotes.length) {
      if (boundQuotes.length) {
        chips[0].text = 'Bound';
        chips[0].color = '$secondary';
        progress = '100%';
      } else if (preboundQuotes.length) {
        chips[0].text = 'Prebound';
        chips[0].color = Colors.quoteStatus.prebound;
        progress = '75%';
      } else if (quotes.length > 1) {
        chips[0].text = 'Quoting';
        if (releasedQuotes.length) {
          chips.push({
            text: `${releasedQuotes.length} Released`,
            color: '$gray.200',
          });
          progress = '50%';
        }
        if (pendingQuotes.length) {
          chips.push({
            text: `${pendingQuotes.length} Pending`,
            color: '$gray.200',
          });
          progress = progress ? progress : '40%';
        }
        progress = progress ? progress : '45%';
      } else if (releasedQuotes.length) {
        chips[0].text = 'Quote Ready';
        chips[0].color = Colors.quoteStatus.released;
        progress = '50%';
      } else if (pendingQuotes.length) {
        chips[0].text = 'Quote Pending';
        chips[0].color = Colors.quoteStatus.pending;
        progress = '40%';
      } else if (rejectedQuotes.length) {
        chips[0].text = 'Quote Rejected';
        chips[0].color = Colors.quoteStatus.rejected;
        progress = '25%';
      } else if (expiredQuotes.length) {
        chips[0].text = 'Quote Expired';
        chips[0].color = Colors.quoteStatus.expired;
        progress = '10%';
      }
    }
    return { statusChips: chips, progress };
  }, [quotes, pendingQuotes, preboundQuotes, expiredQuotes, rejectedQuotes, releasedQuotes, boundQuotes]);

  const agentName = agent && agent.name ? agent.name.split('@')[0] : agent && agent.email ? agent.email.split('@')[0] : 'N/A';
  const brokerName = broker && broker.name ? broker.name : 'N/A';

  return {
    ...application,
    agent: {
      ...agent,
      name: agentName,
    },
    broker: {
      ...broker,
      name: brokerName,
    },
    product,
    numLocations,
    regions,
    totalValue,
    statusChips,
    progress,
  };
}
