import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../../../ui/components/Grid';
import { currencyFormatter } from './utils';
import moment from 'moment-timezone';

export const CATModeling = React.forwardRef((props, ref) => {
  const { macavity, setGridReference, gridReference, setExportHandler } = props;

  const [gridsData, setGridsData] = useState([]);

  const createColumnDefs = (macavity) => {
    const baseCols = [
      {
        headerName: 'Return Period',
        field: 'returnPeriod',
        pinned: true,
        width: 150,
        flex: 1,
        filter: 'agTextColumnFilter',
        lockPinned: true,
        valueFormatter: (params) => {
          if (typeof params.value === 'number') {
            return params.value.toLocaleString();
          }
          return params.value;
        },
      },
      {
        headerName: 'Policy',
        field: 'policy',
        pinned: true,
        width: 150,
        flex: 1,
        filter: 'agTextColumnFilter',
        valueFormatter: (field) => currencyFormatter({ field }),
      },
    ];

    const locationCols = macavity.quoteLocations.map((location, index) => ({
      headerName: `Location ${index + 1}`,
      field: `location_${index + 1}`,
      width: 150,
      flex: 1,
      filter: 'agTextColumnFilter',
      valueFormatter: (field) => currencyFormatter({ field }),
    }));

    return [
      {
        headerName: macavity.config === 'cap_5m' ? '$5MM Quota Share - Insured SCS Model' : 'Full TIV - Insured SCS Model',
        children: [...baseCols, ...locationCols],
      },
    ];
  };

  const transformData = (macavity) => {
    const rows = [];
    const returnPeriods = macavity.returnPeriods;

    returnPeriods.forEach((rp) => {
      const row = {
        returnPeriod: rp.returnPeriod,
        policy: rp.totalClaims,
      };

      macavity.quoteLocations.forEach((loc, index) => {
        const locReturnPeriod = loc.returnPeriods.find((locRp) => locRp.returnPeriod === rp.returnPeriod);
        row[`location_${index + 1}`] = locReturnPeriod ? locReturnPeriod.totalClaims : 0;
      });

      rows.push(row);
    });

    // Add AAL and Median AL rows
    const aalRow = { returnPeriod: 'AAL', policy: macavity.revisedAnnualMean };
    const medianALRow = { returnPeriod: 'Median AL', policy: macavity.annualMedian };

    macavity.quoteLocations.forEach((loc, index) => {
      aalRow[`location_${index + 1}`] = loc.revisedAnnualMean || 0;
      medianALRow[`location_${index + 1}`] = loc.annualMedian || 0;
    });

    // Append AAL and Median AL rows to the end of the rows
    rows.push(aalRow);
    rows.push(medianALRow);

    return rows;
  };

  useEffect(() => {
    const data = macavity.map((macavityItem) => ({
      columnDefs: createColumnDefs(macavityItem),
      rowData: transformData(macavityItem),
      timestamp: macavityItem?.runTimestamp,
    }));
    setGridsData(data);
  }, [macavity]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const grids = document.querySelectorAll('.ag-paging-panel');
      grids.forEach((paginationDiv, index) => {
        const timestampData = gridsData[index]?.timestamp;
        if (paginationDiv) {
          const formattedTimestamp = timestampData ? moment(timestampData).local().format('MMMM D, YYYY HH:mm:ss [CDT]') : 'N/A';
          if (!paginationDiv.querySelector('.last-updated')) {
            const timestampDiv = document.createElement('div');
            timestampDiv.className = 'last-updated';
            timestampDiv.textContent = `Last Updated: ${formattedTimestamp}`;
            timestampDiv.style.textAlign = 'left';
            paginationDiv.insertBefore(timestampDiv, paginationDiv.firstChild);
          }
        }
      });
    }, 0);

    return () => clearTimeout(timer);
  }, [gridsData]);

  const combineDataForExport = () => {
    let csvContent = '';

    gridsData.forEach((gridData, index) => {
      if (index > 0) {
        csvContent += '\n'; // Add one line break between tables
      }

      const sheetName = gridData.columnDefs[0]?.headerName || 'Unnamed Sheet';
      csvContent += `${sheetName}:\n`;

      const headers = gridData.columnDefs[0].children.map((col) => col.headerName || col.field);
      csvContent += headers.join(',') + '\n';

      // Add rows for the current grid
      gridData.rowData.forEach((row) => {
        const rowData = gridData.columnDefs[0].children.map((col) => {
          const field = col.field;
          return row[field] !== undefined ? row[field] : '';
        });
        csvContent += rowData.join(',') + '\n';
      });
    });

    return csvContent;
  };

  const exportToCsv = useCallback(() => {
    const csvContent = combineDataForExport();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'CAT_Modeling.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [gridsData]);

  useEffect(() => {
    setExportHandler(exportToCsv);
    return () => {
      setExportHandler(null);
    };
  }, [gridsData]);

  return (
    <div className="ag-theme-quartz" style={{ maxHeight: '1500px' }}>
      {gridsData.map((gridData, index) => (
        <div key={index} style={{ marginBottom: '20px', maxHeight: '800px' }} className="ag-theme-quartz">
          <Grid
            data={gridData.rowData}
            columns={gridData.columnDefs}
            masterDetail={true}
            floatingFilter={false}
            pageSize={20}
            setGridReference={setGridReference}
            gridReference={gridReference}
          />
        </div>
      ))}
    </div>
  );
});
