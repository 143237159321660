import React from 'react';
import { Box } from '../Box';
import { withStyles } from '../../styling';
import { layout } from '../../system/props';
import { useStepperContext, useStepContext } from './StepperContext';
import { StepIcon } from './StepIcon';

const StepContent = withStyles(
  ({ theme, ...props }) => {
    return {
      root: {
        ...layout({
          marginLeft: StepIcon.width / 2,
          padLeft: StepIcon.width / 2 + theme.spacing(2),
          width: '100%',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'flex-start',
          ...props,
        }),
      },
      horizontal: {
        padTop: theme.spacing(1),
      },
      vertical: {
        borderLeftWidth: 2,
        borderLeftColor: '$gray.200',
        padBottom: theme.spacing(2),
      },
      last: {
        borderLeftColor: 'transparent',
      },
    };
  },
  { filterProps: [...layout.filterProps], preserveStyleProp: true }
)(
  React.forwardRef(function StepContent(props, ref) {
    const { children, style, styles, ...rest } = props;
    const { vertical } = useStepperContext();
    const { last } = useStepContext();
    return (
      <Box
        ref={ref}
        {...styles.toProps(
          {
            root: true,
            vertical: vertical,
            horizontal: !vertical,
            last: last,
          },
          style
        )}
        {...rest}
      >
        {children}
      </Box>
    );
  })
);

export { StepContent };
