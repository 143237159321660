import moment from 'moment-timezone';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dialog } from '../../Dialog';
import { Box, TextField, Heading, DatePicker } from '../../../ui';
import { useEventCallback } from '../../../ui/hooks';
import { isNull, isObject } from '../../../ui/utils';
import { CalendarIcon } from '../../icons';

export const rangeTests = [
  { name: 'number', test: (val, props = {}) => isNull(val) || (typeof val === 'number' && !isNaN(val)) },
  { name: 'gteMin', test: (val, props = {}) => isNull(val) || isNull(props.min) || val >= props.min },
  { name: 'lteMax', test: (val, props = {}) => isNull(val) || isNull(props.max) || val <= props.max },
];

export const MinMaxFilterControl = React.forwardRef(function MinMaxFilterControl(props, ref) {
  const { field, value: valueProp, setQuery, active } = props;
  const [value, controlProps, submit, errors] = useRangeControl(valueProp, field, setQuery, 'min', 'max', rangeTests, {
    number: (v, p) => `Not valid`,
    gteMin: (v, p) => `Must be larger than ${p.min}`,
    lteMax: (v, p) => `Must be smaller than ${p.max}`,
  });
  return (
    <Box width="100%">
      <Heading level={6} dim={active ? 0.8 : true}>
        {field.label}
      </Heading>
      <Box sx={{ flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
        <TextField
          ref={ref}
          type="number"
          label="Min"
          value={value[0]}
          onBlur={(e, inputError) => submit(value, inputError, 0)}
          sx={{ maxWidth: '46%' }}
          {...controlProps.min}
          {...(errors && errors.min ? { helperText: errors.min, error: true } : null)}
          flexGrow="1"
          flexShrink="0"
        />
        <Box sx={{ height: 1, width: 20, flex: 0.75, bg: '$gray.200', mt: '$-3' }} />
        <TextField
          ref={ref}
          type="number"
          label="Max"
          value={value[1]}
          onBlur={(e, inputError) => submit(value, inputError, 1)}
          sx={{ maxWidth: '46%' }}
          {...controlProps.max}
          {...(errors && errors.max ? { helperText: errors.max, error: true } : null)}
          flexGrow="1"
          flexShrink="0"
        />
      </Box>
    </Box>
  );
});

// DATES_FROM_TO field
export const DateRangeFilterControl = React.forwardRef(function DateRangeFilterControl(props, ref) {
  const { field, value: valueProp, setQuery, active } = props;
  const lastKey = useRef(['', '']);
  const handleFromOnKeyPress = useEventCallback((e) => {
    if (e && e.key) {
      lastKey.current[0] = e.key;
    }
  });
  const handleToOnKeyPress = useEventCallback((e) => {
    if (e && e.key) {
      lastKey.current[1] = e.key;
    }
  });
  const handleFormatFrom = useEventCallback((value, p, l, e) => {
    if (lastKey.current[0] === 'Backspace') {
      if (e && e.nativeEvent && e.nativeEvent.text) {
        lastKey.current[0] = '';
        return e.nativeEvent.text;
      }
    }
    return formatDateInput(value);
  });
  const handleFormatTo = useEventCallback((value, p, l, e) => {
    if (lastKey.current[1] === 'Backspace') {
      if (e && e.nativeEvent && e.nativeEvent.text) {
        lastKey.current[1] = '';
        return e.nativeEvent.text;
      }
    }
    return formatDateInput(value);
  });
  const [value, setValue, controlProps, submit, errors] = useRangeControl(valueProp, field, setQuery, 'from', 'to', rangeTests, {
    number: (v, p) => `Not valid`,
    gteMin: (v, p) => `Must be after ${p.from}`,
    lteMax: (v, p) => `Must be before ${p.to}`,
  });
  const [pickerOpen, setPickerOpen] = useState(false);
  const endAdornments = useMemo(() => {
    return [
      <Box
        sx={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '100%',
          maxHeight: '100%',
          position: 'absolute',
          top: 0,
          padTop: '$0.5',
          right: '$1.25',
          opacity: 0.3,
        }}
      >
        <Box
          onPress={() => setPickerOpen(0)}
          sx={{
            minWidth: 28,
            alignSelf: 'stretch',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CalendarIcon />
        </Box>
      </Box>,
      <Box
        sx={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '100%',
          maxHeight: '100%',
          position: 'absolute',
          top: 0,
          padTop: '$0.5',
          right: '$1.25',
          opacity: 0.3,
        }}
      >
        <Box
          onPress={() => setPickerOpen(1)}
          sx={{
            minWidth: 28,
            alignSelf: 'stretch',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CalendarIcon />
        </Box>
      </Box>,
    ];
  }, []);
  const momentValues = useMemo(() => {
    const m1 = value[0] !== null ? moment(value[0]) : null;
    const m2 = value[1] !== null ? moment(value[1]) : null;
    return [m1, m2];
  }, [value]);
  return (
    <Box width="100%">
      <Heading level={6} dim={active ? 0.8 : true}>
        {field.label}
      </Heading>
      <Box sx={{ flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
        <TextField
          ref={ref}
          label="From"
          type="number"
          value={value[0]}
          onBlur={(e, inputError) => submit(value, inputError, 0)}
          {...controlProps.from}
          {...(errors && errors.from ? { helperText: errors.from, error: true } : null)}
          placeholder={'MM/DD/YY'}
          onKeyPress={handleFromOnKeyPress}
          parse={parseDateInput}
          format={handleFormatFrom}
          endAdornment={endAdornments[0]}
          flexGrow="1"
          flexShrink="0"
          sx={{ maxWidth: '46%' }}
        />
        <Box sx={{ height: 1, width: 20, flex: 0.75, bg: '$gray.200', mt: '$-3' }} />
        <TextField
          ref={ref}
          label="To"
          type="number"
          value={value[1]}
          onBlur={(e, inputError) => submit(value, inputError, 1)}
          {...controlProps.to}
          {...(errors && errors.to ? { helperText: errors.to, error: true } : null)}
          placeholder={'MM/DD/YY'}
          onKeyPress={handleToOnKeyPress}
          parse={parseDateInput}
          format={handleFormatTo}
          endAdornment={endAdornments[1]}
          flexGrow="1"
          flexShrink="0"
          sx={{ maxWidth: '46%' }}
        />
      </Box>
      <Dialog open={pickerOpen !== false} onClose={() => setPickerOpen(false)}>
        <DatePicker
          date={momentValues[pickerOpen]}
          onChange={(date) => {
            const next = [...value];
            next[pickerOpen] = date.valueOf();
            setValue(next);
            setPickerOpen(false);
            submit(next, null, pickerOpen);
          }}
        />
      </Dialog>
    </Box>
  );
});

function parseDateInput(text) {
  if (typeof text === 'number') {
    const mt = moment(text);
    if (mt.isValid()) {
      return mt.valueOf();
    }
  } else if (typeof text === 'string') {
    let val = text.trim();
    val = val.replace(/[^0-9]/g, '');
    if (val.length === 6) {
      const mt = moment(val, 'MM/DD/YY');
      if (mt.isValid()) {
        return mt.valueOf();
      }
    }
    if (val) {
      return val;
    }
  }
  return null;
}

function formatDateInput(value) {
  if (isNull(value) || value === '') return '';
  let val = value;
  if (typeof val === 'number') {
    const mt = moment(value);
    if (mt.isValid()) {
      return mt.format('MM/DD/YY');
    }
    val = `${value}`;
  }
  val = val.replace(/[^0-9]/g, '');
  let d = '';
  for (let i = 0; i < val.length; i += 1) {
    if (i === 6) {
      break;
    }
    if (i === 1 || i === 3) {
      d += `${val[i]}/`;
    } else {
      d += val[i];
    }
  }
  return d;
}

export const useRangeControl = (valueProp, field, setQuery, minKey = 'min', maxKey = 'max', tests, errorStrings) => {
  const lastValProp = useRef(null);
  const [value, setValue] = useState(() => {
    if (Array.isArray(valueProp) && valueProp.length === 2) {
      return [!isNull(valueProp[0]) ? valueProp[0] * 1 : null, !isNull(valueProp[1]) ? valueProp[1] * 1 : null];
    }
    return [null, null];
  });

  const [inputErrors, setInputErrors] = useState([false, false]);
  useEffect(() => {
    const currVal = Array.isArray(valueProp) && valueProp.length === 2 ? valueProp : [null, null];
    if (lastValProp.current) {
      if (lastValProp.current[0] !== currVal[0] || lastValProp.current[1] !== currVal[1]) {
        lastValProp.current = [...currVal];
        setInputErrors([false, false]);
        setValue((curr) => {
          const next = [!isNull(currVal[0]) ? currVal[0] * 1 : null, !isNull(currVal[1]) ? currVal[1] * 1 : null];
          if (curr[0] === next[0] && curr[1] === next[1]) {
            return curr;
          }
          return next;
        });
      }
    } else {
      lastValProp.current = [...currVal];
    }
  }, [valueProp]);

  const handleChangeMin = useEventCallback((min) => setValue((v) => [min, v[1]]));
  const handleChangeMax = useEventCallback((max) => setValue((v) => [v[0], max]));
  const controlProps = useMemo(() => {
    if (isObject(field.controls)) {
      const sharedProps = { ...field.controls };
      const min = sharedProps[minKey];
      const max = sharedProps[maxKey];
      if (sharedProps[minKey] !== undefined) {
        delete sharedProps[minKey];
      }
      if (sharedProps[maxKey] !== undefined) {
        delete sharedProps[maxKey];
      }
      let maxProps = null;
      let minProps = null;
      if (isObject(min) && isObject(max)) {
        minProps = { onChangeValue: handleChangeMin, ...sharedProps, ...min };
        maxProps = { onChangeValue: handleChangeMax, ...sharedProps, ...max };
      } else if (isObject(min)) {
        minProps = { onChangeValue: handleChangeMin, ...sharedProps, [maxKey]: max, ...min };
        maxProps = { onChangeValue: handleChangeMax, ...sharedProps };
      } else if (isObject(max)) {
        minProps = { onChangeValue: handleChangeMin, ...sharedProps };
        maxProps = { onChangeValue: handleChangeMax, ...sharedProps, [minKey]: min, ...max };
      } else {
        minProps = { onChangeValue: handleChangeMin, ...field.controls };
        maxProps = { onChangeValue: handleChangeMax, ...field.controls };
      }
      return { [minKey]: minProps, [maxKey]: maxProps };
    }
    return { [minKey]: { onChangeValue: handleChangeMin }, [maxKey]: { onChangeValue: handleChangeMax } };
  }, [field, minKey, maxKey, handleChangeMax, handleChangeMin]);

  const [errors, setErrors] = useState(false);
  const submit = useEventCallback((submitValue, inputError, indexThatChanged) => {
    let next = [...submitValue];

    let valid = true;
    const errorText = { [minKey]: false, [maxKey]: false };

    let nextInputErrors = [...inputErrors];
    if (indexThatChanged === 0 || indexThatChanged === 1) {
      if (inputError && inputError.text) {
        nextInputErrors[indexThatChanged] = inputError.text;
      } else {
        nextInputErrors[indexThatChanged] = false;
      }

      setInputErrors(nextInputErrors);
    }
    let s1 = nextInputErrors[0] !== false ? nextInputErrors[0] : next[0];
    let s2 = nextInputErrors[1] !== false ? nextInputErrors[1] : next[1];
    if (Array.isArray(tests) && tests.length) {
      for (const testItem of tests) {
        if (isObject(testItem)) {
          const { test, name } = testItem;
          const minValid = test(s1, controlProps[minKey]);
          const maxValid = test(s2, controlProps[maxKey]);
          if (!minValid) {
            valid = false;
            if (isObject(errorStrings)) {
              errorText[minKey] = typeof errorStrings[name] === 'function' ? errorStrings[name](s1, controlProps[minKey]) : errorStrings[name];
            } else {
              errorText[minKey] = 'error';
            }
          }
          if (!maxValid) {
            valid = false;
            if (isObject(errorStrings)) {
              errorText[maxKey] = typeof errorStrings[name] === 'function' ? errorStrings[name](s2, controlProps[maxKey]) : errorStrings[name];
            } else {
              errorText[maxKey] = 'error';
            }
          }
        }
        if (!valid) {
          break;
        }
      }
    }

    if (!valid) {
      setErrors(errorText);
    } else {
      if (nextInputErrors[0] || nextInputErrors[1]) {
        setInputErrors([false, false]);
      }
      setErrors(false);
    }

    if (valid && field.setField) {
      if (next[0] !== null && typeof next[0] === 'number' && next[1] !== null && typeof next[1] === 'number') {
        if (next[0] > next[1]) {
          next = [next[indexThatChanged], next[indexThatChanged]];
          setValue(next);
        }
        if (next[1] < next[0]) {
          next = [next[indexThatChanged], next[indexThatChanged]];
          setValue(next);
        }
      }
      if (field.setField) {
        if (next[0] === lastValProp.current[0] && next[1] === lastValProp.current[1]) {
          return;
        }
        field.setField(next, setQuery, field);
      }
    }
  });
  return [value, setValue, controlProps, submit, errors];
};
