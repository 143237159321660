import React, { useCallback, useRef, useMemo, useEffect } from 'react';
import moment from 'moment-timezone';
import { ScrollView } from '../ScrollView';
import { Year } from './Year';

const YearSelect = React.forwardRef(function YearSelect(props, ref) {
  const {
    date: selectedDate,
    year: focusedYearProp,
    viewHeight,
    rowWidth,
    rowPadding,
    columnPadding,
    onChange,
    minDate,
    maxDate,
    renderYear,
    isDateDisabled,
    ...rest
  } = props;

  const currentYearRef = useRef(moment().year());
  const currentYear = currentYearRef.current;

  const focusedMonth = useMemo(() => {
    if (focusedYearProp && focusedYearProp.length > 4) {
      return focusedYearProp.substring(4, 6);
    }
    if (selectedDate) {
      return selectedDate.format('MM');
    }
    return moment().format('MM');
  }, [focusedYearProp, selectedDate]);

  const focusedYear = useMemo(() => {
    if (focusedYearProp) {
      if (focusedYearProp.length > 4) {
        return focusedYearProp.substring(0, 4);
      } else if (focusedYearProp.length === 4) {
        return focusedYearProp;
      }
    }
    if (selectedDate) {
      return selectedDate.format('YYYY');
    }
    return moment().year();
  }, [focusedYearProp, selectedDate]);

  const selectedYear = selectedDate ? selectedDate.format('YYYY') : focusedYear;
  const selectedMonth = selectedDate ? selectedDate.format('MM') : focusedMonth;

  const minYear = minDate ? minDate.format('YYYY') : '1900';
  const maxYear = maxDate ? maxDate.format('YYYY') : `${currentYear + 5}`;
  const minMonth = minDate ? minDate.format('YYYYMM') : null;
  const maxMonth = maxDate ? maxDate.format('YYYYMM') : null;

  const yearSize = useMemo(() => {
    return {
      height: rowWidth / 7 + rowPadding * 4,
      width: rowWidth,
      marginY: rowPadding,
    };
  }, [rowWidth, rowPadding]);

  const handleYearSelect = useCallback(
    (year, toView) => {
      if (onChange) {
        const nextFocusedMonth = `${year}${focusedMonth}`;
        const nextSelectedMonth = `${year}${selectedMonth}`;
        if (minMonth && year === minYear) {
          if (nextFocusedMonth >= minMonth) {
            return onChange(nextFocusedMonth, toView);
          }
          if (nextSelectedMonth >= minMonth) {
            return onChange(nextSelectedMonth, toView);
          }
          return onChange(minMonth, toView);
        }
        if (maxMonth && year === maxYear) {
          if (nextFocusedMonth <= maxMonth) {
            return onChange(nextFocusedMonth, toView);
          }
          if (nextSelectedMonth <= maxMonth) {
            return onChange(nextSelectedMonth, toView);
          }
          return onChange(maxMonth, toView);
        }
        return onChange(nextFocusedMonth, toView);
      }
    },
    [onChange, minMonth, maxMonth, minYear, maxYear, selectedMonth, focusedMonth]
  );

  const scrollInsets = viewHeight / 3;

  const yearCount = useMemo(() => {
    return Math.abs(maxYear - minYear);
  }, [maxYear, minYear]);

  let years = [];
  for (let i = 0; i <= yearCount; i += 1) {
    const year = `${minYear * 1 + i}`;
    let disabled = false;
    if (isDateDisabled) {
      disabled = isDateDisabled(year);
    } else {
      if (minYear && year < minYear) {
        disabled = true;
      }
      if (!disabled && maxYear && year > maxYear) {
        disabled = true;
      }
    }
    const yearProps = {
      key: `year${i}`,
      year,
      selected: year === selectedYear,
      focused: year === focusedYear, // focused state defaults to selected for now
      disabled,
      minDate,
      maxDate,
      onSelect: handleYearSelect,
      ...yearSize,
    };
    if (renderYear) {
      years.push(renderYear(year, selectedDate, yearProps));
    }
    years.push(<Year {...yearProps} />);
  }

  const scroll = useRef(null);
  const scrollData = useRef(null);
  scrollData.current = { scrollInsets, yearSize };

  useEffect(() => {
    if (scroll.current) {
      if (focusedYear) {
        const difference = focusedYear - minYear;

        if (difference > 3) {
          const itemHeight = scrollData.current.yearSize.height + scrollData.current.yearSize.marginY * 2;
          const offset = difference * itemHeight;
          scroll.current.scrollTo({ x: 0, y: offset, animated: false });
        }
      } else {
        scroll.current.scrollToEnd({ animated: false });
      }
    }
  }, [focusedYear, minYear, maxYear]);

  return (
    <ScrollView
      padY={scrollInsets}
      ref={scroll}
      width="100%"
      containerStyle={{ alignItems: 'center' }}
      maxHeight={viewHeight}
      height={viewHeight}
      snapToStart={false}
      showsVerticalScrollIndicator
      {...rest}
    >
      {years}
    </ScrollView>
  );
});

export { YearSelect };
