import React, { useCallback } from 'react';
import { ActivityIndicator } from 'react-native';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';
import { withStyles } from '../../ui/styling';
import { capitalize } from '../../ui/utils';
import { getAddressLines } from '../../utils';
import { useApi, useAsync } from '../../hooks';
import { TextData, NavBar, Page, Main, PageContent, NavBackButton, NavRow, NavGroup, AppBarSpacer } from '../../components';
import { CoverageNames, ProductTypes } from '../../constants';

export const ClaimPage = () => {
  const { claimId } = useParams();
  const { getClaimWithId, getPolicyWithId, getClaims } = useApi();
  const handleGetClaimData = useCallback(async () => {
    let claim = null;
    try {
      // api currently doesnt have permissions for agent to get individual claim for some reason, but can get list. TODO fix this behavior on backend
      claim = await getClaimWithId(claimId);
      if (!claim) {
        throw 'err';
      }
    } catch (err) {
      const claims = await getClaims();
      if (claims && claims.length) {
        claim = claims.find((c) => c.id === claimId);
      }
    }
    claim.policy = await getPolicyWithId(claim.policyId);
    claim.location = claim.policy.locations.find((l) => l.id === claim.locationId);
    return claim;
  }, [getClaimWithId, getPolicyWithId, claimId, getClaims]);
  const { value: data, status } = useAsync(handleGetClaimData);

  if (!data && status === 'pending') {
    return <LoadingPage />;
  } else if (!data) {
    return null;
  }

  return (
    <Page padTop={({ theme: { sizes, breakpoints } }) => breakpoints({ xs: 0, sm: sizes.appBarHeight })}>
      <NavBar padTop="$1">
        <AppBarSpacer />
        <NavRow>
          <NavGroup start>
            <NavBackButton path="/claims" />
          </NavGroup>
        </NavRow>
      </NavBar>
      <Main padBottom={164} padTop={({ theme }) => theme.breakpoints({ sm: theme.spacing(8), md: theme.spacing(13) })}>
        <Contents data={data} />
      </Main>
    </Page>
  );
};

const Contents = ({ data }) => {
  const { status, policy, location, coverageType, incurredAt, openedAt, closedAt } = data;

  const { insured, product } = policy;
  const { address, region, postcode, name } = location;

  const statusText = capitalize(status);
  const coverageTypeName = CoverageNames[coverageType];
  const productType = ProductTypes[product.productType];
  const productName = product.name;
  const addressLines = getAddressLines(address, region, postcode);
  const incurredDate = moment(incurredAt).format('MM-DD-YYYY');
  const openedDate = moment(openedAt).format('MM-DD-YYYY');
  const closedDate = closedAt ? moment(closedAt).format('MM-DD-YYYY') : null;

  const displayData = [
    {
      data: statusText,
      label: 'Status',
    },
    ...(closedDate ? [{ data: closedDate, label: 'Closed At' }] : []),
    {
      data: coverageTypeName,
      label: 'Claim Category',
      size: 'small',
    },
    {
      data: incurredDate,
      label: 'Date Event Incurred',
    },
    {
      data: openedDate,
      label: 'Date Claim Opened',
    },
    {
      data: name,
      label: 'Name of Incident Location',
    },
    {
      data: addressLines,
      label: 'Address',
      size: 'small',
    },
    {
      data: insured,
      label: 'Policy Insured',
    },
    {
      label: 'Product Type',
      data: productType,
    },
    {
      data: productName,
      label: 'Product Name',
    },
  ];
  return (
    <PageContent gap="16">
      {displayData.map((p, i) => {
        return <TextData key={`claimdisplaydata${i}`} {...p} />;
      })}
    </PageContent>
  );
};

const LoadingPage = withStyles(({ theme }) => ({
  root: { props: { layout: 'center' } },
}))(({ styles, ...rest }) => {
  return (
    <Page {...rest}>
      <ActivityIndicator />
    </Page>
  );
});
