import React, { useCallback } from 'react';
import { withStyles } from '../../styling';
import { useControlledState, useId } from '../../hooks';
import { isNull, capitalize } from '../../utils';
import { IconButton } from '../IconButton';
import { Box } from '../Box';
import { Svg } from '../Svg';
import { Text } from '../Text';

const DefaultIcon = ({ checked = false, color, onColor, ...rest }) => (
  <Box
    size={20}
    margin={2}
    borderRadius={4}
    borderWidth={2}
    justifyContent="center"
    alignItems="center"
    borderColor={checked ? color : onColor}
    backgroundColor={checked ? color : 'transparent'}
    {...rest}
  />
);

const paths = [
  {
    d: 'M124.891 273.084C130.749 267.226 140.247 267.226 146.105 273.084L206.916 333.895C212.774 339.753 212.774 349.25 206.916 355.108C201.058 360.966 191.56 360.966 185.703 355.108L124.891 294.297C119.034 288.439 119.034 278.942 124.891 273.084Z',
  },
  {
    d: 'M363.894 155.704C369.751 149.846 379.249 149.846 385.107 155.704C390.965 161.562 390.965 171.059 385.107 176.917L206.916 355.108C201.058 360.966 191.56 360.966 185.703 355.108C179.845 349.25 179.845 339.753 185.703 333.895L363.894 155.704Z',
  },
];

const checkMarkPaths = paths.map((path, i) => <Svg.Path key={`path${i}`} {...path} />);

const DefaultCheckedIcon = ({ color, onColor, ...rest }) => {
  return (
    <DefaultIcon color={color} onColor={onColor} checked {...rest}>
      <Svg size={24} fill={({ theme }) => theme.colors.on(color)} viewBox="0 0 512 512">
        {checkMarkPaths}
      </Svg>
    </DefaultIcon>
  );
};

const Checkbox = withStyles(
  ({ theme, color, onColor, labelColor }) => ({
    root: {
      props: {
        color: color || theme.colors.primary.default,
        onColor: onColor || theme.colors.text.secondary,
        labelColor: labelColor || theme.colors.on('$background'),
      },
    },
    checked: {},
    disabled: {},
    labelStart: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(-1),
    },
    labelEnd: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(1.5),
    },
    labelTop: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
    labelBottom: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
  }),
  { name: 'Checkbox' }
)(
  React.forwardRef(function Checkbox(props, ref) {
    const {
      checked: checkedProp,
      defaultChecked = false, // only used when uncontrolled
      icon = <DefaultIcon />,
      checkedIcon = <DefaultCheckedIcon />,
      component: Component = IconButton,
      styles,
      style,
      onChange,
      onChangeValue, // TODO: <- verify onChangeValue or onValueChange?
      onPress,
      readOnly = false,
      disabled = false,
      color,
      onColor,
      label,
      labelPlacement = 'end',
      gap = 0,
      gapBorder,
      labelColor,
      labelProps,
      labelId: labelIdProp,
      accessibility,
      ...rest
    } = props;

    const [checked, setChecked] = useControlledState({
      controlledValue: checkedProp,
      defaultValue: defaultChecked,
    });

    const handlePress = useCallback(
      (...args) => {
        if (onPress) {
          onPress(...args);
        }
        const nextState = !checked;
        setChecked(nextState);
        if (onChange) {
          // TODO: replicate the actual touch event and add in target.checked or whatever
          onChange(nextState, ...args);
        }
        if (onChangeValue) {
          onChangeValue(nextState);
        }
      },
      [onPress, onChange, onChangeValue, checked, setChecked]
    );

    const definedLabelId = labelIdProp ? labelIdProp : labelProps && labelProps.id ? labelProps.id : null;
    const labelId = useId(definedLabelId, 'zuiChkBxLbl');
    const controlComponent = (
      <Component
        ref={ref}
        style={[checked ? styles.checked : null, disabled ? styles.disabled : null, style]}
        disabled={disabled}
        color={color}
        onColor={onColor}
        onPress={!readOnly && handlePress}
        accessibility={{
          accessibilityRole: 'checkbox',
          accessibilityChecked: checked,
          accessibilityLabelledBy: label && typeof label === 'string' ? labelId : definedLabelId ? labelId : null,
          ...accessibility,
          accessibilityState: {
            checked,
            ...(accessibility && accessibility.accessibilityState ? accessibility.accessibilityState : null),
          },
        }}
        {...rest}
      >
        {checked ? checkedIcon : icon}
      </Component>
    );

    if (!isNull(label)) {
      return (
        <Box
          gap={gap}
          gapBorder={gapBorder}
          disableAnimationDefaults
          disableAnimate
          onPress={handlePress}
          style={styles[`label${capitalize(labelPlacement)}`]}
        >
          {React.isValidElement(label) ? (
            label
          ) : (
            <Text color={labelColor} small id={labelId} {...labelProps}>
              {label}
            </Text>
          )}
          {controlComponent}
        </Box>
      );
    }
    return controlComponent;
  })
);

Checkbox.icon = DefaultIcon;
Checkbox.checkedIcon = DefaultCheckedIcon;

export { Checkbox };
