const CalendarViewTypes = {
  day: 'day',
  month: 'month',
  year: 'year',
};

const MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

const CALENDAR_WIDTH = 308;
const DEFAULT_ROW_WIDTH = CALENDAR_WIDTH;
const DEFAULT_ROW_PADDING = 2;
const DEFAULT_COLUMN_PADDING = 2;

const CALENDAR_HEADER_HEIGHT = 44;
const CALENDAR_HEADER_SPACING = 2.5;

export {
  CalendarViewTypes,
  MONTHS,
  CALENDAR_WIDTH,
  DEFAULT_ROW_WIDTH,
  DEFAULT_ROW_PADDING,
  DEFAULT_COLUMN_PADDING,
  CALENDAR_HEADER_HEIGHT,
  CALENDAR_HEADER_SPACING,
};
