import { defaultStyleSystemOptions } from '../../system';

const { color } = defaultStyleSystemOptions.objects;

const scales = {
  fill: 'colors',
  stroke: 'colors',
  stopColor: 'colors',
};

const aliases = {
  translateX: 'x', // translateX is not cross-platform
  translateY: 'y', // translateY is not cross-platform
};

const multiples = {
  translate: ['x', 'y'],
  origin: ['originX', 'originY'],
  scale: ['scaleX', 'scaleY'],
  skew: ['skewX', 'skewY'],
};

const returnObject = (val) => val;
const objects = {
  fill: (val) => color(val, 'fill'),
  stroke: (val) => color(val, 'stroke'),
  stopColor: (val) => color(val, 'stopColor'),
  translate: returnObject,
  origin: returnObject,
  scale: returnObject,
  skew: returnObject,
  font: returnObject,
};

function parseMultipleArrayOrString(value, multiple) {
  if (!value || typeof value === 'number') return value;

  let values = typeof value === 'string' ? value.split(',') : value;

  if (Array.isArray(values)) {
    if (values.length > 1) {
      if (values[0] === values[1]) {
        return values[0];
      }
      const result = {};
      result[multiple[0]] = values[0];
      result[multiple[1]] = values[1];
      return result;
    }
    return values.length ? values[0] : value;
  }
  return value;
}

const parsers = {
  translate: (val) => parseMultipleArrayOrString(val, multiples.translate),
  origin: (val) => parseMultipleArrayOrString(val, multiples.origin),
  scale: (val) => parseMultipleArrayOrString(val, multiples.scale),
  skew: (val) => parseMultipleArrayOrString(val, multiples.skew),
};

export { scales, aliases, multiples, objects, parsers };
