import { useRef, useState, useEffect, useMemo } from 'react';
import { useQuery, useQueryParam, QUERY_ARRAY_COMMA } from './useQuery';
import { sortByOptions, filterOperators } from '../constants';
import { isNull, isObject } from '../ui/utils';
import { useEventCallback, useMemoCompare } from '../ui/hooks';
import { useNavigate } from './useNavigate';
import { useLocation } from 'react-router-dom';

export const QUERY_PROP_SEPERATOR = '|dH-';

const defaultFallback = { search: [], filters: [], sort: {} };
function useSearchConfig({
  limit: limitProp,
  offset: offsetProp,
  initialConfig,
  options,
  onChange,
  disableURLUpdates: disabledUpdates = false,
  searchPath,
} = {}) {
  const mounted = useRef(true);
  useEffect(() => {
    return () => (mounted.current = false);
  }, []);
  const [shouldUpdateUrlTo, setShouldUpdateUrlTo] = useState(false);
  const valuesForURLUpdate = useRef(null);
  const query = useQuery();
  const location = useLocation();
  const disableURLUpdates = disabledUpdates ? true : searchPath && (!location || location.pathname !== searchPath) ? true : false;
  const currQuery = useRef(null);
  currQuery.current = query;
  const limit = limitProp ? limitProp * 1 : query.has('limit') ? query.get('limit') * 1 : null;
  const offset = offsetProp !== null && offsetProp !== undefined ? offsetProp * 1 : query.has('offset') ? query.get('offset') * 1 : null;
  const [filterResult, , getFilterQuery] = useFilterQueryParams({}, disableURLUpdates);
  const [sortResult, , getSortQuery] = useSortQueryParams({}, disableURLUpdates);
  const [searchResult, , getSearchQuery] = useSearchQueryParams({}, disableURLUpdates);
  const { field: sortField, by: sortBy } = sortResult;
  const initialized = useRef(false);

  const searchConfig = useMemo(() => {
    valuesForURLUpdate.current = {};
    let config = null;
    let sort = null;
    let filters = [];

    const fallback = initialized.current
      ? defaultFallback
      : !initialConfig
      ? options.initialSearchConfig
      : {
          search: Array.isArray(initialConfig.search) && initialConfig.search.length ? initialConfig.search : options.initialSearchConfig.search,
          filters: Array.isArray(initialConfig.filters) && initialConfig.filters.length ? initialConfig.filters : options.initialSearchConfig.filters,
          sort: initialConfig.sort && initialConfig.sort.field && initialConfig.sort.by ? initialConfig.sort : options.initialSearchConfig.sort,
        };

    const sortConfig = sortField && options.sort[sortField] ? { field: sortField, by: sortBy } : fallback.sort;
    valuesForURLUpdate.current.sort = sortConfig;
    if (sortConfig && options.sort[sortConfig.field]) {
      sort = {
        field: sortConfig.field,
        by:
          sortConfig.by && sortByOptions[sortConfig.by]
            ? sortConfig.by
            : options.sort[sortConfig.field].defaults && options.sort[sortConfig.field].defaults.by
            ? options.sort[sortConfig.field].defaults.by
            : sortByOptions.ASC.name,
      };
    }

    const searchConfig = searchResult && Array.isArray(searchResult) && searchResult.length ? searchResult : fallback.search;
    valuesForURLUpdate.current.search = searchConfig;
    const filtersConfig = filterResult && Array.isArray(filterResult) && filterResult.length ? filterResult : fallback.filters;
    valuesForURLUpdate.current.filters = filtersConfig;

    if (filtersConfig || searchConfig) {
      const addFilter = (f, filterOptions) => {
        if (isObject(f)) {
          let { field, value, operator } = f;
          field = typeof field === 'string' ? field.trim() : field;
          value = typeof value === 'string' ? value.trim() : value;
          operator = typeof operator === 'string' ? operator.trim() : operator;
          value =
            value !== undefined
              ? value === null && filterOptions[field] && filterOptions[field].defaults
                ? filterOptions[field].defaults.value !== undefined
                : value
              : null;
          operator =
            operator && filterOperators[operator]
              ? operator
              : filterOptions[field] && filterOptions[field].defaults && filterOptions[field].defaults.operator
              ? filterOptions[field].defaults.operator
              : filterOperators.equals;

          const isValidField = field && filterOptions[field] && !filterOptions[field].exclude;
          if (isValidField && filterOptions[field].transformField) {
            const transformedFilter = filterOptions[field].transformField({ field, value, operator }, filterOptions[field]);
            if (transformedFilter) {
              if (Array.isArray(transformedFilter)) {
                filters = [...filters, ...transformedFilter];
              } else {
                filters.push(transformedFilter);
              }
            }
          } else if (isValidField && value !== undefined && operator) {
            filters.push({ field, value, operator });
          }
        }
      };

      if (filtersConfig) {
        for (const f of filtersConfig) {
          addFilter(f, options.filter);
        }
      }
      if (searchConfig) {
        for (const f of searchConfig) {
          addFilter(f, options.search);
        }
      }
    }

    if (sort || filters.length || limit || offset) {
      config = {};
      config.queryData = valuesForURLUpdate.current;
      if (limit) {
        config.limit = limit;
      }
      if (typeof offset === 'number') {
        config.offset = offset;
      }
      if (sort) {
        config.sort = sort;
      }
      if (filters.length) {
        config.filters = filters;
      }
    }

    if (!initialized.current) {
      initialized.current = true;
    }

    return config;
  }, [filterResult, searchResult, sortField, sortBy, limit, offset, initialConfig, options]);

  // make sure to update url if !disableURLUpdates on mount
  useEffect(() => {
    if (shouldUpdateUrlTo && mounted.current) {
      shouldUpdateUrlTo();
    }
  }, [shouldUpdateUrlTo]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!disableURLUpdates && !shouldUpdateUrlTo && valuesForURLUpdate.current && currQuery.current) {
      let shouldUpdate = false;
      let fq = null;
      let sq = null;
      let searchQ = null;
      if (Array.isArray(valuesForURLUpdate.current.filters)) {
        fq = getFilterQuery(
          valuesForURLUpdate.current.filters.filter((f) => {
            if (isObject(f) && f.field) {
              if (options && options.filter) {
                if (!options.filter[f.field] || options.filter[f.field].exclude) {
                  return false;
                }
              }
            }
            return true;
          })
        );
        const currFq = currQuery.current.has(fq.param) ? currQuery.current.get(fq.param) : '';
        if (fq.value !== currFq) {
          shouldUpdate = true;
          currQuery.current.set(fq.param, fq.value);
        }
      }
      if (Array.isArray(valuesForURLUpdate.current.search)) {
        searchQ = getSearchQuery(
          valuesForURLUpdate.current.search.filter((f) => {
            if (isObject(f) && f.field) {
              if (options && options.search) {
                if (!options.search[f.field] || options.search[f.field].exclude) {
                  return false;
                }
              }
            }
            return true;
          })
        );
        const currSearchQ = currQuery.current.has(searchQ.param) ? currQuery.current.get(searchQ.param) : '';
        if (searchQ.value !== currSearchQ) {
          shouldUpdate = true;
          currQuery.current.set(searchQ.param, searchQ.value);
        }
      }
      if (valuesForURLUpdate.current.sort) {
        sq = getSortQuery(valuesForURLUpdate.current.sort);
        const currSq = currQuery.current.has(sq.param) ? currQuery.current.get(sq.param) : '';
        if (sq.value !== currSq) {
          shouldUpdate = true;
          currQuery.current.set(sq.param, sq.value);
        }
      }
      if (shouldUpdate) {
        const queryString = currQuery.current.toString();
        valuesForURLUpdate.current = null;
        const callback = () => navigate.search(queryString);
        setShouldUpdateUrlTo(() => callback);
      }
    }
  }, [disableURLUpdates, navigate, getFilterQuery, getSortQuery, getSearchQuery, shouldUpdateUrlTo, options]);

  const handleOnChange = useEventCallback((sc) => {
    if (onChange && typeof onChange === 'function') {
      onChange(sc, valuesForURLUpdate.current);
    }
  });

  const memoedSearchConfig = useMemoCompare(searchConfig);

  useEffect(() => {
    handleOnChange(memoedSearchConfig);
  }, [memoedSearchConfig, handleOnChange]);

  return memoedSearchConfig;
}

/*
  (defaults = {
    field // default field if not defined
    value // default value if not defined
    operator // default operator if not defined
    fields = {} // default value/operator per field if not defined and that field exists in query
  })
*/
function useFilterQueryParams(defaults = {}, freeze = false) {
  let fieldDefault = defaults.field ? defaults.field : '';
  let operatorDefault = defaults.operator ? defaults.operator : '';
  let valueDefault = defaults.value;
  const defaultsRef = useRef();
  defaultsRef.current = defaults;
  const param = defaults.param ? defaults.param : 'filter';
  const valRef = useRef('');
  const getRef = useRef(null);
  const [qv, , qg] = useQueryParam(param);
  let val = qv;
  let get = qg;
  if (freeze) {
    val = valRef.current;
    get = getRef.current;
  } else {
    valRef.current = qv;
    getRef.current = qg;
  }
  const filterQuery = val.trim();

  const filters = useMemo(() => {
    let filters = [];
    if (filterQuery) {
      const fArray = filterQuery.split(QUERY_ARRAY_COMMA);
      for (const filter of fArray) {
        if (filter && typeof filter === 'string') {
          let [field = '', value = '', operator = ''] = filter.split(QUERY_PROP_SEPERATOR);
          field = field.trim();
          value = typeof value === 'string' ? value.trim() : value;
          operator = operator.trim();
          field = field ? field : fieldDefault;
          const filterDefaults = defaultsRef.current.fields || {};
          value = value ? value : filterDefaults[field] && filterDefaults[field].value ? filterDefaults[field].value : valueDefault;
          operator = operator ? operator : filterDefaults[field] && filterDefaults[field].operator ? filterDefaults[field].operator : operatorDefault;
          if (value) {
            filters.push({ field, value, operator });
          }
        }
      }
    }
    return filters;
  }, [filterQuery, fieldDefault, operatorDefault, valueDefault]);

  const getQuery = useEventCallback(
    (v) => {
      const next = typeof v === 'function' ? v(filters) : v;
      if (!next) {
        return get();
      } else if (Array.isArray(next)) {
        if (next.length) {
          let result = [];
          for (const f of next) {
            if (isObject(f)) {
              let { field, value, operator } = f;
              field = field ? field : fieldDefault;
              const filterDefaults = defaultsRef.current.fields || {};
              value = value ? value : filterDefaults[field] && filterDefaults[field].value ? filterDefaults[field].value : valueDefault;
              operator = operator
                ? operator
                : filterDefaults[field] && filterDefaults[field].operator
                ? filterDefaults[field].operator
                : operatorDefault;
              if (field && value) {
                result.push(`${field}${QUERY_PROP_SEPERATOR}${value}${operator ? `${QUERY_PROP_SEPERATOR}${operator}` : ''}`);
              }
            }
          }
          if (result.length) {
            return get(result);
          } else {
            return get();
          }
        } else {
          return get();
        }
      } else if (isObject(next)) {
        let { field, value, operator } = next;
        field = field ? field : fieldDefault;
        const filterDefaults = defaultsRef.current.fields || {};
        value = value
          ? value
          : filterDefaults[field] && filterDefaults[field].value
          ? filterDefaults[field].value
          : valueDefault !== undefined
          ? valueDefault
          : value;
        operator = operator ? operator : filterDefaults[field] && filterDefaults[field].operator ? filterDefaults[field].operator : operatorDefault;

        if (field) {
          let result = [];
          let found = false;
          for (const f of filters) {
            if (f.field === field && !found) {
              found = true;
              if (value) {
                let oper = operator ? operator : f.operator;
                result.push(`${field}${QUERY_PROP_SEPERATOR}${value}${oper ? `${QUERY_PROP_SEPERATOR}${oper}` : ''}`);
              }
            } else {
              result.push(`${f.field}${QUERY_PROP_SEPERATOR}${f.value}${f.operator ? `${QUERY_PROP_SEPERATOR}${f.operator}` : ''}`);
            }
          }
          if (!found && value) {
            result.push(`${field}${QUERY_PROP_SEPERATOR}${value}${operator ? `${QUERY_PROP_SEPERATOR}${operator}` : ''}`);
          }
          if (result.length) {
            return get(result);
          } else {
            return get();
          }
        }
      } else {
        return get(v);
      }
    },
    [filters, get]
  );

  const setQuery = useEventCallback(
    (v) => {
      const { navigateTo } = getQuery(v);
      navigateTo();
    },
    [getQuery]
  );

  return useMemo(() => {
    return [filters, setQuery, getQuery];
  }, [filters, setQuery, getQuery]);
}

/*
  (defaults = {
    field // default field if not defined
    by // default sort by if not defined
    fields = {} // default sort by per field if not defined and that field exists in query
  })
*/
function useSortQueryParams(defaults = {}, freeze = false) {
  let field = defaults.field ? defaults.field : '';
  let by = defaults.by ? defaults.by : '';
  const param = defaults.param ? defaults.param : 'sort';
  const valRef = useRef('');
  const getRef = useRef(null);
  const [qv, , qg] = useQueryParam(param);
  let value = qv;
  let get = qg;
  if (freeze) {
    value = valRef.current;
    get = getRef.current;
  } else {
    valRef.current = qv;
    getRef.current = qg;
  }
  const sortQuery = value.trim();
  if (sortQuery) {
    let [f = '', b = ''] = sortQuery.split(QUERY_PROP_SEPERATOR);
    f = f.trim();
    b = b.trim();
    field = f ? f : field;
    by = b;
    if (!by) {
      if (f && defaults.fields && defaults.fields[f] && defaults.fields[f].by) {
        by = defaults.fields[f].by;
      } else {
        by = defaults.by;
      }
    }
  }

  const getQuery = useEventCallback(
    (v) => {
      const next = typeof v === 'function' ? v({ field, by }) : v;
      if (!next || !isObject(next) || !next.field) {
        return get();
      } else {
        let { field: nextField, by: nextBy } = next;
        return get(`${nextField}${nextBy ? `${QUERY_PROP_SEPERATOR}${nextBy}` : ''}`);
      }
    },
    [field, by, get]
  );

  const setQuery = useEventCallback(
    (v) => {
      const { navigateTo } = getQuery(v);
      navigateTo();
    },
    [getQuery]
  );

  return useMemo(() => {
    return [{ field, by }, setQuery, getQuery];
  }, [field, by, setQuery, getQuery]);
}

function useSearchQueryParams(defaults = {}, freeze = false) {
  return useFilterQueryParams({ param: 'search', ...defaults }, freeze);
}

function encodeSearchConfig(config) {
  if (config) {
    if (typeof config === 'string') {
      return config;
    } else if (isObject(config)) {
      const cleanConfig = {};
      const { limit, offset, filters, sort } = config;
      if (!isNull(limit)) {
        cleanConfig.limit = limit;
      }
      if (!isNull(offset)) {
        cleanConfig.offset = offset;
      }
      if (!isNull(sort)) {
        cleanConfig.sort = sort;
      }
      if (!isNull(filters)) {
        cleanConfig.filters = filters;
      }
      return encodeURI(JSON.stringify(cleanConfig));
    }
  }
  return '';
}

export { useSearchConfig, useSortQueryParams, useFilterQueryParams, useSearchQueryParams, encodeSearchConfig };
