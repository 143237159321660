import React from 'react';
import { Text, Heading } from '../ui';
import { AppBar, Page, Main, NavRow, LogoNavGroup, ContentCard } from '../components';

const Page404 = React.forwardRef((props, ref) => {
  return (
    <Page {...props} ref={ref}>
      <AppBar>
        <NavRow>
          <LogoNavGroup title="Understory" />
        </NavRow>
      </AppBar>
      <Main pb="$13" layout="center">
        <ContentCard gap="12">
          <Heading level={3} spaceAfter>
            Page not found
          </Heading>
          <Text>This page has either disappeared or simply never existed!</Text>
          <Text small>
            That or our developers sent you to the wrong place{' '}
            <Text small bold>
              (and they apologize)
            </Text>
            .
          </Text>
        </ContentCard>
      </Main>
    </Page>
  );
});

export { Page404 };
