import React from 'react';
import { Text } from '../ui';
import { useQuoteExpiration } from '../hooks';

export const QuoteExpirationText = React.forwardRef(function ExpirationTime(props, ref) {
  const { createdAt: ca, status: s, quote, ...rest } = props;
  const createdAt = ca ? ca : quote ? quote.createdAt : null;
  const status = s ? s : quote ? quote.status : null;
  const { text: displayText } = useQuoteExpiration({ createdAt, status });

  return (
    <Text ref={ref} {...rest}>
      {displayText}
    </Text>
  );
});
