import React from 'react';
import { Text } from '../Text';
import { Box } from '../Box';
import { withStyles, getStyle } from '../../styling';
import { resolveVariantName } from '../../system';
import { flattenStyles } from '../../styling/utils';
import { useLayout } from '../../hooks';

const Label = React.memo(
  withStyles(
    ({ theme, filled, error, onColor, color, focused, size = 'large', textBoxStyle }) => {
      const { paddingLeft = 0, paddingTop = 0, paddingBottom = 0 } = getStyle(textBoxStyle) || {};
      return {
        root: { props: { x: paddingLeft } },
        box: {
          position: 'absolute',
          top: 0,
          left: paddingLeft,
          translateY: 0,
          translateX: 0,
          zIndex: 9999999,
        },
        text: {
          flex: 1,
          color: theme.colors.alpha(theme.colors.on(onColor), 0.7),
          props: {
            align: 'left',
            large: true,
            size: size,
            maxLines: 1,
            // lineHeight: 0, < -- NOTE commented this out because it was causing label to be incorrectly placed... this happened after removing 100% height to input itself (for multiline autosizing)
          },
        },
        size: {
          variant: resolveVariantName('text', { variantPrefix: 'text', variantSize: size }),
          // lineHeight: 0,
        },
        sizeShrink: {
          variant: resolveVariantName('text', { variantPrefix: 'text', variantSize: 'xSmall' }),
          // lineHeight: 0,
        },
        errorColor: {
          color: '$error',
        },
        focusedColor: {
          color: color || '$secondary',
        },
        filledText: {},
        underlinedText: {},
        outlinedText: {},
        textShrink: {},
        filled: {
          translateY: (paddingTop + paddingBottom) / 2,
        },
        underlined: {
          translateY: paddingTop,
        },
        outlined: {
          translateY: (paddingTop + paddingBottom) / 2,
        },
        filledShrink: {
          translateY: paddingBottom - 2,
        },
        underlinedShrink: {
          translateY: paddingBottom - 2,
        },
        outlinedShrink: {
          translateY: paddingBottom - 2,
        },
      };
    },
    {
      name: 'TextField.Label',
      preserveStyleProp: true,
      filterProps: ['size', 'textBoxStyle', 'disabled', 'endAdornment', 'startAdornment', 'hovered', 'color'],
    }
  )(({ styles, style, x = 0, children, filled, focused, error, required, inputVariant = 'underlined', ...rest }) => {
    const boxStyle = [styles.box];
    let boxProps = { ...styles.props.box, ...styles.props[inputVariant], animate: 'default' };
    const textStyle = [styles.text, styles[`${inputVariant}Text`]];
    let textProps = { ...styles.props.text, ...styles.props[`${inputVariant}Text`], animate: 'default' };

    if (filled || focused) {
      textStyle.push(styles.textShrink);
      boxProps = { ...boxProps, ...styles.props[`${inputVariant}Shrink`] };
      boxProps.animate = 'shrink';
      textProps.animate = ['shrink'];
    }

    if (focused) {
      textStyle.push(styles.focusedColor);
    }

    if (error) {
      textStyle.push(styles.errorColor);
    }

    // TODO: fix this eslint disable - Ryan
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const hasLayout = React.useRef(false);

    // TODO: fix this eslint disable - Ryan
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { onLayout, width: layoutWidth, height: layoutHeight } = useLayout();

    const animations = {
      initial: false,
      default: {
        style: styles[inputVariant],
        // immediate: !hasLayout.current,
      },
      shrink: {
        style: styles[`${inputVariant}Shrink`],
        // immediate: !hasLayout.current,
      },
      config: {
        clamp: true,
        tension: 500,
        friction: 35,
      },
    };

    boxProps.animations = animations;

    const { fontSize = 1 } = getStyle(styles.size || {});
    const { fontSize: fontSizeSmall = 1 } = getStyle(styles.sizeShrink || {});
    const scale = fontSizeSmall / fontSize;
    textProps.animations = {
      initial: false,
      default: {
        opacity: layoutWidth ? 1 : 0,
        scale: 1,
        translateX: 0,
        translateY: 0,
        tx2: 0,
        ty2: 0,
        // immediate: !hasLayout.current,
      },
      shrink: {
        opacity: layoutWidth ? 1 : 0,
        scale,
        translateX: layoutWidth / -2,
        translateY: layoutHeight / -2,
        tx2: layoutWidth / 2,
        ty2: layoutHeight / 2,
        // immediate: !hasLayout.current,
      },
      format: (a, what) => {
        const { translateX, scale, translateY, tx2, ty2, ...r } = a;
        const next = flattenStyles({
          ...r,
          transform: [{ translateX }, { translateY }, { scale }, { translateX: tx2 }, { translateY: ty2 }],
        });
        return next;
      },
      config: {
        clamp: true,
        tension: 500,
        friction: 35,
      },
    };

    if (!hasLayout.current && layoutWidth && layoutHeight) {
      hasLayout.current = true;
    }

    return (
      <Box disableAnimationDefaults onLayout={onLayout} pointerEvents="none" style={boxStyle} {...boxProps}>
        <Text style={textStyle} {...textProps} debugAnimate={children === 'ADJUSTMENT FACTOR'} {...rest}>
          {children && typeof children === 'string' && required && !children.endsWith('*') ? `${children}*` : children}
        </Text>
      </Box>
    );
  })
);

export { Label };
