import { CountryCodeList } from './types';

const localData = { countries: null };

const loadData = () => {
  if (!localData.countries) {
    localData.countries = require('./data.json');
  }
  return localData.countries;
};

const localeUtils = {
  getImageFlag(countryCode = 'US') {
    const countries = loadData();
    return countries[countryCode].flag;
  },
  getCountryName(countryCode = 'US', language = 'common') {
    const countries = loadData();
    const name = countries[countryCode].name[language] || countries[countryCode].name.common;
    return name || '';
  },
  getCountryCallingCode(countryCode = 'US') {
    const countries = loadData();
    const callingCode = countries[countryCode].callingCode[0];
    return callingCode || '';
  },
  getCountryCurrency(countryCode = 'US') {
    const countries = loadData();
    const currency = countries[countryCode].currency[0];
    return currency || '';
  },
  getCountryRegion(countryCode = 'US') {
    const countries = loadData();
    const { region } = countries[countryCode];
    return region || '';
  },
  getCountrySubregion(countryCode = 'US') {
    const countries = loadData();
    const { subregion } = countries[countryCode];
    return subregion || '';
  },
  getCountryCodeListWithValidCodes() {
    const countries = loadData();
    if (!localData.countriesWithValidCodes) {
      localData.countriesWithValidCodes = CountryCodeList.filter((c) => countries[c].callingCode.length && countries[c].currency.length);
    }
    return localData.countriesWithValidCodes;
  },
  getCountryCodeListSortedByLongestCallingCode() {
    const countries = loadData();
    if (!localData.countryListByLongestCallingCode) {
      const countryList = localeUtils.getCountryCodeListWithValidCodes();
      localData.countryListByLongestCallingCode = countryList.sort((a, b) => countries[b].callingCode[0].length - countries[a].callingCode[0].length);
    }
    return localData.countryListByLongestCallingCode;
  },
};

export { localeUtils };
