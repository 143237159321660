import { MIN_MAX_RANGE, DATES_FROM_TO, SELECT, MULTI_SELECT } from './filterTypes';

export const policyItemData = {
  address: {
    display: true,
    search: {
      name: 'address',
      label: 'Address',
    },
    type: 'string',
  },
  agent: {
    type: 'object',
    properties: {
      email: {
        display: true,
        sort: {
          name: 'agent_email',
          label: 'Agent Email',
        },
        search: {
          name: 'agent_email',
          label: 'Agent Email',
        },
        type: 'string',
      },
      name: {
        display: true,
        sort: {
          name: 'agent_name',
          label: 'Agent Name',
        },
        search: {
          name: 'agent_name',
          label: 'Agent Name',
        },
        type: 'string',
      },
      contactId: {
        type: 'number',
      },
      id: {
        type: 'number',
      },
      userId: {
        type: 'number',
      },
    },
  },
  boundAt: {
    display: true,
    filter: {
      type: DATES_FROM_TO,
      name: 'bound_at',
      label: 'Bound At',
    },
    sort: {
      name: 'bound_at',
      label: 'Bound At',
    },
    type: 'number',
  },
  broker: {
    type: 'object',
    properties: {
      id: {
        type: 'number',
      },
      name: {
        display: true,
        sort: {
          name: 'broker_name',
          label: 'Broker Name',
        },
        search: {
          name: 'broker_name',
          label: 'Broker Name',
        },
        type: 'string',
      },
    },
  },
  carrier: {
    type: 'object',
    properties: {
      id: {
        type: 'number',
      },
      name: {
        display: true,
        sort: {
          name: 'carrier_name',
          label: 'Carrier Name',
        },
        search: {
          name: 'carrier_name',
          label: 'Carrier Name',
        },
        type: 'string',
      },
    },
  },
  countryCode: {
    display: true,
    filter: {
      type: SELECT,
      name: 'country_code',
      label: 'Country Code',
      options: {},
      defaults: {
        value: null,
      },
      exclude: true,
    },
    sort: {
      name: 'country_code',
      label: 'Country Code',
      exclude: true,
    },
    search: {
      name: 'country_code',
      label: 'Country Code',
    },
    type: 'string',
  },
  coveredValue: {
    display: true,
    filter: {
      type: MIN_MAX_RANGE,
      name: 'covered_value',
      label: 'Covered Value',
    },
    sort: {
      name: 'covered_value',
      label: 'Covered Value',
    },
    type: 'number',
  },
  currency: {
    type: 'object',
    properties: {
      currencyCode: {
        type: 'string',
      },
    },
  },
  customerId: {
    type: 'string',
  },
  effectiveAt: {
    display: true,
    filter: {
      type: DATES_FROM_TO,
      name: 'effective_at',
      label: 'Effective At',
    },
    sort: {
      name: 'effective_at',
      label: 'Effective At',
      initial: { field: 'effective_at', by: 'DESC' },
    },
    type: 'number',
  },
  effectiveUntil: {
    display: true,
    filter: {
      type: DATES_FROM_TO,
      name: 'effective_until',
      label: 'Effective Until',
    },
    sort: {
      name: 'effective_until',
      label: 'Effective Until',
    },
    type: 'number',
  },
  id: {
    type: 'string',
  },
  insured: {
    display: true,
    sort: {
      name: 'insured',
      label: 'Insured',
    },
    search: {
      name: 'insured',
      label: 'Insured',
    },
    type: 'string',
  },
  locations: {
    type: 'array',
    properties: {
      length: {
        display: true,
        filter: {
          type: MIN_MAX_RANGE,
          name: 'locations_length',
          label: 'Locations Length',
        },
        sort: {
          name: 'locations_length',
          label: 'Locations Length',
        },
        type: 'number',
      },
    },
    values: {
      type: 'object',
    },
  },
  namedInsureds: {
    type: 'array',
    properties: {
      length: {
        type: 'number',
      },
    },
    values: {
      display: true,
      search: {
        name: 'named_insureds',
        label: 'Named Insureds',
      },
      type: 'string',
    },
  },
  netPremium: {
    display: true,
    filter: {
      type: MIN_MAX_RANGE,
      name: 'net_premium',
      label: 'Net Premium',
    },
    sort: {
      name: 'net_premium',
      label: 'Net Premium',
    },
    type: 'string',
  },
  payoutsEnabled: {
    display: true,
    filter: {
      type: SELECT,
      name: 'payouts_enabled',
      label: 'Policy Status',
      options: {
        any: {
          label: 'Any',
          name: 'any',
          value: null,
          fieldValue: null,
        },
        enabled: {
          name: 'enabled',
          label: 'Payouts enabled',
          value: 'enabled',
          fieldValue: [{ field: 'payouts_enabled', value: true, operator: 'equals' }],
        },
        disabled: {
          name: 'disabled',
          label: 'Payouts disabled',
          value: 'disabled',
          fieldValue: [{ field: 'payouts_enabled', value: false, operator: 'equals' }],
        },
      },
      order: 1,
      initial: { field: 'payouts_enabled', value: true, operator: 'equals' },
      defaults: {
        value: null,
      },
    },
    sort: {
      name: 'payouts_enabled',
      label: 'Payouts Enabled',
    },
    type: 'boolean',
  },
  postcode: {
    display: true,
    sort: {
      name: 'postcode',
      label: 'Postcode',
    },
    search: {
      name: 'postcode',
      label: 'Postcode',
    },
    type: 'string',
  },
  product: {
    type: 'object',
    properties: {
      carrierId: {
        type: 'number',
        notes: 'Carrier is also on quote so assuming this is duplicate data.',
      },
      countryCode: {
        type: 'string',
      },
      id: {
        type: 'string',
        notes: 'assuming that product.name is going to be filterd/sorted via id',
      },
      name: {
        display: true,
        filter: {
          type: MULTI_SELECT,
          name: 'product_name',
          label: 'Product',
          options: {},
          defaults: {
            value: null,
          },
        },
        sort: {
          name: 'product_name',
          label: 'Product',
        },
        type: 'string',
      },
      productType: {
        display: true,
        filter: {
          type: MULTI_SELECT,
          name: 'product_type',
          label: 'Product Type',
          options: {},
          defaults: {
            value: null,
          },
        },
        sort: {
          name: 'product_type',
          label: 'Product Type',
        },
        type: 'string',
      },
      triggerType: {
        type: 'string',
      },
      useMetric: {
        type: 'boolean',
      },
    },
  },
  regionCode: {
    display: true,
    sort: {
      name: 'region_code',
      label: 'Region Code',
      exclude: true,
    },
    search: {
      name: 'region_code',
      label: 'Region Code',
      exclude: true,
    },
    filter: {
      type: MULTI_SELECT,
      name: 'region_code',
      label: 'Region Code',
      options: {},
      exclude: true,
    },
    type: 'string',
  },
  region: {
    display: true,
    sort: {
      name: 'region',
      label: 'Region/Province',
    },
    search: {
      name: 'region',
      label: 'Region/Province',
    },
    add: true,
    type: 'string',
    notes: 'Taking into account states/provinces outside of the US and so that I dont need to host a JSON of codes -> names',
  },
  thresholds: {
    type: 'array',
    properties: {
      length: {
        type: 'number',
      },
    },
    values: {
      type: 'object',
    },
  },
  useMetric: {
    type: 'boolean',
  },
};
