import { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment-timezone';
import equal from 'fast-deep-equal/es6';
import { useEventCallback } from './useEventCallback';
import { equalWithout } from '../../utils';
import { LocalStorage, uniqueId } from '../utils';
import { useMemoCompare } from './useMemoCompare';

const useAsync = (asyncFunction, config) => {
  const {
    immediate = true,
    resetValueOnExecute = false,
    cache: cacheProp = false,
    cacheKey: cacheKeyProp,
    comparer,
    onCacheFull,
    cancel: cancelFunc, // a function to cancel the async call (however you want to handle it. axios cancel token for example pass the source function)
    isCanceled: isCancelFunc, // function that recieves error and when canceled how to tell if the call was canceled upon thrown. ((e) => e.message === CANCELED)
  } = config || {};
  const cancel = useEventCallback((...args) => (cancelFunc ? cancelFunc(...args) : null));
  const isCanceled = useEventCallback((...args) => (isCancelFunc ? isCancelFunc(...args) : false));
  const [status, setStatus] = useState(immediate ? 'pending' : 'idle');
  const [value, setValue] = useState(null);
  const [cached, setCached] = useState(null);
  const lastExeId = useRef(null);
  const cachedValue = useRef(undefined);
  const handleCacheFull = useRef();
  handleCacheFull.current = onCacheFull;
  const last = useRef();
  last.current = { value };

  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const mounted = useRef(true);
  useEffect(() => {
    return () => (mounted.current = false);
  }, []);
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    async (...args) => {
      const exeId = uniqueId('exe');
      lastExeId.current = exeId;
      setStatus('pending');
      setError(null);
      setCached(false);
      setLastUpdated(moment().valueOf());

      if (resetValueOnExecute) {
        setValue(null);
      }

      const cache = typeof cacheProp === 'function' ? cacheProp(...args) : cacheProp;
      const cacheKey = typeof cacheKeyProp === 'function' ? cacheKeyProp(...args) : cacheKeyProp;
      let valueEqualsCachedValue = false;
      if (cache && cacheKey) {
        try {
          const storedCachedValue = await LocalStorage.getItem(cacheKey);
          if (storedCachedValue) {
            const { data } = JSON.parse(storedCachedValue);
            if (data) {
              cachedValue.current = data;
              setCached(true);
              if (!resetValueOnExecute) {
                const lastValue = last.current.value;
                if (!comparer(lastValue, cachedValue.current)) {
                  valueEqualsCachedValue = true;
                  setValue(cachedValue.current);
                }
              }
            }
          }
        } catch (cacheError) {
          if (process.env.NODE_ENV !== 'production') {
            console.warn('useAsync cache error for key ', cacheKey);
            console.warn(cacheError);
          }
        }
      }
      // const makeCallWithCallbacks = async (onSuccess, onError) => {
      //   try {
      //     const response = await asyncFunction(...args);
      //     await onSuccess(response);
      //   } catch (err) {
      //     await onError(err);
      //   }
      // }
      // makeCallWithCallbacks(
      //   async (response) => {
      //     if (mounted.current) {
      //       if (valueEqualsCachedValue && !comparer(cachedValue.current, response)) {
      //         setValue(response);
      //       } else {
      //         setValue(response);
      //       }
      //       setStatus("success");
      //       if (cache && cacheKey) {
      //         try {
      //           await LocalStorage.setItem(cacheKey, JSON.stringify({ data: response, timestamp: moment.now() }));
      //         } catch (cacheError) {
      //           console.warn('useAsync cache error for key ', cacheKey, cacheError);
      //         }
      //       }
      //     }
      //   },
      //   async (err) => {
      //     if (mounted.current) {
      //       if (!valueEqualsCachedValue) {
      //         setValue(null);
      //       }
      //       setError(err);
      //       setStatus("error");
      //     }
      //   }
      // )
      return asyncFunction(...args)
        .then(async (response) => {
          if (mounted.current && exeId === lastExeId.current) {
            if (valueEqualsCachedValue && !comparer(cachedValue.current, response)) {
              setValue(response);
            } else {
              setValue(response);
            }
            setStatus('success');
            if (cache && cacheKey) {
              const valueToStore = JSON.stringify({ data: response, timestamp: moment.now() });
              try {
                await LocalStorage.setItem(cacheKey, valueToStore);
              } catch (cacheError) {
                try {
                  const full = cacheError.name === 'QuotaExceededError';
                  if (full && handleCacheFull.current) {
                    await handleCacheFull.current(cacheKey, valueToStore);
                  } else if (process.env.NODE_ENV !== 'production') {
                    console.warn('useAsync cache error for key ', cacheKey);
                    if (full) {
                      console.warn(
                        'Storage is full. Include async func to clear the storage on prop onCacheFull: (cacheKey, valueToStore) => ...you do you'
                      );
                    }
                  }
                } catch (finalError) {
                  console.warn('Error with cacheing value in storage');
                  console.warn(finalError);
                }
              }
            }
          }
        })
        .catch((error) => {
          if (mounted.current) {
            if (isCanceled() === true) {
              setStatus('success');
              setError(null);
              return;
            }
            if (!valueEqualsCachedValue) {
              setValue(null);
            }
            setError(error);
            setStatus('error');
          }
        });
    },
    [asyncFunction, resetValueOnExecute, cacheProp, cacheKeyProp, comparer, isCanceled]
  );
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate && mounted.current) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, status, value, error, lastUpdated, setStatus, cachedValue, cached, cancel };
};

const useMemoAsync = (asyncFunction, config) => {
  const { comparer: compareFunc = equal, compareWithout, ...configProps } = config || {};
  const comparer = useEventCallback(
    (a, b) => {
      const isEqual = compareWithout ? equalWithout(compareWithout, compareFunc ? compareFunc : equal) : compareFunc ? compareFunc : equal;
      return isEqual(a, b);
    },
    [compareFunc, compareWithout]
  );
  const configMemo = useMemoCompare({
    comparer,
    ...configProps,
  });
  return useAsync(asyncFunction, configMemo);
};

export { useMemoAsync as useAsync };
