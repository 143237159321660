import React, { useState, useMemo } from 'react';
import { Pressable as RNPressable, Platform } from 'react-native';
import { useEventCallback } from '../../hooks';
import { withStyles, animated } from '../../styling';
import { ViewStylePropTypes } from '../../system';
import { useHoverable } from '../Hoverable';

const AnimatedPressable = animated(RNPressable);
AnimatedPressable.displayName = 'animated(Pressable)';

function usePressable({
  onPressIn: propOnPressIn,
  onPressOut: propOnPressOut,
  onFocus: propsOnFocus,
  onBlur: propsOnBlur,
  focused: forceFocused = undefined,
  pressed: forcePressed = undefined,
  ...hoverConfig
} = {}) {
  const [pressed, setPressed] = useState(false);
  const [focused, setFocused] = useState(false);
  const onPressIn = useEventCallback((...args) => {
    setPressed(true);
    if (propOnPressIn) {
      propOnPressIn(...args);
    }
  });

  const onPressOut = useEventCallback((...args) => {
    setPressed(false);
    if (propOnPressOut) {
      propOnPressOut(...args);
    }
  });

  const onFocus = useEventCallback((...args) => {
    setFocused(true);
    if (propsOnFocus) {
      propsOnFocus(...args);
    }
  });

  const onBlur = useEventCallback((...args) => {
    setFocused(false);
    if (propsOnBlur) {
      propsOnBlur(...args);
    }
  });

  const hoverState = useHoverable(hoverConfig);

  return {
    pressed: typeof forcePressed === 'boolean' ? forcePressed : pressed,
    focused: typeof forceFocused === 'boolean' ? forceFocused : focused,
    onPressIn,
    onPressOut,
    onFocus,
    onBlur,
    ...hoverState,
  };
}

const Pressable = withStyles(
  {
    root: (props) => {
      const styles = {};
      for (const key in ViewStylePropTypes) {
        if (props[key] !== undefined) {
          styles[key] = props[key];
        }
      }

      return styles;
    },
  },
  {
    name: 'Pressable',
    filterProps: Object.keys(ViewStylePropTypes),
  }
)(
  React.forwardRef(function Pressable(props, ref) {
    const {
      animate: animateProp,
      onPressIn,
      onPressOut,
      onHoverIn,
      onHoverOut,
      onHoverChange,
      onResponderGrant,
      onResponderRelease,
      onFocus,
      onBlur,
      disabled,
      accessibility: accessibilityProp,
      focusable: focusableProp = false,
      accessible: accessibleProp = false,
      children,
      debugMe,
      focused: forceFocused,
      hovered: forceHovered,
      pressed: forcePressed,
      ...rest
    } = props;

    const { pressed, hovered, focused, ...handlers } = usePressable({
      onPressIn,
      onPressOut,
      onHoverIn,
      onHoverOut,
      onHoverChange,
      onResponderGrant,
      onResponderRelease,
      pressed: forcePressed,
      focused: forceFocused,
      hovered: forceHovered,
      onFocus,
      onBlur,
    });
    if (debugMe) {
      console.log(pressed, hovered, focused);
    }
    const animate = useMemo(() => {
      const animate = ['default'];
      if (focused) {
        animate.push('focused');
      }
      if (hovered) {
        animate.push('hovered');
      }
      if (pressed) {
        animate.push('pressed');
      }
      if (Array.isArray(animateProp)) {
        return [...animate, ...animateProp];
      } else if (typeof animateProp === 'string') {
        animate.push(animateProp);
      }
      if (animate.length === 1) {
        return animate[0];
      }
      return animate;
    }, [pressed, hovered, focused, animateProp]);

    const {
      focusable: accessibilityFocusable = false,
      accessible: accessibilityAccessible = false,
      ...accessibility
    } = accessibilityProp ? accessibilityProp : {};
    const focusable = focusableProp || accessibilityFocusable || accessibleProp || accessibilityAccessible;
    if (Platform.OS === 'web') {
      // web uses focusable prop
      accessibility.focusable = focusable;
    } else {
      // native uses accessible prop
      accessibility.accessible = focusable;
    }

    return (
      <AnimatedPressable
        animate={animate}
        ref={ref}
        disabled={disabled}
        {...{
          accessibilityDisabled: disabled,
          ...accessibility,
          accessibilityState: {
            disabled: disabled,
            ...accessibility.accessibilityState,
          },
        }}
        {...handlers}
        {...rest}
      >
        {typeof children === 'function' ? ({ focused }) => children({ focused, hovered, pressed }) : children}
      </AnimatedPressable>
    );
  })
);

export { Pressable, usePressable };
