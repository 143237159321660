import React from 'react';
import { Box, Portal } from '../../ui';
import { withStyles } from '../../ui/styling';
import { layout as layoutProps } from '../../ui/system/props';
import { ProvideContainerContext } from './context';
import { resolveLayoutStyleProp, getFlexLayout } from './utils';

export const LayoutBox = withStyles(
  {
    root: (props) => {
      let posProps = null;
      const { fixed, absolute, defaultLayout } = props;
      if (absolute) {
        posProps = {
          position: resolveLayoutStyleProp(absolute, props) ? 'absolute' : 'relative',
          top: 0,
          left: 0,
        };
      }
      if (fixed) {
        posProps = {
          position: resolveLayoutStyleProp(fixed, props) ? 'fixed' : 'relative',
          top: 0,
          left: 0,
        };
      }
      return {
        ...layoutProps({
          ...posProps,
          ...getFlexLayout(props, defaultLayout ? defaultLayout : { flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'stretch' }),
          ...props,
        }),
        props: { disableAnimationDefaults: true },
      };
    },
  },
  { filterProps: [...layoutProps.filterProps, 'layout', 'defaultLayout', 'stretch', 'row', 'fixed', 'absolute'] }
)(
  React.forwardRef(function LayoutBox(props, ref) {
    const { portal, style, include, component: Component = Box, styleKeys, afterLayoutComponent: AfterLayoutComponent, ...rest } = props;
    const element = <Component ref={ref} style={style} {...rest} />;
    return (
      <ProvideContainerContext style={style} include={include} styleKeys={styleKeys}>
        {portal ? <Portal name={portal}>{element}</Portal> : element}
        {AfterLayoutComponent ? <AfterLayoutComponent /> : null}
      </ProvideContainerContext>
    );
  })
);
