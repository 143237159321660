function capitalize(val) {
  if (typeof val !== 'string') {
    return '';
  }
  return val.charAt(0).toUpperCase() + val.slice(1);
}

const normalizeCamelCase = (camelCaseStr) => {
  const words = camelCaseStr.match(/^[a-z]+|[A-Z][a-z]*/g);

  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
};

export { capitalize, normalizeCamelCase };
