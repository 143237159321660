import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Text, Heading, Spacing } from '../../ui';
import { isObject } from '../../ui/utils';
import { Page, Main, ContentCard } from '../../components';
import { useApi } from '../../hooks';
import { Section } from './Sections/components';
import { useSiteChecklistContext } from './context';
import {
  AdditionalDetailsSection,
  NotesForInternalUse,
  // DeploymentTypeControlForInternalUse,
  PointOfContactData,
  InstallLocationData,
  RequirementsData,
} from './Sections';
import { ApproveDialog, RejectDialog, ReviewSubmitDialog } from './dialogs';
// import moment from 'moment-timezone';
import { getAddressLines } from '../../utils';

/*
for broker/client PSCL url, after or bfore onboard, new step/data piece is selecting the location you're filling info for 
formContactEmail parameter for pscl details jsonb <- filled in at the end after review, in dialog, when pressed Confirm. Submit btn in dialog and ends the process
*/

const FormContent = React.forwardRef((props, ref) => {
  const siteChecklistContext = useSiteChecklistContext();
  const {
    data,
    bindForm,
    submitRequest,
    updateData,
    status,
    errors,
    isUpdatable,
    canMakeChanges,
    canApproveReject,
    // canInternalMakeChanges,
    internalViewer,
    formStatus,
    // editingInternal,
    editingEnabled,
  } = siteChecklistContext;
  const { submitDetailsOnBindingForm, approveBindingFormWithIdForQuoteId, rejectBindingFormWithIdForQuoteId } = useApi();
  const internalSubmission = editingEnabled && internalViewer && isUpdatable;
  const submit = useMemo(() => {
    if (canMakeChanges || internalSubmission) {
      return (submitterEmail) => {
        return submitRequest(async () => {
          if (isUpdatable || internalSubmission) {
            if (!internalSubmission && (!submitterEmail || typeof submitterEmail !== 'string' || !submitterEmail.trim().length)) {
              throw { submitFinal: 'Error: Missing form contact email address' };
            }
            await submitDetailsOnBindingForm(
              {
                ...data,
                submitterEmail,
                finalized: true,
              },
              bindForm,
              internalViewer
            );
            await updateData();
          }
        });
      };
    } else if (canApproveReject) {
      return (accept = false, bForm, rejectReason = '') => {
        return submitRequest(async () => {
          if (bForm && bForm.id && bForm.quoteId) {
            const { id: formId, quoteId } = bForm;
            if (accept) {
              if (bForm.approvedAt) {
                throw { approveReject: 'Error: Already approved' };
              }
              await approveBindingFormWithIdForQuoteId(formId, quoteId);
            } else {
              if (!rejectReason || typeof rejectReason !== 'string' || !rejectReason.trim().length) {
                throw { approveReject: 'Error: Missing reason for rejection' };
              }
              await rejectBindingFormWithIdForQuoteId(formId, quoteId, rejectReason);
              // TODO: ^^ add reason and throw if empty
            }
            await updateData();
          }
        });
      };
    }
    return () => null;
  }, [
    data,
    submitRequest,
    isUpdatable,
    canMakeChanges,
    canApproveReject,
    internalViewer,
    // canInternalMakeChanges,
    internalSubmission,
    updateData,
    submitDetailsOnBindingForm,
    approveBindingFormWithIdForQuoteId,
    rejectBindingFormWithIdForQuoteId,
    bindForm,
  ]);

  const [editing, setEditing] = useState(!internalViewer && formStatus.value === 'not-started' ? 'point-of-contact' : false);
  const [approveOpen, setApproveOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [submitOpen, setSubmitOpen] = useState(false);
  const handleSubmitWithEmail = useCallback(
    (email) => {
      return submit(email);
    },
    [submit]
  );
  const handleApprove = useCallback(() => {
    return submit(true, bindForm);
  }, [submit, bindForm]);
  const handleReject = useCallback(
    (reason) => {
      return submit(false, bindForm, reason);
    },
    [submit, bindForm]
  );

  useEffect(() => {
    if (status !== 'pending' && !(errors && errors.acceptReject) && !(errors && errors.submitFinal)) {
      setApproveOpen(false);
      setRejectOpen(false);
      setSubmitOpen(false);
    }
  }, [status, errors]);

  const { rejectedReason, rejectedAt, receivedAt } = bindForm || {};

  return (
    <Section
      heading=""
      alignItems="center"
      gap={24}
      ref={ref}
      actions={{
        left: [],
        right: internalSubmission
          ? [
              {
                label: 'Submit Form (Admin)',
                onPress: () => setSubmitOpen(true),
                disabled: status === 'pending',
                loading: status === 'pending',
              },
            ]
          : canMakeChanges && !internalViewer
          ? [
              {
                label: 'Submit',
                onPress: () => setSubmitOpen(true),
                disabled: status === 'pending',
                loading: status === 'pending',
              },
            ]
          : canApproveReject
          ? editingEnabled
            ? [{ label: 'Exit editing mode to approve/reject this form', disabled: true, color: '$gray.300', variant: 'text' }]
            : [
                {
                  label: 'Reject',
                  onPress: () => setRejectOpen(true),
                  disabled: status === 'pending',
                  loading: status === 'pending',
                },
                {
                  label: 'Approve',
                  onPress: () => setApproveOpen(true),
                  disabled: status === 'pending',
                  loading: status === 'pending',
                },
              ]
          : [],
      }}
      {...props}
    >
      <Spacing vertical={2} />
      {rejectedAt && rejectedAt > receivedAt && !internalViewer ? (
        <Box
          sx={({ colors }) => ({ bg: colors.alpha('orange', 0.25), borderWidth: 1, borderColor: 'orange', padX: '$3', padY: '$2', borderRadius: 8 })}
        >
          <Text bold>Your submission was rejected and requires revising:</Text>
          <Text>{!rejectedReason ? 'No reason was provided. Please check your email for a communication from us.' : `"${rejectedReason}"`}</Text>
        </Box>
      ) : null}
      <PageHead bindForm={bindForm} status={status} formStatus={formStatus} />
      <Spacing vertical={2} />
      <PointOfContactData editing={editing} setEditing={setEditing} />
      <InstallLocationData editing={editing} setEditing={setEditing} />
      <RequirementsData editing={editing} setEditing={setEditing} />
      <AdditionalDetailsSection siteChecklistContext={siteChecklistContext} />
      <NotesForInternalUse boxGapIgnore siteChecklistContext={siteChecklistContext} />
      <ApproveDialog
        boxGapIgnore
        open={approveOpen}
        onClose={() => setApproveOpen(false)}
        onConfirm={handleApprove}
        status={status}
        errors={errors}
      />
      <RejectDialog boxGapIgnore open={rejectOpen} onClose={() => setRejectOpen(false)} onSubmit={handleReject} status={status} errors={errors} />
      <ReviewSubmitDialog
        boxGapIgnore
        open={submitOpen}
        onClose={() => setSubmitOpen(false)}
        onSubmit={handleSubmitWithEmail}
        status={status}
        errors={errors}
        internalSubmission={internalSubmission}
      />
      {/* <StepEditModal editing={editing} setEditing={setEditing} siteChecklistContext={siteChecklistContext} /> */}
    </Section>
  );
});

const PageHead = ({ bindForm, formStatus, status }) => {
  const { insured, address, region, postcode, location, name } = bindForm;

  let locName = name || '';
  if (name.startsWith('Lease Agreement - ')) {
    let split = name.split('Lease Agreement - ');
    if (split.length) {
      locName = split[1];
    }
  } else if (name.startsWith('Site checklist: ')) {
    let split = name.split('Site checklist: ');
    if (split.length) {
      locName = split[1];
    }
  }

  const addressLines = useMemo(() => {
    let a = address;
    let r = region;
    let p = postcode;
    if (isObject(location)) {
      if (!a && location.address) {
        a = location.address;
      }
      if (!r && location.region) {
        r = location.region;
      }
      if (!p && location.postcode) {
        p = location.postcode;
      }
    }
    let lines = getAddressLines(a, r, p);
    if (!a && !r && !p) {
      lines = ['Address Missing', 'Unknown Region'];
    }
    return lines;
  }, [address, location, postcode, region]);

  // const [overallProgress, statusProps] = useProgressPropsForBindFormStatus(formStatus);
  // const lastUpdated = useMemo(() => {
  //   return moment(approvedAt ? approvedAt : rejectedAt ? rejectedAt : receivedAt ? receivedAt : requestedAt).format('MM-DD-YYYY');
  // }, [approvedAt, rejectedAt, receivedAt, requestedAt]);

  return (
    <Box width="100%">
      <Spacing vertical={3} />
      <Heading level={3} spaceAfter>
        Installation Checklist
      </Heading>
      <Box sx={{ width: '100%', height: 1, bg: '$gray.200' }} />
      <Spacing vertical={2} />
      <Text large weight="$regular" italic>
        Collection of location details is for the following:
      </Text>
      <Spacing vertical={1.5} />
      <Box
        sx={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          bg: '$gray.50',
          borderWidth: 0,
          borderColor: '$gray.100',
          padX: '$2',
          padY: '$2',
        }}
      >
        <Box sx={{ flex: 1.25 }}>
          <Text small bold dim uppercase>
            Insured Location:
          </Text>
          <Spacing vertical={0.75} />
          <Box sx={{ width: '100%', height: 1, bg: '$gray.100' }} />
          <Spacing vertical={0.5} />
          <Text large bold maxLines={1}>
            {locName}
          </Text>
          {addressLines.map((line, i) => (
            <Text medium key={`aline${i}`} maxLines={1}>
              {line}
            </Text>
          ))}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Text small bold dim uppercase>
            Name of Insured:
          </Text>
          <Spacing vertical={0.75} />
          <Box sx={{ width: '100%', height: 1, bg: '$gray.100' }} />
          <Spacing vertical={0.5} />
          <Text large bold>
            {insured}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

/*
return (
    <Box width="100%">
      <Heading level={3} weight="$regular" spaceAfter>Installation Checklist Form</Heading>
      <Spacing vertical={1} />
      <Text dim bold italic>To be completed for the following location under the insured</Text>
      <Spacing vertical={1} />
      <Box sx={{ flexDirection: 'row', alignItems: 'stretch' }}>
        <Box sx={{ width: 92 }}>
          <Text medium>Insured:</Text>
        </Box>
        <Box>
          <Text medium bold>{insured}</Text>
        </Box>
      </Box>
      <Spacing vertical={1} />
      <Box sx={{ flexDirection: 'row', alignItems: 'stretch' }}>
        <Box>
          <Text large bold maxLines={1}>{locName}</Text>
          {addressLines.map((line, i) => (
            <Text large key={`aline${i}`} maxLines={1}>{line}</Text>
          ))}
        </Box>
      </Box>
      
      <Spacing vertical={1.5} />
      <Box sx={{ width: '100%', height: 1, bg: '$gray.200' }} />
      <Spacing vertical={1.5} />
      <Box sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ flexDirection: 'row', alignItems: 'stretch' }}>
          <Box sx={{ width: 92 }}>
            <Text medium>Status:</Text>
          </Box>
          <Chip {...statusProps} />
        </Box>
        {status === 'pending' ? (
            <Box sx={{ flexDirection: 'row' }}>
              <Text small dim>Saving...</Text>
              <Spacing horizontal={0.5} />
              <ActivityIndicator />
            </Box>
          ) : (
            <Text small bold dim>{`Last updated on ${lastUpdated}`}</Text>
        )}
      </Box>
    </Box>
  )
*/

//////////////////////////////////////////////////////

const wrapPageLayout = (Component) => {
  return React.forwardRef(function WrappedStepView(props, ref) {
    const { animation, children, ...rest } = props;
    return (
      <Page row={{ sm: false, md: true }} layout="top-center" animation={animation} ref={ref} {...rest}>
        <Main layout="top-center" padBottom={164} padTop="$5" marginTop={100}>
          <ContentCard padTop="$8" gap="12" shadow={null} borderColor="transparent" padY={0} padX="$4">
            <Component />
          </ContentCard>
        </Main>
      </Page>
    );
  });
};

export const FormPage = wrapPageLayout(FormContent);
