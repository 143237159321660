import React, { useMemo } from 'react';
import { isNull } from '../../../ui/utils';
import { useSearchApiContext, useSearchQueriesContext } from '../contexts';

export const useFilterControls = ({ components }) => {
  const { searchOptions } = useSearchApiContext();
  const [filters, filterKeys] = useMemo(() => {
    const filters = searchOptions && searchOptions.filter ? searchOptions.filter : {};
    const filterKeys = searchOptions && searchOptions.filterKeys ? searchOptions.filterKeys : Object.keys(filters);
    return [filters, filterKeys];
  }, [searchOptions]);
  const { filter } = useSearchQueriesContext();
  const [filterQuery, setFilterQuery] = filter || [null, null];
  const [fieldControls, numSet] = useMemo(() => {
    const controls = [];
    let numSet = 0;
    for (let i = 0; i < filterKeys.length; i += 1) {
      const field = filters[filterKeys[i]];
      if (field.type && components[field.type]) {
        const ControlComponent = components[field.type];
        if (!field.getValue && process.env.NODE_ENV !== 'production') {
          console.warn(
            'Each field for a search option needs a getValue function to filter the query result set down to the value or option of the field if it exists in the query'
          );
        }
        let value = Array.isArray(filterQuery) && field.getValue ? field.getValue(filterQuery, field) : null;
        value = Array.isArray(value) ? (value.length ? value : null) : value;
        let active = false;
        if (!isNull(value)) {
          // if range type filter. getValue for that will return [null, null] on null value
          if (!Array.isArray(value) || !(value.length === 2 && value[0] === null && value[1] === null)) {
            numSet += 1;
            active = true;
          }
        }
        controls.push(<ControlComponent key={`${field.name}${i}`} active={active} field={field} value={value} setQuery={setFilterQuery} />);
      }
    }
    return [controls, numSet];
  }, [filterKeys, filters, filterQuery, setFilterQuery, components]);

  return [fieldControls, numSet];
};

export const useSearchFieldOptions = () => {
  const { searchOptions } = useSearchApiContext();
  const [searchFilters, searchKeys] = useMemo(() => {
    const searchFilters = searchOptions && searchOptions.search ? searchOptions.search : {};
    const searchKeys = searchOptions && searchOptions.searchKeys ? searchOptions.searchKeys : Object.keys(searchFilters);
    return [searchFilters, searchKeys];
  }, [searchOptions]);
  const { search } = useSearchQueriesContext();
  const [searchQuery, setSearchQuery] = search || [null, null];

  const [options, activeFields] = useMemo(() => {
    const options = [];
    const activeFields = [];
    for (let i = 0; i < searchKeys.length; i += 1) {
      const field = searchFilters[searchKeys[i]];
      const option = {
        key: `${field.name}-${i}`,
        name: field.name,
        label: field.label ? field.label : field.name,
        value: '',
        selected: false,
        setQuery: setSearchQuery,
      };
      if (!field.getValue && process.env.NODE_ENV !== 'production') {
        console.warn(
          'Each field for a search option needs a getValue function to filter the query result set down to the value or option of the field if it exists in the query'
        );
      }
      let value = Array.isArray(searchQuery) && field.getValue ? field.getValue(searchQuery, field) : null;
      if (!isNull(value) && typeof value === 'string' && value.trim()) {
        option.value = value;
        option.selected = true; // TODO decide on if defaultValues for search filters make any sense. If not can just rely on value for selected
        activeFields.push(option);
      }
      options.push(option);
    }
    return [options, activeFields];
  }, [searchKeys, searchFilters, searchQuery, setSearchQuery]);

  return [options, activeFields];
};
