import { bindFormItemData } from './bindFormItemData';
import { getSearchOptionsFromDataItem } from '../../utils/searchApi/searchOptionsUtils';
import { quoteItemData } from './quoteItemData';
import { applicationItemData } from './applicationItemData';
import { policyItemData } from './policyItemData';

export const bindFormSearchOptions = getSearchOptionsFromDataItem(bindFormItemData);
export const quoteSearchOptions = getSearchOptionsFromDataItem(quoteItemData);
export const applicationSearchOptions = getSearchOptionsFromDataItem(applicationItemData);
export const policySearchOptions = getSearchOptionsFromDataItem(policyItemData);
