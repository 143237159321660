import * as MapboxGL from 'mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';

const defaultStyleURLs = {
  Dark: 'mapbox://styles/mapbox/dark-v10',
  Light: 'mapbox://styles/mapbox/light-v10',
  Outdoors: 'mapbox://styles/mapbox/outdoors-v11',
  Satellite: 'mapbox://styles/mapbox/satellite-v9',
  SatelliteStreet: 'mapbox://styles/mapbox/satellite-streets-v11',
  Street: 'mapbox://styles/mapbox/streets-v11',
};

class Mapbox {
  _accessToken = null;
  telemetryEnabled = null;
  _customStyleURLs = {};
  defaultImages = null;

  setAccessToken(tokenString, telemetryEnabled = false) {
    this._accessToken = tokenString || this._accessToken;
    MapboxGL.accessToken = this._accessToken;
    // if (this.telemetryEnabled === null) {
    //   this.setTelemetryEnabled(telemetryEnabled);
    // }
  }

  getAccessToken() {
    return this._accessToken;
  }

  setConnected(connected) {
    // MapboxGL.setConnected(connected);
  }

  setTelemetryEnabled(enabled) {
    // this.telemetryEnabled = enabled;
    // MapboxGL.setTelemetryEnabled(enabled);
  }

  async requestAndroidLocationPermissions() {
    // return await MapboxGL.requestAndroidLocationPermissions();
  }

  addStyleURL(styleObj) {
    if (typeof styleObj === 'object') {
      this._customStyleURLs = {
        ...this._customStyleURLs,
        ...styleObj,
      };
    }
  }

  get StyleURL() {
    const defaults = { ...defaultStyleURLs };
    return {
      ...defaults,
      ...this._customStyleURLs,
      defaults,
    };
  }

  setDefaultImages(imagesKeyValueObj) {
    this.defaultImages = {
      ...this.defaultImages,
      ...imagesKeyValueObj,
    };
  }
}

const instance = new Mapbox();

export { instance as Mapbox };
