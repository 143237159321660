import { isObject, isEmpty } from '../utils';
import { aliases } from './aliases';
import { multiples } from './multiples';
import { objects } from './objects';
import { transforms } from './transforms';
import { scales } from './scales';
import { defaults } from './defaults';
import { skip } from './skip';
import { parsers } from './parsers';

const defaultStyleSystemOptions = {
  aliases,
  multiples,
  objects,
  transforms,
  scales,
  defaults,
  skip,
  parsers,
};

function getStyleSystemOptions(overrides, currentOptions) {
  const defaultOpts = isObject(currentOptions) && !isEmpty(currentOptions) ? currentOptions : defaultStyleSystemOptions;

  if (!isObject(overrides) || isEmpty(overrides) || overrides === defaultOpts) {
    return defaultOpts;
  }

  return {
    aliases: { ...defaultOpts.aliases, ...overrides.aliases },
    multiples: { ...defaultOpts.multiples, ...overrides.multiples },
    objects: { ...defaultOpts.objects, ...overrides.objects },
    transforms: { ...defaultOpts.transforms, ...overrides.transforms },
    scales: { ...defaultOpts.scales, ...overrides.scales },
    defaults: { ...defaultOpts.defaults, ...overrides.defaults },
    skip: { ...defaultOpts.skip, ...overrides.skip },
    parsers: { ...defaultOpts.parsers, ...overrides.parsers },
  };
}

export { getStyleSystemOptions, defaultStyleSystemOptions };
