import React from 'react';
import { ActivityIndicator as RNActivityIndicator } from 'react-native';
import { withStyles, animated } from '../../styling';
import { ViewStylePropTypes } from '../../system';

const AnimatedActivityIndicator = animated(RNActivityIndicator);
AnimatedActivityIndicator.displayName = 'animated(ActivityIndicator)';

const ActivityIndicator = withStyles(
  {
    root: ({ size, color = '$primary', ...props }) => {
      const styles = {};
      for (const key in ViewStylePropTypes) {
        if (props[key] !== undefined) {
          styles[key] = props[key];
        }
      }
      styles.color = color;
      return {
        ...styles,
        props: { size },
      };
    },
  },
  { name: 'ActivityIndicator', filterProps: [...Object.keys(ViewStylePropTypes), 'color'], asProp: { color: true } }
)(
  React.forwardRef(function ActivityIndicator(props, ref) {
    const { styles, ...rest } = props;
    return <AnimatedActivityIndicator ref={ref} {...rest} />;
  })
);

export { ActivityIndicator };
