import React, { useContext } from 'react';
import { useMemoCompare } from '../ui/hooks';
import { omit } from '../ui/utils';

const AuthContext = React.createContext({});

const AuthProvider = (props) => {
  const value = useMemoCompare(omit(props, ['children']));
  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
