const sizes = [
  {
    fontSize: 64,
    lineHeight: 72,
  },
  {
    fontSize: 60,
    lineHeight: 64,
  },
  {
    fontSize: 56,
    lineHeight: 60,
  },
  {
    fontSize: 46,
    lineHeight: 48,
  },
  {
    fontSize: 40,
    lineHeight: 44,
  },
  {
    fontSize: 38,
    lineHeight: 40,
  },
  {
    fontSize: 32,
    lineHeight: 36,
  },
  {
    fontSize: 24,
    lineHeight: 32,
  },
  {
    fontSize: 18,
    lineHeight: 28,
  },
  {
    fontSize: 16,
    lineHeight: 28,
  },
  {
    fontSize: 14,
    lineHeight: 20,
  },
  {
    fontSize: 12,
    lineHeight: 16,
  },
  {
    fontSize: 10,
    lineHeight: 16,
  },
];

const text = {
  xLarge: {
    variant: 'text.24',
  },
  large: {
    variant: 'text.18',
  },
  medium: {
    variant: 'text.16',
  },
  small: {
    variant: 'text.14',
  },
  xSmall: {
    variant: 'text.12',
  },
  xxSmall: {
    variant: 'text.10',
  },
  get micro() {
    return this.xxSmall;
  },
  get default() {
    return this.medium;
  },
  h1: {
    large: {
      variant: { xs: 'text.bold.40', sm: 'text.bold.46', md: 'text.bold.64' },
    },
    medium: {
      variant: { xs: 'text.bold.38', sm: 'text.bold.46', md: 'text.bold.56' },
    },
    small: {
      variant: { xs: 'text.bold.38', sm: 'text.bold.46', md: 'text.bold.46' },
    },
    xSmall: {
      variant: 'text.bold.38',
    },
    get default() {
      return this.medium;
    },
  },
  h2: {
    large: {
      variant: { xs: 'text.bold.32', sm: 'text.bold.40', md: 'text.bold.60' },
    },
    medium: {
      variant: { xs: 'text.bold.32', sm: 'text.bold.38', md: 'text.bold.46' },
    },
    small: {
      variant: { xs: 'text.bold.32', sm: 'text.bold.40', md: 'text.bold.40' },
    },
    xSmall: {
      variant: 'text.bold.32',
    },
    get default() {
      return this.medium;
    },
  },
  h3: {
    large: {
      variant: 'text.bold.32',
    },
    small: {
      variant: 'text.bold.24',
    },
    auto: {
      variant: { xs: 'text.bold.24', sm: 'text.bold.32' },
    },
    get medium() {
      return this.large;
    },
    get default() {
      return this.auto;
    },
  },
  h4: {
    large: {
      variant: 'text.bold.24',
    },
    small: {
      variant: 'text.bold.18',
    },
    auto: {
      variant: { xs: 'text.bold.18', sm: 'text.bold.24' },
    },
    get medium() {
      return this.large;
    },
    get default() {
      return this.auto;
    },
  },
  h5: {
    large: {
      variant: 'text.bold.18',
    },
    small: {
      variant: 'text.bold.16',
    },
    auto: {
      variant: { xs: 'text.bold.16', sm: 'text.bold.18' },
    },
    get medium() {
      return this.large;
    },
    get default() {
      return this.auto;
    },
  },
  h6: {
    large: {
      variant: 'text.bold.16',
    },
    small: {
      variant: 'text.bold.14',
    },
    auto: {
      variant: { xs: 'text.bold.14', sm: 'text.bold.16' },
    },
    get medium() {
      return this.large;
    },
    get default() {
      return this.auto;
    },
  },
  overline: {
    xLarge: {
      variant: { xs: 'text.bold.14', sm: 'text.bold.16' },
      textTransform: 'uppercase',
      letterSpacing: 0.5,
    },
    large: {
      variant: { xs: 'text.bold.12', sm: 'text.bold.14' },
      textTransform: 'uppercase',
      letterSpacing: 0.5,
    },
    medium: {
      variant: { xs: 'text.bold.10', sm: 'text.bold.12' },
      textTransform: 'uppercase',
      letterSpacing: 0.5,
    },
    small: {
      variant: 'text.bold.10',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
    },
    get default() {
      return this.medium;
    },
  },
};

text.subheading = {
  large: {
    variant: { xs: 'text.24', md: 'text.32' },
  },
  medium: {
    variant: { xs: 'text.18', sm: 'text.24', md: 'text.32' },
  },
  small: {
    variant: { xs: 'text.18', sm: 'text.24' },
  },
};

text.h1.subheading = {
  large: {
    variant: { xs: 'text.24', md: 'text.32' },
  },
  medium: {
    variant: { xs: 'text.18', sm: 'text.24', md: 'text.32' },
  },
  small: {
    variant: { xs: 'text.18', sm: 'text.24' },
  },
  get default() {
    return this.large;
  },
};

text.h1.body = {
  large: {
    variant: { xs: 'text.18', md: 'text.24' },
  },
  get medium() {
    return this.small;
  },
  small: {
    variant: 'text.18',
  },
  get default() {
    return this.large;
  },
};

text.h2.subheading = {
  large: {
    variant: { xs: 'text.18', sm: 'text.24', md: 'text.32' },
  },
  get medium() {
    return this.small;
  },
  small: {
    variant: { xs: 'text.18', sm: 'text.24' },
  },
  get default() {
    return this.small;
  },
};

text.h2.body = {
  large: {
    variant: { xs: 'text.16', sm: 'text.18', md: 'text.24' },
  },
  medium: {
    variant: { xs: 'text.16', sm: 'text.18' },
  },
  small: {
    variant: 'text.16',
  },
  get default() {
    return this.medium;
  },
};

// map and export defined fontSizes, lineHeights, and text variants
text.bold = {};
const fontSizes = {};
const lineHeights = {};

for (let i = 0; i < sizes.length; i += 1) {
  const { fontSize, lineHeight } = sizes[i];
  text[fontSize] = {
    fontSize: [`${fontSize}`],
    lineHeight: [`${lineHeight}`],
    fontFamily: ['default'],
    fontWeight: ['default'],
  };
  text.bold[fontSize] = {
    fontSize: [`${fontSize}`],
    lineHeight: [`${lineHeight}`],
    fontWeight: ['bold'],
    fontFamily: ['bold'],
  };
  fontSizes[fontSize] = fontSize;
  lineHeights[lineHeight] = lineHeight;
}

export { text, fontSizes, lineHeights };
