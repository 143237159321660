import { isValidPhoneNumber } from 'libphonenumber-js';
import { validate } from 'isemail';

export const inputTests = {
  email(input = '') {
    // eslint-disable-next-line
    if (typeof input !== 'string' || !input.trim()) return false;
    try {
      const valid = validate(input);
      return valid ? true : false;
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(err);
      }
      return false;
    }
    // if (!/^([a-zA-Z0-9_+\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(input)) {
    //   return false;
    // }
    // return true;
  },
  number(input = '') {
    if (typeof input === 'number') return true;
    if (typeof input !== 'string' || !input.trim()) return false;
    if (!/^([1-9]\d*|0)(\.\d+)?$/g.test(input)) {
      return false;
    }
    return true;
  },
  // nonZeroNumberAmount(input) {
  //   if (!/^([1-9]\d*|0)(\.\d+)?$/g.test(input)) {
  //     throw INVALID_NUMBER;
  //   }
  //   if (input === '0' || !input.length) {
  //     throw NO_AMOUNT;
  //   }
  // },
  phone(input = '', country = 'US') {
    if (typeof input !== 'string' || !input.trim()) return false;
    try {
      if (!isValidPhoneNumber(input, country)) {
        return false;
      }
      return true;
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(err);
      }
      return false;
    }
    // if (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/g.test(input)) {
    //   return false;
    // }
    // return true;
  },
  notEmpty(input = '') {
    if (input === undefined || input === null) {
      return false;
    }
    if (!input.replace(/\s/g, '').length) {
      return false;
    }
    return true;
  },
  filled(input) {
    return inputTests.notEmpty(input);
  },
  required(input) {
    return inputTests.notEmpty(input);
  },
};
