import React from 'react';
import { Box } from '../../ui';
import { isNull } from '../../ui/utils';
import { withStyles } from '../../ui/styling';

export const CloseIcon = withStyles(
  ({ theme, size = 24, width, color = 'black', onColor, height }) => {
    const w = isNull(width) ? size : width;
    const h = isNull(height) ? size : height;
    const c = onColor ? onColor : color ? color : 'black';
    return {
      root: {
        width: w,
        height: h,
        justifyContent: 'center',
        alignItems: 'center',
      },
      rotateContainer: {
        width: '90%',
        height: '90%',
        rotate: '45deg',
        justifyContent: 'center',
        alignItems: 'center',
      },
      lineContainer: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
      },
      line1: {
        height: 2,
        width: '100%',
        borderRadius: 2,
        backgroundColor: c,
      },
      line2: {
        height: '100%',
        width: 2,
        borderRadius: 2,
        backgroundColor: c,
      },
    };
  },
  { filterProps: ['size', 'width', 'height', 'color', 'onColor'] }
)(
  React.forwardRef(function CloseIcon(props, ref) {
    const { styles } = props;
    return (
      <Box ref={ref} {...props}>
        <Box style={styles.rotateContainer}>
          <Box style={styles.lineContainer}>
            <Box style={styles.line1} />
          </Box>
          <Box style={styles.lineContainer}>
            <Box style={styles.line2} />
          </Box>
        </Box>
      </Box>
    );
  })
);
