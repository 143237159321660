import React from 'react';
import { withStyles } from '../ui/styling';
import { Box, Modal, Heading, Text, Spacing } from '../ui';

const CloseIcon = (props) => {
  return (
    <Box focusable justifyContent="center" alignItems="center" size={31} borderRadius="$circle" borderWidth="1" borderColor="$gray.200" {...props}>
      <Box position="absolute" width="100%" height="100%" justifyContent="center" alignItems="center">
        <Box width="70%" height={1} bg="$gray.300" rotate={'45deg'} />
      </Box>
      <Box position="absolute" width="100%" height="100%" justifyContent="center" alignItems="center">
        <Box width="70%" height={1} bg="$gray.300" rotate={'-45deg'} />
      </Box>
    </Box>
  );
};

export const Dialog = withStyles({
  root: {},
  dialogBox: {
    width: 500,
    maxWidth: '96%',
    bg: '$white',
    borderRadius: 6,
    padX: '$4',
    padY: '$4',
  },
  exitBar: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    padBottom: '$1',
  },
  content: {
    width: '100%',
    padTop: '$1',
    padBottom: '$2',
    border: {
      bottom: {
        width: 1,
        color: '$gray.200',
      },
    },
  },
  actionBar: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padTop: '$1.75',
  },
})(
  React.forwardRef(function Dialog(props, ref) {
    const {
      heading,
      prompt,
      actions,
      children,
      styles,
      hideExitButton = false,
      // modal props,
      open,
      onClose,
      ...rest
    } = props;

    return (
      <Modal ref={ref} open={open} onClose={onClose} scrollable {...rest}>
        <Box style={styles.dialogBox} {...styles.props.dialogBox}>
          {!hideExitButton && (
            <Box style={styles.exitBar} {...styles.props.exitBar}>
              <CloseIcon onPress={onClose} marginLeft="-4" />
            </Box>
          )}
          <Box style={styles.content} {...styles.props.content}>
            {heading ? (
              <Heading level={4} padBottom="$0.5">
                {heading}
              </Heading>
            ) : prompt ? (
              <Spacing vertical={1.5} />
            ) : null}
            {prompt && <Text padBottom="$1.5">{prompt}</Text>}
            {children}
          </Box>
          <Box style={styles.actionBar} {...styles.props.actionBar}>
            {actions}
          </Box>
        </Box>
      </Modal>
    );
  })
);
