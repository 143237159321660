import React from 'react';
import { withStyles } from '../../../../ui/styling';
import { StarIcon } from '../../../../components/icons';
import { Box, Heading } from '../../../../ui';
import { Chip } from '../../../../components';
import { Colors } from '../../../../constants';
import moment from 'moment-timezone';

export const QuoteNav = withStyles(({ theme }) => ({
  root: {},
  orderDetails: {
    marginBottom: '$40',
  },
  orderDetailsCard: {
    marginBottom: '$24',
    padding: '$32',
  },
  orderDiscRow: {
    display: 'flex',
    gap: '$8',
    alignItems: 'flex-start',
    marginBottom: '$16',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    marginBottom: 8,
  },
  orderPointersList: {
    color: '#919EAB',
    fontSize: 14,
    lineHeight: 22,
    fontWeight: 700,
    display: 'flex',
    rowGap: 6,
    columnGap: 16,
    flexWrap: 'wrap',
  },
}))(
  React.forwardRef(function QuoteNav(props, ref) {
    const { styles, data, expiration, historyDisplayText } = props;
    const { insured, product, quoteNumber } = data;

    return (
      <Box>
        <Box style={styles.titleContainer} flexDirection="row">
          <Box>
            <StarIcon />
          </Box>
          <Heading level={3}>{insured}</Heading>
          <Chip color={Colors.quoteStatus[data.status] || '$gray.100'}>{data.status}</Chip>
        </Box>
        <Box style={styles.orderPointersList} flexDirection="row" justifyContent="space-between">
          <span style={{ color: '#212B36 ' }}>Effective {moment.utc(data.coverageAt).format('MMM D, YYYY')}</span>

          <li>{expiration}</li>

          <li>Quote No. {quoteNumber}</li>

          <li>{historyDisplayText}</li>

          <li>{product.name}</li>
        </Box>
      </Box>
    );
  })
);
