import React from 'react';
import { Box } from '../Box';
import { withStyles } from '../../styling';

const FormGroup = withStyles(
  {
    root: ({ row = false, flexDirection }) => ({
      flexDirection: flexDirection || (row ? 'row' : 'column'),
      flexWrap: 'wrap',
    }),
  },
  { name: 'FormGroup', filterProps: ['row', 'flexDirection'] }
)(
  React.forwardRef(function FormGroup(props, ref) {
    const { styles, ...rest } = props;
    return <Box ref={ref} {...rest} />;
  })
);

export { FormGroup };
