import React, { useState, useMemo, useRef, useEffect } from 'react';
import { IconButton, Radio, RadioGroup, Text } from '../../../ui';
import { isNull } from '../../../ui/utils';
import { ReviewCard, ReviewCardContentList, ReviewItem, Section } from './components';
import { roofPitchOptions, roofAccessOptions } from '../constants';
import { useApi } from '../../../hooks';
import { useSiteChecklistContext } from '../context';
import { CollapseArrowIcon, LockIcon } from '../../../components/icons';

export const RequirementsData = React.forwardRef((props, ref) => {
  const { editing, setEditing, ...rest } = props;
  const { editingEnabled, data, submitRequest, status, errors, bindForm, updateData, internalViewer } = useSiteChecklistContext();
  const open = editing === 'requirements-data' && editingEnabled;
  const { submitDetailsOnBindingForm } = useApi();

  const submit = useMemo(() => {
    return (inputs = {}) => {
      return submitRequest(async () => {
        const { roofPitchIsOverLimit, roofIsCorrugated, roofHeightIsOverLimit, roofAccessibleWith } = inputs;
        const errors = {
          roofPitchIsOverLimit: false,
          roofIsCorrugated: false,
          roofHeightIsOverLimit: false,
          roofAccessibleWith: false,
        };
        if (roofAccessibleWith === null) {
          errors.roofAccessibleWith = 'Please select a response';
        }
        if (roofIsCorrugated === null) {
          errors.roofIsCorrugated = 'Required';
        }
        if (roofHeightIsOverLimit === null) {
          errors.roofHeightIsOverLimit = 'Required';
        }
        if (roofPitchIsOverLimit === null) {
          errors.roofPitchIsOverLimit = 'Required';
          throw errors;
        } else if (roofIsCorrugated === null || roofHeightIsOverLimit === null) {
          if (roofPitchIsOverLimit !== true) {
            throw errors;
          }
        }
        await submitDetailsOnBindingForm(
          {
            ...data,
            roofPitchIsOverLimit,
            roofIsCorrugated,
            roofHeightIsOverLimit,
            roofAccessibleWith,
          },
          bindForm,
          internalViewer
        );
        await updateData();
        setEditing(false);
      });
    };
  }, [data, submitRequest, submitDetailsOnBindingForm, setEditing, internalViewer, updateData, bindForm]);

  const {
    roofPitchIsOverLimit,
    roofIsCorrugated,
    roofHeightIsOverLimit,
    roofAccessibleWith,
    groundDeployment,
    roofDeployment,
    lat,
    lon,
    locationDescription,
  } = data;
  const heading = 'Roof and Building Details';
  const [pitch, setPitch] = useState(roofPitchIsOverLimit);
  const [corrugated, setCorrugated] = useState(roofIsCorrugated);
  const [height, setHeight] = useState(roofHeightIsOverLimit);
  const [roofAccess, setRoofAccess] = useState(roofAccessibleWith);

  const lastData = useRef({ roofPitchIsOverLimit, roofIsCorrugated, roofHeightIsOverLimit, roofAccessibleWith });
  useEffect(() => {
    if (roofPitchIsOverLimit !== lastData.current.roofPitchIsOverLimit) {
      setPitch(roofPitchIsOverLimit);
    }
    if (roofIsCorrugated !== lastData.current.roofIsCorrugated) {
      setCorrugated(roofIsCorrugated);
    }
    if (roofHeightIsOverLimit !== lastData.current.roofHeightIsOverLimit) {
      setHeight(roofHeightIsOverLimit);
    }
    if (roofAccessibleWith !== lastData.current.roofAccessibleWith) {
      setRoofAccess(roofAccessibleWith);
    }
    lastData.current = { roofPitchIsOverLimit, roofIsCorrugated, roofHeightIsOverLimit, roofAccessibleWith };
  }, [roofPitchIsOverLimit, roofIsCorrugated, roofHeightIsOverLimit, roofAccessibleWith]);

  useEffect(() => {
    if (!open) {
      setPitch(lastData.current.roofPitchIsOverLimit);
      setCorrugated(lastData.current.roofIsCorrugated);
      setHeight(lastData.current.roofHeightIsOverLimit);
      setRoofAccess(lastData.current.roofAccessibleWith);
    }
  }, [open]);

  const locationDetailItems = useMemo(() => {
    let items = [];
    let slopeValue = null;
    let roofAccessValue = null;
    for (const rp of roofPitchOptions) {
      if (rp.value === roofPitchIsOverLimit) {
        slopeValue = rp.label;
        break;
      }
    }
    for (const opt of roofAccessOptions) {
      if (opt.value === roofAccessibleWith) {
        roofAccessValue = opt.label;
        break;
      }
    }
    items = [
      {
        heading: 'Slope of Roof',
        value: slopeValue,
      },
      {
        heading: 'Roof Corrugated',
        value: !isNull(roofIsCorrugated) ? (roofIsCorrugated ? 'Yes' : 'No') : null,
      },
      {
        heading: 'Height of building',
        value: !isNull(roofHeightIsOverLimit) ? (roofHeightIsOverLimit ? 'More than two stories' : 'Less than two stories') : null,
      },
      {
        heading: 'Roof Access',
        value: roofAccessValue,
      },
    ];
    return items;
  }, [roofPitchIsOverLimit, roofIsCorrugated, roofHeightIsOverLimit, roofAccessibleWith]);

  const notApplicable = !groundDeployment && !roofDeployment;
  // const disabled = !internalViewer && (notApplicable || (formStatus.value === 'not-started' || (!lat && !lon && !locationDescription)));
  const disabled = !internalViewer && (notApplicable || (!lat && !lon && !locationDescription));
  const sectionNotStarted =
    roofPitchIsOverLimit === null && roofIsCorrugated === null && roofHeightIsOverLimit === null && roofAccessibleWith === null;

  if (groundDeployment) {
    return null;
  }
  return (
    <ReviewCard
      heading={heading}
      subheading={internalViewer && notApplicable ? 'NOTE: Not applicable to current deployment type.' : undefined}
      ref={ref}
      opacity={notApplicable && !internalViewer ? 0.5 : disabled ? 0.62 : !editingEnabled || !editing || open ? 1 : 0.77}
      ActionComponent={open && editingEnabled && !disabled ? IconButton : disabled && !notApplicable ? IconButton : undefined}
      headerAction={
        !open && editingEnabled && !disabled
          ? {
              label: sectionNotStarted && !internalViewer ? 'Start' : 'Edit',
              onPress: () => setEditing('requirements-data'),
            }
          : open && editingEnabled && !disabled
          ? {
              children: <CollapseArrowIcon />,
              onPress: () => setEditing(false),
            }
          : notApplicable
          ? {
              type: 'text',
              color: '$gray.300',
              disabled: true,
              label: 'Does not apply',
            }
          : disabled
          ? {
              children: <LockIcon />,
              color: '$gray.300',
              disabled: true,
            }
          : undefined
      }
      footerActions={
        open &&
        editingEnabled &&
        !disabled && {
          right: [
            {
              label: 'Save',
              onPress: () => {
                submit({
                  roofPitchIsOverLimit: pitch,
                  roofIsCorrugated: corrugated,
                  roofHeightIsOverLimit: height,
                  roofAccessibleWith: roofAccess,
                });
              },
              disabled: status === 'pending',
              loading: status === 'pending',
            },
          ],
        }
      }
      {...rest}
    >
      {open && !disabled ? (
        <>
          <Section heading="Is the roof steeper than 5 degrees?" size="xSmall">
            {errors && errors.roofPitchIsOverLimit ? (
              <Text color="$error" bold small>
                {errors.roofPitchIsOverLimit}
              </Text>
            ) : null}
            <RadioGroup
              name="roofPitchIsOverLimit"
              value={pitch}
              onChange={(v) => setPitch(v)}
              alignSelf="flex-start"
              alignItems="flex-start"
              padLeft="$0.5"
            >
              {roofPitchOptions.map((opt, i) => (
                <Radio key={`pitchOpt${i}`} value={opt.value} label={opt.label} labelProps={{ bold: true }} />
              ))}
            </RadioGroup>
          </Section>
          <Section heading="Is the roof corrugated?" size="xSmall" padTop="$4">
            {errors && errors.roofIsCorrugated ? (
              <Text color="$error" bold small>
                {errors.roofIsCorrugated}
              </Text>
            ) : null}
            <RadioGroup
              name="corrugated"
              value={corrugated}
              onChange={(v) => setCorrugated(v)}
              alignSelf="flex-start"
              alignItems="center"
              padLeft="$0.5"
              row
            >
              <Radio value={true} label="Yes" labelProps={{ bold: true }} />
              <Radio value={false} label="No" labelProps={{ bold: true }} />
            </RadioGroup>
          </Section>
          <Section heading="Is the building of this roof more than two stories tall?" size="xSmall" padTop="$4">
            {errors && errors.roofHeightIsOverLimit ? (
              <Text color="$error" bold small>
                {errors.roofHeightIsOverLimit}
              </Text>
            ) : null}
            <RadioGroup
              name="roofHeightIsOverLimit"
              value={height}
              onChange={(v) => setHeight(v)}
              alignSelf="flex-start"
              alignItems="center"
              padLeft="$0.5"
              row
            >
              <Radio value={true} label="Yes" labelProps={{ bold: true }} />
              <Radio value={false} label="No" labelProps={{ bold: true }} />
            </RadioGroup>
          </Section>
          <Section heading="How is the roof accessed?" padTop="$6" size="small" maxWidth={400}>
            {errors && errors.roofAccessibleWith ? (
              <Text color="$error" bold small>
                {errors.roofAccessibleWith}
              </Text>
            ) : null}
            <RadioGroup
              name="roofAccess"
              value={roofAccess}
              onChange={(v) => setRoofAccess(v)}
              alignSelf="flex-start"
              alignItems="flex-start"
              padLeft="$1.5"
            >
              {roofAccessOptions.map(({ value, label }) => (
                <Radio value={value} label={label} key={`${value}${label}`} labelProps={{ bold: true }} />
              ))}
            </RadioGroup>
          </Section>
        </>
      ) : !disabled ? (
        <ReviewCardContentList>
          {locationDetailItems.length
            ? locationDetailItems.map(({ heading, value }, i) => (
                <ReviewItem key={`ldeti${i}`} heading={heading}>
                  <Text bold={value ? true : false}>{value ? value : 'No information provided'}</Text>
                </ReviewItem>
              ))
            : null}
        </ReviewCardContentList>
      ) : null}
    </ReviewCard>
  );
});
