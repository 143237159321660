import React, { useRef, useMemo } from 'react';
import moment from 'moment-timezone';
import { Box } from '../Box';
import { Month } from './Month';
import { MONTHS } from './constants';

const MonthSelect = React.forwardRef(function MonthSelect(props, ref) {
  const {
    date: selectedDate,
    month: focusedMonth,
    viewHeight,
    rowWidth,
    rowPadding,
    columnPadding,
    onChange,
    minDate,
    maxDate,
    renderMonth,
    isDateDisabled,
    disableHighlightTodaysMonth = false,
    ...rest
  } = props;

  const todaysMonthRef = useRef(moment().format('YYYYMM'));
  const todaysMonth = todaysMonthRef.current;
  const selectedMonth = selectedDate ? selectedDate.format('YYYYMM') : focusedMonth;

  const year = useMemo(() => {
    return focusedMonth.substring(0, 4);
  }, [focusedMonth]);

  const minMonth = minDate ? minDate.format('YYYYMM') : null;
  const maxMonth = maxDate ? maxDate.format('YYYYMM') : null;

  const monthSize = useMemo(() => {
    const dayViewHeight = (rowWidth / 7 + rowPadding * 2) * 7;
    const width = Math.floor(rowWidth / 3 - columnPadding * 2);
    const height = dayViewHeight / 4 - rowPadding * 8;
    return {
      height,
      width,
      marginX: columnPadding,
      marginY: rowPadding * 4,
    };
  }, [rowWidth, rowPadding, columnPadding]);

  return (
    <Box flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="flex-start" width={rowWidth} maxHeight={viewHeight} {...rest}>
      {MONTHS.map((monthNum, j) => {
        const month = `${year}${monthNum}`;
        let disabled = false;
        if (isDateDisabled) {
          disabled = isDateDisabled(month);
        } else {
          if (minMonth && month < minMonth) {
            disabled = true;
          }
          if (!disabled && maxMonth && month > maxMonth) {
            disabled = true;
          }
        }
        const monthProps = {
          key: `month${monthNum}`,
          month,
          selected: month === selectedMonth,
          focused: month === focusedMonth, // focused state defaults to selected for now
          disabled,
          minDate,
          maxDate,
          isTodaysMonth: month === todaysMonth,
          disableHighlightTodaysMonth,
          onSelect: onChange,
          ...monthSize,
        };
        if (renderMonth) {
          return renderMonth(month, selectedDate, monthProps);
        }
        return <Month {...monthProps} />;
      })}
    </Box>
  );
});

export { MonthSelect };
